<div class="wrapper">
  <div class="tristate-toggle">
    <button matButton
            class="checked"
            [ngClass]="status === 'checked' ? ['checked-on'] : ['checked-off']"
            type="button"
            (click)="status = 'checked'">
      <mat-icon class="rem-16">{{ iconChecked }}</mat-icon>
    </button>

    <button matButton
            class="none"
            [ngClass]="status === '' ? ['none-on'] : ['none-off']"
            (click)="status = ''">
      <mat-icon class="rem-16">{{ iconNone }}</mat-icon>
    </button>

    <button matButton
            class="unchecked"
            [ngClass]="status === 'unchecked' ? ['unchecked-on'] : ['unchecked-off']"
            (click)="status = 'unchecked'">
      <mat-icon class="rem-16">{{ iconUnchecked }}</mat-icon>
    </button>
  </div>
</div>
