//#region ng
import {
  Component,
  EventEmitter,
  effect,
  signal
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import { get } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  first,
  finalize,
  takeUntil,
  switchMap,
  map,
  filter,
  debounceTime,
  distinctUntilChanged,
  // filter
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  // obsSeparacao: string;
  searchTerm: string;
  showInfo: boolean;
  splitVertical: boolean;
  tab: TPedidoTab;
  viewStyle: TCorViewStyle;
};
type TPedidoTab = 'status' | 'totais' | 'detalhes';
import {
  ILoja,
  IPedido
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import { TCorViewStyle } from '../../_shared/_core/_misc/_models/_types';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  CarrinhoService,
  LojasService,
  PedidosSeparacaoService,
  PedidosService
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  AppService,
  StorageService
} from '../../services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.page.html',
  styleUrls: ['./pedido.page.scss']
})
export class PedidoPage {
  //#region actions
  showInfoAction$ = signal<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(true)).asReadonly();
  private _destroyAction$: Subject<void> = onDestroy();
  searchTermAction$ = signal<BehaviorSubject<string>>(new BehaviorSubject<string>('')).asReadonly();
  splitVerticalAction$ = signal<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(this._storageServ.splitVerticalGet())).asReadonly();
  viewStyleAction$ = signal<BehaviorSubject<TCorViewStyle>>(new BehaviorSubject<TCorViewStyle>(this._storageServ.separandoViewstyleGet())).asReadonly();
  // obsSeparacaoAction$ = signal<BehaviorSubject<string>>(new BehaviorSubject<string>('')).asReadonly();
  tabPedidoAction$ = signal<BehaviorSubject<TPedidoTab>>(new BehaviorSubject<TPedidoTab>('status')).asReadonly();
  //#endregion

  //#region publics
  loja = signal<ILoja>(null);
  // lojaPath = signal<string>('');
  obsSeparacao: string = '';
  pedido = signal<IPedido>(null);
  // obsSeparacao = effect<string>(
  //   () => {
  //     const PEDIDO: IPedido = this.pedido() || null;
  //     return PEDIDO?.obsSeparacao || '';
  //   }
  // );
  viewStyle = signal<TCorViewStyle>(this._storageServ.separandoViewstyleGet());
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region viewStyle
  // private _viewStyle: TCorViewStyle;
  // set viewStyle(val: TCorViewStyle) {
  //   this._viewStyle = val;
  //   // console.log(val);
  //   this._storageServ.separandoViewstyleSet(val);
  // }
  // get viewStyle(): TCorViewStyle {
  //   // const QTDE_ITENS: number = Number(get(this.pedido, 'carrinho.__solicitado.qtde.itens.total')) || 0;
  //   // console.log(QTDE_ITENS);
  //   return this._viewStyle;
  // }
  //#endregion

  //#region privates
  private _pedidoUnsub: FbUnsubscribe;
  private _vm: IVm;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region constructor
  constructor(
    // private _departamentosServ: DepartamentosService,
    // private _bannersServ: BannersService,
    private _carrinhoServ: CarrinhoService,
    private _db: Firestore,
    private _loaderServ: CorLoaderService,
    private _lojasServ: LojasService,
    private _lojasStore: LojasStore,
    // private _produtosServ: ProdutosService,
    private _pedidosServ: PedidosService,
    private _pedidosSeparacaoServ: PedidosSeparacaoService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _storageServ: StorageService,
  ) {
    effect(
      () => {
        const PEDIDO: IPedido = this.pedido() || null;
        this.obsSeparacao = PEDIDO?.obsSeparacao || '';
      }
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // this.searchTermAction$()
    //   .pipe(
    //     debounceTime(300),
    //     distinctUntilChanged(),
    //     switchMap((searchTerm: string) => { this._refresh(searchTerm); return of(null); }),
    //     takeUntil(this._destroyAction$)
    //   )
    //   .subscribe();

    combineLatest([
      this._route.paramMap,
      this._lojasStore.lojaStateChanged$,
    ])
      .pipe(takeUntil(this._destroyAction$))
      .subscribe(([params, loja]) => {
        // console.log(params, loja);
        // this.lojaPath.set(loja?.__idInfo?.lojaPath || '');
        const LOJA: ILoja = !!loja ? this._lojasServ.fix(loja) : null;
        const LOJA_PATH: string = LOJA?.__idInfo?.lojaPath || '';
        this.loja.set(LOJA);
        const PEDIDO_ID: string = params['params']?.id || '';
        // console.log(PEDIDO_ID, this._lojaPath);
        if (!!LOJA_PATH && !!PEDIDO_ID) {
          const PATH: string = `${LOJA_PATH}/pedidos/${PEDIDO_ID}`;
          // console.log(PATH);
          !!this._pedidoUnsub && this._pedidoUnsub();
          this._pedidoUnsub = onSnapshot(
            doc(this._db, PATH),
            snap => {
              // console.log(snap);
              this.pedido.set(
                !!snap.data()
                  ? this._pedidosServ.fix(
                    {
                      id: snap.id,
                      ...snap.data()
                    } as IPedido
                  )
                  : null
              );
              this._focus();
              // this._pedidosServ.setState(PEDIDO);
              // !!this.pedido && this._router.navigateByUrl(HOME_ROUTE);
            }
          );
        } // if
      });

    this.vm$.set(
      combineLatest([
        this.tabPedidoAction$(),
        this.showInfoAction$(),
        this.searchTermAction$(),
        this.splitVerticalAction$(),
        this.viewStyleAction$(),
      ])
        .pipe(
          map(([tab, showInfo, searchTerm, splitVertical, viewStyle]) => {
            const VIEW_STYLE: TCorViewStyle = viewStyle || 'cards';
            this._storageServ.splitVerticalSet(!!splitVertical);
            this._storageServ.separandoViewstyleSet(VIEW_STYLE);
            this._vm = {
              tab,
              showInfo,
              searchTerm,
              // obsSeparacao
              splitVertical,
              viewStyle: VIEW_STYLE,
            };
            // console.log(this._vm);
            return this._vm;
          })
        )
    );
  }

  ngAfterViewInit() {
    this._focus();
  }

  ngOnDestroy(): void {
    !!this._pedidoUnsub && this._pedidoUnsub();
  }
  //#endregion

  //#region functions
  private _focus() {
    setTimeout(
      () => this.searchFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onIniciarSeparacaoClick() {
    // console.log(p);
    !!this.pedido()?.obsSeparacao && this.onGravaObsSeparacaoClick();
    !!this.pedido() && AppService.pedidoIniciaSeparacao$.emit(this.pedido());
  }

  onRelatorioSeparadoClick() {
    !!this.pedido() && AppService.relatorioSeparado$.emit(this.pedido());
  }

  onConcluirSeparacaoClick() {
    // console.log(p);
    !!this.pedido() && AppService.pedidoConcluirSeparacao$.emit(this.pedido());
  }

  onCancelarPedidoClick() {
    // console.log(p);
    !!this.pedido() && AppService.pedidoCancelarPedido$.emit(this.pedido());
  }

  onConcluirPedidoClick() {
    // console.log(p);
    !!this.pedido() && AppService.pedidoConcluirPedido$.emit(this.pedido());
  }

  // onRelatorioModificacoesClick() {
  //   // console.log(p);
  //   !!this.pedido() && AppService.relatorioModificacoes$.emit(this.pedido());
  // }

  onRelatorioSeparacaoClick() {
    // console.log(p);
    !!this.pedido() && AppService.relatorioSeparacao$.emit(this.pedido());
  }

  onGravaObsSeparacaoClick() {
    // console.log(this.pedido);
    const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
    if (!!LOJA_PATH && !!this.pedido()?.id) {
      const CHANGES: Partial<IPedido> = { obsSeparacao: this.obsSeparacao || '' };
      // console.log(JSON.stringify(CHANGES));

      const SUB: Subscription = this._loaderServ.showUntilCompleted(
        this._pedidosServ.update(
          LOJA_PATH,
          CHANGES,
          this.pedido()?.id || ''
        )
          .pipe(first(), finalize(() => SUB.unsubscribe()))
      )
        .subscribe(
          async () => {
            this._loaderServ.lstop();
            // this._relatorioSeparacao(p, this.conta?.__nomeCompleto || '');
            this._snackBar.open('Obs pedido atualizada.', '');
          }
        );
    } // if
  }

  onRefreshClick() {
    const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
    // console.log(this.pedido()?.id, LOJA_PATH);
    if (!!LOJA_PATH && !!this.pedido()?.id) {
      const SUB: Subscription = this._loaderServ.showUntilCompleted(
        this._pedidosSeparacaoServ.refresh(
          LOJA_PATH,
          this.pedido()?.id || ''
        )
          .pipe(first(), finalize(() => SUB.unsubscribe()))
      )
        .subscribe(
          async () => {
            this._loaderServ.lstop();
            // this._relatorioSeparacao(p, this.conta?.__nomeCompleto || '');
            this._snackBar.open('Pedido atualizado.', '');
          }
        );
    } // if 
  }

  onViewStyleClick(viewStyle: TCorViewStyle) {
    // console.log(viewStyle);
    this.viewStyleAction$().next(viewStyle)
  }
  //#endregion



  /*
  //#region publics
  carrinho: ICarrinhoMap;
  lojaPath: string;
  tabPedido: TPedidoTab;
  tabItens: TPedidoStatusItens;
  search: string;
  //#endregion

  //#region pedido
  private _pedido: IPedido;
  set pedido(val: IPedido) {
    this._pedido = !!val ? this._pedidosServ.fix(val) : null;
    this.carrinho = !!val?.carrinho ? this._carrinhoServ.fix(val?.carrinho) : null;
  }
  get pedido(): IPedido {
    return this._pedido;
  }
  //#endregion

  //#region showInfo
  private _showInfo: boolean;
  set showInfo(val: boolean) {
    this._showInfo = val;
    // console.log(val);
    this._storageServ.showSidebarSet(val);
  }
  get showInfo(): boolean {
    return this._showInfo;
  }
  //#endregion

  //#region viewStyle
  private _viewStyle: TCorViewStyle;
  set viewStyle(val: TCorViewStyle) {
    this._viewStyle = val;
    // console.log(val);
    this._storageServ.separandoViewstyleSet(val);
  }
  get viewStyle(): TCorViewStyle {
    // const QTDE_ITENS: number = Number(get(this.pedido, 'carrinho.__solicitado.qtde.itens.total')) || 0;
    // console.log(QTDE_ITENS);
    return this._viewStyle;
  }
  //#endregion

  //#region splitVertical
  private _splitVertical: boolean;
  set splitVertical(val: boolean) {
    this._splitVertical = val;
    // console.log(val);
    this._storageServ.splitVerticalSet(!!val);
  }
  get splitVertical(): boolean {
    return this._splitVertical;
  }
  //#endregion

  //#region pedidoId
  private _pedidoId: string;
  set pedidoId(val: string) {
    this._pedidoId = val;
    // console.log(val);
    this._refresh();
  }
  get pedidoId(): string {
    return this._pedidoId;
  }
  //#endregion

  //#region loja
  private _loja: ILoja;
  set loja(val: ILoja) {
    this._loja = !!val ? this._lojasServ.fix(val) : null;
    // console.log(val);
    this._refresh();
  }
  get loja(): ILoja {
    return this._loja;
  }
  //#endregion

  //#region privates
  private _subs: Subscription[] = [];
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this._subs.push
      /* LojasService.lojaStateChanged$
        .subscribe(
          (loja: ILoja) => {
            // console.log(loja);
            this.loja = loja;
            this.tabPedido = 'status';
            // this.tabItens = !!get(this.pedido, '_separado') ? 'pendentes' : 'todos';
            this.viewStyle = this._storageServ.separandoViewstyleGet() || 'cartoes';
            this.splitVertical = this._storageServ.splitVerticalGet() ?? true;
            this.showInfo = this._storageServ.showSidebarGet() ?? true;
          }
        ), * /
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((s: Subscription) => s && s.unsubscribe());
    !!this._pedidoUnsub && this._pedidoUnsub();
  }
  //#endregion

  //#region functions
  private _focus() {
    setTimeout(
      () => this.searchFocusEvent$.emit(true),
      FOCUS_TIMEOUT
    );
  }

  private _refresh() {
    // console.log(this._pedidoId);
    // console.log(this._loja);
    this.lojaPath = get(this.loja, '__idInfo.lojaPath') || '';
    // console.log(this.lojaPath);
    !!this._pedidoUnsub && this._pedidoUnsub();
    if (!!this.lojaPath && !!this._pedidoId) {
      const PATH: string = `${this.lojaPath}/pedidos/${this._pedidoId}`;
      // console.log(PATH);
      const PRODUTO_REF = doc(
        this._db,
        PATH
      );
      this._pedidoUnsub = onSnapshot(
        PRODUTO_REF,
        snap => {
          // console.log(snap);
          this.pedido = !!snap.data()
            ? {
              id: snap.id,
              ...snap.data()
            } as IPedido
            : null;
          // this._pedidosServ.setState(PEDIDO);
          // !!this.pedido && this._router.navigateByUrl(HOME_ROUTE);
        }
      );
    } // if
  }
  //#endregion

  //#region methods
  //#endregion
  */
}
