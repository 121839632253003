<!-- <p>isMobile {{ isMobile }}</p> -->
<!-- <p>integradoraForm() {{ integradoraForm().value | json }}</p> -->
<!-- <p>rede {{ rede | json }}</p> -->
<!-- <p>temas {{ temas | json }}</p> -->
<!-- <p>integradoraForm() {{ integradoraForm().get('nome').value }}</p> -->
<!-- <p>integradoraForm().value {{ integradoraForm().value | json }}</p> -->
<!-- <p>integradoraForm().valid {{ integradoraForm().valid }}</p> -->
<!-- <p>idRef.errors {{ idRef.errors | json }}</p> -->
<!-- <p>idRef.pending {{ idRef.pending | json }}</p> -->

<form [formGroup]="integradoraForm()"
      class="px-2 py-4"
      *ngIf="integradoraForm()">
  <!-- (ngSubmit)="onSubmitClick()" -->

  <div class="row">
    <div class="col">
      <mat-form-field class="w-100 mb-2"
                      appearance="outline">
        <mat-label>ID</mat-label>
        <input type="text"
               matInput
               formControlName="id"
               [focus]="idFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.error(idRef) || (idRef?.errors && idRef?.errors['idRepeated'])">
          <span class="danger">
            {{ fv()?.error(idRef) || 'ID já cadastrado.' }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field class="w-100 mb-2"
                      appearance="outline">
        <mat-label>Nome</mat-label>
        <input type="text"
               matInput
               formControlName="nome"
               onReturn>
        <mat-hint *ngIf="fv()?.error(nomeRef, 'nome')">
          <span class="danger">
            {{ fv()?.error(nomeRef, 'nome') }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field class="w-100 mb-2"
                      appearance="outline">
        <mat-label>E-mail</mat-label>
        <input type="email"
               matInput
               formControlName="email"
               onReturn>
        <mat-hint *ngIf="fv()?.error(emailRef, 'email')">
          <span class="danger">
            {{ fv()?.error(emailRef, 'email') }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col d-flex">
      <div *ngIf="idRef.pending"
           class="my-auto ms-2">
        Aguarde, verificando...
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <!-- <button type="submit"
              class="ms-auto pointer"
              mat-raised-button
              color="primary"
              [disabled]="!integradoraForm().valid">
        CONFIRMAR
      </button> -->
    </div>
  </div>
</form>