//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
//#endregion

//#region models
import { IItemCarrinhoMap } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { STATUS_ITEM_PEDIDO } from '../../../_shared/_mercadeiro/_misc/_models/consts';
import { TPedidoSeparacaoStage } from '../../../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

@Component({
  selector: 'item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss']
})
export class ItemCardComponent {
  //#region inputs
  // item
  @Input({ required: true }) item: IItemCarrinhoMap;
  // stage
  @Input({ required: true }) stage: TPedidoSeparacaoStage;
  // stage
  @Input() off: boolean = false;
  // qtdeSel
  @Input() qtdeSel: number = 0;
  //#endregion

  //#region outputs
  @Output() separado$: EventEmitter<IItemCarrinhoMap> = new EventEmitter<IItemCarrinhoMap>();
  @Output() encontrado$: EventEmitter<IItemCarrinhoMap> = new EventEmitter<IItemCarrinhoMap>();
  @Output() solicitado$: EventEmitter<IItemCarrinhoMap> = new EventEmitter<IItemCarrinhoMap>();
  @Output() remove$: EventEmitter<IItemCarrinhoMap> = new EventEmitter<IItemCarrinhoMap>();
  //#endregion

  //#region publics
  statusItemPedido = signal<any>(STATUS_ITEM_PEDIDO).asReadonly();
  //#endregion
}
