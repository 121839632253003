//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors
} from '@angular/forms';
//#endregion

//#region 3rd
import {
  Observable,
  of,
} from 'rxjs';
import {
  first,
  map,
} from 'rxjs/operators';
//#endregion

//#region models
import { CorFormValidation } from '../../../../_core/_ng/_models/_classes';
// import { IConta } from '../../../_misc/_models/_interfaces/_cols';
import { IRede } from '../../../_misc/_models/_interfaces/_cols';
import {
  FOCUS_TIMEOUT,
  THEMES
} from '../../../../_core/_misc/_models/consts';
//#endregion

//#region custom validators
class CustomValidator {
  static idRepeated(
    redesServ: RedesService,
    loaderServ: CorLoaderService
  ) {
    return (control: FormControl): Observable<ValidationErrors | null> => {
      const ID = (control.value || '').toLowerCase();
      // console.log(ID);
      if (!!ID) {
        return loaderServ.showUntilCompleted(
          redesServ?.doc(ID)
            .pipe(
              first(),
              // debounceTime(500),
              map((rede: IRede) => !!rede ? { idRepeated: true } : null)
            )
        )
      } else {
        return of(null);
      } // else
    }
  }
}
//#endregion

//#region services
import { RedesService } from '../../../_ng/_services';
import { CorLoaderService } from '../../../../_core/_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-rede-form',
  templateUrl: './rede-form.component.html',
  styleUrls: ['./rede-form.component.scss']
})
export class MeMatRedeFormComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // rede
  #rede: IRede;
  get rede(): IRede { return this.#rede; }
  @Input({ required: true }) set rede(val: IRede) {
    this.#rede = !!val ? this.#redesServ.fix(val) : null;
    this.novo.set(!!val?.id);
  }
  // submitCaption
  @Input() submitCaption: string = '';
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<Partial<IRede>>();
  //#endregion

  //#region publics
  fv = signal<CorFormValidation>(null);
  novo = signal<boolean>(false);
  redeForm = signal<FormGroup>(null);
  temas = signal<string[]>(null);
  //#endregion

  //#region events
  idFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #redesServ = inject(RedesService);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation());
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.temas.set(THEMES.filter((t: string) => !['primary', 'tertiary'].includes(t)));
    this.redeForm.set(
      this.#fb.group({
        ativo: this.#fb.group({
          status: [this.rede?.ativo?.status ?? true],
        }),
        id: [
          this.rede?.id || '',
          {
            validators: [Validators.required],
            asyncValidators: [CustomValidator.idRepeated(this.#redesServ, this.#loaderServ)],
            // updatedOn: 'blur'
          }
        ],
        nome: [this.rede?.nome || '', [Validators.required]],
        tema: [this.rede?.tema || '', [Validators.required]],
      })
    );
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region Controls getters
  get idRef(): AbstractControl { return this.redeForm()?.get('id'); }
  get nomeRef(): AbstractControl { return this.redeForm()?.get('nome'); }
  get temaRef(): AbstractControl { return this.redeForm()?.get('tema'); }
  //#endregion

  //#region functions
  focus() {
    // console.log(this.isMobile);
    !this.isMobile && setTimeout(
      () => { this.idFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  // tid(index: any, item: any): string { return item || ''; }
  //#endregion

  //#region methods
  onTemaClick(tema: string) {
    // console.log(tema);
    this.redeForm()?.patchValue({ tema });
  }
  //#endregion
}
