<div class="box d-flex flex-column">
  <div class="identificacao p-3 center m-auto">
    <div class="text-center">
      <h2 class="primary">Identificação</h2>
    </div>

    <div class="mt-4">
      <cor-mat-manage-identificacao emailLoginButtonCaption="ENTRAR"
                                    [isMobile]="false"
                                    [methods]="['email', 'google']"
                                    [showRegistrar]="false"
                                    [showEsqueciSenha]="false">
      </cor-mat-manage-identificacao>
    </div>
  </div>
</div>
