//#region ng
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Renderer2,
} from '@angular/core';
//#endregion

//#region directive
@Directive({
  selector: '[select]'
})
export class CorSelectDirective {
  //#region inputs
  @Input('select') public selectEvent: EventEmitter<boolean>;
  //#endregion

  //#region contructor
  constructor(
    @Inject(ElementRef) private element: ElementRef,
    private renderer: Renderer2,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.selectEvent?.subscribe(
      event => {
        // console.log('FocusDirective', event);
        // this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
        // this.renderer.invokeElementMethod(this.element.nativeElement, 'select', []);
        this.renderer.selectRootElement(this.element.nativeElement).focus();
        this.renderer.selectRootElement(this.element.nativeElement).select();
      });
  }
  //#endregion
}
