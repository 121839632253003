<mat-button-toggle-group [(ngModel)]="viewStyle"
                         [class.lg]="size === 'lg'"
                         *ngIf="storageKey">
  <mat-button-toggle value="cards"
                     class="d-flex"
                     matTooltip="Galeria">
    <div class="m-auto">
      <div class="icon-o-grid-small"></div>
    </div>
  </mat-button-toggle>

  <mat-button-toggle value="table"
                     class="d-flex"
                     matTooltip="Tabela">
    <div class="m-auto">
      <div class="icon-o-list"></div>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>