//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  setDoc
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
//#endregion

//#region models
import { IProduto } from '../../_misc/_models/_interfaces/_cols';
import { IItemCarrinhoMap } from '../../_misc/_models/_interfaces/_maps';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

//#region libs
import { nextId } from '../../../_libs/_misc/_numbers';
import { mapItemCarrinhoInfo } from '../../_misc/_libs';
//#endregion

//#region services
import { ProdutosService } from './produtos.service';
import { CorMessagesService } from '../../../_core/_ng/_services';
// import { mapItemCarrinhoInfo } from '../../_misc/_libs/itens-carrinho';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ItensCarrinhoService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  #produtosServ = inject(ProdutosService);
  //#endregion

  //#region misc
  fix(row: Partial<IItemCarrinhoMap>): IItemCarrinhoMap {
    // console.log(row);
    const PRODUTO: TNullable<IProduto> = !!row?.produto ? this.#produtosServ.fix(row?.produto) : null;
    const QTDE_SOLICITADO: TNullable<number> = row?.__solicitado?.qtde?.val || null;
    const QTDE_ENCONTRADO: TNullable<number> = row?._separado?.qtde?.encontrado || null;
    const QTDE_SEPARADO: TNullable<number> = row?._separado?.qtde?.val || null;
    // console.log({ QTDE_SOLICITADO, QTDE_ENCONTRADO, QTDE_SEPARADO });

    return {
      // id
      id: row?.id || nextId().toString(),

      // user
      obs: row?.obs || '', // 60,
      produto: PRODUTO as IProduto,
      // tipoQtde: TProdutoTipoQtde;

      // system
      _criadoEm: row?._criadoEm || null,
      // _preco: Number(row?._preco) || 0,
      _pos: Number(row?._pos) || 0,
      // _separado: row?._separado || null,
      _separado: mapItemCarrinhoInfo(
        row as IItemCarrinhoMap,
        QTDE_ENCONTRADO,
        true,
        QTDE_SEPARADO
      ),
      _status: row?._status || '',

      // realtime
      __modificado: !!row?.__modificado,
      __sel: !!row?.__sel,
      // __solicitado: row?.__solicitado, 
      __solicitado: mapItemCarrinhoInfo(
        row as IItemCarrinhoMap,
        QTDE_SOLICITADO,
        false,
        /* row?._separado?.qtde?.val ?? */ null
      ),
      /* {
        qtde: {
          fixed: ajustaQtdeFracionado(PRODUTO, QTDE_SOLICITADO),
          val: QTDE_SOLICITADO,
          isMin: isQtdeMin(PRODUTO, QTDE_SOLICITADO),
        },
        valor: {
          unit: {
            desc: DESC_SOLICITADO,
            liq: VAL_SOLICITADO - DESC_SOLICITADO,
            val: VAL_SOLICITADO,
          },
          total: {
            desc: DESC_SOLICITADO * QTDE_SOLICITADO,
            liq: (VAL_SOLICITADO - DESC_SOLICITADO) * QTDE_SOLICITADO,
            val: VAL_SOLICITADO * QTDE_SOLICITADO,
          },
        }
      } */
    };

    // row = row || {};
    // let R: IItemCarrinhoMap = row;
    // const QTDE_SOLICITADO: number = Number(row?.__solicitado?.qtde?.val) || 0;
    // const TOTAL_SOLICITADO: number = Number(row?.__solicitado?._valor?.total) || 0;
    // const DESC_SOLICITADO: number = Number(row?.__solicitado?._valor?.desconto) || 0;
    // const QTDE_SEPARADO: number = Number(row?._separado?.qtde) || 0;

    // // id
    // R.id = row?.id || nextId().toString();

    // // user
    // R.obs = row?.obs || '';
    // R.produto = this.#produtosServ.fix(row?.produto || null);
    // R._separado = row?._separado || null;
    // /* R._separado = {
    //   // fixed: ajustaQtdeFracionado(R.produto, QTDE_SEPARADO),
    //   qtde: {
    //     fixed: ajustaQtdeFracionado(R?.produto, QTDE_SEPARADO),
    //     val: QTDE_SEPARADO,
    //   },
    //   _valor: {
    //     desconto: Number(row?._separado?._valor?.desconto) || 0,
    //     liquido: 0,
    //     unitario: Number(row?._separado?._valor?.unitario) || 0,
    //     total: Number(row?._separado?._valor?.total) || 0
    //   }
    // }; */
    // // R.tipoQtde = get(row, 'tipoQtde');
    // // console.log(R.produto);

    // // system
    // R._preco = Number(row?._preco) || 0;
    // // R.separado._valor.liquido = R.separado._valor.total - R.separado._valor.desconto;
    // R._pos = Number(row?._pos) || 0;
    // R._status = row?._status;

    // // realtime
    // // R.__brindes = this._brindesProdutosServ.fixes(get(row, '__brindes') || []);
    // R.__modificado = !!R?.__modificado
    // // console.log(
    // //   QTDE_SOLICITADO,
    // //   JSON.stringify(R?.produto?.__carrinho),
    // //   JSON.stringify(R?.__solicitado?.qtde?.isMin)
    // // );
    // R.__sel = !!row?.__sel;
    // R.__solicitado = {
    //   qtde: {
    //     fixed: ajustaQtdeFracionado(R?.produto, QTDE_SOLICITADO),
    //     val: QTDE_SOLICITADO,
    //     isMin: isQtdeMin(R.produto, QTDE_SOLICITADO),
    //   },
    //   _valor: {
    //     desconto: DESC_SOLICITADO,
    //     liquido: TOTAL_SOLICITADO - DESC_SOLICITADO,
    //     unitario: Number(row?.__solicitado?._valor?.unitario) || 0,
    //     total: TOTAL_SOLICITADO
    //   }
    // };
    // // console.log(JSON.stringify(R?.__solicitado?.qtde));
    // // R.__solicitado._valor.liquido = R.__solicitado._valor.total - R.__solicitado._valor.desconto;

    // return R;
  }

  fixes(docs: Partial<IItemCarrinhoMap>[]): IItemCarrinhoMap[] {
    return (docs || []).map((doc: Partial<IItemCarrinhoMap>) => this.fix(doc));
  }

  produto2Item(
    produto: IProduto,
    qtde: number,
    pendente: boolean = true
  ): IItemCarrinhoMap {
    return this.fix(
      {
        id: String(nextId()),
        obs: '',
        produto: this.#produtosServ.fix(produto),
        // tipoQtde: TProdutoTipoQtde,
        _separado: null,
        _status: !!pendente ? 'pendente' : 'adicionado',
        __solicitado: {
          qtde: {
            fixed: 0,
            val: qtde,
            isMin: false,
          },
          valor: {
            unit: {
              desc: Number(produto?.preco?.desc) || 0,
              liq: 0,
              val: Number(produto?.preco?.produto) || 0
            },
            total: {
              desc: 0,
              liq: 0,
              val: 0,
            }
          }
        }
      }
    );

    /*
    // console.log(produto);
    const QTDE: number = Number(qtde.toFixed(3)) || 0;
    if (!!produto /* && QTDE > 0) {
      const ITEM: IItemCarrinhoMap = {
        id: String(nextId()),
        obs: '',
        // _status: 'pendente',
        produto: this.#produtosServ.fix(produto),
  
        // produto: {
        //   id: get(produto, 'id') || '',
        //   atacado: {
        //     status: !!get(produto, 'atacado.status'),
        //     qtde: Number(get(produto, 'atacado.qtde')) || 0,
        //     preco: Number(get(produto, 'atacado.preco')) || 0,
        //   },
        //   barcode: get(produto, 'barcode') || '',
        //   _base: get(produto, '_base') || null,
        //   // complemento: get(produto, 'complemento') || '',
        //   // departamento: {
        //   //   id: get(produto, 'departamento.id') || '',
        //   //   nome: get(produto, 'departamento.nome') || '',
        //   //   subdepartamento: {
        //   //     id: get(produto, 'departamento.subdepartamento.id') || '',
        //   //     nome: get(produto, 'departamento.subdepartamento.nome') || '',
        //   //   },
        //   // },
        //   fracionado: {
        //     _status: !!get(produto, 'fracionado._status'),
        //     // percDescPromocaoAutomatica: parseFloat(get(produto, 'fracionado.percDescPromocaoAutomatica')) || 0,
        //     unidade: {
        //       _converter: Number(get(produto, 'fracionado.unidade._converter')) || 0,
        //       _legenda: get(produto, 'fracionado.unidade._legenda') || '',
        //       fracao: Number(get(produto, 'fracionado.unidade.fracao')) || 0,
        //       fracaoAproxUn: Number(get(produto, 'fracionado.unidade.fracaoAproxUn')) || 0,
        //       tipo: get(produto, 'fracionado.unidade.tipo'),
        //     },
        //   },
        //   imgs: get(produto, 'imgs') || [],
        //   _industrializado: !!get(produto, '_industrializado'),
        //   limiteVenda: {
        //     max: Number(get(produto, 'limiteVenda.max')) || 0,
        //     min: Number(get(produto, 'limiteVenda.min')) || 0,
        //   },
        //   nome: get(produto, 'nome') || '',
        //   // _nomeDepartamento: get(produto, '_nomeDepartamento') || '',
        //   // _nomeSubdepartamento: get(produto, '_nomeSubdepartamento') || '',
        //   preco: Number(get(produto, 'preco', 0)) || 0,
        //   // _promocaoAutomatica: {
        //   //   percDesconto: Number(get(produto, '_promocaoAutomatica.percDesconto')) || 0,
        //   //   preco: Number(get(produto, '_promocaoAutomatica.preco')) || 0,
        //   // },
        //   // _promocoes: get(produto, '_promocoes') || {},
        //   // __qtdeCarrinho: {
        //   //   calc: Number(get(produto, '__qtdeCarrinho.calc')) || 0,
        //   //   sel: Number(get(produto, '__qtdeCarrinho.sel')) || 0,
        //   //   step: Number(get(produto, '__qtdeCarrinho.step')) || 0,
        //   //   tipoQtde: get(produto, '__qtdeCarrinho.tipoQtde') || '',
        //   // },
        // },
        // _preco: 0,
        // tipoQtde: get(produto, '__qtdeCarrinho.tipoQtde'),
        __solicitado: {
          qtde: {
            fixed: 0,
            val: 0,
            isMin: false
          },
          valor: {
            unit: {
              desconto: 0,
              liquido: 0,
              val: 0,
            },
            total: {
              desconto: 0,
              liquido: 0,
              val: 0,
            },
          }
        },
        _separado: {
          qtde: {
            fixed: 0,
            val: 0
          },
          valor: {
            unit: {
              desconto: 0,
              liquido: 0,
              val: 0,
            },
            total: {
              desconto: 0,
              liquido: 0,
              val: 0,
            },
          }
        },
        _status: pendente ? 'pendente' : 'adicionado',
      };
  
      // const QTDE_SEP: number = 0; // Number(produto?._separado?.qtde?.val)) || 0;
      // const UNITARIO: number = Number(produto?.preco?._liquido) || 0;
      // if (!!pendente) {
      //   ITEM.__solicitado = {
      //     qtde: {
      //       fixed: ajustaQtdeFracionado(produto, QTDE),
      //       val: QTDE,
      //       isMin: isQtdeMin(produto, QTDE)
      //     },
      //     valor: {
      //       unitario: UNITARIO,
      //       produtos: QTDE * UNITARIO,
      //     },
      //   };
      // } else {
      //   ITEM._separado = {
      //     qtde: {
      //       fixed: ajustaQtdeFracionado(produto, QTDE_SEP),
      //       val: QTDE_SEP
      //     },
      //     // qtde: QTDE,
      //     valor: {
      //       unitario: UNITARIO,
      //       produtos: QTDE * UNITARIO,
      //     },
      //   };
      // } // else
      return this.fix(ITEM);
    } else {
      return null;
    } // else
    */
  }
  //#endregion

  //#region U  
  update(
    changes: Partial<IItemCarrinhoMap>,
    lojaPath: string,
    idPedido: string,
    idItemSeparacao: string
  ): Observable<any> {
    if (!!lojaPath) {
      if (!!idPedido) {
        if (!!idItemSeparacao) {
          const PATH: string = `${lojaPath}/pedidos/${idPedido}/separacao/${idItemSeparacao}`;
          // console.log(PATH);          
          return from(
            setDoc(
              doc(this.#db, PATH),
              changes,
              { merge: true }
            )
          )
            .pipe(
              // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro apagando item de pedido.')
              )
            );
        } // if
        return throwError(() => 'Item de pedido não indicado.');
      } // if
      return throwError(() => 'Pedido não indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}