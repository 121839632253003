//#region 3rd
import { set } from 'lodash';
//#endregion

//#region models
// import { ILoja } from "../_models/_interfaces/_cols";
import {
  ICarrinhoInfoMap,
  ICarrinhoMap,
  IItemCarrinhoMap,
} from "../_models/_interfaces/_maps";
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

//#region libs
import {
  // fixProdutos,
  fixProdutos,
  mapItemCarrinhoInfo,
} from '.';
//#endregion

/* 
export function fixCarrinho(
  carrinho: ICarrinhoMap,
  loja: ILoja
): ICarrinhoMap {
  // console.log(carrinho, loja);  
  if (!!carrinho && !!loja) {
    // solicitado
    const SOLICITADO_PROD_LIQ: number = Number(carrinho?.__solicitado?.total?.liq) || 0;
    // separado
    const SEPARADO_PROD_LIQ: number = Number(carrinho?._separado?.total?.liq) || 0;
    // loja
    const LOJA_ENT_PED_MINIMO: number = Number(loja?.pedidos?.minimo?.entrega) || 0;
    const LOJA_RET_PED_MINIMO: number = Number(loja?.pedidos?.minimo?.retirada) || 0;
    const LOJA_TAXA_SERV_CLI: number = Number(loja?.faturamento?.servicos?.__valCliente) || 0;
    const LOJA_TAXA_ENTREGA: number = 0;
    return {
      ...carrinho,
      __limites: {
        entrega: {
          perc: !!LOJA_ENT_PED_MINIMO ? SOLICITADO_PROD_LIQ / LOJA_ENT_PED_MINIMO : 100,
          val: LOJA_ENT_PED_MINIMO,
        },
        retirada: {
          perc: !!LOJA_RET_PED_MINIMO ? SOLICITADO_PROD_LIQ / LOJA_RET_PED_MINIMO : 100,
          val: LOJA_RET_PED_MINIMO,
        },
      },
      _separado: {
        ...carrinho?._separado,
        __total: {
          totalPagar: SEPARADO_PROD_LIQ + LOJA_TAXA_SERV_CLI + LOJA_TAXA_ENTREGA,
          valorProdutos: SEPARADO_PROD_LIQ,
          valorTaxaServ: LOJA_TAXA_SERV_CLI,
          valorTaxaEntrega: LOJA_TAXA_ENTREGA,
        }
      },
      __solicitado: {
        ...carrinho?.__solicitado,
        __total: {
          totalPagar: SOLICITADO_PROD_LIQ + LOJA_TAXA_SERV_CLI + LOJA_TAXA_ENTREGA,
          valorProdutos: SOLICITADO_PROD_LIQ,
          valorTaxaServ: LOJA_TAXA_SERV_CLI,
          valorTaxaEntrega: LOJA_TAXA_ENTREGA,
        }
      },
    };
  } // if

  return carrinho;
} 
*/

export function mapCarrinhoInfo(
  carrinho: ICarrinhoMap,
  isSeparado: boolean,
  itens: TNullable<IItemCarrinhoMap[]> = null,
): ICarrinhoInfoMap {
  // console.log({ isSeparado });
  const IS_SEPARADO_CAPTION: string = !!isSeparado ? '_separado' : '__solicitado';
  const RET: ICarrinhoInfoMap = {
    itens: !!itens
      ? itens
      : carrinho?.[IS_SEPARADO_CAPTION]?.itens || [],
    qtde: {
      itens: 0,
      volumes: 0,
    },
    total: {
      desc: 0,
      liq: 0,
      prod: 0,
    },
    /* __total: {
      totalPagar: 0,
      valorProdutos: 0,
      valorTaxaServ: 0,
      valorTaxaEntrega: 0,
    }, */
  };

  if (!!carrinho) {
    const LOJA_TAXA_SERV: number = Number(carrinho?.__loja?.faturamento?.servicos?.__valCliente) || 0;
    const LOJA_TAXA_ENTREGA = carrinho?.__loja?.taxaEntrega?._liquido || 0;

    // console.clear();
    (RET?.itens || [])
      .forEach(
        (i: IItemCarrinhoMap) => {
          if (!!i && i?.produto) {
            // const QTDE_VOLUMES: number = (Number(i?.[IS_SEPARADO_CAPTION]?.qtde?.val) || 0);
            const QTDE_VOLUMES: TNullable<number> = !!isSeparado
              ? i?._separado?.qtde?.encontrado ?? null
              : Number(i?.__solicitado?.qtde?.val) || 0;
            // i.produto = fixProdutos([i?.produto], carrinho)[0];
            fixProdutos([i?.produto], carrinho);
            i[IS_SEPARADO_CAPTION] = mapItemCarrinhoInfo(
              i,
              QTDE_VOLUMES,
              isSeparado,
              i?._separado?.qtde?.fixed ?? null,
            );
            const PRODUTO_PRECO = Number(Number(i?.produto?.preco?._liquido) || 0);
            // !!isSeparado && console.log(
            //   QTDE_VOLUMES,
            //   PRODUTO_PRECO,
            //   i?._separado?.qtde?.fixed,
            //   JSON.stringify(i[IS_SEPARADO_CAPTION]?.qtde)
            // );
            if (i?._status !== 'removido') { set(RET, 'qtde.itens', Math.abs(((Number(RET?.qtde?.itens) || 0) + 1))); }
            set(RET, 'qtde.volumes', Math.abs((Number(RET?.qtde?.volumes) || 0) + Number(QTDE_VOLUMES) || 0));
            set(RET, 'total.desc', Math.abs((Number(RET?.total?.desc) || 0) + Number(i?.produto?.__descItem)));
            set(RET, 'total.prod', Math.abs((Number(RET?.total?.prod) || 0) + (PRODUTO_PRECO * Number(QTDE_VOLUMES) || 0)));
          } // if
        });

    set(
      RET,
      'total.liq',
      Math.abs((Number(RET?.total?.prod) || 0) - (Number(RET?.total?.desc) || 0)) + LOJA_TAXA_SERV + LOJA_TAXA_ENTREGA
    );
    /* 
    set(
      RET,
      'total.liq',
      Math.abs((Number(RET?.total?.prod) || 0) - (Number(RET?.total?.desc) || 0))
    ); 
    */

    /* const PRODUTOS_TOT_LIQ: number = Math.abs(Number(RET?.total?.liq) || 0);
    // console.log({ isSeparado, PRODUTOS_TOT_LIQ, LOJA_TAXA_SERV, LOJA_TAXA_ENTREGA });
    RET.__total = {
      totalPagar: (PRODUTOS_TOT_LIQ + LOJA_TAXA_SERV + LOJA_TAXA_ENTREGA) || 0,
      valorProdutos: PRODUTOS_TOT_LIQ || 0,
      valorTaxaServ: LOJA_TAXA_SERV || 0,
      valorTaxaEntrega: LOJA_TAXA_ENTREGA,
    }; */
    // set(
    //   RET,
    //   '__total',
    //   {
    //     totalPagar: PRODUTOS_TOT_LIQ + LOJA_TAXA_SERV + (LOJA_TAXA_ENTREGA?._liquido || 0),
    //     valorProdutos: PRODUTOS_TOT_LIQ || 0,
    //     valorTaxaServ: LOJA_TAXA_SERV || 0,
    //     valorTaxaEntrega: LOJA_TAXA_ENTREGA || 0,
    //   }
    // );

    carrinho[IS_SEPARADO_CAPTION] = RET;
  } // if

  // let ret = { ...RET };
  // delete ret.itens;
  // !!isSeparado && console.log(JSON.stringify(RET?.itens?.[0]?._separado?.qtde));

  return RET;
}
