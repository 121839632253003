//#region ng
import {
  Component,
  Input,
} from '@angular/core';
//#endregion

//#region models
import { IPedido } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

@Component({
  selector: 'me-mat-pedido-info-detalhes',
  templateUrl: './pedido-info-detalhes.component.html',
  styleUrls: ['./pedido-info-detalhes.component.scss']
})
export class MeMatPedidoInfoDetalhesComponent {
  //#region inputs
  @Input({ required: true }) pedido: IPedido;
  //#endregion
}
