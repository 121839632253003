//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region libs
import { formataCNPJ } from '../../../_libs/_misc/_formats';
//#endregion

@Pipe({ name: 'cnpj' })
export class CorFormataCnpj implements PipeTransform {
  transform(value: string): string {
    return formataCNPJ(value);
  }
}
