//#region models
import { ICorFoneInfoRet } from '../../../_core/_misc/_models/_interfaces/_misc';
//#endregion

export const breakFone = (val: string) => {
  const VAL = !!val ? val.toString().trim().replace(/^\+|-|\(|\)/g, '') : '';
  // console.log(val);

  const RET: ICorFoneInfoRet = {
    country: '',
    ddd: '',
    number: '',
    n1: '',
    n2: '',
    cellphone: VAL.length % 2 !== 0,
  };

  switch (VAL.length) {
    case 8:
      RET.country = '';
      RET.ddd = '';
      RET.number = VAL;
      RET.n1 = VAL.slice(0, 4);
      RET.n2 = VAL.slice(4);
      break;

    case 9:
      RET.country = '';
      RET.ddd = '';
      RET.number = VAL;
      RET.n1 = VAL.slice(0, 5);
      RET.n2 = VAL.slice(5);
      break;

    case 10:
      RET.country = '';
      RET.ddd = VAL.slice(0, 2);
      RET.number = VAL.slice(2);
      RET.n1 = VAL.slice(2, 6);
      RET.n2 = VAL.slice(6);
      break;

    case 11:
      RET.country = '';
      RET.ddd = VAL.slice(0, 2);
      RET.number = VAL.slice(2);
      RET.n1 = VAL.slice(2, 7);
      RET.n2 = VAL.slice(7);
      break;

    case 12:
      RET.country = VAL.slice(0, 2);
      RET.ddd = VAL.slice(2, 4);
      RET.number = VAL.slice(4);
      RET.n1 = VAL.slice(4, 8);
      RET.n2 = VAL.slice(8);
      break;

    case 13:
      RET.country = VAL.slice(0, 2);
      RET.ddd = VAL.slice(2, 4);
      RET.number = VAL.slice(4);
      RET.n1 = VAL.slice(4, 9);
      RET.n2 = VAL.slice(9);
      break;

    default:
      return null;
  } // switch

  // console.log(RET);
  return RET;
};

/* 
// AQUI VEM A DECLARACAO DA FUNCAO
export const breakFone = (val: string) => {...};

// AQUI E A CHAMADA DELA CORRIGINDO O VALOR
const foneQuebrado = breakFone(foneNumber);
if (!!foneQuebrado && !!foneQuebrado.ddd) {
  const CELULAR_SEM_9 = (foneQuebrado?.n1?.length === 4) && ['7','8','9'].includes(foneQuebrado.n1[0]);
  if (!!CELULAR_SEM_9) {
    foneQuebrado.number += '9';
  }
  const FONE_FINAL = '0' + foneQuebrado.ddd + foneQuebrado.number;  

  // AQUI CHAMA O QUE QUISER COM FONE_FINAL
}
 */
