export * from './banners.service';
export * from './carrinho.service';
export * from './conta-enderecos.service';
export * from './contas.service';
export * from './departamentos.service';
export * from './devices.service';
export * from './formas-pgto.service';
export * from './itens-carrinho.service';
export * from './integradoras.service';
export * from './integradora-lojas.service';
export * from './localidades.service';
export * from './localidade-bairros.service';
export * from './loja-formas-pgto.service';
export * from './lojas.service';
export * from './meilisearch.service';
export * from './mov-pedidos-pedidos.service';
export * from './mov-pedidos-tot.service';
export * from './notificacoes.service';
export * from './redes.service';
export * from './pedidos.service';
export * from './pedidos-separacao.service';
export * from './produtos.service';
export * from './p-tags.service';
export * from './qtde.service';
export * from './setores-entrega.service';
export * from './terminais.service';
export * from './tipos-lojas.service';
export * from './ufs.service';
