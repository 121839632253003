export function printDialog(html: string): void {
  const IFRAME = document.createElement('iframe');
  IFRAME.setAttribute(
    'style',
    'display: none'
  );
  IFRAME.setAttribute(
    'srcdoc',
    html
  );
  document.body.appendChild(IFRAME);
  setTimeout(
    () => { IFRAME.contentWindow.print(); },
    1000
  );
}
