//#region libs
import { lpad } from '../_strings';
//#endregion

export const min2time = (value: number, hours: boolean = true): string => {
  if (typeof value === 'number') {
    const H: string = lpad(`${Math.trunc(value / 60)}`);
    const M: string = lpad(`${value % 60}`);
    // console.log({
    //     H,
    //     M
    // });
    let str: string = `${H === '24' ? '00' : H}:${M}`;
    if (hours) {
      str = str.replace(':', 'h');
    } // if
    return str;
  } else {
    return '';
  } // else
};
