//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region libs
import { formataNCM } from '../../../_libs/_misc/_formats';
//#endregion

@Pipe({ name: 'ncm' })
export class CorFormataNcm implements PipeTransform {
  transform(value: string): string {
    return formataNCM(value);
  }
}
