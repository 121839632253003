<!-- <p>imgs {{ imgs | json }}</p> -->

<div class="imgs">
  <div class="container-fluid">
    <div class="slider-wrap">
      <div class="slider-main">
        <div class="item">
          <div class="text">1</div>
        </div>

        <div class="item">
          <div class="text">2</div>
        </div>

        <div class="item">
          <div class="text">3</div>
        </div>

        <div class="item">
          <div class="text">4</div>
        </div>

        <div class="item">
          <div class="text">5</div>
        </div>
      </div>

      <div class="button-area">
        <button type="button"
                class="prev">
          Prev
        </button>

        <button type="button"
                class="next">
          Next
        </button>
      </div>
    </div>
  </div>
</div>