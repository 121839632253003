<!-- <p>endereco: {{ endereco | json }}</p> -->
<div *ngIf="endereco"
     [class.small]="small">
  <p class="mb-0">
    <strong>
      <small>
        {{ endereco?.logradouro }}<span *ngIf="endereco?.nro || endereco?.complemento">,</span> {{ endereco?.nro === 0 ?
        ''
        : endereco?.nro }}
        {{ endereco?.complemento }}
      </small>
    </strong>
  </p>

  <p class="mb-0">
    <span *ngIf="endereco?.referencia">
      <small>{{ endereco?.referencia }}</small>
      <br>
    </span>
    <small>{{ endereco?.bairro }}</small>
  </p>

  <p class="mb-0">
    <small>
      {{ endereco?.cep | cep }} - {{ endereco?.localidade }}, {{ endereco?.uf }}
    </small>
  </p>
</div>