//#region ng
import {
  Component,
  inject,
  Input,
  signal
} from '@angular/core';
import { STATUS_PEDIDO } from '../../../_misc/_models/consts';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region models
import { IPedido } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { PedidosService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-html-pedido-timeline',
  templateUrl: './pedido-timeline.component.html',
  styleUrls: ['./pedido-timeline.component.scss']
})
export class MeHtmlPedidoTimelineComponent {
  //#region inputs
  // idPedido
  #idPedido: string;
  get idPedido(): string { return this.#idPedido; }
  @Input({ required: true }) set idPedido(val: string) {
    this.#idPedido = val;
    this.#buscaPedido();
  }
  // lojaPath
  #lojaPath: string;
  get lojaPath(): string { return this.#lojaPath; }
  @Input({ required: true }) set lojaPath(val: string) {
    this.#lojaPath = val;
    this.#buscaPedido();
  }
  // showOperadores
  @Input() showOperadores: boolean = false;
  //#endregion

  //#region publics
  pedido = signal<IPedido>(null);
  statusPedido = signal<any>(STATUS_PEDIDO).asReadonly();
  //#endregion

  //#region privates
  #pedidoUnsub: FbUnsubscribe;
  //#endregion

  //#region inject
  #db = inject(Firestore);
  #pedidosServ = inject(PedidosService);
  //#endregion

  //#region lifecycles
  ngOnDestroy(): void {
    !!this.#pedidoUnsub && this.#pedidoUnsub();
  }
  //#endregion

  //#region functions
  #buscaPedido() {
    // console.log(this.idPedido, this.lojaPath);
    if (
      !!this.idPedido
      && !!this.lojaPath
    ) {
      // this._loaderServ.lstart();
      const PATH: string = `${this.lojaPath}/pedidos/${this.idPedido}`;
      // console.log(PATH);
      this.#pedidoUnsub && this.#pedidoUnsub();
      this.#pedidoUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          // console.log(snap.data());
          if (!!snap.id) {
            this.pedido.set(this.#pedidosServ.fix({ id: snap.id, ...snap.data() }));
          } // if
        }
      );
    } // if
  }
  //#endregion
}
