export function isCnpj(cnpj: string): boolean {
  const CNPJ: string = `${cnpj}`.replace(/[^\d]+/g, '');

  if (!CNPJ) return false;

  if (CNPJ.length !== 14) {
    return false;
  } // if

  // Elimina CNPJs invalidos conhecidos
  if (CNPJ === '00000000000000' ||
    CNPJ === '11111111111111' ||
    CNPJ === '22222222222222' ||
    CNPJ === '33333333333333' ||
    CNPJ === '44444444444444' ||
    CNPJ === '55555555555555' ||
    CNPJ === '66666666666666' ||
    CNPJ === '77777777777777' ||
    CNPJ === '88888888888888' ||
    CNPJ === '99999999999999') {
    return false;
  } // if

  // Valida DVs
  let tamanho: number = CNPJ.length - 2;
  let soma: number = 0;
  let pos: number = tamanho - 7;
  let numeros: string = CNPJ.substring(0, tamanho);
  const DIGITOS = CNPJ.substring(tamanho);

  for (let i = tamanho; i >= 1; i--) {
    soma += +numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    } // if
  } // for

  let resultado: number = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== +DIGITOS.charAt(0)) {
    return false;
  } // if

  tamanho = tamanho + 1;
  numeros = CNPJ.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += +numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    } // if
  } // for

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado !== +DIGITOS.charAt(1)) {
    return false;
  } // if

  return true;
}

