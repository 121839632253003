<!-- <p>itens {{ itens | json }}</p> -->
<!-- <p>carrinho {{ carrinho | json }}</p> -->
<!-- <p>stage {{ stage }}</p> -->

<div *ngIf="!!itens?.length">
  <table class="desktop table table-striped">
    <thead>
      <tr>
        <th scope="col"
            class="row-img">
        </th>

        <th scope="col">
          Produto
        </th>

        <th scope="col"
            class="text-center">
          Solicitado
        </th>

        <th scope="col"
            class="text-center">
          Encontrado
        </th>

        <th scope="col"
            class="text-center">
          Separado
        </th>

        <th scope="col"
            class="text-end">
          Preço
        </th>

        <th scope="col"
            class="text-end"
            *ngIf="stage === 'separando'">
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let i of itens; trackBy:tid_ite"
          [class.off]="!!i?.__sel">
        <td class="row-img">
          <div class="thumb pointer">
            <img [src]="i?.produto?.__img?.thumb"
                 class="img-fluid">
          </div>
        </td>

        <td>
          <div>
            <app-item-status-badge [itemStatus]="i?._status">
            </app-item-status-badge>
            <br>
            <small>{{ i?.produto?.nome }}</small>
            <small class="o-50"
                   *ngIf="i?.produto?._base?.embalagem">
              ( {{ i?.produto?._base?.embalagem }} )
            </small>
          </div>

          <div *ngIf="!!i?.obs">
            <small class="o-50 italic mt-1">
              Obs: {{ i?.obs }}
            </small>
          </div>
        </td>

        <td class="text-center">
          <button type="button"
                  class="btn btn-light"
                  [class.sel]="stage === 'pendentes' && i?.__sel"
                  [class.off]="stage !== 'pendentes' || !!i?._separado?.qtde?.encontrado"
                  (click)="onSolicitadoClick(i)">
            <separacao-qtde-caption [item]="i"
                                    tipoQtde="solicitado" />
          </button>
        </td>

        <td class="text-center">
          <button type="button"
                  class="btn btn-light"
                  [class.off]="['separando', 'separados'].includes(stage) || (stage === 'pendentes' && !!qtdeSel)"
                  (click)="onEncontradoClick(i)">
            <separacao-qtde-caption [item]="i"
                                    tipoQtde="encontrado" />
          </button>
        </td>

        <td class="text-center">
          <button type="button"
                  class="btn btn-light"
                  [class.off]="stage === 'separando' || (stage === 'pendentes' && !!qtdeSel) || !i?._separado?.qtde?.encontrado"
                  (click)="onSeparadoClick(i)">
            <separacao-qtde-caption [item]="i"
                                    tipoQtde="separado" />
          </button>
        </td>

        <td class="text-end">
          <me-html-item-carrinho-preco [item]="i"
                                       [isInline]="false" />
          <!-- {{ i?._separado?.qtde?.__dif }} {{ i?.id }} -->
        </td>

        <td *ngIf="stage === 'separando'">
          <button mat-icon-button
                  matTooltip="Remover da seleção"
                  color="danger"
                  (click)="onRemoverClick(i)">
            <div class="icon-o-trashbin"></div>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>