<form [formGroup]="cepForm"
      class="d-flex flex-column text-center"
      novalidate
      (ngSubmit)="onSubmitClick()">
  <div class="form-group">
    <input type="text"
           class="form-control text-center cep mb-2"
           [placeholder]="placeholder"
           mask="00000-000"
           [showMaskTyped]="false"
           formControlName="cep"
           [focus]="cepFocusEvent$()">
  </div>

  <button type="submit"
          class="btn btn-lg btn-primary w-100 pointer"
          [disabled]="!cepForm.valid">
    <span class="icon-b-search o-50"></span>
    &nbsp;BUSCAR
  </button>

  <div class="mt-2">
    <a href="https://buscacepinter.correios.com.br/app/endereco/index.php"
       target="_blank"
       class="btn btn-link-primary"
       role="button"
       aria-disabled="true">
      NÃO SEI O CEP!
    </a>
  </div>
</form>