//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  addDoc,
  collection,
  doc,
  Firestore,
  setDoc
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  from,
  Observable,
  ObservableInput,
  throwError
} from "rxjs";
import { catchError } from "rxjs";
//#endregion

//#region models
import { INotificacao } from '../../_misc/_models/_interfaces/_cols';
import { TOperadorRole } from '../../_misc/_models/_types';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class NotificacoesService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region events
  static notificacaoChanged$: BehaviorSubject<INotificacao> = new BehaviorSubject<INotificacao>(null);
  //#endregion

  //#region misc
  fix(
    row: Partial<INotificacao>,
    // contaId: string = ''
  ): INotificacao {
    const ID_CONTA_SOURCE: string = row?.source?.idConta || '';
    const ID_CONTA_TARGET: string = row?.target?.idConta || '';
    const ROLE: TOperadorRole = row?.target?.role || '';
    // const ROLE_TARGET: TOperadorRole = row?.target?.role;

    return {
      // id
      id: row?.id || '',

      // user
      caption: row?.caption || '',
      msg: row?.msg || '',
      source: {
        idConta: row?.source?.idConta || '',
        nome: row?.source?.nome || '',
      },
      target: {
        idConta: row?.target?.idConta || '',
        idIntegradora: row?.target?.idIntegradora || '',
        role: ROLE,
        nome: row?.target?.nome || '',
      },

      // system
      _criadoEm: row?._criadoEm || '',
      _lido: !!row?._lido,
      _lidoEm: row?._lidoEm || null,
      // _pos: number;

      // realtime
      __displayInPagination: !!row?.__displayInPagination,
      // __icon: '',
      __response: row?.source?.idConta !== row?.target?.idConta,
    };

    // R.__icon = '';
    // if (!!contaId) {
    //   if (contaId === ID_CONTA_SOURCE) {
    //     R.__icon = contaId === ID_CONTA_TARGET ? 'icon-r-transfer' : 'icon-b-arrow-r-2';
    //   } else if (
    //     contaId === ID_CONTA_TARGET
    //     // || ROLE === ROLE_TARGET
    //   ) {
    //     R.__icon = 'icon-b-arrow-l';
    //   } // if
    // } // if

    // return R;

    // row = row || {};
    // let R: INotificacao | Partial<INotificacao> = row;
    // // console.log(R);
    // // console.log('contaId', contaId);

    // // id
    // R.id = row?.id || '';

    // const ID_CONTA_SOURCE: string = row?.source?.idConta || '';
    // const ID_CONTA_TARGET: string = row?.target?.idConta || '';
    // const ROLE: TOperadorRole = row?.target?.role;
    // const ROLE_TARGET: TOperadorRole = row?.target?.role;

    // // user
    // // R.ativo = {
    // //   status: !!get(row, 'ativo.status')
    // // };
    // R.caption = row?.caption || '';
    // R.msg = row?.msg || '';
    // R.source = {
    //   idConta: ID_CONTA_SOURCE,
    //   nome: row?.source?.nome || '',
    // };
    // R.target = {
    //   idConta: ID_CONTA_TARGET,
    //   idIntegradora: row?.target?.idIntegradora || '',
    //   nome: row?.target?.nome || '',
    //   role: ROLE,
    // };

    // // system
    // R._criadoEm = row?._criadoEm || null;
    // R._lido = !!row?._lido;
    // R._lidoEm = row?._lidoEm || null;

    // // realtime
    // R.__displayInPagination = !!row?.__displayInPagination;
    // R.__icon = '';
    // if (contaId) {
    //   if (contaId === ID_CONTA_SOURCE) {
    //     R.__icon = contaId === ID_CONTA_TARGET ? 'icon-r-transfer' : 'icon-b-arrow-r-2';
    //   } else if (
    //     contaId === ID_CONTA_TARGET
    //     || ROLE === ROLE_TARGET
    //   ) {
    //     R.__icon = 'icon-b-arrow-l';
    //   } // if
    // } // if

    // return R;
  }

  fixes(docs: any[] = []): INotificacao[] {
    return (docs || []).map((doc: Partial<INotificacao>) => this.fix(doc));
  }
  //#endregion

  //#region C  
  add(
    caption: string,
    msg: string,
    idContaSource: string,
    nomeSource: string,
    target: string,
    nomeTarget: string,
    tipoTarget: 'conta' | 'integradora' | 'role'
  ): Observable<any> {
    if (!!msg) {
      if (!!idContaSource) {
        if (
          !!target
          && ['conta', 'integradora', 'role'].includes(tipoTarget)
        ) {
          const PATH: string = `_notificacoes`;
          return from(
            addDoc(
              collection(this.#db, PATH),
              {
                caption,
                msg,
                source: {
                  idConta: idContaSource || '',
                  nome: nomeSource || '',
                },
                target: {
                  idConta: tipoTarget === 'conta' && target,
                  idIntegradora: tipoTarget === 'integradora' && target,
                  role: tipoTarget === 'role' && target,
                  nome: nomeTarget || '',
                },
              },
            )
          )
            .pipe(
              // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro adicionando notificação.')
              )
            );
        } // if
        return throwError(() => 'Nenhum destino indicado ou inválido.');
      } // if
      return throwError(() => 'Nenhuma origem indicada.');
    } // if
    return throwError(() => 'Nenhuma mensagem indicada.');
  }
  //#endregion

  //#region U
  update(
    idNotificacao: string,
    changes: any,
  ): Observable<any> {
    if (!!idNotificacao) {
      const PATH: string = `_notificacoes/${idNotificacao}`;
      return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
        .pipe(
          // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando notificação.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma integradora indicada.');
  }
  //#endregion
}
