export * from './_alert-dialog/alert-dialog.component';
export * from './_algolia-form/algolia-form.component';
export * from './_completa-endereco-form/completa-endereco-form.component';
export * from './_confirm-dialog/confirm-dialog.component';
export * from './_credit-card-form/credit-card-form.component';
export * from './_email-get-form/email-get-form.component';
export * from './_endereco-form/endereco-form.component';
export * from './_login-email-form/login-email-form.component';
export * from './_login-fone-form/login-fone-form.component';
export * from './_manage-endereco/manage-endereco.component';
export * from './_manage-identificacao/manage-identificacao.component';
export * from './_messages/messages.component';
export * from './_ngx-pagination-nav/ngx-pagination-nav.component';
export * from './_pagination-nav/pagination-nav.component';
export * from './_pag-sel-offset/pag-sel-offset.component';
export * from './_register-email-form/register-email-form.component';
export * from './_repeat-form/repeat-form.component';
export * from './_senha-confirmacao-form/senha-confirmacao-form.component';
export * from './_tristate-toggle/tristate-toggle.component';
export * from './_viewstyle-sel/viewstyle-sel.component';
