//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
  //   setDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
//#endregion

//#region services
import { IMeilisearch } from '../../_misc/_models/_interfaces/_cols';
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class MeilisearchService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IMeilisearch>): IMeilisearch {
    return {
      // id
      id: row?.id || '',

      // user
      appId: row?.appId || '',
      host: row?.host || '',
      nome: row?.nome || '',
      searchKey: row?.searchKey || '',
      adminKey: row?.adminKey || '',

      // system
      _criadoEm: row?._criadoEm || null,
    };
  }

  fixes(docs: Partial<IMeilisearch>[]): IMeilisearch[] {
    return (docs || []).map((doc: Partial<IMeilisearch>) => this.fix(doc));
  }
  //#endregion

  //#region R
  doc(idMeilisearch: string): Observable<IMeilisearch> {
    // console.log('iMeilisearch', iMeilisearch);
    if (!!idMeilisearch) {
      const PATH: string = `/_meilisearch/${idMeilisearch}`;
      // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IMeilisearch>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IMeilisearch, ObservableInput<IMeilisearch>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando meilisearch.')
          )
        );
    } // if
    return of(null); //  throwError(() => 'Nenhum meilisearch indicado.');
  }

  docs(): Observable<IMeilisearch[]> {
    const PATH: string = `/_meilisearch/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH)) as Observable<IMeilisearch[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<any, ObservableInput<any>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando meilisearchs.')
        )
      );
  }
  //#endregion
}