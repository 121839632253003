//#region ng
import {
  Component,
  inject,
  Input,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  combineLatest,
  Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  hasSeparado: boolean;
  itens: IItemCarrinhoMap[];
  pedido: IPedido;
  searchTerm: string;
};
import { IItemCarrinhoMap } from '../../../_misc/_models/_interfaces/_maps';
import { IPedido } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { desacentua } from '../../../../_libs/_misc/_strings';
//#endregion

//#region services
import {
  ItensCarrinhoService,
  PedidosService
} from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-html-pedido-itens',
  templateUrl: './pedido-itens.component.html',
  styleUrls: ['./pedido-itens.component.scss']
})
export class MeHtmlPedidoItensComponent {
  //#region actions
  #searchTermAction$ = new BehaviorSubject<string>('');
  #pedidoAction$ = new BehaviorSubject<Partial<IPedido>>(null);
  //#endregion

  //#region inputs
  // pedido
  #pedido: Partial<IPedido>;
  get pedido(): Partial<IPedido> { return this.#pedido; }
  @Input({ required: true }) set pedido(val: Partial<IPedido>) {
    this.#pedido = val;
    // this._itens = val?.itens || [];
    // this._refresh();
    this.#pedidoAction$.next(this.pedido);
  }
  // isMobile
  @Input() isMobile: boolean = false;
  // searchTerm
  #searchTerm: string = '';
  get searchTerm(): string { return this.#searchTerm; }
  @Input() set searchTerm(val: string) {
    this.#searchTerm = val;
    // console.log(val);
    // this._refresh();
    this.#searchTermAction$.next(this.searchTerm);
  }
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #pedidosServ = inject(PedidosService);
  #itensServ = inject(ItensCarrinhoService);
  //#endregion

  //#region functions
  tid_ite(index: any, item: IItemCarrinhoMap): string { return item?.id || ''; }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        this.#searchTermAction$,
        this.#pedidoAction$,
      ])
        .pipe(
          map(([searchTerm, pedido]) => {
            // console.log(pedido);
            const SEARCH_TERM: string = desacentua((searchTerm || '').trim().toLowerCase());
            const SEPARADO_ITENS: IItemCarrinhoMap[] = pedido?.carrinho?._separado?.itens || [];
            const ITENS: IItemCarrinhoMap[] = this.#itensServ.fixes(
              !!SEPARADO_ITENS?.length
                ? SEPARADO_ITENS
                : pedido?.carrinho?.__solicitado?.itens || []
            )
              .filter(
                (i: IItemCarrinhoMap) => {
                  // console.log(i);
                  const ITEM_NOME: string = (i?.produto?.nome || '').trim().toLowerCase()
                  // console.log(ITEM_NOME, FILTER);
                  return desacentua(ITEM_NOME).includes(searchTerm);
                }
              );

            const VM: IVm = {
              searchTerm: SEARCH_TERM,
              pedido: !!pedido ? this.#pedidosServ.fix(pedido) : null,
              itens: ITENS,
              hasSeparado: !!SEPARADO_ITENS?.length,
            };
            // console.log(VM);
            return VM;
          })
        )
    );
  }
  //#endregion
}
