<ng-container *ngIf="vm$() | async as vm">
  <div class="loader-container"
       *ngIf="vm?.isLoading">
    <div class="spinner-container">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
  </div>

  <div class="page d-flex flex-column">
    <div class="header d-flex justify-content-between p-4">
      <div class="my-auto flex-1">
        <img src="assets/img/logo_mercadeiro_dark.svg"
             class="ms-2 img-fluid pointer"
             alt=""
             routerLink="/pedidos">
        <span class="my-auto ms-2 primary o-50 rem-20">// monitor
        </span>
      </div>

      <div class="my-auto">
        <div class="d-flex me-2">
          <me-bs-loja class="flex-1"
                       [loja]="loja()" />
        </div>
      </div>

      <div class="my-auto">
        <div class="d-flex">
          <me-mat-operador-actions class="flex-1"
                                   [conta]="vm?.conta"
                                   (logout$)="onLogoutClick()" />
        </div>
      </div>

      <!-- <div class="my-auto">
        <div class="m-auto">
          <button mat-icon-button
                  matTooltip="Configurações"
                  (click)="onConfigClick()">
            <span class="ms-2 o-70 icon-s-cog rem-15"></span>
          </button>
        </div>
      </div> -->
    </div>

    <div class="body d-flex flex-1">
      <div class="routes flex-1 w-100">

        <!-- <p>vm {{ vm | json }}</p> -->

        <router-outlet />
      </div>
    </div>
  </div>
</ng-container>