//#region ng
import {
  Component,
  Input,
  inject
} from '@angular/core';
//#endregion

//#region models
import { IProduto } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

@Component({
  selector: 'me-bs-produto-promocoes',
  templateUrl: './produto-promocoes.component.html',
  styleUrls: ['./produto-promocoes.component.scss']
})
export class MerBsProdutoPromocoesComponent {
  //#region inputs
  @Input({ required: true }) produto: IProduto;
  //#endregion
}
