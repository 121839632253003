<form novalidate
      class="py-3"
      [formGroup]="emailForm()"
      (ngSubmit)="submit$.emit(emailRef.value)"
      *ngIf="emailForm()">

  <mat-form-field class="w-100 mb-2"
                  [appearance]="isMobile ? 'fill' : 'outline'">
    <mat-label>E-mail</mat-label>
    <input type="email"
           matInput
           formControlName="email"
           [focus]="emailFocusEvent$()"
           onReturn>
    <mat-hint *ngIf="fv()?.error(emailRef, 'email')">
      <span class="danger"> {{ fv()?.error(emailRef, 'email') }} </span>
    </mat-hint>
  </mat-form-field>

  <button type="submit"
          class="pointer w-100"
          mat-raised-button
          color="primary"
          [disabled]="!emailForm()?.valid">
    {{ submitCaption }}
  </button>
</form>