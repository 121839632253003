//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region models
import { ICarrinhoMap } from '../../_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
import { mapCarrinhoInfo } from '../../_misc/_libs';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {
  //#region misc
  fix(row: Partial<ICarrinhoMap>): ICarrinhoMap {
    // console.log(row);
    // console.log(row?.__solicitado);

    // solicitado
    const SOLICITADO_PROD_LIQ: number = Number(row?.__solicitado?.total?.liq) || 0;
    // loja
    const LOJA_ENT_PED_MINIMO: number = Number(row?.__loja?.pedidos?.minimo?.entrega) || 0;
    const LOJA_RET_PED_MINIMO: number = Number(row?.__loja?.pedidos?.minimo?.retirada) || 0;
    // const LOJA_TAXA_ENTREGA: number = 0;

    return {
      // id
      id: row?.id || '',

      // system
      _separado: mapCarrinhoInfo(
        row as ICarrinhoMap,
        true
      ),

      // realtime
      __solicitado: mapCarrinhoInfo(
        row as ICarrinhoMap,
        false
      ),
      __limites: {
        entrega: {
          perc: !!LOJA_ENT_PED_MINIMO ? SOLICITADO_PROD_LIQ / LOJA_ENT_PED_MINIMO : 100,
          val: LOJA_ENT_PED_MINIMO,
        },
        retirada: {
          perc: !!LOJA_RET_PED_MINIMO ? SOLICITADO_PROD_LIQ / LOJA_RET_PED_MINIMO : 100,
          val: LOJA_RET_PED_MINIMO,
        },
      },
      __loja: {
        faturamento: {
          cod: row?.__loja?.faturamento?.cod || '',
          mensalidade: Number(row?.__loja?.faturamento?.mensalidade) || 0,
          servicos: {
            anistiaMeses: Number(row?.__loja?.faturamento?.servicos?.anistiaMeses) || 0,
            percCliente: Number(row?.__loja?.faturamento?.servicos?.percCliente) || 0,
            piso: Number(row?.__loja?.faturamento?.servicos?.piso) || 0,
            __valCliente: Number(row?.__loja?.faturamento?.servicos?.__valCliente) || 0,
            valor: Number(row?.__loja?.faturamento?.servicos?.valor) || 0,
          },
        },
        id: row?.__loja?.id || '',
        integradora: {
          id: row?.__loja?.integradora?.id || '',
          _nome: row?.__loja?.integradora?._nome || '',
        },
        isRetirada: !!row?.__loja?.isRetirada,
        nome: row?.__loja?.nome || '',
        parceiro: {
          id: row?.__loja?.parceiro?.id || '',
          _nome: row?.__loja?.parceiro?._nome || '',
        },
        pedidos: {
          limiteDiasAgendamento: {
            entrega: Number(row?.__loja?.pedidos?.limiteDiasAgendamento?.entrega) || 0,
            retirada: Number(row?.__loja?.pedidos?.limiteDiasAgendamento?.retirada) || 0,
          },
          minimo: {
            entrega: Number(row?.__loja?.pedidos?.minimo?.entrega) || 0,
            retirada: Number(row?.__loja?.pedidos?.minimo?.retirada) || 0,
          },
          valorCarrinhoEntregaGratis: Number(row?.__loja?.pedidos?.valorCarrinhoEntregaGratis) || 0,
        },
        taxaEntrega: {
          _liquido: Number(row?.__loja?.taxaEntrega?._liquido) || 0,
          desc: Number(row?.__loja?.taxaEntrega?.desc) || 0,
          val: Number(row?.__loja?.taxaEntrega?.val) || 0,
        },
      },
    };
  }
  //#endregion
}
