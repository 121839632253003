<div *ngIf="(msgServ.msgChanged$ | async) as msg">
  <div class="msg-wrapper {{ msg?.theme }} p-2 d-flex justify-content-between text-center"
       *ngIf="msg?.scope === 'all' || (!!isMain ? msg?.scope === 'main' : msg?.scope === 'dialog')">
    <div class="my-auto">
      <span class="o-50 rem-25"
            [class]="icons()[msg?.theme]">
      </span>
    </div>

    <div class="rem-14 my-auto">
      {{ msg?.msg }}
    </div>

    <div class="my-auto">
      <button mat-icon-button
              color="light"
              (click)="onFecharClick()">
        <span class="icon-b-cancel"></span>
      </button>
    </div>
  </div>
</div>