<!-- <p>produto?.__promocoes {{ produto?.__promocoes | json }}</p> -->

<ng-container *ngIf="!!produto?.__promocoes">
  <ul class="promocoes-chips"
      *ngFor="let p of produto?.__promocoes">
    <li [class.active]="!!p?.__aplicada"
        *ngIf="p?.__visivel">
      {{p?.__caption}}
    </li>
  </ul>
</ng-container>
