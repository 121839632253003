//#region ng
import { Component, computed, signal } from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region models
import { environment } from "src/environments/environment";
import { CorFbMessaging } from '../../_shared/_core/_ng/_models/_classes';
// import { ITerminal } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { HOME_ROUTE } from '../../models/consts';
import { TerminaisService } from '../../_shared/_mercadeiro/_ng/_services';
import { ITerminal } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { md5 } from '../../_shared/_libs/_misc/_strings';
import { copyToClipboard } from '../../_shared/_libs/_www/_system';
//#endregion

//#region stores
import { TerminaisStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-habilita-terminal',
  templateUrl: './habilita-terminal.page.html',
  styleUrls: ['./habilita-terminal.page.scss']
})
export class HabilitaTerminalPage {
  //#region publics
  terminalId = signal<string>(null);
  //#endregion

  //#region privates
  private _messaging: CorFbMessaging;
  private _terminalUnsub: FbUnsubscribe;
  //#endregion

  //#region constructor
  constructor(
    private _db: Firestore,
    // private _terminaisServ: TerminaisService,
    private _terminaisStore: TerminaisStore,
    private _router: Router,
    private _snackbar: MatSnackBar
  ) {
    this._messaging = new CorFbMessaging(
      environment?.firebase?.config?.vapidKey || '',
      environment?.firebase?.region || ''
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // this.terminalId = '';
    this._messaging.getToken()
      .then(
        (token: string) => {
          // console.log(token);
          if (!!token) {
            // this.terminalId.set(md5(token));
            this.terminalId.set(md5(token) || '');
            console.log(this.terminalId());
            !!this._terminalUnsub && this._terminalUnsub();
            if (!!this.terminalId) {
              const PATH: string = `_terminais/${this.terminalId()}`;
              // console.log(PATH);
              this._terminalUnsub = onSnapshot(
                doc(this._db, PATH),
                snap => {
                  const TERMINAL: ITerminal = !!snap.data()
                    ? {
                      id: snap.id,
                      ...snap.data()
                    } as ITerminal
                    : null;
                  // console.log(TERMINAL);
                  this._terminaisStore.setState(TERMINAL);
                  !!TERMINAL && this._router.navigateByUrl(HOME_ROUTE);
                }
              );
            } // if

          } else {
            this._router.navigateByUrl('/notificacoesBloqueadas');
          } // if
        }
      )
      .catch((err) => this._router.navigateByUrl('/notificacoesBloqueadas'));
  }
  //#endregion

  //#region methods
  onCopiarClick() {
    copyToClipboard(this.terminalId());
    this._snackbar.open('Identificador copiado para Área de transferência.');
  }
  //#endregion

  /*
  //#region publics
  // terminal: ITerminal;
  //#endregion

  //#region terminalId
  private _terminalId: string;
  get terminalId(): string {    return this._terminalId;}
  set terminalId(val: string) {
    this._terminalId = val;
    // console.log(val);
    !!this._terminalUnsub && this._terminalUnsub();
    if (this._terminalId) {
      const PATH: string = `_terminais/${this._terminalId}`;
      // console.log(PATH);
      const PRODUTO_REF = doc(
        this._db,
        PATH
      );
      this._terminalUnsub = onSnapshot(
        PRODUTO_REF,
        snap => {
          const TERMINAL: ITerminal = !!snap.data()
            ? {
              id: snap.id,
              ...snap.data()
            } as ITerminal
            : null;
          // this._terminaisServ.setState(TERMINAL);
          !!TERMINAL && this._router.navigateByUrl(HOME_ROUTE);
        }
      );
    } // if
  };
  //#endregion


*/
}
// https://dev.to/rodrigokamada/adding-the-social-media-share-buttons-component-to-an-angular-application-320h
