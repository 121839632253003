//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region libs
import { formataCPF } from '../../../_libs/_misc/_formats';
//#endregion

@Pipe({ name: 'cpf' })
export class CorFormataCpf implements PipeTransform {
  transform(value: string): string {
    return formataCPF(value);
  }
}
