//#region models
import {
  ILoja,
  IPedido,
} from "../_models/_interfaces/_cols";
// import { ICarrinhoMap } from "../_models/_interfaces/_maps";
//#endregion

export const formataNumPedido = (value: any): string => { // numPedido
  if (!!value) {
    const VALUE = `${value}`;
    // console.log(VALUE, VALUE?.length);
    if (VALUE.length === 13) {
      return VALUE.substring(0, 5) + '-' + VALUE.substring(5, 9) + '-' + VALUE.substring(9);
    } // if
  } // if
  return '';
};

export function fixPedido( // calcPedido
  pedido: Partial<IPedido>,
  loja: ILoja | null = null,
  // carrinho: ICarrinhoMap,
  // isRetirada: boolean | null = null,
): Partial<IPedido> {
  // console.log(pedido);
  // console.log(loja);
  
  // const LOJA_TAXA_ENTREGA: number = 0;
  // const LOJA_TAXA_SERVICOS_CLIENTE: number = !!loja
  //   ? Number(loja?.faturamento?.servicos?.__valCliente) || 0
  //   : Number(pedido?.__total?.valorTaxaServ) || 0;
  // const PRODUTOS_TOTAL: number = !!pedido?._isSeparado
  //   ? Number(pedido?.carrinho?._separado?.total?.liq) || 0
  //   : Number(pedido?.carrinho?.__solicitado?.total?.liq) || 0;

  // return {
  //   ...pedido,
  //   __total: {
  //     valorTaxaServ: LOJA_TAXA_SERVICOS_CLIENTE,
  //     valorTaxaEntrega: LOJA_TAXA_ENTREGA,
  //     valorProdutos: PRODUTOS_TOTAL,
  //     totalPagar: PRODUTOS_TOTAL + LOJA_TAXA_SERVICOS_CLIENTE + LOJA_TAXA_ENTREGA,
  //   },
  // };

  return pedido;
}
