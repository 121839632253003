<ng-container *ngIf="vm$() | async as vm">
  <div class="d-flex">
    <div class="col search">
      <mat-form-field appearance="outline"
                      class="w-100">
        <mat-label>Nome</mat-label>
        <button mat-button
                matPrefix
                mat-icon-button>
          <mat-icon class="o-50">search</mat-icon>
        </button>
        <input matInput
               type="text"
               [focus]="searchFocusEvent$()"
               [ngModel]="searchTermAction$() | async"
               (ngModelChange)="searchTermAction$()?.next($event)">
        <button mat-button
                *ngIf="!!vm?.searchTerm"
                matSuffix
                mat-icon-button
                (click)="searchTermAction$()?.next('')">
          <mat-icon class="o-50">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <mat-chip-list>
    <mat-chip *ngFor="let t of vm?.tipos; trackBy:tid_tip"
              [selected]="t?.id === idSelected"
              [class.pointer]="!!idSelected"
              (click)="click$.emit(t)">
      {{ t?.nome }}
    </mat-chip>
  </mat-chip-list>

  <div class="alert alert-light text-center"
       role="alert"
       *ngIf="!vm?.tipos?.length">
    Nenhuma tipo de loja.
  </div>
</ng-container>