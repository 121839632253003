<mat-stepper linear
             orientation="vertical"
             labelPosition="bottom"
             #stepper>
  <mat-step label="Telefone"
            [stepControl]="foneForm()">
    <div class="alert alert-light text-center"
         role="alert">
      Indique seu número de celular para receber um SMS com o código de liberação.
    </div>
    <form [formGroup]="foneForm()">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <span matPrefix>
          <span class="o-50"> +55&nbsp;&nbsp; </span>
        </span>
        <input matInput
               type="text"
               mask="(00) 00000-0000"
               [focus]="onFoneFocusEvent$()"
               formControlName="fone"
               onReturn>
        <mat-hint>
          Ex +55 (34) 91234-1234
        </mat-hint>
      </mat-form-field>

      <div class="d-flex flex-column">
        <div class="m-auto"
             id="recaptcha-container">
        </div>

        <button mat-raised-button
                color="primary"
                class="flex-1 mt-2"
                [disabled]="!foneForm().valid"
                (click)="sendSMSCode()">
          ENVIAR SMS
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step label="SMS"
            [stepControl]="otpForm()">
    <form [formGroup]="otpForm()">
      <div class="alert alert-light text-center"
           role="alert">
        Indique o código de 6 dígitos recebido por SMS no telefone.
      </div>

      <!-- <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'"> -->
      <input type="text"
             mask="000000"
             class="form-control otp"
             [focus]="onOTPFocusEvent$()"
             formControlName="otp"
             onReturn>
      <!-- </mat-form-field> -->

      <div class="d-flex text-center">
        <button mat-raised-button
                color="primary"
                class="flex-1 mt-2"
                [disabled]="!otpForm()?.valid"
                (click)="onVerificaOTP()">
          VERIFICAR CÓDIGO
        </button>
      </div>

      <div class="d-flex text-center">
        <button mat-flat-button
                color="light"
                class="flex-1 mt-2"
                (click)="onVoltarFone()">
          <span class="icon-r-chevron-l me-2 o-50"> Voltar para</span>{{ foneRef.value | fone
          }}
        </button>
      </div>
    </form>
  </mat-step>
</mat-stepper>

<!-- 

<mat-form-field class="w-100 mb-2"
                [appearance]="isMobile ? 'fill' : 'outline'">
  <span matPrefix>
    <span class="o-50"> +55&nbsp;&nbsp; </span>
  </span>
  <input matInput
         required
         type="text"
         minlength="11"
         mask="(00) 00000-0000"
         [readonly]="waiting"
         [focus]="onFoneFocusEvent$"
         [(ngModel)]="fone"
         onReturn>
  <mat-hint>
    Ex +55 (34) 91234-1234
  </mat-hint>
</mat-form-field>

<div *ngIf="waiting">
  <hr>

  <div class="alert alert-light text-center"
       role="alert">
    Indique o código de 6 dígitos que recebeu em seu celular.
  </div>

  <mat-form-field class="w-100 mb-2"
                  [appearance]="isMobile ? 'fill' : 'outline'">
    <input matInput
           required
           type="text"
           minlength="6"
           maxlength="6"
           [focus]="onCodeFocusEvent$"
           [(ngModel)]="verificationCode"
           onReturn>

    <button mat-raised-button
            class="btn bg-primary btn-block"
            (click)="verifyLoginCode()">
      VERIFICAR CÓDIGO
    </button>
  </mat-form-field>
</div> -->




<!-- <div [hidden]="user">
  <div class="alert alert-light text-center"
       role="alert"
       *ngIf="!busy">
    Indique o número de seu celular para receber um SMS com seu código de liberação.
  </div>

  <mat-form-field class="w-100 mb-2"
                  [appearance]="isMobile ? 'fill' : 'outline'">
    <!-- <mat-label>Número celular</mat-label>
    <span matPrefix>
      <span class="o-50"> +55&nbsp;&nbsp; </span>
    </span>
    <input matInput
           required
           type="text"
           minlength="11"
           mask="(00) 00000-0000"
           [focus]="onFoneFocusEvent$"
           [(ngModel)]="fone"
           [readonly]="busy"
           onReturn>
    <mat-hint>
      Ex +55 (34) 91234-1234
    </mat-hint>
  </mat-form-field>

  <div class="d-flex flex-column"
       *ngIf="!busy">
    <div class="m-auto"
         id="recaptcha-container">
    </div>

    <button mat-raised-button
            class="btn bg-light btn-block mt-2"
            [disabled]="fone?.length !== 11"
            (click)="sendLoginCode()">
      Solicitar código SMS
    </button>
  </div>

  <div *ngIf="busy">
    <hr>

    <div class="alert alert-light text-center"
         role="alert">
      Indique o código de 6 dígitos que recebeu em seu celular.
    </div>

    <mat-form-field class="w-100 mb-2"
                    [appearance]="isMobile ? 'fill' : 'outline'">
      <!-- <mat-label>Digite o código de verificação</mat-label>
      <input matInput
             required
             type="text"
             minlength="6"
             maxlength="6"
             [focus]="onCodeFocusEvent$"
             [(ngModel)]="verificationCode"
             onReturn>
      <!-- <mat-hint>
        Insira o código de 6 dígitos que recebeu em seu celular.
      </mat-hint>
    </mat-form-field>

    <button mat-raised-button
            class="btn bg-primary btn-block"
            (click)="verifyLoginCode()">
      VERIFICAR CÓDIGO
    </button>
  </div>
</div>
 -->