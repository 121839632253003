//#region ng
import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors
} from '@angular/forms';
//#endregion

//#region firebase
import { Firestore } from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  Observable,
  of,
} from 'rxjs';
import {
  // debounceTime,
  first,
  map,
} from 'rxjs/operators';
//#endregion

//#region models
import { CorFormValidation } from '../../../../_core/_ng/_models/_classes';
// import { IConta } from '../../../../_misc/_models/_interfaces/_cols';
import { IIntegradora } from '../../../_misc/_models/_interfaces/_cols';
import { FOCUS_TIMEOUT } from '../../../../_core/_misc/_models/consts';
//#endregion

//#region custom validators
class CustomValidator {
  static idRepeated(
    integradorasServ: IntegradorasService,
    loaderServ: CorLoaderService
  ) {
    return (control: FormControl): Observable<ValidationErrors | null> => {
      const ID = (control.value || '').toLowerCase();
      // console.log(ID);
      if (!!ID) {
        return loaderServ.showUntilCompleted(
          integradorasServ?.doc(ID)
            .pipe(
              first(),
              // debounceTime(500),
              map((integradora: IIntegradora) => !!integradora ? { idRepeated: true } : null)
            )
        )
      } else {
        return of(null);
      } // else
    }
  }
}
//#endregion

//#region services
import { IntegradorasService } from '../../../_ng/_services';
import { CorLoaderService } from '../../../../_core/_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-integradora-form',
  templateUrl: './integradora-form.component.html',
  styleUrls: ['./integradora-form.component.scss']
})
export class MeMatIntegradoraFormComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // integradora
  @Input({ required: true }) integradora: IIntegradora;
  //#endregion

  //#region outputs
  // @Output() submit$ = new EventEmitter<Partial<IIntegradora>>();
  //#endregion

  //#region publics
  fv = signal<CorFormValidation>(null);
  novo = signal<boolean>(false);
  integradoraForm = signal<FormGroup>(null);
  //#endregion

  //#region events
  idFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  _fb = inject(FormBuilder);
  _integradorasServ = inject(IntegradorasService);
  _loaderServ = inject(CorLoaderService);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation());
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    const ATIVO: any = this.integradora?.ativo?.status;
    this.integradoraForm.set(
      this._fb.group({
        ativo: this._fb.group({
          status: [ATIVO === undefined ? true : !!ATIVO],
        }),
        id: [
          this.integradora?.id || '',
          {
            validators: [Validators.required],
            asyncValidators: [CustomValidator.idRepeated(this._integradorasServ, this._loaderServ)],
            // updatedOn: 'blur'
          }
        ],
        nome: [this.integradora?.nome || '', [Validators.required]],
        email: [this.integradora?.email || '', [Validators.required, Validators.email]],
      })
    );

    const REDE_ID: string = this.integradora?.id || '';
    this.novo.set(!REDE_ID);
  }


  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region Controls getters
  get idRef(): AbstractControl { return this.integradoraForm()?.get('id'); }
  get nomeRef(): AbstractControl { return this.integradoraForm()?.get('nome'); }
  get emailRef(): AbstractControl { return this.integradoraForm()?.get('email'); }
  //#endregion

  //#region functions
  focus() {
    // console.log(this.isMobile);
    !this.isMobile && setTimeout(
      () => { this.idFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  /* onSubmitClick() {
    this.submit$.emit(this.integradoraForm.value);
    // console.log(
    //   this.integradoraForm.value
    // );
  } */
  //#endregion
}
