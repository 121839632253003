//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  setDoc
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
// #endregion

//#region models
import { ISetorEntrega } from '../../_misc/_models/_interfaces/_cols';
import { ILojaIdInfoRet } from '../../_misc/_models/_interfaces/_rets';
//#endregion

//#region libs
import { calcLojaIdInfo } from '../../_misc/_libs/lojas';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class SetoresEntregaService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<ISetorEntrega>): ISetorEntrega {

    const TS_DESC_PERC: number = Number(row?.taxaEntrega?.desc?.perc) || 0;
    const TS_DESC_MIN_CARRINHO: number = Number(row?.taxaEntrega?.desc?.minTotalCarrinho) || 0;

    return {
      // id
      id: row?.id || '',

      // user
      ativo: {
        _status: !!row?.ativo?._status,
        status: !!row?.ativo?.status,
      },
      bairros: row?.bairros || [],
      nome: row?.nome || '',
      taxaEntrega: {
        val: Number(row?.taxaEntrega?.val) || 0,
        desc: {
          status: !!row?.taxaEntrega?.desc?.status,
          perc: TS_DESC_PERC,
          minTotalCarrinho: TS_DESC_MIN_CARRINHO,
        },
      },

      // system
      _criadoEm: row?._criadoEm || null,
    };
  }

  fixes(docs: Partial<ISetorEntrega>[]): ISetorEntrega[] {
    return (docs || []).map((doc: Partial<ISetorEntrega>) => this.fix(doc));
  }
  //#endregion

  //#region R
  docs(lojaPath: string): Observable<ISetorEntrega[]> {
    if (!!lojaPath) {
      const PATH: string = `${lojaPath}/setores-entrega/${ODOCS_TAG}`;
      // return (docData(doc(this.#db, PATH)) as Observable<IBanner[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}) || [])),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<ISetorEntrega[], ObservableInput<ISetorEntrega[]>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando banners.')
          )
        );
      /* <IBanner[], ObservableInput<IBanner[]>> */
    } // if
    return of(null); // throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region U
  addUpdate(
    idLoja: string,
    idSetor: string,
    changes: Partial<ISetorEntrega>
  ): Observable<any> {
    // console.log(idLoja);
    // console.log(idSetor);
    // console.log(changes);
    if (!!idLoja) {
      const INFO: TNullable<ILojaIdInfoRet> = calcLojaIdInfo(idLoja);
      // console.log(INFO);
      const LOJA_PATH: string = INFO?.lojaPath || '';
      // console.log('PATH', PATH);
      if (!!LOJA_PATH) {
        if (!!idSetor) {
          const PATH: string = `${LOJA_PATH}/setores-entrega/${idSetor}`;
          return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
            .pipe(
              // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando setor de entrega.')
              )
            );
        } else {
          const PATH: string = `${LOJA_PATH}/setores-entrega`;
          // console.log(PATH);
          return from(addDoc(collection(this.#db, PATH), changes))
            .pipe(
              // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro adicionando setor de entrega.')
              )
            );
        } // else
      } // if
      return throwError(() => 'Identificador de loja inválido.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region U
  // /_ufs/mg/localidades/uberaba--mg/lojas/67e4c05a--rede1--mg--uberaba /setores-entrega/EDCyl99DCoEFhI0uG91o
  update(
    lojaPath: string,
    idSetorEntrega: string,
    changes: any // Partial<ILocalidadeBairro>
  ): Observable<any> {
    // console.log(idSetorEntrega);
    // console.log(changes);
    if (!!lojaPath) {
      if (!!idSetorEntrega) {
        const PATH: string = `${lojaPath}/setores-entrega/${idSetorEntrega}`;
        return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
          .pipe(
            // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando setor de entrega.')
            )
          );
      } // if
      return throwError(() => 'Nenhuma loja indicada.');
    } // if
    return throwError(() => 'Nenhum setor de entrega indicado.');
  }

  //#region D
  del(
    idLoja: string,
    idSetor: string
  ): Observable<any> {
    // console.log(changes);
    if (!!idLoja) {
      const INFO: ILojaIdInfoRet | null = calcLojaIdInfo(idLoja);
      // console.log(INFO);
      const LOJA_PATH: string = INFO?.lojaPath || '';
      // console.log('PATH', PATH);
      if (!!LOJA_PATH) {
        if (!!idSetor) {
          const PATH: string = `${LOJA_PATH}/setores-entrega/${idSetor}`;
          return from(deleteDoc(doc(this.#db, PATH)))
            .pipe(
              // map(() => { throw new Error(`Erro apagando ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro apagando setor de entrega.')
              )
            );
        } // if
        return throwError(() => 'Nenhum setor de entrega indicado.');
      } // if
      return throwError(() => 'Identificador de loja inválido.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}