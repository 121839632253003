<!-- <p>pedido {{ pedido | json }}</p> -->
<!-- <p>carrinho {{ carrinho | json }}</p> -->
<!-- <p>isMobile {{ isMobile }}</p> -->

<div *ngIf="!!pedido">
  <div class="d-flex align-items-start"
       [class.flex-column]="!!isMobile">
    <div class="status me-4">
      <me-html-pedido-timeline [idPedido]="pedido?.id"
                               [lojaPath]="lojaPath" />
    </div>

    <div class="flex-1">
      <div class="segment mb-4">
        <ul class="nav justify-content-center nav-pills">
          <li class="nav-item"
              (click)="tab.set('totais')">
            <a class="nav-link"
               [class.active]="tab() === 'totais'">
              Totais
            </a>
          </li>

          <li class="nav-item"
              (click)="tab.set('detalhes')">
            <a class="nav-link"
               [class.active]="tab() === 'detalhes'">
              Detalhes
            </a>
          </li>

          <li class="nav-item"
              (click)="tab.set('produtos')">
            <a class="nav-link"
               [class.active]="tab() === 'produtos'">
              Produtos
            </a>
          </li>
        </ul>
        <!-- <p>pedido?._changes {{ pedido?._changes | json }}</p> -->
      </div>

      <div [ngSwitch]="tab()">
        <div *ngSwitchCase="'totais'">
          <me-mat-pedido-info-totais [pedido]="pedido" />
        </div>

        <div *ngSwitchCase="'produtos'">
          <me-html-pedido-itens [pedido]="pedido"
                                [isMobile]="true" />
          <!-- [hasSeparado]="!!pedido?._separado?.status" -->
          <!-- [itens]="pedido?.carrinho?.__solicitado?.itens" -->
        </div>

        <div *ngSwitchDefault>
          <me-mat-pedido-info-detalhes [pedido]="pedido" />
        </div>
      </div>
    </div>
  </div>
</div>