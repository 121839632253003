//#region ng
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Renderer2
} from "@angular/core";
//#endregion

//#region models
import { FOCUS_TIMEOUT } from '../../../_core/_misc/_models/consts';
//#endregion

@Directive({
  selector: "[focus]"
})
export class CorFocusDirective {
  //#region inputs
  @Input("focus") public focusEvent: EventEmitter<boolean>;
  //#endregion

  //#region contructor
  constructor(
    @Inject(ElementRef) private element: ElementRef,
    private renderer: Renderer2
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // console.log(this.element.nativeElement);
    this.focusEvent?.subscribe(event => {
      // console.log('FocusDirective', event);
      setTimeout(
        () => {
          this.renderer.selectRootElement(
            this.element.nativeElement,
            true
          ).focus();
        },
        FOCUS_TIMEOUT
      );
      // this.renderer.invokeElementMethod(this.element.nativeElement, 'select', []);
    });
  }
  //#endregion
}
