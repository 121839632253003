//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region firebase
import { DocumentReference } from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  combineLatest,
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import {
  finalize,
  first,
  map,
  startWith,
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  pag: {
    itemsPerPage: string;
    page: number;
  };
};
import {
  IMovPedidoPedido,
  IPedido
} from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorLoaderService } from '../../../../_core/_ng/_services';
import { PedidosService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-mov-pedido-itens',
  templateUrl: './mov-pedido-itens.component.html',
  styleUrls: ['./mov-pedido-itens.component.scss']
})
export class MeMatMovPedidoItensComponent {
  //#region actions
  pagPageAction$ = signal<Subject<number>>(new Subject<number>()).asReadonly();
  pagItemsPerPageAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  //#endregion

  //#region inputs
  @Input({ required: true }) pedidos: IMovPedidoPedido[];
  //#endregion

  //#region outputs
  @Output() select$ = new EventEmitter<IPedido>();
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #pedidosServ = inject(PedidosService);
  #loaderServ = inject(CorLoaderService);
  //#endregion

  //#region lifecycles

  ngOnInit() {
    this.vm$.set(
      combineLatest([
        this.pagPageAction$().pipe(startWith(1)),
        this.pagItemsPerPageAction$().pipe(startWith('10')),
      ])
        .pipe(
          map(([pagPage, pagItemsPerPage]) => {
            // console.log(hits);
            const VM: IVm = {
              pag: {
                itemsPerPage: pagItemsPerPage,
                page: pagPage
              },
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }
  //#endregion

  //#region functions
  tid_ite(index: any, item: IMovPedidoPedido): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onDetalhesClick(p: IMovPedidoPedido) {
    // console.log(p);
    const REF: DocumentReference = p?._ref;
    // console.log(REF);
    if (!!REF) {
      const SUB: Subscription = this.#loaderServ?.showUntilCompleted(
        this.#pedidosServ?.docFromRef(REF)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          (pedido: IPedido) => this.select$.next(pedido)
        );
    } // if
  }
  //#endregion
}
