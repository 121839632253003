//#region ng
import {
  Component,
  Input,
} from '@angular/core';
//#endregion

//#region models
import { TItemCarrinhoStatus } from '../../_shared/_mercadeiro/_misc/_models/_types';
import { STATUS_ITEM_PEDIDO } from '../../_shared/_mercadeiro/_misc/_models/consts';
//#endregion

@Component({
  selector: 'app-item-status-badge',
  templateUrl: './item-status-badge.component.html',
  styleUrls: ['./item-status-badge.component.scss']
})
export class ItemStatusBadgeComponent {
  //#region inputs
  @Input({ required: true }) itemStatus: TItemCarrinhoStatus;
  //#endregion

  //#region publics
  statusItemPedido = STATUS_ITEM_PEDIDO;
  //#endregion
}
