//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  // docData,
  DocumentReference,
  Firestore,
  getDoc,
  setDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError
} from 'rxjs';
import {
  catchError,
  map
} from "rxjs/operators";
//#endregion

//#region models
import { IFormaPgto } from '../../_misc/_models/_interfaces/_cols';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { FormasPgtoService } from './formas-pgto.service';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class LojaFormasPgtoService {
  //#region injects
  #db = inject(Firestore);
  #formasPgtoServ = inject(FormasPgtoService);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region C
  add(
    lojaPath: string,
    changes: Partial<IFormaPgto>,
    // id
  ): Observable<any> {
    // console.log(endereco);
    if (!!lojaPath) {
      if (!!changes) {
        const PATH: string = `${lojaPath}/formas-pgto`;
        return from(addDoc(collection(this.#db, PATH), changes)
        )
          .pipe(
            // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
            catchError<Partial<IFormaPgto>, ObservableInput<Partial<IFormaPgto>>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro adicionando forma de pgto.')
            )
          );
      } // if
      return throwError(() => 'Nenhuma forma de pgto indicada.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region R
  doc(
    formaId: string,
    lojaPath: string
  ): Observable<IFormaPgto> {
    // console.log('formaId', formaId);
    if (!!formaId) {
      if (!!lojaPath) {
        const PATH: string = `/${lojaPath}/formas-pgto/${formaId}`;
        // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IFormaPgto>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<IFormaPgto, ObservableInput<IFormaPgto>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando forma de pgto.')
            )
          );
      } // if
      return throwError(() => 'Nenhuma loja indicada.');
    } // if
    return throwError(() => 'Nenhuma forma de pgto indicada.');
  }

  docs(
    lojaPath: string
  ): Observable<IFormaPgto[]> {
    if (!!lojaPath) {
      const PATH: string = `${lojaPath}/formas-pgto/${ODOCS_TAG}`;
      // console.log(PATH);      
      // return (docData(doc(this.#db, PATH),) as Observable<IFormaPgto[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => this.#formasPgtoServ.fixes(Object.values(doc?.data()?._odocs || {}))),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando formas de pgto.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region U
  update(
    lojaPath: string,
    idForma: string,
    changes: Partial<IFormaPgto>
  ): Observable<any> {
    // console.log(idForma);
    // console.log(changes);
    if (!!lojaPath) {
      if (!!idForma) {
        const PATH: string = `${lojaPath}/formas-pgto/${idForma}`;
        return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
          .pipe(
            map((doc: any) => Object.values(doc?._odocs || {}) || []),
            // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando formas de pgto.')
            )
          );
      } // if
      return throwError(() => 'Nenhuma forma de pgto indicada.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}
