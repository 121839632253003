//#region ng
import { CommonModule } from "@angular/common";
// import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
// import { FormsModule } from '@angular/forms';
//#endregion

//#region directives 
import {
  CorDisableControlDirective,
  CorFocusDirective,
  CorOnlyNumbers,
  CorOnReturnDirective,
  CorSelectDirective,
} from ".";
//#endregion

//#region app pipes
// import { LicencaPipe } from "./_pipes";
//#endregion

@NgModule({
  imports: [
    CommonModule,
    // HttpClientModule,
    // IonicModule,
    // FormsModule,
  ],
  declarations: [
    // directives
    CorFocusDirective,
    CorDisableControlDirective,
    CorSelectDirective,
    CorOnlyNumbers,
    CorOnReturnDirective,
  ],
  exports: [
    // directives
    CorFocusDirective,
    CorDisableControlDirective,
    CorSelectDirective,
    CorOnlyNumbers,
    CorOnReturnDirective,
  ]
})
export class CorDirectivesModule { }
