<!-- <p>isMobile {{ isMobile }}</p> -->
<!-- <p>endereco {{ endereco | json }}</p> -->
<!-- <p>step {{ step }}</p> -->

<span [ngSwitch]="step()">
  <div *ngSwitchCase="'completar'">
    <cor-mat-completa-endereco-form [endereco]="endereco"
                                    [isMobile]="isMobile"
                                    (submit$)="endereco = $event" />
  </div>

  <div *ngSwitchCase="'endereco'">
    <cor-mat-endereco-form [endereco]="endereco"
                           [isMobile]="isMobile"
                           [showDelete]="true"
                           [showApelido]="showApelido"
                           [submitCaption]="submitCaption"
                           (delete$)="endereco = null"
                           (submit$)="submit$.emit($event)" />
  </div>

  <div *ngSwitchDefault>
    <div class="cep-box m-auto">
      <cor-bs-cep-form [isMobile]="isMobile"
                       [placeholder]="placeholder"
                       (submit$)="endereco = $event" />
    </div>
  </div>
</span>