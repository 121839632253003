//#region ng
import {
  Pipe,
  PipeTransform,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
//#endregion

//#region models
import { IProduto } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { ProdutosService } from '../_services';
//#endregion

@Pipe({ name: 'produtosLfixes' })
export class MeProdutosLfixesPipe implements PipeTransform {
  //#region injects
  #produtosServ = inject(ProdutosService);
  //#endregion

  transform(val: IProduto[]): Observable<IProduto[]> {
    return this.#produtosServ.lfixes(val);
  }
}
