//#region ng
import {
  EventEmitter,
  Injectable
} from '@angular/core';
//#endregion

//#region 3rd
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '.';
//#endregion

//#region models
import { CorRota } from '../_shared/_core/_misc/_models/_classes';
import { ICorRota } from '../_shared/_core/_misc/_models/_interfaces/_misc';
import {
  ILoja,
  IPedido
} from '../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { TOperadorRole } from '../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

//#region services
import { LojasService } from '../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region models
/* export const ROTAS: any = {
  dashboard: new CdRota(
    'Dashboard',
    '/dashboard',
    'icon-o-home',
    [],
    '',
    { calcValid: () => true }
  ),
  // habilitacao: new CdRota(
  //   'Habilitação',
  //   '/habilitacao',
  //   'icon-home',
  //   [],
  //   '',
  //   { calcValid: () => true }
  // ),
  login: new CdRota(
    'Login',
    '/login',
    'icon-o-home',
    [],
    '',
    { calcValid: () => true }
  ),
}; */
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AppService {
  //#region lojaState
  private _lojaState: Partial<ILoja>;
  setLojaState(val: Partial<ILoja>) {
    // console.log(val);
    this._lojaState = !!val ? this._lojasServ.fix(val) : null;
    const LOJA_ID: string = val?.id || '';
    // console.log(LOJA_ID);
    LOJA_ID && this._storageServ.lojaIdSet(LOJA_ID);
    AppService.lojaStateChanged$.next(this._lojaState as ILoja);
  }
  getLojaState(): Partial<ILoja> {
    return this._lojaState;
  }
  //#endregion

  //#region events
  static lojaStateChanged$: BehaviorSubject<ILoja> = new BehaviorSubject<ILoja>(null);
  static logout$: EventEmitter<string> = new EventEmitter<string>();
  static pedidoCancelarPedido$: EventEmitter<IPedido> = new EventEmitter<IPedido>();
  static pedidoConcluirSeparacao$: EventEmitter<IPedido> = new EventEmitter<IPedido>();
  static pedidoConcluirPedido$: EventEmitter<IPedido> = new EventEmitter<IPedido>();
  static pedidoIniciaSeparacao$: EventEmitter<IPedido> = new EventEmitter<IPedido>();
  static relatorioSeparacao$: EventEmitter<IPedido> = new EventEmitter<IPedido>();
  // static relatorioModificacoes$: EventEmitter<IPedido> = new EventEmitter<IPedido>();
  static relatorioSeparado$: EventEmitter<IPedido> = new EventEmitter<IPedido>();
  // static operadorHabilitadoChanged$: EventEmitter<IConta> = new EventEmitter<IConta>();
  // static horarioChanged$: EventEmitter<IHorarioAgendamentoMap> = new EventEmitter<IHorarioAgendamentoMap>();
  //#endregion

  //#region constructor
  constructor(
    private _lojasServ: LojasService,
    private _storageServ: StorageService,
  ) { }
  //#endregion

  //#region functions
  /* getRotas(
    role: TOperadorRole,
    integradoraId: string
  ): ICdRota[] {
    const RET: ICdRota[] = [];
    Object.keys(ROTAS).forEach(
      (id: string) => {
        const ROTA: ICdRota = ROTAS?.id;
        if (ROTA) {
          // console.log(ROTAS, id, role);
          ROTA.validate([role], integradoraId);
          RET.push(ROTA);
        } // if
      }
    );
    // console.log(RET);

    return RET;
  } */
}
