<form [formGroup]="enderecoForm()"
      novalidate
      *ngIf="!!endereco && !!enderecoForm()"
      (ngSubmit)="onSubmitClick()">
  <div class="row">
    <div class="col-8">
      <mat-form-field class="w-100"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Localidade *</mat-label>
        <input matInput
               disabled
               readonly
               [value]="endereco?.localidade">
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-form-field class="w-100"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>UF</mat-label>
        <input matInput
               disabled
               readonly
               [value]="endereco?.uf">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col mb-2">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Bairro</mat-label>
        <input matInput
               formControlName="bairro"
               [focus]="bairroFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(bairroRef)">
          <span class="danger"> {{ fv()?.error(bairroRef) }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col mb-2">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Logradouro</mat-label>
        <input matInput
               formControlName="logradouro"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(logradouroRef)">
          <span class="danger"> {{ fv()?.error(logradouroRef) }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <!-- <div class="row"
       *ngIf="!!submitCaption">
    <div class="col d-flex">
      <div class="flex-1">
        <button type="submit"
                class="w-100"
                mat-raised-button
                color="primary"
                [disabled]="!enderecoForm()?.valid">
          {{ submitCaption }}
        </button>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col d-flex">
      <button type="button"
              tabindex="-1"
              class="me-2 flex-1"
              mat-button
              color="light"
              (click)="submit$.emit(null)">
        <span class="icon-r-chevron-l o-50"></span>&nbsp;Voltar
      </button>

      <button type="submit"
              class="flex-1"
              mat-raised-button
              color="primary"
              [disabled]="!enderecoForm()?.valid">
        Continuar&nbsp;<span class="icon-r-chevron-r o-50"></span>
      </button>
    </div>
  </div>
</form>