<!-- <p>redeForm()?.value {{ redeForm()?.value | json }}</p> -->
<!-- <p>rede {{ rede | json }}</p> -->
<!-- <p>temas {{ temas | json }}</p> -->
<!-- <p>redeForm {{ redeForm.get('nome').value }}</p> -->
<!-- <p>redeForm.valid {{ redeForm.valid }}</p> -->
<!-- <p>idRef.errors {{ idRef.errors | json }}</p> -->
<!-- <p>idRef.pending {{ idRef.pending | json }}</p> -->

<form [formGroup]="redeForm()"
      class="px-2 py-4"
      (ngSubmit)="submit$?.emit(redeForm()?.value)"
      *ngIf="!!redeForm()">
  <div class="row">
    <div class="col">
      <mat-form-field class="w-100 mb-2"
                      appearance="outline">
        <mat-label>ID</mat-label>
        <input type="text"
               matInput
               formControlName="id"
               [focus]="idFocusEvent$()"
               onReturn>
        <!-- <mat-hint *ngIf="fv()?.lerror(idRef, 'id')">
          <span class="danger">
            {{ fv()?.error(idRef, 'id') }}
          </span>
        </mat-hint> -->
        <mat-hint *ngIf="fv()?.error(idRef) || (idRef?.errors && idRef?.errors['idRepeated'])">
          <span class="danger">
            {{ fv()?.error(idRef) || 'ID já cadastrado.' }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field class="w-100 mb-2"
                      appearance="outline">
        <mat-label>Nome</mat-label>
        <input type="text"
               matInput
               formControlName="nome"
               onReturn>
        <mat-hint *ngIf="fv()?.error(nomeRef, 'nome')">
          <span class="danger">
            {{ fv()?.error(nomeRef, 'nome') }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-label>Tema</mat-label>
      <cor-bs-theme-sel [tema]="temaRef?.value"
                        (select$)="onTemaClick($event)" />
    </div>
  </div>

  <div class="row">
    <div class="col d-flex">
      <div *ngIf="idRef.pending"
           class="my-auto ms-2">
        Aguarde, verificando...
        <mat-progress-bar mode="indeterminate" />
      </div>

      <button type="submit"
              class="pointer w-100"
              mat-raised-button
              color="primary"
              [disabled]="!redeForm()?.valid"
              *ngIf="!!submitCaption">
        {{ submitCaption }}
      </button>
    </div>
  </div>
</form>