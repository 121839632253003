//#region algolia
export const ALGOLIA = {
  APP_ID: '9INPMTJH63',
  API_SEARCH_KEY: 'ca1f4dcfa5ca7441f53e8db828c06705',
};
//#endregion 

//#region storage
export const KEY_EMAIL_LOGIN: string = 'KEY_EMAIL_LOGIN';
//#endregion

//#region departamentos
export const ID_DEPTO_INDEFINIDO: string = 'DEPTO_INDEFINIDO';
export const NOME_DEPTO_INDEFINIDO: string = 'INDEFINIDO';
//#endregion

//#region sweetalert
export const YES_COLOR: string = '#3CB371'; /* '#3085d6', */
export const NO_COLOR: string = 'tomato'; /* '#d33', */
//#endregion

//#region firebase
export const QTDE_TAG: string = '{{QTDE}}';
export const CONFIG_TAG: string = '{{CONFIG}}';
export const DOCS_TAG: string = '{{DOCS}}';
export const ODOCS_TAG: string = '{{ODOCS}}';
export const PRIVATE_TAG: string = '{{PRIVATE}}';
export const RESOLVIDAS_TAG: string = '{{RESOLVIDAS}}';
export const ROWS_TAG: string = '{{ROWS}}';
export const FIRESTORE_WHERE_OPS: string[] = ['==', '!=', '>', '>=', '<', '<=', 'in', 'not-in', 'array-contains', 'array-contains-any'];
export const FIRESTORE_SORT_DIRECTIONS: string[] = ['asc', 'desc'];
// export const COMPARISON_VALUES_LIMIT: number = 10; // in, not-in and array-contains-any
//#endregion

//#region conecdata.formasPgto
export const FORMAS_PGTO_STORAGE_ROOT = 'https://firebasestorage.googleapis.com/v0/b/formas-pgto.appspot.com/o';
//#endregion 

//#region conecdata.produtos
export const PRODUTOS_STORAGE_ROOT: string = 'https://firebasestorage.googleapis.com/v0/b/produtos-d5cd0.appspot.com/o';
//#endregion

//#region themes
export type TThemes = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';

export const THEMES: string[] = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'light', 'medium', 'dark'];

/* export type TBgColor =
  'bg-success' | 'bg-danger' | 'bg-primary' | 'bg-warning' | 'bg-info' | 'bg-light' | 'bg-dark' | '';

export const BG_COLORS: string[] = [
  'bg-success',
  'bg-danger',
  'bg-primary',
  'bg-warning',
  'bg-info',
  'bg-light',
  'bg-dark',
]; */
//#endregion

//#region imgs
export const IMG_H_NO_IMG: string = 'https://firebasestorage.googleapis.com/v0/b/produtos-d5cd0.appspot.com/o/misc%2Fimg_h_placeholder.svg?alt=media';
export const THUMB_NO_IMG: string = 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/placeholders%2Fthumb_placeholder.svg?alt=media';
export const IMG_NO_IMG: string = 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/placeholders%2Fimg_placeholder.svg?alt=media';
export const IMG_OPEN_FOLDER: string = 'https://firebasestorage.googleapis.com/v0/b/produtos-d5cd0.appspot.com/o/misc%2Fimg-folder-open.svg?alt=media';
export const PHONE_AVATAR: string = 'https://firebasestorage.googleapis.com/v0/b/conecdata-e8ed0.appspot.com/o/misc%2Fphone-avatar.svg?alt=media';
export const IMG_ADD: string = 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/placeholders%2Fadd.svg?alt=media';
//#endregion

/*
| Bandeira   | Comeca com                                  | Máximo de número | Máximo de número cvc |
| ---------- | ------------------------------------------- | ---------------- | -------------------- |
| Visa       | 4                                           | 13,16            | 3                    |
| Mastercard | 5                                           | 16               | 3                    |
| Diners     | 301,305,36,38                               | 14,16            | 3                    |
| Elo        | 636368,438935,504175,451416,509048,509067,  |                  | 3(?)
|            | 509049,509069,509050,509074,509068,509040,
|            | 509045,509051,509046,509066,509047,509042,
|            | 509052,509043,509064,509040                 |                  |                      
|            | 36297, 5067,4576,4011                       | 16               | 3
| Amex       | 34,37                                       | 15               | 4                    |
| Discover   | 6011,622,64,65                              | 16               | 4                    |
| Aura       | 50                                          | 16               | 3                    |
| jcb        | 35                                          | 16               | 3                    |
| Hipercard  | 38,60                                       | 13,16,19         | 3                    |

Amex Card: ^3[47][0-9]{13}$
BCGlobal: ^(6541|6556)[0-9]{12}$
Carte Blanche Card: ^389[0-9]{11}$
Diners Club Card: ^3(?:0[0-5]|[68][0-9])[0-9]{11}$
Discover Card: ^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$
Insta Payment Card: ^63[7-9][0-9]{13}$
JCB Card: ^(?:2131|1800|35\d{3})\d{11}$
KoreanLocalCard: ^9[0-9]{15}$
Laser Card: ^(6304|6706|6709|6771)[0-9]{12,15}$
Maestro Card: ^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$
Mastercard: ^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$
Solo Card: ^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$
Switch Card: ^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$
Union Pay Card: ^(62[0-9]{14,17})$
Visa Card: ^4[0-9]{12}(?:[0-9]{3})?$
Visa Master Card: ^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$

let card_types = {
  "Amex Card": /^3[47][0-9]{13}$/,
  "BCGlobal": /^(6541|6556)[0-9]{12}$/,
  "Carte Blanche Card": /^389[0-9]{11}$/,
  "Diners Club Card": /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  "Discover Card": /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  "Insta Payment Card": /^63[7-9][0-9]{13}$/,
  "JCB Card": /^(?:2131|1800|35\d{3})\d{11}$/,
  "KoreanLocalCard": /^9[0-9]{15}$/,
  "Laser Card": /^(6304|6706|6709|6771)[0-9]{12,15}$/,
  "Maestro Card": /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
  "Mastercard":/ ^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
  "Solo Card": /^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/,
  "Switch Card": /^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/,
  "Union Pay Card": /^(62[0-9]{14,17})$/,
  "Visa Card": /^4[0-9]{12}(?:[0-9]{3})?$/,
  "Visa Master Card": /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/,
};

$brands = array(
  'visa'       => '/^4\d{12}(\d{3})?$/',
  'mastercard' => '/^(5[1-5]\d{4}|677189)\d{10}$/',
  'diners'     => '/^3(0[0-5]|[68]\d)\d{11}$/',
  'discover'   => '/^6(?:011|5[0-9]{2})[0-9]{12}$/',
  'elo'        => '/^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/',
  'amex'       => '/^3[47]\d{13}$/',
  'jcb'        => '/^(?:2131|1800|35\d{3})\d{11}$/',
  'aura'       => '/^(5078\d{2})(\d{2})(\d{11})$/',
  'hipercard'  => '/^(606282\d{10}(\d{3})?)|(3841\d{15})$/',
  'maestro'    => '/^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/',
*/

//#region credit cards
export const ACCEPTED_CREDIT_CARDS: any = {
  amex: {
    nome: 'American Express',
    regex: {
      validate: /^3[47][0-9]{13}$/,
      detect: /^3[47][0-9]{13}/,
    },
    img: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/formas-pgto%2Famex.svg?alt=media',
  },

  /* aura: {
    nome: 'Aura',
    regex: { 
      validate: /^(5078\d{2})(\d{2})(\d{11})$/,
      detect: /^(5078\d{2})(\d{2})(\d{11})$/
    },
    img: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/formas-pgto%2Faura.svg?alt=media',
  }, */

  hipercard: {
    nome: 'Hipercard',
    regex: {
      validate: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
      detect: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    },
    img: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/formas-pgto%2Fhipercard.svg?alt=media',
  },

  diners_club: {
    nome: 'Diner\'s Club',
    regex: {
      validate: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      detect: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    },
    img: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/formas-pgto%2Fdiners.svg?alt=media',
  },

  discover: {
    nome: 'Discover',
    regex: {
      validate: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
      detect: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    },
    img: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/formas-pgto%2Fdiscover.svg?alt=media',
  },

  jcb: {
    nome: 'JCB',
    regex: {
      validate: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
      detect: /^(?:2131|1800|35\d{3})\d{11}/,
    },
    img: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/formas-pgto%2Fjcb.svg?alt=media',
  },

  mastercard: {
    nome: 'Mastercard',
    regex: {
      validate: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
      detect: /^5[1-5][0-9]{14}/,
    },
    img: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/formas-pgto%2Fmastercard.svg?alt=media',
  },

  visa: {
    nome: 'Visa',
    regex: {
      validate: /^4[0-9]{12}(?:[0-9]{3})?$/,
      detect: /^4[0-9]{12}(?:[0-9]{3})/,
    },
    img: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o/formas-pgto%2Fvisa.svg?alt=media',
  },
};
//#endregion

//#region gridsystem
export const GRID_WIDTH_SM: number = 576;
export const GRID_WIDTH_MD: number = 768;
export const GRID_WIDTH_LG: number = 992;
export const GRID_WIDTH_XL: number = 1200;
export const GRID_WIDTH_XXL: number = 1400;
//#endregion

//#region misc
export const FOCUS_TIMEOUT: number = 200;

export const SEXOS: string[] = [
  'M',
  'F',
];

export type DOW = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const DIAS_SEMANA_NOME_CURTO: string[] = [
  'Dom',
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb',
];

export const DIAS_SEMANA_NOME_LONGO: string[] = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const DIAS_SEMANA: any[] = [
  {
    nomeCurto: 'Dom',
    nome: 'Domingo',
    dow: 0,
  },
  {
    nomeCurto: 'Seg',
    nome: 'Segunda',
    dow: 1,
  },
  {
    nomeCurto: 'Ter',
    nome: 'Terça',
    dow: 2,
  },
  {
    nomeCurto: 'Qua',
    nome: 'Quarta',
    dow: 3,
  },
  {
    nomeCurto: 'Qui',
    nome: 'Quinta',
    dow: 4,
  },
  {
    nomeCurto: 'Sex',
    nome: 'Sexta',
    dow: 5,
  },
  {
    nomeCurto: 'Sáb',
    nome: 'Sábado',
    dow: 6,
  },
];

export const MESES_NOME_LONGO: string[] = [
  'Janeiro',
  'Feveveiro',
  'Marco',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const MESES_NOME_CURTO: string[] = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

export const MESES_MAX_DIAS: number[] = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];

export const SIGLAS_UFS: string[] = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const NOMES_UFS: any = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};
export const UPPERCASE_LETTERS: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//#endregion

//#region pagination
export const PAG_ITENS_POR_PAG: number[] = [
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
];

export const HITS_POR_PAGE: number[] = [
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
];
//#endregion
