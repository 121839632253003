<!-- <p> apiErrors {{ apiErrors | json }}</p> -->
<!-- <p> fv()?.errosApi {{ fv()?.errosApi | json }}</p> -->

<form [formGroup]="algoliaForm()"
      novalidate
      (ngSubmit)="submit$?.emit(algoliaForm()?.value)"
      *ngIf="!!algoliaForm()">
  <div formGroupName="algolia">
    <div class="row">
      <div class="col">
        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>App Id</mat-label>
          <input type="text"
                 matInput
                 formControlName="appId"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(appIdRef, 'appId')">
            <span class="danger">
              {{ fv()?.error(appIdRef, 'appId') }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div [class]="halfSize">
        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>Search API key</mat-label>
          <input type="text"
                 matInput
                 formControlName="searchApiKey"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(searchApiKeyRef, 'searchApiKey')">
            <span class="danger">
              {{ fv()?.error(searchApiKeyRef, 'searchApiKey') }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div [class]="halfSize">
        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>Admin API key</mat-label>
          <input type="text"
                 matInput
                 formControlName="adminApiKey"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(adminApiKeyRef, 'adminApiKey')">
            <span class="danger">
              {{ fv()?.error(adminApiKeyRef, 'adminApiKey') }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div [class]="halfSize">
        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>Email</mat-label>
          <input type="text"
                 matInput
                 formControlName="email"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(emailRef, 'email')">
            <span class="danger">
              {{ fv()?.error(emailRef, 'email') }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div [class]="halfSize">
        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>Password</mat-label>
          <input type="text"
                 matInput
                 formControlName="senha"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(senhaRef, 'senha')">
            <span class="danger">
              {{ fv()?.error(senhaRef, 'senha') }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row mt-4"
       *ngIf="!!submitCaption">
    <div class="col text-right">
      <button type="submit"
              class="pointer"
              mat-raised-button
              color="primary"
              [disabled]="!algoliaForm().valid">
        {{ submitCaption }}
      </button>
    </div>
  </div>
</form>