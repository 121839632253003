//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild
} from '@angular/core';
//#endregion

//#region models
type TStep = 'cep' | 'completar' | 'endereco';
import { ICorViacepApi } from '../../../_misc/_models/_interfaces/_apis/viacep';
//#endregion

//#region components
import { CorBsCepFormComponent } from '../../_bs/_cep-form/cep-form.component';
import { CorMatEnderecoFormComponent } from '../_endereco-form/endereco-form.component';
import { CorMatCompletaEnderecoFormComponent } from '../_completa-endereco-form/completa-endereco-form.component';
//#endregion

@Component({
  selector: 'cor-mat-manage-endereco',
  templateUrl: './manage-endereco.component.html',
  styleUrls: ['./manage-endereco.component.scss']
})
export class CorMatManageEnderecoComponent {
  //#region viewchilds
  @ViewChild(CorBsCepFormComponent) cepFormRef: CorBsCepFormComponent;
  @ViewChild(CorMatEnderecoFormComponent) enderecoFormRef: CorMatEnderecoFormComponent;
  @ViewChild(CorMatCompletaEnderecoFormComponent) completaEnderecoFormRef: CorMatCompletaEnderecoFormComponent;
  //#endregion

  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // placeholder
  @Input() placeholder: string = 'Seu CEP';
  // showApelido
  @Input() showApelido: boolean = true;
  // submitCaption
  @Input() submitCaption: string = '';
  // endereco
  _endereco: ICorViacepApi | null;
  get endereco(): ICorViacepApi | null { return this._endereco; }
  @Input({ required: true }) set endereco(value: ICorViacepApi | null) {
    this._endereco = value;
    const CEP: string = this.endereco?.cep || '';
    const BAIRRO: string = this.endereco?.bairro || '';
    this.step.set(
      !CEP ? 'cep'
        : BAIRRO
          ? 'endereco'
          : 'completar'
    );
  }
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<ICorViacepApi>();
  @Output() delete$ = new EventEmitter<null>();
  //#endregion

  //#region publics
  step = signal<TStep>('cep');
  //#endregion

  //#region functions
  focus() {
    // console.log(this.step);
    switch (this.step()) {
      case 'cep':
        this.cepFormRef?.focus();
        break;

      case 'completar':
        this.completaEnderecoFormRef?.focus();
        break;

      case 'endereco':
        this.enderecoFormRef?.focus();
        break;
    } // switch
  }
  //#endregion

  //#region methods
  /* onSetEndereco(endereco: TNullable<ICorViacepApi> = null): void {
    // console.log(endereco);
    this.endereco = endereco;
  }

  onSubmitClick(endereco: TNullable<ICorViacepApi>): void {
    // console.log(endereco);
    this.submit$.emit(endereco);
  } */
  //#endregion
}
