//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
//#endregion

//#region models
import { TCorTristateToggleStatus } from '../../../_misc/_models/_types';
//#endregion

@Component({
  selector: 'cor-mat-tristate-toggle',
  templateUrl: './tristate-toggle.component.html',
  styleUrls: ['./tristate-toggle.component.scss']
})
export class CorMatTristateToggleComponent {
  //#region inputs
  // iconChecked
  @Input() iconChecked = 'check';
  // iconNone
  @Input() iconNone = 'pause';
  // iconUnchecked
  @Input() iconUnchecked = 'close';
  //#endregion

  //#region outputs
  @Output() change$ = new EventEmitter<TCorTristateToggleStatus>();
  //#endregion

  //#region status
  #status: TCorTristateToggleStatus = '';
  get status(): TCorTristateToggleStatus { return this.#status; }
  @Input() set status(val: TCorTristateToggleStatus) {
    this.#status = val;
    // console.log(val);
    this.change$.emit(val);
  }
  //#endregion
}
