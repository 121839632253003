<!-- <p>tokenLoja {{ tokenLoja | json }}</p> -->
<!-- <p>meilisearch {{ meilisearch | json }}</p> -->
<!-- <p>produto {{ produto | json }}</p> -->

<div class="row mt-4">
  <div class="col-6">
    <ul class="list-group">
      <li class="list-group-item list-group-item-secondary">
        Produto
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">ID produto</span>
        <div class="flex-1"></div>
        <small>{{ produto?.id || '-' }}</small>
      </li>

      <li class="list-group-item">
        <span class="o-50">Nome</span><br />
        <small>{{ produto?.nome || '-' }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Volume</span>
        <div class="flex-1"></div>
        <small>{{ produto?._base?.volume || '-' }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Embalagem</span>
        <div class="flex-1"></div>
        <small>{{ produto?._base?.embalagem || '-' }}</small>
      </li>

      <!-- <li class="list-group-item">
        <span class="o-50">Atacado</span><br />
        <small>{{ produto?._base?.atacado || '-' }}</small>
      </li> -->

      <li class="list-group-item d-flex">
        <span class="o-50">Dimensões</span>
        <div class="flex-1"></div>
        <small>{{ produto?._base?.dimensoes || '-' }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Cód. barras</span>
        <div class="flex-1"></div>
        <small>{{ produto?.barcode || '-' }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">NCM</span>
        <div class="flex-1"></div>
        <small>{{ produto?.ncm | ncm }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Referência</span>
        <div class="flex-1"></div>
        <small>{{ produto?._base?.ref }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Marca</span>
        <div class="flex-1"></div>
        <small>{{ produto?._base?.marca?.nome || '-' }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Linha</span>
        <div class="flex-1"></div>
        <small>{{ produto?._base?.marca?.linha?.nome || '-' }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Estoque crítico</span>
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?.estoqueCritico"></cor-html-status-checkbox>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Industrializado</span>
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?._industrializado"></cor-html-status-checkbox>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Preço</span>
        <div class="flex-1"></div>
        <small>{{ produto?.preco?._liquido | currency: "BRL":"symbol":"1.2-2" }}</small>
      </li>

      <li class="list-group-item">
        <span class="o-50">Info</span><br />
        <small>{{ produto?._base?.info || '-' }}</small>
      </li>
    </ul>
  </div>

  <div class="col-6">
    <ul class="list-group">
      <li class="list-group-item list-group-item-secondary d-flex">
        Fracionado
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?.fracionado?._status"></cor-html-status-checkbox>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Unidade tipo</span>
        <div class="flex-1"></div>
        <small>{{ produto?.fracionado?.unidade?.tipo || '-' }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Unidade fração</span>
        <div class="flex-1"></div>
        <small>{{ produto?.fracionado?.unidade?.fracao | number: "1.2-2" }}</small>
      </li>

      <li class="list-group-item list-group-item-secondary d-flex">
        Status
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?.ativo?._status"></cor-html-status-checkbox>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Ativo</span>
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?.ativo?.status"></cor-html-status-checkbox>
        <!-- <small>{{ produto?.atacado?.status }}</small> -->
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Online</span>
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?.ativo?.online"></cor-html-status-checkbox>
        <!-- <small>{{ produto?.atacado?.status }}</small> -->
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Consistente</span>
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?.ativo?._consistente"></cor-html-status-checkbox>
        <!-- <small>{{ produto?.atacado?.status }}</small> -->
      </li>

      <li class="list-group-item">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <span class="o-50">Departamento 1</span>
            <div class="flex-1"></div>
            <cor-html-status-checkbox [status]="produto?.ativo?._d1"></cor-html-status-checkbox>
          </div>
          <small>{{ produto?.departamentos?.d1?.nome || '-' }}</small>
        </div>
      </li>

      <li class="list-group-item">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <span class="o-50">Departamento 2</span>
            <div class="flex-1"></div>
            <cor-html-status-checkbox [status]="produto?.ativo?._d2"></cor-html-status-checkbox>
          </div>
          <small>{{ produto?.departamentos?.d2?.nome || '-' }}</small>
        </div>
      </li>

      <li class="list-group-item">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <span class="o-50">Departamento 3</span>
            <div class="flex-1"></div>
            <cor-html-status-checkbox [status]="produto?.ativo?._d3"></cor-html-status-checkbox>
          </div>
          <small>{{ produto?.departamentos?.d3?.nome || '-' }}</small>
        </div>
      </li>

      <li class="list-group-item list-group-item-secondary d-flex">
        Atacado
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?.atacado?.status"></cor-html-status-checkbox>
      </li>

      <!-- <li class="list-group-item d-flex">
        <span class="o-50">Status</span>
        <div class="flex-1"></div>
        <cor-html-status-checkbox [status]="produto?.atacado?.status"></cor-html-status-checkbox>
      </li> -->

      <li class="list-group-item d-flex">
        <span class="o-50">Preço</span>
        <div class="flex-1"></div>
        <small>{{ produto?.atacado?.preco | currency: "BRL":"symbol":"1.2-2" }}</small>
      </li>

      <li class="list-group-item d-flex">
        <span class="o-50">Qtde</span>
        <div class="flex-1"></div>
        <small>{{ produto?.atacado?.qtde }}</small>
      </li>
    </ul>
  </div>
</div>