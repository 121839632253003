<div class="modal-body p-relative"
     *ngIf="!!item()">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto flex-1">
        <h3>
          <small>{{ this.item()?.produto?.departamentos?.d1?.nome }}</small>
          <span class="o-50 mx-1">/</span>
          <small>{{ this.item()?.produto?.departamentos?.d2?.nome }}</small>
          <span *ngIf="this.item()?.produto?.departamentos?.d3?.nome">
            <span class="o-50 mx-1">/</span>
            <small>{{ this.item()?.produto?.departamentos?.d3?.nome }}</small>
          </span>
        </h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                class="ms-3"
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-3 container-fluid">
    <!-- <p>op {{ op() }}</p> -->
    <!-- <p>isEncontrar {{ isEncontrar() }}</p> -->
    <!-- <p>itemForm()?.value {{ itemForm()?.value | json }}</p> -->
    <!-- <p>encontradoRef?.errors {{ encontradoRef?.errors | json }}</p> -->
    <!-- <p>valRef?.errors {{ valRef?.errors | json }}</p> -->
    <!-- <p>item() {{ item() | json }}</p> -->

    <div class="d-flex">
      <div class="flex-1 me-4">
        <me-mat-produto-imgs [produto]="item()?.produto" />
      </div>

      <div class="flex-2 d-flex flex-column">
        <div class="d-flex justify-content-between">
          <div class="flex-1 o-50 rem-12"
               *ngIf="!!this.item()?.produto?._base?.marca?.nome">
            {{ this.item()?.produto?._base?.marca?.nome }}
          </div>

          <div>
            <app-item-status-badge [itemStatus]="this.item()?._status" />
          </div>
        </div>

        <div class="text-center">

          <h4 class="my-2">{{ this.item()?.produto?.nome }} <small class="o-50"
                   *ngIf="this.item()?.produto?._base?.embalagem">
              (&nbsp;{{ this.item()?.produto?._base?.embalagem }}&nbsp;)
            </small>
          </h4>

          <me-html-produto-preco [produto]="this.item()?.produto"
                                 size="lg" />
        </div>

        <mat-radio-group class="d-flex flex-column"
                         [ngModel]="op()"
                         (ngModelChange)="op.set($event)"
                         *ngIf="!isEncontrar()">
          <mat-radio-button value="del"
                            class="mb-2">
            Remover
          </mat-radio-button>

          <mat-radio-button value="set"
                            class="mb-2">
            {{ setOpCaption() }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <form novalidate
          class="rem-14 d-flex flex-column"
          [formGroup]="itemForm()"
          *ngIf="!!itemForm()"
          (ngSubmit)="onSubmitClick()">
      <div class="d-flex flex-column">
        <div *ngIf="op() === 'set'"
             class="d-flex">
          <div class="flex-1 me-2">
            <mat-form-field class="w-100 mb-2 off"
                            appearance="outline">
              <mat-label class="off">Qtde solicitada</mat-label>
              <input type="number"
                     matInput
                     [disabled]="true"
                     class="text-end"
                     [value]="qtdeSolicitada()">
            </mat-form-field>
          </div>

          <div formGroupName="_separado"
               class="flex-1 me-2">
            <mat-form-field class="w-100 mb-2"
                            [class.off]="!isEncontrar()"
                            appearance="outline"
                            formGroupName="qtde">
              <mat-label [class.off]="!isEncontrar()">Qtde encontrada</mat-label>
              <input type="number"
                     matInput
                     class="text-end"
                     [disabled]="!isEncontrar()"
                     [select]="encontradoSelectEvent$()"
                     formControlName="encontrado">
              <mat-hint *ngIf="fv()?.lerror(encontradoRef) || encontradoRef?.errors?.['encontrado']">
                <span class="danger"> {{ fv()?.error(encontradoRef) || 'Qtde encontrada deve ser igual ou inferior à
                  solicitada.' }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div formGroupName="_separado"
               class="flex-1"
               *ngIf="!isEncontrar()">
            <mat-form-field class="w-100 mb-2"
                            appearance="outline"
                            formGroupName="qtde">
              <mat-label>Qtde separada</mat-label>
              <input type="number"
                     matInput
                     class="text-end"
                     formControlName="val"
                     [select]="separadoSelectEvent$()">
              <mat-hint *ngIf="fv()?.lerror(valRef) || valRef?.errors?.['separado']">
                <span class="danger"> {{ fv()?.error(valRef) || 'Qtde separada deve ser igual ou inferior à
                  encontrada.' }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex">
          <button mat-flat-button
                  type="submit"
                  class="flex-1 rem-15"
                  color="primary"
                  [disabled]="!itemForm()?.valid">
            <div *ngIf="!!isEncontrar; separar">
              <small class="o-50 me-2">ENCONTRADO</small>
              &nbsp;<strong>
                {{ encontradoRef?.value | number:'1.0-3' }}&nbsp;{{
                item()?.produto?.fracionado?.unidade?._legenda || 'un' }}
              </strong>
            </div>
            <ng-template #separar>
              <div *ngIf="!!valRef?.value; else removerItemCaption">
                <div *ngIf="!!qtdeSolicitada(); else adicionarItemCaption">
                  <div *ngIf="qtdeSolicitada() === valRef?.value; else modificarItemCaption">
                    <small class="o-50 me-2">CONFIRMAR</small>
                    &nbsp;<strong>
                      {{ valRef?.value | number:'1.0-3' }}&nbsp;{{
                      item()?.produto?.fracionado?.unidade?._legenda || 'un' }}
                    </strong>
                  </div>
                  <ng-template #modificarItemCaption>
                    <small class="o-50 me-2">MODIFICAR PARA</small>
                    &nbsp;<strong>
                      {{ valRef?.value |number:'1.0-3' }}&nbsp;{{ item()?.produto?.fracionado?.unidade?._legenda || 'un'
                      }}
                    </strong>
                  </ng-template>
                </div>
                <ng-template #adicionarItemCaption>
                  <small class="o-50 me-2">{{ setOpCaption().toUpperCase() }}</small>
                  &nbsp;<strong>{{ valRef?.value | number:'1.0-3' }}&nbsp;{{
                    item()?.produto?.fracionado?.unidade?._legenda || 'un' }}
                  </strong>
                </ng-template>
              </div>
              <ng-template #removerItemCaption>
                <small class="o-50 me-2">REMOVER</small>
                &nbsp;<strong>{{ separadoDefault() | number : '1.0-3' }}&nbsp;{{
                  item()?.produto?.fracionado?.unidade?._legenda || 'un' }}</strong>
              </ng-template>
            </ng-template>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- <p>item() {{ item() | json }}</p> -->
<!-- <p>setOpCaption {{ setOpCaption() }}</p> -->
<!-- <p>op {{ op() }}</p> -->

<!-- <div class="d-flex">
      <div class="flex-1 me-4">
        <me-mat-produto-imgs [produto]="item()?.produto" />
      </div>

      <div class="flex-3 d-flex flex-column">
        <div class="content flex-1">
          <div class="text-center">
            <div class="d-flex">
              <div class="me-auto o-50 rem-12"
                   *ngIf="!!this.item()?.produto?._base?.marca?.nome">
                {{ this.item()?.produto?._base?.marca?.nome }}
              </div>

              <div class="ms-auto">
                <app-item-status-badge [itemStatus]="this.item()?._status" />
              </div>
            </div>

            <h4 class="my-2">{{ this.item()?.produto?.nome }} <small class="o-50"
                     *ngIf="this.item()?.produto?._base?.embalagem">
                (&nbsp;{{ this.item()?.produto?._base?.embalagem }}&nbsp;)
              </small>
            </h4>

            <me-html-produto-preco [produto]="this.item()?.produto"
                                   size="lg" />

            <mat-radio-group class="d-flex flex-column"
                             [ngModel]="op()"
                             (ngModelChange)="op.set($event)">
              <mat-radio-button value="del"
                                class="mb-2">
                Remover
              </mat-radio-button>

              <mat-radio-button value="set"
                                class="mb-2">
                {{ setOpCaption() }}
              </mat-radio-button>
            </mat-radio-group>

            <form novalidate
                  class="rem-14"
                  [formGroup]="itemForm()"
                  *ngIf="!!itemForm()"
                  (ngSubmit)="onSubmitClick()">
              <!-- <div class="d-flex flex-column bg-danger"> -- >
              <div *ngIf="op() === 'set'"
                   class="d-flex">
                <div class="flex-1 me-2">
                  <mat-form-field class="w-100 mb-2 off"
                                  appearance="outline">
                    <mat-label class="off">Qtde solicitada</mat-label>
                    <input type="number"
                           matInput
                           [disabled]="true"
                           class="text-end"
                           [value]="qtdeSolicitada()">
                  </mat-form-field>
                </div>

                <div formGroupName="_separado"
                     class="flex-1 me-2">
                  <mat-form-field class="w-100 mb-2"
                                  appearance="outline"
                                  formGroupName="qtde">
                    <mat-label>Qtde separada</mat-label>
                    <input type="number"
                           matInput
                           class="text-end"
                           formControlName="val"
                           [select]="separadoSelectEvent$()">
                    <mat-hint *ngIf="fv()?.lerror(valRef)">
                      <span class="danger"> {{ fv()?.error(valRef) }} </span>
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div formGroupName="_separado"
                     class="flex-1">
                  <mat-form-field class="w-100 mb-2"
                                  appearance="outline"
                                  formGroupName="qtde">
                    <mat-label>Qtde coletada</mat-label>
                    <input type="number"
                           matInput
                           class="text-end"
                           formControlName="coletado">
                    <!-- <mat-hint *ngIf="fv()?.lerror(valRef)">
                        <span class="danger"> {{ fv()?.error(valRef) }} </span>
                      </mat-hint> -- >
                  </mat-form-field>
                </div>
              </div>
              <!-- </div> -- >

              <div class="d-flex">
                <button mat-flat-button
                        type="submit"
                        class="flex-1 rem-15"
                        color="primary"
                        [disabled]="!itemForm()?.valid">
                  <div *ngIf="!!valRef?.value; else removerItemCaption">
                    <div *ngIf="!!qtdeSolicitada(); else adicionarItemCaption">
                      <div *ngIf="qtdeSolicitada() === valRef?.value; else modificarItemCaption">
                        <small class="o-50 me-2">CONFIRMAR</small><strong>{{ valRef?.value |
                          number
                          :
                          '1.0-3' }}
                          {{ item()?.produto?.fracionado?.unidade?._legenda || 'un' }}</strong>

                      </div>
                      <ng-template #modificarItemCaption>
                        <small class="o-50 me-2">MODIFICAR PARA</small><strong>{{ valRef?.value |
                          number
                          :
                          '1.0-3' }}
                          {{ item()?.produto?.fracionado?.unidade?._legenda || 'un' }}</strong>
                      </ng-template>
                    </div>
                    <ng-template #adicionarItemCaption>
                      <small class="o-50 me-2">{{ setOpCaption().toUpperCase() }} </small><strong>{{ valRef?.value |
                        number
                        :
                        '1.0-3' }}
                        {{ item()?.produto?.fracionado?.unidade?._legenda || 'un' }}</strong>
                    </ng-template>
                  </div>
                  <ng-template #removerItemCaption>
                    <small class="o-50 me-2">REMOVER</small><strong>{{ separadoDefault() | number : '1.0-3' }} {{
                      item()?.produto?.fracionado?.unidade?._legenda || 'un' }}</strong>
                  </ng-template>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->