//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors
} from "@angular/forms";
//#endregion

//#region 3rd
import Swal from 'sweetalert2';
import { get } from 'lodash';
//#endregion

//#region models
import { CorFormValidation } from '../../../_ng/_models/_classes';
import {
  FOCUS_TIMEOUT,
  YES_COLOR
} from '../../../_misc/_models/consts';
import { ICorRegisterRet } from '../../../_misc/_models/_interfaces/_rets/register';
//#endregion

//#region Custom Validators
class CustomValidator {
  static notEqual() {
    return (control: FormControl): TNullable<ValidationErrors> => {
      // console.log(control);
      const FORM_GROUP: FormGroup = get(control, '_parent');
      // console.log(FORM_GROUP);
      const SENHA: string = FORM_GROUP?.value.senha || '';
      const CONFIRMACAO: string = control.value || '';
      // console.log(SENHA, CONFIRMACAO);
      if (!!CONFIRMACAO && !!SENHA) {
        return CONFIRMACAO !== SENHA ? { notEqual: true } : null;
      } // if
      return null;
    }
  }
}
//#endregion

//#region services
import {
  CoreService,
  CorFbAuthService
} from '../../../_ng/_services';
import { TNullable } from '../../../_misc/_models/_types';
//#endregion

@Component({
  selector: 'cor-mat-register-email-form',
  templateUrl: './register-email-form.component.html',
  styleUrls: ['./register-email-form.component.scss']
})
export class CorMatRegisterEmailFormComponent {
  //#region inputs
  // email
  @Input() email: string = '';
  // isMobile
  @Input() isMobile: boolean = false;
  //#endregion

  //#region outputs
  @Output() cancel$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  fv = signal<CorFormValidation>(null);
  registerForm = signal<FormGroup>(null);
  //#endregion

  //#region methods
  emailFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  senhaFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #authServ = inject(CorFbAuthService);
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation);
  }

  ngOnInit() {
    this.registerForm.set(
      this.#fb.group({
        confirmacao: ['', [Validators.minLength(6), Validators.required, CustomValidator.notEqual()]],
        email: [this.email, [Validators.maxLength(60), Validators.required, Validators.email]],
        senha: ['', [Validators.minLength(6), Validators.required]],
      })
    );
  }

  ngAfterViewInit() {
    this.email ? this.focusSenha() : this.focusEmail();
  }
  //#endregion

  //#region Controls getters
  get confirmacaoRef(): AbstractControl { return this.registerForm()?.get('confirmacao'); }
  get emailRef(): AbstractControl { return this.registerForm()?.get('email'); }
  get senhaRef(): AbstractControl { return this.registerForm()?.get('senha'); }
  //#endregion

  //#region functions
  private focusEmail() {
    // console.log('focus');
    !this.isMobile && setTimeout(
      () => { this.emailFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  private focusSenha() {
    // console.log('focus');
    !this.isMobile && setTimeout(
      () => { this.senhaFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onSubmitClick() {
    /* if (this.senhaRef.value !== this.confirmacaoRef.value) {
      this.fv.setApiErrors(
        { senha: 'Senha e confirmação são diferentes.' }
      );
      this.focusSenha();
    } else { */
    const {
      email: EMAIL,
      senha: SENHA
    }: ICorRegisterRet = this.registerForm()?.value;
    // console.log(EMAIL, SENHA);

    CoreService.swalLoaderEvent$.next({
      title: '',
      text: 'Criando cadastro.'
    });
    this.#authServ.register(EMAIL, SENHA)
      .then((val) => {
        // console.log(val);
        CoreService.swalLoaderEvent$.next(null);
      })
      .catch((error: any) => {
        console.error(error);
        CoreService.swalLoaderEvent$.next(null);
        setTimeout(
          () => {
            Swal.fire({
              icon: "error",
              title: "Ooops...",
              html: '<h2>E-mail inválido ou já cadastrado.</h2>',
              backdrop: false,
              confirmButtonColor: YES_COLOR,
            });
          },
          300
        );
      });
    // } // else
  }
  //#endregion
}
