//#region ng
import {
  Component,
  inject,
  Input,
} from '@angular/core';
//#endregion

//#region models
import { IProduto } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { ProdutosService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-produto-info',
  templateUrl: './produto-info.component.html',
  styleUrls: ['./produto-info.component.scss']
})
export class MeMatProdutoInfoComponent {
  //#region inputs
  #produto: IProduto;
  get produto(): IProduto { return this.#produto; }
  @Input({ required: true }) set produto(val: IProduto) {
    this.#produto = val ? this.#produtosServ.fix(val) : null;
    // console.log(val);
  }
  //#endregion

  //#region injects
  #produtosServ = inject(ProdutosService);
  //#endregion
}
