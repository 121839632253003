<!-- <p>imgSel() {{ imgSel() | json }}</p> -->
<!-- <p>produto {{ produto | json }}</p> -->
<!-- <p>produto {{ produto?.__imgs | json }}</p> -->
<!-- <p>imgSel()?.id {{ imgSel()?.id }}</p> -->

<div class="d-flex flex-column h-100">
  <div class="m-auto">
    <div class="produto-imgs">
      <div *ngIf="!!imgSel()?._res && !isMobile; else noZoom">
        <lib-ngx-image-zoom [thumbImage]="imgSel()?.thumb"
                            [fullImage]="imgSel()?.img" />
      </div>
      <ng-template #noZoom>
        <div class="m-auto">
          <img [src]="imgSel()?.thumb"
               class="img-fluid">
        </div>
      </ng-template>

      <div class="mt-2"
           *ngIf="produto?.__imgs?.length > 1">
        <ul class="thumblist">
          <li *ngFor="let i of produto?.__imgs; trackBy:tid_img; let index=index">
            <img [src]="i?.thumb"
                 class="pointer img-fluid"
                 [class.active]="i?.id === imgSel()?.id"
                 (click)="imgSel.set(i)">
          </li>
        </ul>
      </div>

      <div class="btn-group d-flex mt-2"
           role="group"
           *ngIf="!!editable">

        <button type="button"
                matTooltip="Mover para esquerda"
                class="btn btn-primary tint flex-1"
                (click)="left$.emit(imgSel())"
                [disabled]="imgSel()?._pos?.first">
          <div class="icon-b-arrow-l"></div>
        </button>

        <button type="button"
                matTooltip="Adicionar imagem"
                class="btn btn-primary tint flex-1"
                (click)="add$.emit()">
          <div class="icon-r-plus"></div>
        </button>

        <button type="button"
                matTooltip="Remover imagem"
                class="btn btn-primary tint flex-1"
                (click)="del$.emit(imgSel())"
                [disabled]="!imgSel()?.id">
          <div class="icon-o-trashbin"></div>
        </button>

        <button type="button"
                matTooltip="Mover para direita"
                class="btn btn-primary tint flex-1"
                (click)="right$.emit(imgSel())"
                [disabled]="imgSel()?._pos?.last">
          <div class="icon-b-arrow-r"></div>
        </button>
      </div>
    </div>
  </div>
</div>
