//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from "@angular/core";
//#endregion

//#region models
import { TCorViewStyle } from "../../../_misc/_models/_types";
//#endregion

//#region services
import { CorStorageService } from "../../../_ng/_services";
//#endregion

@Component({
  selector: "cor-mat-viewstyle-sel",
  templateUrl: "viewstyle-sel.component.html",
  styleUrls: ["viewstyle-sel.component.scss"]
})
export class CorMatViewstyleSelComponent {
  //#region inputs
  @Input() size: 'sm' | 'lg' = 'sm';
  // storageKey
  #storageKey: string;
  get storageKey(): string { return this.#storageKey; }
  @Input() set storageKey(val: string) {
    this.#storageKey = val;
    // console.log(val);    
    this.viewStyle = this.#corStorageServ.ls_get(val) || 'cards';
  }
  //#endregion

  //#region outputs
  @Output() change$ = new EventEmitter<TCorViewStyle>();
  //#endregion

  //#region viewStyle
  #viewStyle: TCorViewStyle;
  get viewStyle(): TCorViewStyle { return this.#viewStyle; }
  set viewStyle(val: TCorViewStyle) {
    const CHANGED: boolean = this.#viewStyle !== val;
    this.#viewStyle = val;
    if (!!CHANGED) {
      this.storageKey && this.#corStorageServ.ls_set(
        this.storageKey,
        val
      );
      this.change$.emit(val);
    } // if
  }
  //#endregion

  //#region injects
  #corStorageServ = inject(CorStorageService);
  //#endregion
}
