<!-- <p>enderecoForm.value {{ enderecoForm.value | json }}</p> -->
<!-- <p>isMobile {{ isMobile }}</p> -->
<!-- <p>endereco {{ endereco | json }}</p> -->

<div class="d-flex endereco justify-content-center align-items-center mb-4">
  <div class="text flex-1 p-4"
       *ngIf="endereco">
    <cor-bs-endereco [endereco]="endereco" />
  </div>

  <div class="button">
    <button type="button"
            matTooltip="Remover endereço"
            class="btn btn-link me-2"
            *ngIf="showDelete"
            (click)="delete$.emit()">
      <span class="icon-o-trashbin danger tint"></span>
    </button>
  </div>
</div>

<form [formGroup]="enderecoForm()"
      novalidate
      (ngSubmit)="submit$?.emit(enderecoForm()?.value)"
      *ngIf="enderecoForm()">
  <div class="row mb-3">
    <div class="col-sm-3">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Nro </mat-label>
        <input type="number"
               matInput
               formControlName="nro"
               [focus]="nroFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.error(nroRef) || nroRef?.errors?.['nro']">
          <span class="danger"> {{ fv()?.error(nroRef) || 'Inválido.' }} </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-sm-9">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Complemento</mat-label>
        <input type="text"
               matInput
               formControlName="complemento"
               onReturn>
        <mat-hint>
          Casa, Apto, Bloco, Quadra...
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-12">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Ponto de referência</mat-label>
        <input type="text"
               matInput
               formControlName="referencia"
               onReturn>
        <mat-hint>
          Perto do supermercado, em frente a farmácia...
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row mt-5"
       *ngIf="showApelido">
    <div class="d-flex">
      <!--  <div *ngIf="!isMobile"
           class="d-flex">
        <h5 class="primary shade my-auto me-2">Tipo</h5>
      </div> -->

      <div class="flex-1">
        <mat-button-toggle-group formControlName="apelido"
                                 class="mt-2 mt-sm-0 w-100">
          <mat-button-toggle class="w-33"
                             value="">

            <small>Nenhum</small>
          </mat-button-toggle>

          <mat-button-toggle class="w-33"
                             value="casa">
            <small>
              <span class="icon-b-home o-50 me-1"
                    *ngIf="!isMobile">
              </span>
              Casa
            </small>
          </mat-button-toggle>

          <mat-button-toggle class="w-33"
                             value="trabalho">
            <small>
              <span class="icon-o-coffee o-50 me-1"
                    *ngIf="!isMobile">
              </span>
              Trabalho
            </small>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <!-- <div class="col-xs-12 col-sm-4 d-flex">
      <h5 class="primary shade m-auto me-sm-1">Tipo</h5>
    </div>

    <div class="col-xs-12 col-sm-8">
      <mat-button-toggle-group formControlName="apelido"
                               class="mt-2 mt-sm-0 w-100">
        <mat-button-toggle class="w-33"
                           value="">

          <small> Nenhuma</small>
        </mat-button-toggle>

        <mat-button-toggle class="w-33"
                           value="casa">
          <small>
            <span class="icon-home o-50 me-1"
                  *ngIf="!isMobile">
            </span>
            Casa
          </small>
        </mat-button-toggle>

        <mat-button-toggle class="w-33"
                           value="trabalho">
          <small>
            <span class="icon-coffee o-50 me-1"
                  *ngIf="!isMobile">
            </span>
            Trabalho
          </small>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div> -->
  </div>

  <div class="row mt-4"
       *ngIf="!!submitCaption">
    <div class="col d-flex">
      <button type="submit"
              class="ms-auto pointer flex-1"
              mat-raised-button
              color="primary"
              [disabled]="!enderecoForm().valid">
        {{ submitCaption }}
      </button>
    </div>
  </div>
</form>