//#region ng
import {
  Component,
  inject,
  Inject,
  signal
} from '@angular/core';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

@Component(
  {
    selector: 'cor-mat-confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss']
  }
)
export class CorMatConfirmDialog {
  //#region publics
  cancelCaption = signal<string>('');
  confirmCaption = signal<string>('');
  msg = signal<string>('');
  title = signal<string>('');
  //#endregion

  //#region injects
  #dialogRef = inject(MatDialogRef<CorMatConfirmDialog>);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    if (data) {
      this.msg.set(data?.msg || '');
      this.title.set(data?.title || '');
      this.confirmCaption.set(data?.confirmCaption || 'OK');
      this.cancelCaption.set(data?.cancelCaption || 'Cancela');
    }
  }
  //#endregion

  //#region methods
  onConfirmClick(): void {
    this.#dialogRef?.close(true);
  }
  //#endregion
}