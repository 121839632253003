<!-- <p>isMobile {{ isMobile }}</p> -->

<div class="mb-3">
  <cor-mat-messages />
</div>

<form novalidate
      [formGroup]="loginForm()"
      (ngSubmit)="onSubmitClick()"
      *ngIf="!!loginForm()">
  <div class="row">
    <div class="col pb-2">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>E-mail</mat-label>
        <input type="email"
               matInput
               formControlName="email"
               [focus]="emailFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(emailRef)">
          <span class="danger"> {{ fv()?.error(emailRef) }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col pb-2">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'"
                      *ngIf="!!reveal()">
        <mat-label>Senha</mat-label>
        <input [type]="reveal()?.type || 'text'"
               matInput
               formControlName="senha"
               onReturn>
        <button type="button"
                mat-icon-button
                matSuffix
                class="move-up"
                (click)="onRevealClicked()">
          <span [class]="reveal()?.icon"></span>
        </button>
        <mat-hint *ngIf="fv()?.lerror(senhaRef)">
          <span class="danger"> {{ fv()?.error(senhaRef) }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row"
       *ngIf="showLembrarEmail">
    <div class="col-12 col-sm-6 text-start">
      <mat-checkbox formControlName="lembrar"
                    onReturn>
        Lembrar e-mail
      </mat-checkbox>
    </div>

    <div class="col-12 col-sm-6 text-center text-sm-end"
         [class.my-2]="!!isMobile">
      <span class="primary underline pointer"
            *ngIf="showEsqueciSenha"
            (click)="esqueciSenha$?.emit()">
        Esqueci minha senha!!!
      </span>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <button type="submit"
              class="pointer w-100"
              mat-raised-button
              color="primary"
              [disabled]="!loginForm().valid">
        {{ buttonCaption }}
      </button>
    </div>
  </div>

  <div class="row mt-5"
       *ngIf="!!showRegistrar">
    <div class="col text-center">
      Não tem conta ainda, <span class="primary underline pointer"
            (click)="registrar$?.emit()"> crie uma aqui</span>.
    </div>
  </div>
</form>