//#region ng
import {
  Component,
  Input,
  inject
} from '@angular/core';
//#endregion

//#region models
import { ILoja } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { LojasService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-bs-loja',
  templateUrl: './loja.component.html',
  styleUrls: ['./loja.component.scss']
})
export class MerBsLojaComponent {
  //#region inputs
  #loja: ILoja;
  get loja(): ILoja { return this.#loja }
  @Input({ required: true }) set loja(val: ILoja) {
    // console.log(val);
    this.#loja = null;
    if (!!val) {
      this.#lojasServ.lfix(val)
        .then(
          (lojas) => {
            // console.log(lojas[1]);
            this.#loja = lojas[1];
          }
        );
    } // if
  }
  //#endregion

  //#region injects
  #lojasServ = inject(LojasService);
  //#endregion
}
