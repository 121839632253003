//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  signal,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
//#endregion

//#region 3rd
import { Subscription } from "rxjs";
//#endregion

//#region models
import { CorFormValidation } from "../../../_ng/_models/_classes/form-validation";
import {
  ACCEPTED_CREDIT_CARDS,
  FOCUS_TIMEOUT
} from "../../../_misc/_models/consts";
import { ICorCartaoCreditoMap } from "../../../_misc/_models/_interfaces/_maps";
//#endregion

//#region custom validators
class CustomValidator {
  static cardValid() {
    return (control: FormControl): ValidationErrors => {
      const CARTAO: string = (control.value || '');
      // console.log(CARTAO);
      const CARD_FLAG: string = detectCreditCard(CARTAO);
      // console.log(CARD_FLAG);
      if (!!CARTAO) {
        return !!CARD_FLAG ? null : { card: true };
        // return isCreditCard(CARTAO) ? null : { card: true };
      } else {
        return null;
      } // else
    }
  }

  static cvcValid() {
    return (control: FormControl): ValidationErrors => {
      const CVC: string = (control.value || '');
      // console.log(CVC);
      if (!!CVC) {
        return [3, 4].includes(CVC.length || 0) ? null : { cvc: true };
      } else {
        return null;
      } // else
    }
  }
}
//#endregion

//#region libs
import { detectCreditCard } from "../../../../_libs/_misc/_validations";
//#endregion

@Component({
  selector: "cor-mat-credit-card-form",
  templateUrl: "credit-card-form.component.html",
  styleUrls: ["credit-card-form.component.scss"]
})
export class CorMatCreditCardFormComponent {
  //#region inputs
  // submitButtonCaption
  @Input() submitButtonCaption: string;
  // isMobile
  @Input() isMobile: boolean = false;
  // cartao
  @Input({ required: true }) cartao: ICorCartaoCreditoMap;
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<ICorCartaoCreditoMap>();
  //#endregion

  //#region publics
  cardForm = signal<FormGroup>(null);
  cards = signal<any>(ACCEPTED_CREDIT_CARDS).asReadonly();
  flag = signal<string>('');
  fv = signal<CorFormValidation>(null);
  //#endregion

  //#region privates
  #busy: boolean = false;
  #subs: Subscription[] = [];
  //#endregion

  //#region events
  nomeFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.cardForm.set(
      this.#fb.group({
        nomeTitular: [this.cartao?.nomeTitular || '', [Validators.required]],
        mesVcto: [this.cartao?.mesVcto || null, [Validators.required]],
        anoVcto: [this.cartao?.anoVcto || null, [Validators.required]],
        nroCartao: [this.cartao?.nroCartao || '', [Validators.required, CustomValidator.cardValid()]],
        cvc: [this.cartao?.cvc || '', [Validators.required, CustomValidator.cvcValid()]],
      })
    );

    this.#subs.push(
      this.nroCartaoRef
        .valueChanges
        .subscribe(
          () => {
            if (!this.#busy) {
              this.#busy = true;
              const NRO_CARTAO: string = this.nroCartaoRef?.value || '';
              this.flag.set(detectCreditCard(NRO_CARTAO) || '');
              this.nroCartaoRef.setValue(this.#formatCard(NRO_CARTAO));
              this.#busy = false;
            } // if
          }
        )
    );
  }

  ngOnDestroy() {
    this.#subs.forEach(e => e && e.unsubscribe());
  }
  //#endregion

  //#region Controls getters
  get nomeTitularRef(): AbstractControl { return this.cardForm()?.get('nomeTitular'); }
  get mesVctoRef(): AbstractControl { return this.cardForm()?.get('mesVcto'); }
  get anoVctoRef(): AbstractControl { return this.cardForm()?.get('anoVcto'); }
  get nroCartaoRef(): AbstractControl { return this.cardForm()?.get('nroCartao'); }
  get cvcRef(): AbstractControl { return this.cardForm()?.get('cvc'); }
  //#endregion

  //#region functions
  #formatCard(nroCartao: string): string {
    const NRO_CARTAO: string = nroCartao.replace(/\D/g, '');
    let formattedValue: string;
    let maxLength: number;
    // american express, 15 digits
    if ((/^3[47]\d{0,13}$/).test(NRO_CARTAO)) {
      formattedValue = NRO_CARTAO.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
      maxLength = 17;
    } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(NRO_CARTAO)) { // diner's club, 14 digits
      formattedValue = NRO_CARTAO.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
      maxLength = 16;
    } else if ((/^\d{0,16}$/).test(NRO_CARTAO)) { // regular cc number, 16 digits
      formattedValue = NRO_CARTAO.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
      maxLength = 19;
    } // else

    // $('#cc').attr('maxlength', maxLength);
    return formattedValue;
  }

  focus() {
    // console.log(this.isMobile);
    !this.isMobile && setTimeout(
      () => { this.nomeFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
