//#region firebase
import {
  collection,
  CollectionReference,
  DocumentData,
  endBefore,
  Firestore,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  Query,
  QueryConstraint,
  QuerySnapshot,
  startAt,
  Unsubscribe,
  where,
  // WhereFilterOp
} from "@angular/fire/firestore";
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  from,
  Observable
} from "rxjs";
import {
  map,
  switchMap,
  tap,
  filter
} from "rxjs/operators";
//#endregion

//#region models
import {
  ICorPaginatorSort,
  ICorPaginatorFilter
} from '../../../../_core/_misc/_models/_interfaces/_misc';

import {
  FIRESTORE_SORT_DIRECTIONS,
  FIRESTORE_WHERE_OPS
} from "../../../../_core/_misc/_models/consts";

export type TPaginatorActions = 'current' | 'first' | 'prev' | 'next' | 'last' | 'reset';
//#endregion

export class CorAngularFirePaginator<T>{
  // export class AngularFirePaginator<T>{
  private readonly path: string;
  private pageSize: number;
  private realtimeCB: any;
  private sort: ICorPaginatorSort[] | null;
  private filter: ICorPaginatorFilter[] | null;
  private stalled = false;
  private debug = false;

  // items$: Observable<T[]>;
  items$: Observable<any>;

  // private #paging$: BehaviorSubject<TPaginatorActions | null>;
  #paging$: BehaviorSubject<any>;

  private firstItemId: string; // the id of the first record of this query on the first page, used as marker for enabling previous

  // private #prevAnchor: QueryDocumentSnapshot<any>; // anchor for querying previous page
  // private #nextAnchor: QueryDocumentSnapshot<any>; // anchor for querying next page
  #prevAnchor: any // anchor for querying previous page
  #nextAnchor: any; // anchor for querying next page

  #unsub: Unsubscribe;

  // pending: boolean = false;
  firstEnabled: boolean = false;
  lastEnabled: boolean = false;
  nextEnabled: boolean = false;
  previousEnabled: boolean = false;

  queryFilterMethods: QueryConstraint[] = [];
  querySortMethods: QueryConstraint[] = [];
  queryPagMethods: QueryConstraint[] = [];

  /**
   * AngularFire-based Paginator.
   *
   * @param fs AngularFire service instance
   * @param path Firebase collection data path
   * @param realtimeCB Realtime monitoring callback function
   * @param pageSize Elements per page
   * @param sortOptions Array of sorting criteria
   * @param filterOptions Array of filter criteria
   * @param stalled Sets stalled flag. Useful when you have to wait for other data to load before displaying paginator. The paginator will query after resume() is called.
   * @param debug Turns console logs on or off.
   */

  constructor(
    // private fs: AngularFirestore,
    private fs: Firestore,
    path: string,
    pageSize: number,
    sortOptions: ICorPaginatorSort[] = [],
    filterOptions: ICorPaginatorFilter[] = [],
    realtimeCB: any = null,
    debug: boolean = false,
    stalled: boolean = false,
  ) {

    this.pageSize = pageSize;
    this.path = path;
    this.realtimeCB = realtimeCB;
    this.sort = sortOptions;
    this.filter = filterOptions;
    this.stalled = (stalled === undefined) ? false : stalled;
    this.debug = debug;
    const ID_TAG: string = /* systemId ? '_id' : */ 'id';
    // console.log('stalled', this.stalled);
    // console.log('debug', this.debug);    

    this.#paging$ = new BehaviorSubject('first');

    this.items$ = this.#paging$
      .pipe(
        tap(
          (pagingAction) => {
            // this.debug && console.log('pending = true');
            // this.pending = true;

            this.trace("start ------------------------ ");
            // disable all navigation buttons during query
            this.firstEnabled = this.previousEnabled = this.nextEnabled = this.lastEnabled = false;
            this.trace('page size ', this.pageSize, this.path, pagingAction);
          }),

        filter(() => {
          this.stalled && this.trace("stalled ------------------------ ");
          return this.stalled === false;
        }),

        switchMap(
          (pagingAction) => {
            const snaps2Pag = (obs$: Observable<any>): Observable<any> => {
              return obs$
                .pipe(
                  tap((snaps: QuerySnapshot<DocumentData>) => {
                    // console.log(snaps);
                    const DOCS: any[] = snaps.docs;
                    this.trace('snapshot tapper ------------------ ', pagingAction, DOCS.length);
                    // console.log(DOCS);
                    // console.log(DOCS[0].id);

                    if (DOCS.length) {
                      const PS: number = +this.pageSize;
                      switch (pagingAction) {
                        case 'reset':
                        case 'first':
                          this.firstItemId = DOCS[0].id;
                          break;

                        case 'prev':
                          // in case of previous: if there are less items than page-size, it means that we reached the
                          // first page but the paging took place from an item that would usually display itself on the first page.
                          // e.g. only first 2 items returned because previous was called from item 3 with a page size of 4.
                          // This  can happen when the user uses prev all the way from the end of the list to the start.
                          // Forcefully refresh the page to first.
                          this.nextEnabled = this.lastEnabled = true;
                          if (DOCS.length < PS + 1) {
                            this.paginate('reset');
                            this.trace('enablePrev:', this.previousEnabled, 'items.length', DOCS.length, 'ps', PS, pagingAction);
                          } // if
                          break;

                        case 'next':
                          if (DOCS.length < this.pageSize + 1) {
                            // but only if we are not already on the first page
                            if (DOCS[0].id !== this.firstItemId) {
                              this.firstEnabled = this.previousEnabled = true;
                              this.paginate('last');
                            } // if
                          }
                          this.trace('enableNext:', this.nextEnabled, 'items.length', DOCS.length, 'ps', PS, pagingAction);
                          break;

                        case 'last':
                          break;
                      } // switch

                      // Check if we have a next page by the number of items.
                      // If we have pagination-size + 1 results, then the next page exists.
                      this.lastEnabled = this.nextEnabled = DOCS.length == PS + 1;

                      // enablePrev if we are not at the very first element
                      // enableFirst is just for convenience. Actually we could do with enablePrev
                      // Todo: what happens if the first element (firstItemId) changes somewhere in between?
                      this.firstEnabled = this.previousEnabled = DOCS[0].id !== this.firstItemId;

                      // remember the anchors for moving to previous and next pages
                      this.#prevAnchor = DOCS[1]; // item[1] because we have to use endBefore for previous and have to query one item extra
                      this.#nextAnchor = DOCS.slice(-1)[0]; // last item because we have to use startAt for next and queried one item extra only for this purpose
                      // this.trace('Anchor for prev', JSON.stringify(this.current#PrevAnchor.data()), "Anchor for next", JSON.stringify(this.current#NextAnchor.data()));
                      this.trace('#prevAnchor', this.#prevAnchor && JSON.stringify(this.#prevAnchor.data()));
                      this.trace('#nextAnchor', this.#nextAnchor && JSON.stringify(this.#nextAnchor.data()));
                    } else {
                      // no items were found for the new page, reset to first. But only if we did not just move to first anyway.
                      if (pagingAction !== 'first' && pagingAction !== 'reset') {
                        this.paginate('reset');
                        this.trace('reset');
                      } // if
                    } // else  
                  }),

                  map((snaps: QuerySnapshot<DocumentData>) => {
                    // console.log(snaps.docs);
                    return snaps.docs.map(
                      (snap, index) => {
                        const PS: number = +this.pageSize;
                        const DATA = snap.data() as any;
                        // set the display to false for the extra item (that was queried for checking to enable the next button)
                        // DATA.displayInPagination = index < PS;
                        // DATA.id = snap.id;
                        // console.log(DATA);
                        // return DATA as any;
                        return {
                          // [ID_TAG]: snap.id,
                          [ID_TAG]: snap.id,
                          ...DATA,
                          __displayInPagination: index < PS,
                        };
                      }
                    );
                  }),
                  // filter((d: any) => {
                  //   console.log(JSON.stringify(!!d.displayInPagination));
                  //   return true;
                  //   // return d.displayInPagination
                  // })
                )
            }

            this.trace("switchMap query building start ------------------------ ");
            // console.log(pagingAction);
            const COLLECTION_REF: CollectionReference = collection(
              fs,
              this.path
            );

            // let query = this.applyFilter(ref);
            // query = this.applySort(query);
            this.applyFilter();
            this.applySort();

            switch (pagingAction) {
              case 'current':
                this.queryCurrent();
                break;

              case 'next':
                this.queryNext();
                break;

              case 'prev':
                this.queryPrev();
                break;

              case 'last':
                this.queryLast();
                break;

              case 'first':
              case 'reset':
              default:
                this.queryFirst();
                break;
            } // switch

            this.trace("query methods list -------------------------");
            [
              ...this.queryFilterMethods,
              ...this.querySortMethods,
              ...this.queryPagMethods
            ]
              .forEach(m => this.trace(m.type));
            this.trace("query building done ------------------------ ");

            const Q: Query = query(
              COLLECTION_REF,
              ...this.queryFilterMethods,
              ...this.querySortMethods,
              ...this.queryPagMethods
            ) as any;

            // console.log(typeof this.realtime);
            if (
              !!this.realtimeCB
              && typeof this.realtimeCB === 'function'
            ) {
              !!this.#unsub && this.#unsub();
              this.#unsub = onSnapshot(
                Q,
                // { includeMetadataChanges: true },
                snaps => {
                  // console.log(snaps);
                  const DOCS: any[] = [];

                  // snaps.docChanges().forEach(
                  //   (change) => {
                  //     // console.log(change?.doc?.id, change?.type);
                  //     DOCS.push(
                  //       {
                  //         ...change?.doc?.data(),
                  //         id: change?.doc?.id,
                  //         __docChange: change?.type
                  //       }
                  //     );
                  //     // if (change.type === "added") {
                  //     //   console.log("added: ", change.doc.data());
                  //     // }
                  //     // if (change.type === "modified") {
                  //     //   console.log("modified: ", change.doc.data());
                  //     // }
                  //     // if (change.type === "removed") {
                  //     //   console.log("removed: ", change.doc.data());
                  //     // }
                  //   }
                  // );

                  snaps?.forEach(
                    doc => DOCS.push(
                      {
                        ...doc.data(),
                        id: doc.id
                      }
                    )
                  );
                  this.realtimeCB(DOCS);
                }
              );
              // return snaps2Pag(from(getDocs(Q)));
            } // if
            const DOCS: any = getDocs(Q);
            // console.log('static', DOCS);
            // DOCS.then(docs => console.log('static', docs))
            let obs$: Observable<any> = from(DOCS);

            // this.debug && console.log('pending = false');
            // this.pending = false;

            return snaps2Pag(obs$);
          })
      ) // switchMap combineLatest
  } // constructor

  private queryFirst() {
    this.trace('Query first');
    this.queryPagMethods = [];
    // return query.limit(this.pageSize + 1);
    this.queryPagMethods.push(limit(this.pageSize + 1));
  }

  private queryPrev() {
    this.trace('Query prev');
    this.queryPagMethods = [];
    if (this.#prevAnchor) {
      this.trace('endBefore', JSON.stringify(this.#prevAnchor.data()));
      // return query.endBefore(this.#prevAnchor).limitToLast(this.pageSize + 1);
      this.queryPagMethods.push(
        endBefore(this.#prevAnchor),
        limitToLast(this.pageSize + 1)
      );
    } else {
      // return query.limit(this.pageSize + 1);
      this.queryPagMethods.push(limit(this.pageSize + 1));
    } // else
  }

  private queryNext() {
    // console.log('Query next');
    this.trace('Query next');
    this.queryPagMethods = [];
    if (this.#nextAnchor) {
      // query one more item than the pagination size in order to know whether there is a next page.
      this.trace('startAt', JSON.stringify(this.#nextAnchor.data()));
      // return query.startAt(this.#nextAnchor).limit(this.pageSize + 1);
      this.queryPagMethods.push(
        startAt(this.#nextAnchor),
        limit(this.pageSize + 1)
      );
    } else {
      // return query.limit(this.pageSize + 1);
      this.queryPagMethods.push(limit(this.pageSize + 1));
    } // else
  }

  private queryLast() {
    // console.log('Query last');
    this.trace('Query last');
    this.queryPagMethods = [];
    // return query.limitToLast(this.pageSize);
    this.queryPagMethods.push(limitToLast(this.pageSize));
  }

  private queryCurrent() {
    this.trace('Query current');
    this.queryPagMethods = [];
    if (this.#prevAnchor) {
      this.trace('startAt', JSON.stringify(this.#nextAnchor.data()));
      // return query.startAt(this.#prevAnchor).limit(this.pageSize + 1);
      this.queryPagMethods.push(
        startAt(this.#prevAnchor),
        limit(this.pageSize + 1)
      );
    } else {
      // return query.limit(this.pageSize + 1);
      this.queryPagMethods.push(limit(this.pageSize + 1));
    } // else
  }

  private applySort() {
    this.querySortMethods = [];
    if (this.sort) {
      this.sort
        .forEach(s => {
          // q = q.orderBy(s.field, s.direction);
          let { field, direction } = s;
          field = (field || '').trim();
          direction = ((direction || '').trim()) as any;
          const DIR_OK: boolean = FIRESTORE_SORT_DIRECTIONS.includes(direction);
          // field && DIR_OK && this.querySorts.push(
          //   { field, direction }
          // );
          if (field && direction) {
            this.trace('applySort.orderBy', field, direction);
            this.querySortMethods.push(
              orderBy(field, direction)
            );
          } // if
        })
    } // if
  }

  private applyFilter() {
    this.queryFilterMethods = [];
    if (this.filter) {
      this.filter
        .forEach(f => {
          let { field, op, val } = f;
          field = (field || '').trim();
          const OP_OK: boolean = FIRESTORE_WHERE_OPS.includes(op);
          // val = (val || '').trim();
          if (field && OP_OK) {
            // q = q.where(f.field, f.op, f.val);
            this.trace('applyFilter.where', field, op, val);
            field && OP_OK && this.queryFilterMethods.push(
              where(field, op, val)
            );
            this.trace('where', field, op, val);
          } // if
        });
    } // if
  }

  public paginate(action: TPaginatorActions) {
    this.#paging$.next(action);
  }

  /**
   * Sets all filter criteria.
   *
   * The array structure is used because filters are ordered.
   *
   * @param filter
   */

  public setFilter(filter: ICorPaginatorFilter[] | null) {
    this.filter = filter;
    this.paginate('reset');
  }

  public unsub() {
    !!this.#unsub && this.#unsub();
  }

  /**
   * Use to update a single filter value.
   *
   * Searches the field in the this.filter array and updates it.
   *
   * @param filter
   */
  public setFilterValue(filter: ICorPaginatorFilter) {
    // search the field to be updated in the array of filters
    (this.filter || [])
      .forEach((f, i) => {
        if (f.field === filter.field) {
          this.filter[i] = filter;
        } // if
      })
    this.paginate('reset');
  }

  /**
   * Use to update a single sort value.
   *
   * Searches the field in the this.sort array and updates it.
   *
   * @param sort
   */

  public setSortValue(sort: ICorPaginatorSort) {
    // search the field to be updated in the array of sort
    this.sort.forEach((f, i) => {
      if (f.field === sort.field) {
        this.sort[i] = sort;
      }
    })
    this.paginate('reset');
  }

  /**
   * Sets all sorting criteria.
   *
   * The array structure is used because sorts are ordered.
   *
   * @param sort
   */
  public setSort(sort: ICorPaginatorSort[] | null) {
    this.sort = sort;
    this.paginate('reset');
  }

  public setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.paginate('current');
  }

  private trace(...items: any) {
    this.debug && console.log(...items);
  }

  /**
   * call to resume loading documents in paginator.
   * can also be used to reload the current page if joined data was updated by simply calling resume() again.
   */

  public resume() {
    console.log('pag resumed');
    this.setLoadingAndRefresh(false);
  }

  /**
   * call to stall loading documents in paginator.
   */
  public stall() {
    this.setLoadingAndRefresh(true);
  }

  public first() {
    this.paginate("first");
  }

  public last() {
    this.paginate("last")
  }

  public next() {
    this.paginate("next")
  }

  public previous() {
    this.paginate("prev");
  }

  public prev() {
    this.previous();
  }

  private setLoadingAndRefresh(loading: boolean) {
    if (this.stalled) {
      this.trace("loading set to ", loading);
      this.stalled = loading;
      if (!loading) {
        this.paginate('reset');
      }
    } else {
      this.stalled = loading;
      if (!loading) {
        this.paginate('current');
      }
    }
  }
}
