//#region 3rd
import { get } from 'lodash';
//#endregion

export function filtros2queries(
  filtros: any[],
  idField: string = 'id'
): any {
  // console.log(filtros);
  const QUERIES: any = filtros.reduce(
    (acc: any, val: any) => {
      return {
        ...acc,
        [get(val, idField)]: get(val, 'val'),
      };
    },
    {}
  );
  // console.log(QUERIES);
  return QUERIES;
}
