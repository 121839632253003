export const toProperCase = (text: string): string => {
  if (text) {
    const TEXT: string = (text || '').trim();
    return TEXT.replace(
      /\w\S*/g,
      (substr) => substr.charAt(0).toUpperCase() + substr.substr(1).toLowerCase()
    );
  } else {
    return '';
  } // else
};
