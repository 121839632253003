<!-- <p>statusItemPedido() {{ statusItemPedido() | json }}</p> -->
<!-- <p>item?.__solicitado {{ item?.__solicitado | json }}</p> -->
<!-- <p>item?.__sel {{ item?.__sel }}</p> -->
<!-- <p>item?._pos {{ item?._pos }}</p> -->
<!-- <p>isAguardandoEncontrado() {{ isAguardandoEncontrado() }}</p> -->

<div class="i-card flex-1 d-flex flex-column h-100 p-relative"
     [class.off]="off"
     attr.data-status="{{ statusItemPedido()[item?._status]?.label }}"
     attr.data-pos="{{ item?._pos }}"
     [class.pendente]="item?._status === 'pendente'"
     [class.confirmado]="item?._status === 'confirmado'"
     [class.modificado]="item?._status === 'modificado'"
     [class.removido]="item?._status === 'removido'"
     [class.adicionado]="item?._status === 'adicionado'"
     [class.naoEncontrado]="item?._status === 'naoEncontrado'"
     *ngIf="!!item ">
  <div class="flex-1">
    <div class="thumb">
      <img class="img-fluid"
           [src]="item?.produto?.__img?.thumb"
           *ngIf="!!item?.produto?.__img?.thumb">
    </div>

    <div class="nome my-2">
      <small>{{ item?.produto?.nome }} <!-- {{ item?._pos }} --></small>
    </div>
  </div>

  <div class="d-flex flex-column">
    <ul class="list-group qtde">
      <li class="list-group-item pointer"
          [class.sel]="stage === 'pendentes' && item?.__sel"
          caption="Solicitado"
          (click)="solicitado$.emit(item)">
        <div class="qtde">
          <separacao-qtde-caption [item]="item"
                                  [off]="stage !== 'pendentes' || !!item?._separado?.qtde?.encontrado"
                                  tipoQtde="solicitado" />
        </div>
      </li>

      <li class="list-group-item pointer"
          [class.off]="['separando', 'separados'].includes(stage) || (stage === 'pendentes' && !!qtdeSel)"
          caption="Encontrado"
          (click)="encontrado$.emit(item)">
        <!-- {{stage}} {{ qtdeSel }} -->
        <div class="qtde">
          <separacao-qtde-caption [item]="item"
                                  tipoQtde="encontrado" />
        </div>
      </li>

      <li class="list-group-item pointer"
          [class.off]="stage === 'separando' || (stage === 'pendentes' && !!qtdeSel) || !item?._separado?.qtde?.encontrado"
          caption="Separado"
          (click)="separado$.emit(item)">
        <div class="qtde">
          <separacao-qtde-caption [item]="item"
                                  tipoQtde="separado" />
          <!-- <div *ngIf="!isAguardandoEncontrado(); else aguardandoEncontrado">
            <separacao-qtde-caption [item]="item"
                                    tipoQtde="separado" />
            [off]="stage === 'separando' || (stage === 'pendentes' && !!qtdeSel) || !item?._separado?.qtde?.encontrado"
          </div>
          <ng-template #aguardandoEncontrado>
            <span class="icon-o-trashbin"></span>
          </ng-template> -->
        </div>
      </li>
    </ul>
  </div>

  <div class="mt-auto d-flex pt-2 mx-auto">
    <me-html-item-carrinho-preco [item]="item"
                                 [isInline]="false"
                                 [isSeparado]="stage !== 'pendentes'" />
  </div>

  <div class="d-flex"
       *ngIf="stage === 'separando'">
    <div class="flex-1">
      <button mat-icon-button
              matTooltip="Remover da seleção"
              color="danger"
              (click)="remove$.emit(item)">
        <!-- <mat-icon>home</mat-icon> -->
        <div class="icon-o-trashbin"></div>
      </button>
    </div>
  </div>
</div>