//#region models
import { desacentua } from '../../../_libs/_misc/_strings';
//#endregion

//#region models
import { ICorViacepApi } from '../../../_core/_misc/_models/_interfaces/_apis';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

export function calcLocalidadeId(endereco: TNullable<ICorViacepApi>): string { // localidadeId
  return !!endereco ?
    `${desacentua(endereco?.localidade || '')
      .replace(/\s/g, '-')}--${(endereco?.uf || '')
        .toLowerCase()}`
    : '';
}
