<div class="p-4">
  <h5 *ngIf="!!title()">{{ title() }}</h5>
  <mat-dialog-content *ngIf="!!msg()">
    <p>{{ msg() }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button
            color="primary"
            (click)="onConfirmClick()"
            tabindex="1">{{ confirmCaption() }}</button>
    <!-- 
    <button mat-raised-button
          mat-dialog-close
          tabindex="-1">{{ cancelCaption }}
    </button> 
    -->
  </mat-dialog-actions>
</div>