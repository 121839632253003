<mat-toolbar [color]="color">
  <div class="d-flex justify-content-between w-100">
    <div class="my-auto flex-1">
      <strong>{{ carrinhoInfo?.qtde?.itens }}</strong>&nbsp;<span class="o-70">ite{{
        carrinhoInfo?.qtde?.itens === 1 ? 'm' : 'ns' }}</span>,&nbsp;<strong>{{
        carrinhoInfo?.qtde?.volumes }}</strong>&nbsp;<span class="o-70">volume{{
        carrinhoInfo?.qtde?.volumes === 1 ?
        '' : 's' }}</span>
    </div>

    <div class="my-auto">
      <strong>{{ carrinhoInfo?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}</strong>
    </div>

    <div class="my-auto"
         *ngIf="stage === 'separando'">
      <button mat-button
              matTooltip="Limpar seleção"
              class="me-2"
              (click)="limpar$.emit()">
        <span class="icon-o-trashbin"></span>
      </button>

      <button mat-flat-button
              color="light"
              (click)="confirmar$.emit()">
        <span class="icon-b-check"></span>&nbsp;<span class="o-70">Confirmar</span>
      </button>
    </div>
  </div>
</mat-toolbar>
