export * from './detect-credit-card';
export * from './fix-barcode';
export * from './is-barcode';
export * from './is-cep';
export * from './is-cnpj';
export * from './is-cpf';
export * from './is-cpf-cnpj';
export * from './is-credit-card';
export * from './is-ie';
export * from './is-email';
export * from './is-fone-cel';
export * from './is-fone-fixo';
