import {
  isCpf,
  isCnpj,
} from '../_validations';
import {
  formataCNPJ,
  formataCPF,
} from '.';

export function formataCpfCnpj(cpfCnpj: string): string {
  if (isCpf(cpfCnpj)) {
    return formataCPF(cpfCnpj);
  } else if (isCnpj(cpfCnpj)) {
    return formataCNPJ(cpfCnpj);
  } // else

  return '';
}
