//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
  serverTimestamp,
  setDoc,
} from '@angular/fire/firestore';

//#endregion

//#region 3rd
import {
  EMPTY,
  from,
  Observable,
  ObservableInput,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { IItemCarrinhoMap } from '../../_misc/_models/_interfaces/_maps';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class PedidosSeparacaoService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region C
  add(
    lojaPath: string,
    pedidoId: string,
    item: IItemCarrinhoMap
    // produto: IProduto
  ): Observable<any> {
    // console.log(endereco);
    if (!!lojaPath) {
      if (!!pedidoId) {
        if (!!item) {
          const PATH: string = `${lojaPath}/pedidos/${pedidoId}/separacao`;
          return from(setDoc(doc(this.#db, PATH, item?.id || ''), item))
            .pipe(
              // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro adicionando item de pedido.')
              )
            );
        } // if
        return throwError(() => 'Nenhum item indicado.');
      } // if
      return throwError(() => 'Nenhum pedido indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region R
  item(
    lojaPath: string,
    pedidoId: string,
    itemId: string
  ): Observable<IItemCarrinhoMap> {
    if (!!lojaPath) {
      if (!!pedidoId) {
        if (!!itemId) {
          const PATH: string = `${lojaPath}/pedidos/${pedidoId}/separacao/${itemId}`;
          // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IItemCarrinhoMap>)
          return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
            .pipe(
              map((doc: any) => Object.values(doc?.data()?._odocs || {}) || []),
              // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando item de separação.')
              )
            );
        } // if
        return throwError(() => 'Nenhum produto indicado.');
      } // if
      return throwError(() => 'Nenhum pedido indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }

  docs(
    lojaPath: string,
    pedidoId: string
  ): Observable<IItemCarrinhoMap[]> {
    console.log(lojaPath, pedidoId);
    if (!!lojaPath) {
      if (!!pedidoId) {
        const PATH: string = `${lojaPath}/pedidos/${pedidoId}/separacao/${ODOCS_TAG}`;
        // return (docData(doc(this.#db, PATH)) as Observable<IItemCarrinhoMap[]>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            map((doc: any) => Object.values(doc?.data()?._odocs || {}) || []),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<IItemCarrinhoMap[], ObservableInput<IItemCarrinhoMap[]>>(
              (err: any) => {
                !!err?.message ?? console.error(err?.message);
                this.#msgServ.onCatchError(err, 'Erro carregando separação de pedidos.')
                return EMPTY;
              }
            )
          );
      } // if
      return throwError(() => 'Nenhum pedido indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region U
  refresh(
    lojaPath: string,
    pedidoId: string,
  ): Observable<any> {
    if (!!lojaPath) {
      if (!!pedidoId) {
        // /_ufs/mg/localidades/uberaba--mg/lojas/699fc369--modelo--mg--uberaba/pedidos/220584c09586d/separacao/{{ODOCS}}
        const PATH: string = `${lojaPath}/pedidos/${pedidoId}/separacao/${ODOCS_TAG}`;
        return from(
          setDoc(
            doc(this.#db, PATH),
            { _refreshEm: serverTimestamp() },
            { merge: true }
          )
        )
          .pipe(
            // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando conta.')
            )
          );
      } // if
      return throwError(() => 'Nenhum pedido indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}