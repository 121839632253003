//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

@Pipe({ name: 'months2human' })
export class CorMonths2HumanPipe implements PipeTransform {
  transform(value: number): string {
    if (typeof value === 'number') {
      const Y: number = Math.trunc(value / 12);
      const M: number = value % 12;
      let y: string = Y > 0
        ? `${Y} ` + (
          Y === 1 ? 'ano' : 'anos'
        )
        : '';
      let m: string = M > 0
        ? `${M} ` + (
          M === 1 ? 'mês' : 'meses'
        )
        : '';
      const SEP: string = y && m ? ' e ' : '';

      return y + SEP + m;
    } else {
      return '';
    } // else
  }
}