//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region libs
import { formataCEP } from '../../../_libs/_misc/_formats';
//#endregion

@Pipe({ name: 'cep' })
export class CorFormataCep implements PipeTransform {
  transform(value: string): string {
    return formataCEP(value);
  }
}
