//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

@Pipe({ name: 'minutes2human' })
export class CorMinutes2HumanPipe implements PipeTransform {
  transform(value: number): string {
    if (typeof value === 'number') {
      const D: number = Math.trunc(value / 1440);
      value -= (D * 1440);
      const H: number = Math.trunc(value / 60);
      value -= (H * 60);
      const M: number = value;
      let d: string = D > 0
        ? `${D} ` + (
          D === 1 ? 'dia' : 'dias'
        )
        : '';
      let h: string = H > 0
        ? `${H} ` + (
          H === 1 ? 'hora' : 'horas'
        )
        : '';
      let m: string = M > 0
        ? `${M} ` + (
          M === 1 ? 'minuto' : 'minutos'
        )
        : '';
      let qtdeLabels: number = 0;
      d && qtdeLabels++;
      h && qtdeLabels++;
      m && qtdeLabels++;

      switch (qtdeLabels) {
        case 3:
          return d + ', ' + h + ' e ' + m;

        case 2:
          return d
            ? d + ' e ' + h + m
            : h + ' e ' + m;

        default:
          return d + h + m;
      } // switch
    } else {
      return '';
    } // else
  }
}