<!-- <p>loja {{ loja | json }}</p> -->

<div *ngIf="!!loja"
     class="loja-sel h-100 d-flex p-2 pointer">
  <div class="d-flex align-items-center me-2">
    <img [src]="loja?.__img?.thumb"
         class="avatar">
  </div>

  <div class="flex-1 my-auto">
    {{ loja?.nome }}
    <small class="o-50">( {{ loja?.id.slice(0, 8) }} )</small>
    <br />
    <span *ngIf="loja?.endereco">
      <!-- {{ loja?.__endereco | json }}<br /> -->
      <cor-bs-endereco [endereco]="loja?.endereco"
                       [small]="true" />
    </span>
  </div>
</div>
