export * from './dow.pipe';
export * from './formata-fone.pipe';
export * from './formata-cep.pipe';
export * from './formata-cnpj.pipe';
export * from './formata-cpf.pipe';
export * from './formata-cpfCnpj.pipe';
export * from './formata-ncm.pipe';
export * from './mes.pipe';
export * from './min2time.pipe';
export * from './minutes2human.pipe';
export * from './months2human.pipe';
export * from './safe-html.pipe';
export * from './sexo.pipe';
export * from './typeof.pipe';