//#region ng
import {
  Pipe,
  PipeTransform,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
//#endregion

//#region models
import { IRede } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { RedesService } from '../_services';
//#endregion

@Pipe({ name: 'redesLfixes' })
export class MeRedesLfixesPipe implements PipeTransform {
  //#region injects
  #redesServ = inject(RedesService);
  //#endregion

  transform(val: IRede[]): Observable<IRede[]> {
    return this.#redesServ.lfixes(val);
  }
}
