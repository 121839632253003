//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  signal,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
//#endregion

//#region models
import { CorFormValidation } from "../../../_ng/_models/_classes/form-validation";
import { FOCUS_TIMEOUT } from '../../../_misc/_models/consts';
//#endregion

@Component({
  selector: "cor-mat-email-get-form",
  templateUrl: "email-get-form.component.html",
  styleUrls: ["email-get-form.component.scss"]
})
export class CorMatEmailGetFormComponent {
  //#region inputs
  // submitCaption
  @Input() submitCaption: string = 'CONFIRMAR';
  // initialVale
  @Input() initialValue: string;
  // isMobile
  @Input() isMobile: boolean = false;
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<string>();
  //#endregion

  //#region publics
  emailForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  //#endregion

  //#region events
  emailFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.emailForm.set(
      this.#fb.group({
        email: [this.initialValue, [Validators.maxLength(60), Validators.required, Validators.email]],
      })
    );
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region Controls getters
  get emailRef(): AbstractControl { return this.emailForm()?.get('email'); }
  //#endregion

  //#region functions
  focus() {
    !this.isMobile && setTimeout(
      () => { this.emailFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
