//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
//#endregion

//#region 3rd
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask
} from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
//#endregion

//#region modules
import { CorDirectivesModule } from '../../_ng/_directives/cor-directives.module';
import { CorBsComponentsModule } from '../_bs/cor-bs-components.module';
import { CorHtmlComponentsModule } from '../_html/cor-html-components.module';
import { CorPipesModule } from '../../_ng/_pipes/cor-pipes.module';
//#endregion 

//#region components
import {
  CorMatAlertDialog,
  CorMatAlgoliaFormComponent,
  CorMatCompletaEnderecoFormComponent,
  CorMatConfirmDialog,
  CorMatCreditCardFormComponent,
  CorMatEmailGetFormComponent,
  CorMatEnderecoFormComponent,
  CorMatLoginEmailFormComponent,
  CorMatLoginFoneFormComponent,
  CorMatManageEnderecoComponent,
  CorMatManageIdentificacaoComponent,
  CorMatMessagesComponent,
  CorMatNgxPaginationNavComponent,
  CorMatPaginationNavComponent,
  CorMatPagSelOffsetComponent,
  CorMatRegisterEmailFormComponent,
  CorMatRepeatFormComponent,
  CorMatSenhaConfirmacaoFormComponent,
  CorMatTristateToggleComponent,
  CorMatViewstyleSelComponent,
} from '.';
//#endregion

@NgModule({
  imports: [
    CorBsComponentsModule,
    CorDirectivesModule,
    CorPipesModule,
    CorHtmlComponentsModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    MatStepperModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxPaginationModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CorMatAlertDialog,
    CorMatAlgoliaFormComponent,
    CorMatCompletaEnderecoFormComponent,
    CorMatConfirmDialog,
    CorMatCreditCardFormComponent,
    CorMatEmailGetFormComponent,
    CorMatEnderecoFormComponent,
    CorMatLoginEmailFormComponent,
    CorMatLoginFoneFormComponent,
    CorMatManageEnderecoComponent,
    CorMatManageIdentificacaoComponent,
    CorMatMessagesComponent,
    CorMatNgxPaginationNavComponent,
    CorMatPaginationNavComponent,
    CorMatPagSelOffsetComponent,
    CorMatRegisterEmailFormComponent,
    CorMatRepeatFormComponent,
    CorMatSenhaConfirmacaoFormComponent,
    CorMatTristateToggleComponent,
    CorMatViewstyleSelComponent,
  ],
  exports: [
    CorMatAlertDialog,
    CorMatAlgoliaFormComponent,
    CorMatCompletaEnderecoFormComponent,
    CorMatConfirmDialog,
    CorMatCreditCardFormComponent,
    CorMatEmailGetFormComponent,
    CorMatEnderecoFormComponent,
    CorMatLoginEmailFormComponent,
    CorMatLoginFoneFormComponent,
    CorMatManageEnderecoComponent,
    CorMatManageIdentificacaoComponent,
    CorMatMessagesComponent,
    CorMatNgxPaginationNavComponent,
    CorMatPaginationNavComponent,
    CorMatPagSelOffsetComponent,
    CorMatRegisterEmailFormComponent,
    CorMatRepeatFormComponent,
    CorMatSenhaConfirmacaoFormComponent,
    CorMatTristateToggleComponent,
    CorMatViewstyleSelComponent,
  ],
  providers: [
    provideNgxMask(),
  ]
})
export class CorMatComponentsModule { }