<!-- <p>item {{ item | json }}</p> -->
<!-- <p>item?.__solicitado {{ item?.__solicitado | json }}</p> -->
<!-- <p>{{ tipoQtde }}</p> -->
<!-- <p>{{ item?._separado?.qtde | json }}</p> -->

<div class="d-flex"
     [class.off]="off">
  <div [ngSwitch]="tipoQtde">
    <div *ngSwitchCase="'separado'">
      <strong>{{ item?._separado?.qtde?.fixed ?? '?' }}</strong>
    </div>

    <div *ngSwitchCase="'encontrado'">
      <strong>{{ item?._separado?.qtde?.encontrado ?? '?' }}</strong>
    </div>

    <div *ngSwitchDefault>
      <strong>{{ item?.__solicitado?.qtde?.fixed ?? '?' }}</strong>
    </div>
  </div>
  <small class="o-70">
    &nbsp;{{ item?.produto?.fracionado?.unidade?._legenda || 'un' }} 
  </small>
</div>

<!-- <button class="sep-btn btn-light separado h-100 w-100 d-flex active"
        [disabled]="off"
        (click)="onItemClick()"
        *ngIf="!!item">
  <div class="caption m-auto">
    <ng-container *ngIf="!!hasCaption">
      <small class="o-70">Separado</small><br />
    </ng-container>
    {{ item?._separado?.qtde?.fixed || 0 }}
    <small class="o-70">
      {{ item?.produto?.fracionado?.unidade?._legenda || 'un' }}
    </small>
  </div>
</button>
-->


