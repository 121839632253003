//#region  models
import { ACCEPTED_CREDIT_CARDS } from '../../../_core/_misc/_models/consts';
//#endregion

export function detectCreditCard(cardNo: string): string {
  // remove all non digit characters
  const CARD_NO: string = cardNo.replace(/\D/g, '');

  /* var cards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})/,
    mastercard: /^5[1-5][0-9]{14}/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    amex: /^3[47][0-9]{13}/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    aura: /^(5078\d{2})(\d{2})(\d{11})$/
  }; */

  // if (!!ACCEPTED_CREDIT_CARDS) {
  for (const flag in ACCEPTED_CREDIT_CARDS) {
    const REGEX: any = ACCEPTED_CREDIT_CARDS[flag]?.regex?.detect;
    // console.log(ACCEPTED_CREDIT_CARDS[flag]);
    // console.log(REGEX);
    if (REGEX?.test(CARD_NO)) {
      return flag;
    } // if
  } // for
  // } // if

  return '';
}

// https://cleilsontechinfo.netlify.app/jekyll/update/2019/12/08/um-guia-completo-para-validar-e-formatar-cartoes-de-credito.html
