//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region models
import { FOCUS_TIMEOUT } from '../../../_misc/_models/consts';
//#endregion

@Component({
  selector: 'cor-mat-repeat-form',
  templateUrl: './repeat-form.component.html',
  styleUrls: ['./repeat-form.component.scss']
})
export class CorMatRepeatFormComponent {
  //#region inputs
  // texto
  @Input() texto: string = '';
  // isMobile
  @Input() isMobile: boolean = false;
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<void>();
  //#endregion

  //#region publics
  repeated = signal<boolean>(false);
  //#endregion

  //#region repeat
  #repeat: string;
  get repeat(): string { return this.#repeat; }
  set repeat(val: string) {
    this.#repeat = val;
    // console.log(val);
    this.texto = val;
    this.repeated.set(val === this.texto);
  }
  //#endregion

  //#region events
  textoFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region lifecycles
  ngAfterViewInit(): void {
    this.#focus();
  }
  //#endregion

  //#region functions
  #focus() {
    !this.isMobile && setTimeout(
      () => { this.textoFocusEvent$().emit(); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
