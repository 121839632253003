<!-- <p>email {{ email }}</p> -->
<!-- <p>registerForm().value {{ registerForm()?.value | json }}</p> -->
<!-- <p>fv()?.errosApi {{ fv?.errosApi | json }}</p> -->
<!-- <p>isMobile {{ isMobile }}</p> -->

<form novalidate
      [formGroup]="registerForm()"
      (ngSubmit)="onSubmitClick()"
      *ngIf="registerForm()">

  <div class="row">
    <div class="col pb-2">
      <mat-form-field class="w-100 mb-2"
                      [class.off]="email"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>E-mail</mat-label>
        <input type="email"
               matInput
               [readonly]="email !== ''"
               formControlName="email"
               [focus]="emailFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(emailRef)">
          <span class="danger"> {{ fv()?.error(emailRef) }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col pb-2">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Senha</mat-label>
        <input type="password"
               matInput
               formControlName="senha"
               [focus]="senhaFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(senhaRef)">
          <span class="danger"> {{ fv()?.error(senhaRef) }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col mb-2">
      <mat-form-field class="w-100"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Confirmação</mat-label>
        <input type="password"
               matInput
               formControlName="confirmacao"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(confirmacaoRef) || confirmacaoRef?.errors?.['notEqual']">
          <span class="danger"> {{ fv()?.error(confirmacaoRef) || 'Senha e confirmação não correspondem.' }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col d-flex">
      <button type="button"
              class="pointer flex-1"
              mat-flat-button
              color="light"
              (click)="cancel$.emit()">
        <span class="icon-r-chevron-l o-50 me-2"></span>
        <span>Voltar</span>
      </button>

      <!-- <div class="flex-1"></div> -->

      <button type="submit"
              class="pointer flex-1"
              mat-raised-button
              color="primary"
              [disabled]="!registerForm().valid">
        CONFIRMAR
      </button>
    </div>
  </div>
</form>