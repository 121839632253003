//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  signal,
  Output
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
//#endregion

//#region models
import { CorFormValidation } from '../../../_ng/_models/_classes/form-validation';
import { ICorViacepApi } from '../../../_misc/_models/_interfaces/_apis/viacep';
import { FOCUS_TIMEOUT } from '../../../_misc/_models/consts';
import { TNullable } from '../../../_misc/_models/_types';
//#endregion

@Component({
  selector: 'cor-mat-completa-endereco-form',
  templateUrl: './completa-endereco-form.component.html',
  styleUrls: ['./completa-endereco-form.component.scss']
})
export class CorMatCompletaEnderecoFormComponent {
  //#region inputs
  // endereco
  @Input({ required: true }) endereco: ICorViacepApi;
  // isMobile
  @Input() isMobile: boolean = false;
  // // submitCaption
  // @Input() submitCaption: string = '';
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<TNullable<ICorViacepApi>>();
  @Output() statusChange$ = new EventEmitter<boolean>();
  //#endregion

  //#region publics
  enderecoForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  //#endregion

  //#region events
  bairroFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region lifecycles
  async ngOnInit() {
    this.enderecoForm.set(
      this.#fb.group({
        bairro: [this.endereco?.bairro || '', [Validators.required, Validators.maxLength(120)]],
        logradouro: [this.endereco?.logradouro || '', [Validators.required, Validators.maxLength(120)]],
      })
    );

    this.statusChange$.emit(this.enderecoForm()?.valid);
  }


  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region Controls getters
  get bairroRef(): AbstractControl { return this.enderecoForm()?.get('bairro'); }
  get logradouroRef(): AbstractControl { return this.enderecoForm()?.get('logradouro'); }
  //#endregion

  //#region functions
  focus() {
    // console.log('focus');
    !this.isMobile && setTimeout(
      () => { this.bairroFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onSubmitClick() {
    this.submit$.emit(
      {
        ...this.endereco,
        ...this.enderecoForm()?.value
      } as ICorViacepApi
    );
  }

  onCancelarClick() {
    this.submit$.emit(null);
  }
  //#endregion
}
