//#region ng
import {
  EventEmitter,
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
  setDoc
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map,
  shareReplay
} from 'rxjs/operators';
//#endregion

//#region models
import {
  IMG_NO_IMG,
  ODOCS_TAG
} from '../../../_core/_misc/_models/consts';
import {
  ILoja,
  IRede
} from '../../_misc/_models/_interfaces/_cols';
import { environment } from 'src/environments/environment';
//#endregion

//#region libs
import { checkImg } from '../../../_libs/_misc/_imgs';
//#endregion

//#region setvices
import { CorMessagesService } from '../../../_core/_ng/_services';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class RedesService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region events
  static redeAdded$: EventEmitter<Partial<IRede>> = new EventEmitter<Partial<IRede>>();
  static redeUpdated$: EventEmitter<Partial<IRede>> = new EventEmitter<Partial<IRede>>();
  static redesChanged$: EventEmitter<void> = new EventEmitter<void>();
  //#endregion

  //#region misc
  fix(row: Partial<IRede>): IRede {
    return {
      // id
      id: row?.id || '',

      // user
      ativo: {
        status: !!row?.ativo?.status,
        _status: !!row?.ativo?._status,
      },
      dono: {
        _email: row?.dono?._email || '',
        id: row?.dono?.id || '',
        _nome: row?.dono?._nome || '',
        // __status: !!row?.dono?.__status,
      },
      nome: row?.nome || '',
      tema: row?.tema || '',

      // system
      _criadoEm: row?._criadoEm || null,
      _criadoPor: {
        id: row?._criadoPor?.id || '',
        nome: row?._criadoPor?.nome || '',
      },
      _exists: !!row?._exists,
      // _lojasRefs: row?._lojasRefs || [],
      // _qtde: any;

      // realtime
      __displayInPagination: !!row?.__displayInPagination,
      __logo: row?.__logo || IMG_NO_IMG,
    };

    // const IMG: string = `${environment.firebase.mercadeiro.storage.root}redes%2f${R.id}.png?alt=media`;
    // checkImg(
    //   IMG,
    //   () => R.__logo = IMG,
    //   () => R.__logo = IMG_NO_IMG
    // );

    // row = row || {};
    // let R: IRede = row;

    // // id 
    // // R.objectID = get(row, 'objectID') || '';
    // R.id = row?.id || '';

    // // user
    // // R.algolia = {
    // //   adminApiKey: get(row, 'algolia.adminApiKey') || '',
    // //   appId: get(row, 'algolia.appId') || '',
    // //   email: get(row, 'algolia.email') || '',
    // //   searchApiKey: get(row, 'algolia.searchApiKey') || '',
    // //   senha: get(row, 'algolia.senha') || '',
    // // };
    // R.ativo = {
    //   status: !!row?.ativo?.status,
    //   _status: !!row?.ativo?._status
    // };
    // R.nome = row?.nome || '';
    // R.tema = row?.tema || '';

    // // system
    // R._criadoEm = row?._criadoEm || null;
    // R._criadoPor = {
    //   id: row?._criadoPor?.id,
    //   nome: row?._criadoPor?.nome
    // };
    // R._exists = !!row?._exists
    // R._lojasRefs = row?._lojasRefs || [];
    // // R._qtde = row?._qtde || null;

    // // realtime
    // R.__displayInPagination = !!row?.__displayInPagination

    // const IMG: string = `${environment.firebase.mercadeiro.storage.root}redes%2f${R.id}.png?alt=media`;
    // checkImg(
    //   IMG,
    //   () => R.__logo = IMG,
    //   () => R.__logo = IMG_NO_IMG
    // );

    // return R;
  }

  lfix(row: Partial<IRede>): Promise<TNullable<IRede>> {
    // const R: IRede = this.fix(row);
    return new Promise(
      (resolve, reject) => {
        const REDE_ID: string = row?.id || '';
        if (!REDE_ID) {
          resolve(null); // )reject(new Error('Nenhuma rede indicada.'));
        } else {
          const IMG: string = `${environment.firebase.mercadeiro.storage.root}/redes%2f${REDE_ID}.png?alt=media`;
          // console.log(IMG);
          checkImg(
            IMG,
            // () => { console.log(IMG); resolve(this.fix({ ...row, __logo: IMG })) },
            () => resolve(this.fix({ ...row, __logo: IMG })),
            () => resolve(this.fix({ ...row, __logo: IMG_NO_IMG })),
          );
        } // else
      }
    );
  }

  fixes(docs: Partial<IRede>[]): IRede[] {
    return (docs || []).map((doc: Partial<IRede>) => this.fix(doc));
  }

  lfixes(docs: Partial<IRede>[]): Observable<IRede[]> {
    // console.log(docs);
    const PROMISES: Promise<IRede>[] = (docs || [])
      .map((doc: Partial<IRede>) => this.lfix(doc));
    return from(Promise.all(PROMISES));
  }
  //#endregion

  //#region R
  lojasDocs(idRede: string): Observable<ILoja[]> {
    if (idRede) {
      const PATH: string = `_redes/${idRede}/rede-lojas/${ODOCS_TAG}`;
      // return (docData(doc(this.#db, PATH)) as Observable<ILoja[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => Object.values(doc?.data()?._odocs || {}) || []),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando lojas da rede.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma rede indicada.');
  }

  docs(): Observable<IRede[]> {
    const PATH: string = `_redes/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH)) as Observable<IRede[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<any, ObservableInput<any>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando redes.')
        ),
        // shareReplay(),
      );
  }

  doc(redeId: string): Observable<IRede> {
    // console.log('redeId', redeId);
    if (redeId) {
      const PATH: string = `/_redes/${redeId}`;
      // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IRede>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IRede, ObservableInput<IRede>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando rede.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma rede indicada.');
  }
  //#endregion

  //#region U
  update(
    idRede: string,
    changes: Partial<IRede>
  ): Observable<any> {
    // console.log(idRede);
    // console.log(changes);
    if (!!idRede) {
      const PATH: string = `_redes/${idRede}`;
      return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
        .pipe(
          // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando rede.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma rede indicada.');
  }
  //#endregion
}
