//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
//#endregion

//#region models
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
import { ILocalidadeBairro } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class LocalidadeBairrosService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<ILocalidadeBairro>): ILocalidadeBairro {
    return {
      // id
      id: row?.id || '',

      // system
      _criadoEm: row?._criadoEm || null,
      _nome: row?._nome || '',
    };
  }

  fixes(docs: Partial<ILocalidadeBairro>[]): ILocalidadeBairro[] {
    return (docs || []).map((doc: Partial<ILocalidadeBairro>) => this.fix(doc));
  }
  //#endregion

  //#region R
  docs(localidadePath: string): Observable<ILocalidadeBairro[]> {
    if (!!localidadePath) {
      const PATH: string = `${localidadePath}/localidade-bairros/${ODOCS_TAG}`;
      // console.log(PATH);
      // return (docData(doc(this.#db, PATH)) as Observable<ILocalidadeBairro[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando bairros.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma localidade indicada.');
  }
  //#endregion
}