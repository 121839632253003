<!-- <h2>produtos-skel works !!!!</h2> -->
<ul class="w-cards departamentos">
  <li class="departamento"
      *ngFor="let dummy of [].constructor(itemsPerPage); trackBy:tid">
    <div class="thumb">
      <img [src]="imgPlaceholder()"
           alt="">
    </div>
    <ngx-skeleton-loader [count]="1"></ngx-skeleton-loader>

  </li>
</ul>