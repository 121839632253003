export function tcenter(
  txt: string,
  len: number,
  sep: string = ' '
): string {
  txt = txt.trim();
  const L: number = len - txt.length;
  if (L > 0) {
    const L1: number = Math.trunc(L / 2);
    const L2: number = L1 + L % 2;
    // console.log(txt, len, L, L1, L2);
    return sep.repeat(L1) + txt + sep.repeat(L2);
  };
  return txt.slice(0, len);
}
