//#region ng
import {
  Component,
  ElementRef,
  EventEmitter,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import {
  collection,
  Firestore,
  onSnapshot,
  query,
  Query,
  where,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import * as $ from 'jquery';
// import { get } from 'lodash';
import { AppService } from '../../services';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators';
// import { SoundsService } from 'ngx-sounds';
//#endregion

//#region models
// interface IVm {
//   itemsPerPage: string;
//   loja: ILoja;
//   pedidoCod: string;
//   pedidos: IPedido[];
//   pedidoStatus: TPedidoStatus | '';
// };
import {
  ILoja,
  IPedido
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  KEY_OFFSET_PEDIDOS,
  PEDIDOS_POR_PAG
} from '../../models/consts';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import { CorAngularFirePaginator } from '../../_shared/_core/_ng/_models/_classes';
import { TPedidoStatus } from '../../_shared/_mercadeiro/_misc/_models/_types';
import { ICorPaginatorFilter } from '../../_shared/_core/_misc/_models/_interfaces/_misc';
//#endregion

//#region services
import {
  LojasService,
  PedidosService
} from '../../_shared/_mercadeiro/_ng/_services';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.page.html',
  styleUrls: ['./pedidos.page.scss']
})
export class PedidosPage {
  //#region actions
  // private _hitsAction$: Subject<IConta[]> = new Subject<IConta[]>();
  #destroyAction$: Subject<void> = onDestroy();
  pagItemsPerPageAction$ = signal<BehaviorSubject<string>>(new BehaviorSubject<string>(PEDIDOS_POR_PAG)).asReadonly();
  pedidosAction$ = signal<BehaviorSubject<IPedido[]>>(new BehaviorSubject<IPedido[]>(null)).asReadonly();
  pedidoCodChangedAction$ = signal<BehaviorSubject<string>>(new BehaviorSubject<string>('')).asReadonly();
  #pedidoStatusAction$: BehaviorSubject<TPedidoStatus | ''> = new BehaviorSubject<TPedidoStatus | ''>('');
  //#endregion

  //#region viewchilds
  @ViewChild('audio', { static: true }) audioRef: HTMLAudioElement;
  //#endregion

  //#region publics
  offsetStorageKey = signal<string>(KEY_OFFSET_PEDIDOS).asReadonly();
  paginator: CorAngularFirePaginator<IPedido>;
  // vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region searchTerm
  #searchTerm: string;
  get searchTerm(): string { return this.#searchTerm; }
  set searchTerm(val: string) {
    const OLD_FOUND: boolean = (this.#searchTerm || '').length >= 5;
    this.#searchTerm = (val || '').trim();
    const NEW_FOUND: boolean = this.searchTerm.length >= 5;
    if (OLD_FOUND !== NEW_FOUND) {
      if (!!NEW_FOUND) { this.status = ''; }
      this.pedidoCodChangedAction$().next(!!NEW_FOUND ? val : '');
    } // ifs
  }

  //#endregion

  //#region status
  #status: TPedidoStatus | '' = '';
  get status(): TPedidoStatus | '' { return this.#status; }
  set status(val: TPedidoStatus | '') {
    this.#status = val;
    // console.log(val);
    this.#pedidoStatusAction$.next(val);
    // if (!!val) {
    //   this.search = '';
    // } // if
    // this._refresh();
  }
  //#endregion

  //#region privates
  #pedidosUnsub: Subscription;
  #notificacoesUnsub: FbUnsubscribe;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #lojasStore = inject(LojasStore);
  #pedidosServ = inject(PedidosService);
  #router = inject(Router);
  // #soundsServ = inject(SoundsService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // let audio = new Audio();
    // audio.src = 'assets/audio/laser.mp3';
    // audio.load();
    // audio.play();

    // var audio = new Audio('/assets/audio/laser.mp3');
    // audio.play();

    combineLatest([
      this.pedidoCodChangedAction$(),
      this.#pedidoStatusAction$,
      this.#lojasStore.lojaStateChanged$
        .pipe(filter((loja: ILoja) => !!loja)),
      this.pagItemsPerPageAction$(),
    ])
      .pipe(
        tap(([pedidoCod, pedidoStatus, loja, itemsPerPage]) => {
          // console.log(pedidoCod, pedidoStatus, loja, itemsPerPage);

          // const PATH: string = `${LOJA_PATH}/pedidos`;
          if (!!loja) {
            const Q: Query = query(
              collection(this.#db, '/_notificacoes'),
              where('source.idConta', '==', loja?.id),
              where('_alertaPendente', '==', true),
              // limit(1)
            );
            // console.log(PATH);
            !!this.#notificacoesUnsub && this.#notificacoesUnsub();
            this.#notificacoesUnsub = onSnapshot(
              Q,
              snaps => {
                // console.log(snaps);
                let ids: string = '';
                snaps?.forEach(snap => ids += (snap.id || ''));
                if (!!ids) {
                  $(".warningSound").trigger('load');
                  $(".warningSound").trigger('play');
                } // if
                // const ENDERECOS: ICorViacepApi[] = [];
                // // console.log(snaps?.docs);
                // snaps?.docs?.forEach(snap => snap?.id !== ODOCS_TAG && ENDERECOS.push({ ...snap?.data(), id: snap?.id }));
                // this.#enderecosAction$.next(
                //   this.#cepServ.fixes(
                //     // uniqueListBy(ENDERECOS, 'id')
                //     ENDERECOS
                //       .sort(compareValues('_criadoEm', 'desc'))
                //   )
                // );
              }
            );
          } // if

          const LOJA_PATH: string = loja?.__idInfo?.lojaPath || '';
          // console.log(LOJA_PATH);
          if (!!LOJA_PATH) {
            const FILTERS: ICorPaginatorFilter[] = [];
            !!pedidoCod && FILTERS.push({ field: '_cod', 'op': '==', val: pedidoCod });
            !!pedidoStatus && FILTERS.push({ field: '_status', 'op': '==', val: pedidoStatus });
            // console.log(pedidoCod, pedidoStatus, JSON.stringify(FILTERS));
            this.paginator = new CorAngularFirePaginator(
              this.#db,
              `${LOJA_PATH}/pedidos`,
              Number(itemsPerPage || '12'),
              [{ field: '_criadoEm', direction: 'desc' }],
              FILTERS,
              docs => {
                // console.log(docs);
                this.pedidosAction$().next(this.#pedidosServ.fixes(docs));
                this.#focus();

                /* docs?.forEach(d => console.log(d?.id, d?.__docChange));
                console.log(this._muted, 'assets/audio/laser.mp3');
                if (
                  !this._muted
                  && (docs || []).some(d => d?.__docChange === 'modified')
                  && this.#router?.url === '/pedidos'
                ) {
                  this._muted = true;
                  this.mute();
                  this.#soundsServ.play('assets/audio/laser.mp3')
                    .subscribe(() => this._muted = false);
                } // if

                this.pedidos = [];
                !!docs && docs.forEach(
                  (d: Partial<IPedido>) => {
                    // console.log(d);
                    // !!this.pedidos && this.pedidos.push(this.#pedidosServ.fix(d));
                    this.pedidos?.push(this.#pedidosServ.fix(d));
                  }
                  );
                  */
              },
              false
            );

            this.#pedidosUnsub?.unsubscribe();
            this.#pedidosUnsub = this.paginator.items$
              .subscribe(
                (pedidos: IPedido[]) => {
                  // console.log(pedidos);
                  this.pedidosAction$().next(
                    this.#pedidosServ.fixes(pedidos)
                      .filter((e: IPedido) => !!e?.__displayInPagination)
                  );
                  this.#focus();
                  // console.log(JSON.stringify(this.pedidos));
                }
              );
          } // if

          // const VM: IVm = {
          //   itemsPerPage,
          //   loja,
          //   pedidoCod,
          //   // pedidos: null,
          //   pedidoStatus,
          // };
          // // console.log(VM);
          // return VM;
        }),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.#focus();
  }

  ngOnDestroy(): void {
    !!this.#notificacoesUnsub && this.#notificacoesUnsub();
    this.#pedidosUnsub?.unsubscribe();
    this.paginator?.unsub();
  }
  //#endregion

  //#region functions
  tid_ped(index: any, item: IPedido): string { return item?.id || ''; }

  #focus() {
    setTimeout(
      () => { this.searchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onLogoutClick() {
    AppService.logout$.emit();
  }

  onDetalhesClick(p: IPedido) {
    // console.log(p);
    !!p && this.#router.navigate(['/pedido', p?.id || '']);
  }

  onIniciarSeparacao(p: IPedido) {
    // console.log(p);
    !!p && AppService.pedidoIniciaSeparacao$.emit(p);
  }

  onConcluirPedido(p: IPedido) {
    // console.log(p);
    !!p && AppService.pedidoConcluirPedido$.emit(p);
  }

  onConcluirSeparacao(p: IPedido) {
    // console.log(p);
    !!p && AppService.pedidoConcluirSeparacao$.emit(p);
  }
  //#endregion

  /*
//#region publics
// conta: IConta;
paginator: CorAngularFirePaginator<IPedido>;
pedidos: IPedido[];
storageKey: string = KEY_OFFSET_PEDIDOS;
//#endregion

//#region search
private _search: string = '';
set search(val: string) {
  this._search = val;
  // console.log(val);
  if ((val || '').length >= 5) {
    this.status = '';
  } else {
    this._refresh();
  } // if
}
get search(): string {
  return this._search;
}
//#endregion

//#region status
private #status: TPedidoStatus | '';
set status(val: TPedidoStatus | '') {
  this.#status = val;
  // console.log(val);
  if (!!val) {
    this.search = '';
  } // if
  this._refresh();
}
get status(): TPedidoStatus | '' {
  return this.#status;
}
//#endregion

//#region loja
private _loja: ILoja;
set loja(val: ILoja) {
  this._loja = !!val ? this._lojasServ.fix(val) : null;
  // console.log(val);
  this._refresh();
}
get loja(): ILoja {
  return this._loja;
}

//#region itemsPerPage
private _itemsPerPage: string = '';
set itemsPerPage(val: string) {
  this._itemsPerPage = val;
  // console.log(val);
  this._refresh();
}
get itemsPerPage(): string {
  return this._itemsPerPage;
}
//#endregion

//#region privates
private _muted: boolean = false;
// private _lojaPath: string;
private #pedidosUnsub: Subscription;
private _subs: Subscription[] = [];
//#endregion

//#region events
searchFocusEvent$: EventEmitter<boolean> = new EventEmitter<boolean>();
//#endregion

//#region constructor
constructor(
  // private _bsModalServ: BsModalService,
  // private _contasServ: ContasService,
  private #db: Firestore,
  private _lojasServ: LojasService,
  private #pedidosServ: PedidosService,
  private #router: Router,
  private #soundsServ: SoundsService
) { }
//#endregion

//#region lifecycles
ngOnInit() {
  this._subs.push(
    LojasService.lojaStateChanged$
      .subscribe(
        (loja: ILoja) => {
          // console.log(loja);
          this.loja = loja;
          this.status = '';
        }
      ),
  );
}

ngAfterViewInit() {
  this.#focus();
}

ngOnDestroy(): void {
  this._subs.forEach((s: Subscription) => s && s.unsubscribe());
  this.#pedidosUnsub && this.#pedidosUnsub.unsubscribe();
  !!this.paginator && this.paginator.unsub();
}
//#endregion

//#region functions
private #focus() {
  setTimeout(
    () => this.searchFocusEvent$.emit(true),
    FOCUS_TIMEOUT
  );
}


mute(duration: number = 2000) {
  this._muted = true;
  setTimeout(() => this._muted = false, duration);
}

private _refresh() {
  const LOJA_PATH: string = this.loja?.__idInfo?.lojaPath || '';
  // console.log(this._lojaPath);
  if (!!LOJA_PATH) {
    const FILTERS: ICorPaginatorFilter[] = [];
    (this.search || '').length > 4 && FILTERS.push({ field: '_cod', 'op': '==', val: this.search });
    !!this.status && FILTERS.push({ field: '#status', 'op': '==', val: this.status });
    // console.log(this.search, this.status, FILTERS);
    this.paginator = new CorAngularFirePaginator(
      this.#db,
      `${LOJA_PATH}/pedidos`,
      Number(this.itemsPerPage || '12'),
      [{ field: '_criadoEm', direction: 'desc' }],
      FILTERS,
      docs => {
        // console.log(docs);
        /* docs?.forEach(d => console.log(d?.id, d?.__docChange));
        console.log(this._muted, 'assets/audio/laser.mp3');
        if (
          !this._muted
          && (docs || []).some(d => d?.__docChange === 'modified')
          && this.#router?.url === '/pedidos'
        ) {
          this._muted = true;
          this.mute();
          this.#soundsServ.play('assets/audio/laser.mp3')
            .subscribe(() => this._muted = false);
        } // if * /

        this.pedidos = [];
        !!docs && docs.forEach(
          (d: Partial<IPedido>) => {
            // console.log(d);
            // !!this.pedidos && this.pedidos.push(this.#pedidosServ.fix(d));
            this.pedidos?.push(this.#pedidosServ.fix(d));
          }
        );
      },
      false
    );

    this.#pedidosUnsub && this.#pedidosUnsub.unsubscribe();
    this.#pedidosUnsub = this.paginator.items$
      /* .pipe(
        switchMap(results => results),
        filter(p => !!get(p, '__displayInPagination'))
      ) * /
      .subscribe(
        // resp => console.log(resp)
        /* p => this.pedidos = uniqueListBy(
          [this.pedidos, ...this.#pedidosServ.fix(p)],
          'id'
        ) * /
        (pedidos: IPedido[]) => {
          // console.log(pedidos);
          this.pedidos = this.#pedidosServ.fixes(pedidos)
            .filter((e: IPedido) => !!e?.__displayInPagination);
          // console.log(JSON.stringify(this.pedidos));
        }
      );
  } // if
}
//#endregion

//#region methods
onLogoutClick() {
  AppService.logout$.emit();
}

onDetalhesClick(p: IPedido) {
  // console.log(p);
  !!p && this.#router.navigate(['/pedido', p?.id || '']);
}

onIniciarSeparacao(p: IPedido) {
  // console.log(p);
  !!p && AppService.pedidoIniciaSeparacao$.emit(p);
}

onConcluirPedido(p: IPedido) {
  // console.log(p);
  !!p && AppService.pedidoConcluirPedido$.emit(p);
}

onConcluirSeparacao(p: IPedido) {
  // console.log(p);
  !!p && AppService.pedidoConcluirSeparacao$.emit(p);
}

onSearchClearClick() {
  this.search = '';
  this.#focus();
}
//#endregion
}
*/
}
