export const environment = {
  production: false,
  firebase: {
    config: {
      apiKey: "AIzaSyB0q_a8d9HJXlwi_j1I4JUBU0omc22m2fs",
      authDomain: "mercadeiro-896b2.firebaseapp.com",
      databaseURL: "https://mercadeiro-896b2.firebaseio.com",
      projectId: "mercadeiro-896b2",
      storageBucket: "mercadeiro-896b2.appspot.com",
      messagingSenderId: "515951311863",
      appId: "1:515951311863:web:9a96983a008db1cf13ccf2",
      measurementId: "G-FXPV1EXPBL",
      vapidKey: 'BFUPHLZkbwntt1zCPD--aagubH232Lsy2BvE1QqTYjMSLL0fxIUaZBTG6urKICiHBXlnns-ySe6H2HX5hIaP1AE'
    },
    region: 'us-central1',
    conecdata: {
      storage: {
        root: 'https://firebasestorage.googleapis.com/v0/b/produtos-d5cd0.appspot.com/o'
      }
    },
    mercadeiro: {
      storage: {
        root: 'https://firebasestorage.googleapis.com/v0/b/mercadeiro-896b2.appspot.com/o'
      }
    }
  },
  meilisearch: {
    host: 'https://msh.conecdata.com.br',
    apiKey: '16f105731d67847dce7667965dd61bddd2649ec19bfa802e27c8f62617ced14a',
  }
};
