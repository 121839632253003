//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
} from "rxjs";
//#endregion

//#region models
import { ILoja } from '../../_misc/_models/_interfaces/_cols';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

//#region libs
import { setIon4Theme } from '../../../_libs/_www/_theme';
//#endregion

//#region services
import { LojasService } from '../_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class LojasStore {
  //#region lojaState
  #lojaState: TNullable<ILoja>;
  getState(): TNullable<ILoja> { return this.#lojaState; }
  setState(val: TNullable<ILoja>, defaultTheme: string) {
    const LOJA: TNullable<ILoja> = !!val ? this.#lojasServ.fix(val) : null;
    const THEME: string = LOJA?.rede?._tema || '';
    // console.log(THEME, defaultTheme);
    setIon4Theme(!!THEME ? THEME : defaultTheme);
    this.#lojaState = LOJA;
    this.#lojaStateSubject$.next(LOJA);
  }
  //#endregion

  //#region events
  // lojaStateChanged$
  #lojaStateSubject$ = new BehaviorSubject<ILoja | null>(null);
  lojaStateChanged$: Observable<ILoja | null> = this.#lojaStateSubject$?.asObservable();
  //#endregion

  //#region injects
  #lojasServ = inject(LojasService);
  //#endregion
}
