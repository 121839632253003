//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable
} from "rxjs";
//#endregion

//#region models
import { IConta } from '../../_misc/_models/_interfaces/_cols';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

//#region services
import { ContasService } from '../_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ContasStore {
  //#region contaState
  #contaState: TNullable<IConta>;
  getState(): TNullable<IConta> { return this.#contaState; }
  setState(val: TNullable<IConta>) {
    this.#contaState = !!val ? this.#contaServ.fix(val) : null;
    this.#contaStateSubject$.next(this.#contaState);
  };
  //#endregion

  //#region events
  // contaStateChanged$
  #contaStateSubject$ = new BehaviorSubject<TNullable<IConta>>(null);
  contaStateChanged$: Observable<TNullable<IConta>> = this.#contaStateSubject$?.asObservable();
  //#endregion

  //#region injects
  #contaServ = inject(ContasService);
  //#endregion
}
