<!-- <p>isMobile {{ isMobile }}</p> -->
<!-- <p>confirmarSenhaForm?.value {{ confirmarSenhaForm?.value | json }}</p> -->
<!-- <p>confirmarSenhaForm?.valid {{ confirmarSenhaForm?.valid }}</p> -->
<!-- <p>senhaRef?.errors {{ senhaRef?.errors | json }}</p> -->
<!-- <p>senhaAtualRef?.errors {{ senhaAtualRef?.errors | json }}</p> -->
<!-- <p>confirmacaoRef?.errors {{ confirmacaoRef?.errors | json }}</p> -->
<form novalidate
      [formGroup]="confirmarSenhaForm()"
      class="container"
      *ngIf="!!confirmarSenhaForm()">
  <!-- (keyup.enter)="confirmarSenhaForm.valid && !!confirmacaoOk && onSubmitClick()" -->
  <div class="row mb-2"
       *ngIf="pedirSenhaAtual">
    <div class="col">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Senha atual</mat-label>
        <input type="password"
               matInput
               formControlName="senhaAtual"
               [focus]="senhaAtualFocusEvent$()"
               onReturn>
        <mat-hint>
          <div *ngIf="fv()?.lerror(senhaAtualRef); else senhaHint">
            <span class="danger"> {{ fv()?.error(senhaAtualRef) }} </span>
          </div>
          <ng-template #senhaHint>
            Mínimo {{ minPassLen() }} caracteres.
          </ng-template>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-2">
    <div [class]="colSize()">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Nova senha</mat-label>
        <input type="password"
               matInput
               formControlName="senha"
               [focus]="senhaFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(senhaRef, 'senha')">
          <span class="danger"> {{ fv()?.error(senhaRef, 'senha') }} </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div [class]="colSize()">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="!!isMobile ? 'fill' : 'outline'">
        <mat-label>Repetir nova senha</mat-label>
        <input type="password"
               matInput
               formControlName="confirmacao"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(confirmacaoRef) || confirmacaoRef?.errors?.['notEqual']">
          <span class="danger"> {{ fv()?.error(confirmacaoRef) || 'Senha e confirmação não correspondem.' }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col d-flex">
      <div class="flex-1"></div>

      <div class="ms-auto">
        <button type="button"
                class="btn btn-block btn-primary pointer"
                [disabled]="!confirmarSenhaForm()?.valid"
                (click)="submit$.emit(this.senhaRef?.value || '')">
          CONFIRMAR
        </button>
      </div>
    </div>
  </div>
</form>