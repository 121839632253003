//#region ng
import {
  Component,
  inject,
  Input,
  signal,
} from '@angular/core';
//#endregion

//#region models
type TPedidoInfoTab = 'totais' | 'detalhes' | 'produtos';
import { IPedido } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

@Component({
  selector: 'me-mat-pedido-info',
  templateUrl: './pedido-info.component.html',
  styleUrls: ['./pedido-info.component.scss']
})
export class MeMatPedidoInfoComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // pedido
  @Input({ required: true }) pedido: IPedido | null;
  //  #pedido: IPedido | null;
  // get pedido(): IPedido | null { return this.#pedido; }
  // @Input({ required: true }) set pedido(val: IPedido | null) {
  //   this.#pedido = !!val ? this.#pedidosServ.fix(val) : null;
  //   // this.carrinho = !!val?.carrinho ? this.#carrinhoServ.fix(val?.carrinho) : null;
  // }
  // lojaPath
  @Input() lojaPath: string;
  //#endregion

  //#region publics
  // carrinho = signal<ICarrinhoMap>(null);
  tab = signal<TPedidoInfoTab>(null);
  //#endregion

  /* //#region injects
  #carrinhoServ = inject(CarrinhoService);
  #pedidosServ = inject(PedidosService);
  //#endregion */

  //#region lifecycles
  ngOnInit() {
    this.tab.set('totais');
  }
  //#endregion

  //#region methods
  //#endregion
}
