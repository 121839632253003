//#region ng
import {
  inject,
  Injectable
} from "@angular/core";
import { HttpClient } from "@angular/common/http";

//#region 3rd
import { Observable } from "rxjs";

//#region models
const VIA_CEP_API = 'https://viacep.com.br/ws';
// http://api.postmon.com.br/v1/cep/38017030
import { ICorViacepApi } from "../../../_core/_misc/_models/_interfaces/_apis";

//#region libs
import { guid } from "../../../_libs/_misc/_strings";

@Injectable({
  providedIn: "root"
})
export class CorCepService {
  //#region inject
  #http = inject(HttpClient);
  //#endregion

  //#region misc
  fix(row: any): any {
    row = row || {};
    let R: ICorViacepApi = row;

    // id
    R.id = row?.id || guid(12);
    // const VALIDO: any = !!row?.__valido;

    // user
    R.apelido = row?.apelido || '';
    R.bairro = row?.bairro || '';
    R.cep = (row?.cep || '').replace(/[^0-9]/g, '');
    R.complemento = row?.complemento || '';
    R.erro = !!row.erro;
    R.gia = row?.gia || 0;
    R.ibge = row?.ibge || 0;
    R.localidade = row?.localidade || '';
    R.logradouro = row?.logradouro || '';
    R.nro = parseInt(row?.nro) || 0;
    R.referencia = row?.referencia || '';
    R.tipo = row?.tipo || '';
    R.uf = row?.uf || '';
    R.unidade = row?.unidade || '';

    // system
    R._criadoEm = row?._criadoEm || null;
    R._modificadoEm = row?._modificadoEm || null;

    // realtime
    R.__atendido = !!row?.__atendido;
    R.__favorito = !!row?.__favorito;
    R.__taxaEntrega = parseFloat(row?.__taxaEntrega) || 0;
    // R.__valido = VALIDO ?? true;
    // R.__gravado = !!gravado;
    // R.__gravado = gravado !== undefined ? gravado : !!get(row, '__gravado');
    // if () {
    // } // if

    return R;
  }

  unfix(row: any): ICorViacepApi {
    let R: ICorViacepApi = this.fix(row);

    // realtime
    delete R.__atendido;
    delete R.__favorito;
    // delete R.__gravado;
    delete R.__taxaEntrega;
    // delete R.__valido;

    return R;
  }

  fixes(rows: ICorViacepApi[]): ICorViacepApi[] {
    if (rows) {
      for (let row of rows) {
        row = this.fix(row);
      } // for
    } // if
    // console.log(rows);
    return rows || [];
  }
  //#endregion

  //#region viacep
  // https://viacep.com.br/ws/01001000/json/
  cep(cep: string): Observable<any> {
    const URL = encodeURI(`${VIA_CEP_API}/${cep}/json/`);
    return this.#http.get(URL);
  }

  // https://viacep.com.br/ws/RS/Porto%20Alegre/Domingos/json/
  logradouros(
    estado: string,
    cidade: string,
    logradouro: string
  ): Observable<any> {
    const URL = encodeURI(`${VIA_CEP_API}/${estado}/${cidade}/${logradouro}/json/`);
    // console.log(URL);
    return this.#http.get(URL);
  }
  //#endregion
}

// http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm