//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region models
import { IIntegradora } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

@Component({
  selector: 'me-mat-integradora-card',
  templateUrl: './integradora-card.component.html',
  styleUrls: ['./integradora-card.component.scss']
})
export class MeMatIntegradoraCardComponent {
  //#region inputs
  // integradora
  @Input({ required: true }) integradora: IIntegradora;
  // isSelected
  @Input() isSelected: boolean = false;
  // checkBtnHint
  #checkBtnHint: string = '';
  get checkBtnHint(): string { return this.#checkBtnHint; }
  @Input() set checkBtnHint(val: string) {
    this.#checkBtnHint = val;
    this.#verificaBotoes();
  }
  // deleteBtnHint
  #deleteBtnHint: string = '';
  get deleteBtnHint(): string { return this.#deleteBtnHint; }
  @Input() set deleteBtnHint(val: string) {
    this.#deleteBtnHint = val;
    this.#verificaBotoes();
  }
  // editBtnHint
  #editBtnHint: string = '';
  get editBtnHint(): string { return this.#editBtnHint; }
  @Input() set editBtnHint(val: string) {
    this.#editBtnHint = val;
    this.#verificaBotoes();
  }
  // searchBtnHint
  #searchBtnHint: string = '';
  get searchBtnHint(): string { return this.#searchBtnHint; }
  @Input() set searchBtnHint(val: string) {
    this.#searchBtnHint = val;
    this.#verificaBotoes();
  }
  //#endregion

  //#region outputs
  @Output() check$ = new EventEmitter<IIntegradora>();
  @Output() delete$ = new EventEmitter<IIntegradora>();
  @Output() edit$ = new EventEmitter<IIntegradora>();
  @Output() search$ = new EventEmitter<IIntegradora>();
  //#endregion

  //#region publics
  hasButtons = signal<boolean>(false);
  //#endregion

  //#region functions
  #verificaBotoes() {
    this.hasButtons.set(!!`${this.#checkBtnHint.trim()}${this.#deleteBtnHint.trim()}${this.#editBtnHint.trim()}${this.#searchBtnHint.trim()}`);
  };
  //#endregion
}
