//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
//#endregion

//#region models
import { CorAngularFirePaginator } from "../../../_ng/_models/_classes";
//#endregion

@Component({
  selector: "cor-mat-ngx-pagination-nav",
  templateUrl: "ngx-pagination-nav.component.html",
  styleUrls: ["ngx-pagination-nav.component.scss"]
})
export class CorMatNgxPaginationNavComponent {
  //#region inputs
  // maxSize
  // #maxSize: number = 7;
  // get maxSize(): number { return this.#maxSize; }
  // @Input() set maxSize(val: number) {
  //   this.#maxSize = val > 5 ? val : 7;
  // }
  // autoHide
  @Input() autoHide: boolean = true;
  // isMobile
  @Input() isMobile: boolean = false;
  // pageId
  @Input() pagId: string = '';
  // // paginator
  // @Input({ required: true }) paginator: CorAngularFirePaginator<any>;
  // size
  @Input() size: 'sm' | 'lg' = 'sm';
  // //#endregion

  //#region outputs
  @Output() offsetChange$ = new EventEmitter<string>();
  @Output() pageChange$ = new EventEmitter<number>();
  //#endregion
}
