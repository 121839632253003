//#region ng
import {
  Component,
  inject,
  Input,
} from '@angular/core';
//#endregion

//#region models
type TPrecoProdutoSize = 'lg' | 'md';
import { IProduto } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { ProdutosService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-html-produto-preco',
  templateUrl: './produto-preco.component.html',
  styleUrls: ['./produto-preco.component.scss']
})
export class MeHtmlProdutoPrecoComponent {
  //#region inputs
  // inline
  @Input() inline: boolean = false;
  // produto
  @Input({ required: true }) produto: Partial<IProduto>;
  // size
  @Input() size: TPrecoProdutoSize = 'md';
  //#endregion

  //#region inject  
  #produtosServ = inject(ProdutosService);
  //#endregion

  //#region lifecycles  
  ngOnInit(): void {
    if (this.produto) {
      this.produto = this.#produtosServ.fix(this.produto);
    } // if
  }
  //#endregion
}
