//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors
} from "@angular/forms";
//#endregion

//#region 3rd
import { get } from 'lodash';
//#endregion

//#region models
import { CorFormValidation } from "../../../_ng/_models/_classes";
import { FOCUS_TIMEOUT } from "../../../_misc/_models/consts";
//#endregion

//#region Custom Validators
class CustomValidator {
  static notEqual() {
    return (control: FormControl): ValidationErrors | null => {
      // console.log(control);
      const FORM_GROUP: FormGroup = get(control, '_parent');
      // console.log(FORM_GROUP);
      const SENHA: string = FORM_GROUP?.value?.senha || '';
      const CONFIRMACAO: string = control?.value || '';
      // console.log(SENHA, CONFIRMACAO);
      if (!!CONFIRMACAO && !!SENHA) {
        return CONFIRMACAO !== SENHA ? { notEqual: true } : null;
      } // if
      return null;
    }
  }
}
//#endregion

@Component({
  selector: "cor-mat-senha-confirmacao-form",
  templateUrl: "senha-confirmacao-form.component.html",
  styleUrls: ["senha-confirmacao-form.component.scss"]
})
export class CorMatSenhaConfirmacaoFormComponent {
  //#region inputs
  // isMobile
  #isMobile: boolean;
  get isMobile(): boolean { return this.#isMobile; }
  @Input() set isMobile(val: boolean) {
    this.#isMobile = val;
    this.colSize.set(val ? 'col-12' : 'col-6');
  }
  // pedirSenhaAtual
  @Input() pedirSenhaAtual: boolean;
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<string>();
  //#endregion

  //#region publics
  colSize = signal<string>('');
  // confirmacaoOk = signal<boolean>(false);
  fv = signal<CorFormValidation>(null);
  // placeholder = signal<string>('');
  confirmarSenhaForm = signal<FormGroup>(null);
  minPassLen = signal<number>(6).asReadonly();
  //#endregion

  //#region events
  senhaAtualFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  senhaFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    // classes
    this.fv.set(new CorFormValidation());
  }
  //#endregion

  //#region lifecycles
  async ngOnInit() {
    // this.placeholder.set(this.pedirSenhaAtual ? 'Nova senha' : 'Sua senha');
    !this.pedirSenhaAtual && this.senhaAtualRef?.disable();
    this.confirmarSenhaForm.set(
      this.#fb.group(
        {
          senhaAtual: ['', [Validators.minLength(this.minPassLen()), Validators.required]],
          senha: ['', [Validators.minLength(this.minPassLen()), Validators.required]],
          confirmacao: ['', [Validators.minLength(this.minPassLen()), Validators.required, CustomValidator.notEqual()]]
        }
      )
    );
    // console.log(this.pedirSenhaAtual);
    !!this.pedirSenhaAtual
      ? this.senhaAtualRef?.enable()
      : this.senhaAtualRef?.disable();
  }


  ngAfterViewInit() {
    this.#focus();
  }
  //#endregion

  //#region Controls getters
  get senhaRef(): AbstractControl { return this.confirmarSenhaForm()?.get('senha'); }
  get senhaAtualRef(): AbstractControl { return this.confirmarSenhaForm()?.get('senhaAtual'); }
  get confirmacaoRef(): AbstractControl { return this.confirmarSenhaForm()?.get('confirmacao'); }
  //#endregion

  //#region functions
  #focus() {
    !!this.pedirSenhaAtual ? this.#senhaAtualFocus() : this.#senhaFocus();
  }

  #senhaFocus() {
    // console.log('focus');
    !this.isMobile && setTimeout(
      () => { this.senhaFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  #senhaAtualFocus() {
    // console.log('focus');
    !this.isMobile && setTimeout(
      () => { this.senhaAtualFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
