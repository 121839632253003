//#region ng
import {
  Directive,
  inject,
  Input
} from '@angular/core';
import { NgControl } from '@angular/forms';
//#endregion

@Directive({
  selector: '[disableControl]'
})
export class CorDisableControlDirective {
  //#region inputs
  @Input({ required: true }) set disableControl(condition: boolean) {
    const action = !!condition ? 'disable' : 'enable';
    // console.log(action);
    !!this.#ngControl && this.#ngControl?.control[action]();
  }
  //#endregion

  //#region injects
  #ngControl = inject(NgControl);
  //#endregion

}