//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  // signInWithPhoneNumber,
  signOut,
} from '@angular/fire/auth';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CorFbAuthService {
  //#region privates
  #getAuth: any;
  //#endregion

  //#region injects
  #fireAuth = inject(Auth);
  //#endregion

  //#region constructor
  constructor() {
    this.#getAuth = getAuth();
  }
  //#endregion

  //#region misc
  getFirebaseUser(): any {
    return this.#fireAuth?.currentUser;
  }

  logout(): Promise<any> {
    return signOut(this.#getAuth);
  }

  recaptchaVerifier(container: string): any {
    return new RecaptchaVerifier(
      container,
      {},
      this.#getAuth
    );
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.#fireAuth, email);
  }

  async register(email: string, password: string): Promise<any> {
    try {
      if (email && password) {
        const registeredUser = await createUserWithEmailAndPassword(
          getAuth(),
          email,
          password
        );
        // console.log(registeredUser);
        return registeredUser;
      } else {
        return null;
      } // else
    } catch (error) {
      throw (error);
    } // try-catch
  }

  async loginWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<any> {
    try {
      if (email && password) {
        const RESPONSE: any = await signInWithEmailAndPassword(
          getAuth(),
          email,
          password
        );
        // console.log(RESPONSE);
        return Promise.resolve(RESPONSE?.user || null);
      } else {
        return Promise.reject(null);
      } // else
    } catch (error) {
      // throw (error);
      return Promise.reject(error);
    } // try-catch
  }

  signInWithGooglePopup(): Promise<any> {
    return new Promise(
      async (resolve, reject) => {
        try {
          const PROVIDER = new GoogleAuthProvider();
          // const AUTH = getAuth();
          const RESULT: any = await signInWithPopup(
            this.#getAuth,
            PROVIDER
          );
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(RESULT);
          // const TOKEN = credential.accessToken;
          // console.log(TOKEN);        
          // The signed-in user info.
          const USER = RESULT?.user;
          // console.log(USER);
          resolve(USER);
        } catch (error: any) {
          // console.error(error);
          // // Handle Errors here.
          // const ERROR_CODE = error.code;
          const ERROR_MESSAGE = error?.message;
          reject(ERROR_MESSAGE);
          // // The email of the user's account used.
          // const email = error.email;
          // // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
        } // try-catch
      }
    );
  }
  //#endregion
}
