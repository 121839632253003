//#region ng
import { Component } from '@angular/core';
// import { Router } from '@angular/router';
//#endregion

@Component({
  selector: 'app-notificacoes-bloqueadas',
  templateUrl: './notificacoes-bloqueadas.page.html',
  styleUrls: ['./notificacoes-bloqueadas.page.scss']
})
export class NotificacoesBloqueadasPage {
}

