//#region ng
import {
  Component,
  inject,
  Input,
  EventEmitter,
  signal,
  ViewChild
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
//#endregion

//#region firebase
import {
  Auth,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber
} from "firebase/auth";
//#endregion

//#region mat
import { MatStepper } from '@angular/material/stepper';
//#endregion

//#region 3rd
import Swal from 'sweetalert2';
//#endregion

//#region services
import {
  CorFbAuthService,
  CorWindowService
} from "../../../_ng/_services";
import { FOCUS_TIMEOUT } from "../../../_misc/_models/consts";
//#endregion

@Component({
  selector: "cor-mat-login-fone-form",
  templateUrl: "login-fone-form.component.html",
  styleUrls: ["login-fone-form.component.scss"]
})
export class CorMatLoginFoneFormComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  //#endregion

  //#region viewchilds
  @ViewChild('stepper', { static: true }) _stepperRef: MatStepper;
  //#endregion

  //#region publics
  // optEnviado: boolean;
  foneForm = signal<FormGroup>(null);
  otpForm = signal<FormGroup>(null);
  windowRef: any;
  //#endregion

  //#region privates
  _getAuth: Auth;
  _confirmationResult: any;
  //#endregion

  //#region events
  onFoneFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  onOTPFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  #windowServ = inject(CorWindowService);
  //#endregion

  //#region constructor
  constructor() {
    this.windowRef = this.#windowServ.windowRef;
    this._getAuth = getAuth();
    this._getAuth.languageCode = 'pt-br';
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // this.waiting = false;
    this.foneForm.set(
      this.#fb.group({
        fone: ['', [Validators.minLength(11), Validators.maxLength(11), Validators.required]],
      })
    );

    this.otpForm.set(
      this.#fb.group({
        otp: ['', [Validators.minLength(6), Validators.maxLength(6), Validators.required]]
      })
    );
  }

  ngAfterViewInit() {
    // const AUTH: any = getAuth();
    // this._recaptchaVerifier = this._authServ.recaptchaVerifier('recaptcha-container');
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {},
      this._getAuth,
    );
    this.windowRef.recaptchaVerifier.render();
    this.#focusFone();
  }
  //#endregion

  //#region Controls getters
  get foneRef(): AbstractControl { return this.foneForm()?.get('fone'); }
  get otpRef(): AbstractControl { return this.otpForm().get('otp'); }
  //#endregion

  //#region methods
  async sendSMSCode() {
    const FONE: string = '+55' + this.foneRef.value;
    // console.log(FONE);
    // const AUTH: any = getAuth();
    try {
      const CONFIRMATION_RESULT: any = await signInWithPhoneNumber(
        this._getAuth,
        FONE,
        this.windowRef.recaptchaVerifier
      );
      this._confirmationResult = CONFIRMATION_RESULT;
      this._stepperRef.selectedIndex = 1;
      !this.isMobile && setTimeout(
        () => { this.#focusOTP(); },
        FOCUS_TIMEOUT
      );
    } catch (error: any) {
      console.error(error?.message);
      Swal.fire({
        icon: "warning",
        title: "Ooops...",
        html: "<h2>Erro gerando SMS com código.</h2>",
        backdrop: false
      });
    } // try-catch
  }

  onVoltarFone() {
    this._stepperRef.selectedIndex = 0;
    !this.isMobile && setTimeout(
      () => { this.#focusFone(); },
      FOCUS_TIMEOUT
    );
  }

  async onVerificaOTP() {
    // const code = getCodeFromUserInput();
    const OTP: string = this.otpRef.value;
    // console.log(OTP);
    // this.windowRef
    await this._confirmationResult
      .confirm(OTP);
  }
  //#endregion

  //#region functions
  #focusFone() {
    // console.log('focus');
    !this.isMobile && setTimeout(
      () => { this.onFoneFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  #focusOTP() {
    // console.log('focus');
    !this.isMobile && setTimeout(
      () => { this.onOTPFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
