//#region ng
import {
  Component,
  inject,
  Inject,
  signal
} from '@angular/core';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

@Component(
  {
    selector: "cor-mat-alert-dialog",
    templateUrl: 'alert-dialog.component.html',
    styleUrls: ['alert-dialog.component.scss']
  }
)
export class CorMatAlertDialog {
  //#region publics
  confirmCaption = signal<string>('');
  msg = signal<string>('');
  title = signal<string>('');
  //#endregion

  //#region injects
  #dialogRef = inject(MatDialogRef<CorMatAlertDialog>);
  //#endregion

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    if (!!data) {
      this.msg.set(data?.msg || '');
      this.title.set(data?.title || '');
      this.confirmCaption.set(data?.confirmCaption || 'OK');
      // this.cancelCaption = data?.cancelCaption || 'Cancela';
    }
  }

  onConfirmClick(): void {
    this.#dialogRef?.close(true);
  }
}