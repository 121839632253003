//#region ng
import {
  Pipe,
  PipeTransform,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
//#endregion

//#region models
import { IDepartamento } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { DepartamentosService } from '../_services';
//#endregion

@Pipe({ name: 'departamentosLfixes' })
export class MeDepartamentosLfixesPipe implements PipeTransform {
  //#region injects
  #departamentosServ = inject(DepartamentosService);
  //#endregion

  transform(val: IDepartamento[]): Observable<IDepartamento[]> {
    return this.#departamentosServ.lfixes(val);
  }
}
