<div class="w-card loja d-flex flex-column"
     [class.off]="!loja?.ativo?._status"
     [class.sel]="!!isSelected">
  <div class="w-card--thumb p-relative p-1">
    <!-- <img [ngSrc]="loja?.__img?.thumb"
         [width]="256"
         [height]="256"
         [alt]="loja?.nome"
         class="img-fluid"
         *ngIf="!!loja?.__img?.thumb"> -->
    <img [src]="loja?.__img?.thumb"
         [alt]="loja?.nome"
         class="img-fluid"
         *ngIf="!!loja?.__img?.thumb">
  </div>

  <!-- <p>checkBtnHint {{ checkBtnHint| json }}</p> -->
  <!-- <p>deleteBtnHint {{ deleteBtnHint| json }}</p> -->
  <!-- <p>editBtnHint {{ editBtnHint| json }}</p> -->
  <!-- <p>searchBtnHint {{ searchBtnHint| json }}</p> -->
  <!-- <p>hasButtons {{ hasButtons| json }}</p> -->

  <div class="w-card--content">
    <h6>
      {{ loja?.nome }}
    </h6>

    <strong>
      #<span class="o-50"> {{ loja?.__idInfo?.op }} </span>
    </strong>

    <div>
      <small class="o-50">
        {{ loja?.rede?._nome }} / {{ loja?.integradora?._nome }} / {{ loja?.parceiro?._nome }}
      </small>
    </div>
  </div>

  <div class="w-card--content"
       *ngIf="!!hasButtons()">
    <div class="d-flex justify-content-around">
      <button mat-icon-button
              [matTooltip]="checkBtnHint"
              (click)="check$?.emit(loja)"
              *ngIf="!!checkBtnHint">
        <mat-icon>check</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="searchBtnHint"
              (click)="search$?.emit(loja)"
              *ngIf="!!searchBtnHint">
        <mat-icon>search</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="editBtnHint"
              (click)="edit$?.emit(loja)"
              *ngIf="!!editBtnHint">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="deleteBtnHint"
              (click)="delete$?.emit(loja)"
              *ngIf="!!deleteBtnHint">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>