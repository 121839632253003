<div *ngIf="!!departamento"
     class="w-card departamento"
     (click)="click$?.emit(departamento)">
  <div class="w-card--thumb">
    <!-- <img [ngSrc]="departamento?._img?.__img"
         class="img-fluid"
         [width]="256"
         [height]="256"
         *ngIf="!!departamento?._img?.__img"> -->
    <img [src]="departamento?.img?.__img"
         class="img-fluid"
         *ngIf="!!departamento?.img?.__img">
  </div>

  <div class="w-card--content">
    <small>{{ departamento?.nome }}</small>
    <span *ngIf="!!departamento?.__parent?.nome">
      <br />
      <small>({{ departamento?.__parent?.nome }})</small>
    </span>
  </div>
</div>