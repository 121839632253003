//#region ng
import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router
} from "@angular/router";
//#endregion

//#region models
import { environment } from "src/environments/environment";
import { CorFbMessaging } from "../_shared/_core/_ng/_models/_classes";
import { ITerminal } from "../_shared/_mercadeiro/_misc/_models/_interfaces/_cols";
//#endregion

//#region libs
import { md5 } from "../_shared/_libs/_misc/_strings";
//#endregion

//#region services
import { TerminaisService } from "../_shared/_mercadeiro/_ng/_services";
//#endregion

//#region stores
import { TerminaisStore } from "../_shared/_mercadeiro/_ng/_stores";
//#endregion

@Injectable({
  providedIn: 'root'
})
export class LojaGuard implements CanActivate {

  //#region privates
  private _messaging: CorFbMessaging;
  //#endregion

  //#region constructor
  constructor(
    private _terminaisServ: TerminaisService,
    private _terminaisStore: TerminaisStore,
    private _router: Router,
    // private _terminaisStore: TerminaisStore,
  ) {
    this._messaging = new CorFbMessaging(
      environment?.firebase?.config?.vapidKey || '',
      environment?.firebase?.region || ''
    );
  }
  //#endregion

  canActivate(): Promise<boolean> {
    const URL: string = this._router?.url || '';
    // console.log(URL);

    return new Promise(
      (resolve) => {
        this._messaging.getToken()
          .then(
            (token: string) => {
              // console.log(token);
              if (!!token) {
                const TERMINAL_ID: string = md5(token);
                // console.log(TERMINAL_ID);
                this._terminaisServ.doc(TERMINAL_ID)
                  .subscribe(
                    {
                      next: (terminal: ITerminal) => {
                        // console.log(terminal);
                        this._terminaisStore.setState(terminal);
                        !terminal && URL !== '/habilitaTerminal' && this._router.navigateByUrl('/habilitaTerminal');
                        resolve(!!terminal);
                      },
                      error: () => {
                        URL !== '/habilitaTerminal' && this._router.navigateByUrl('/habilitaTerminal');
                        resolve(false);
                      }
                    }
                  );
              } else {
                // console.log('!token');
                this._router.navigateByUrl('/notificacoesBloqueadas');
                resolve(false);
              } // else
            }
          )
          .catch(() => {
            // Notification.requestPermission()
            //   .then(
            //     status => {
            //       console.log(status);
            //       // https://www.digitalcitizen.life/wp-content/uploads/2018/12/chrome_notifications.png
            //     }
            //   );
            // console.log('catch');
            this._router.navigateByUrl('/notificacoesBloqueadas');
            resolve(false);
          });

      }
    );
  }
}
