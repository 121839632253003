<!-- <h2>produtos-skel works !!!!</h2> -->
<ul class="w-cards produtos">
  <li class="produto"
      *ngFor="let dummy of [].constructor(itemsPerPage); trackBy:tid">
    <div class="thumb">
      <img [src]="imgPlaceholder()"
           alt="">
    </div>
    <ngx-skeleton-loader [count]="3"></ngx-skeleton-loader>
    <div class="botao-comprar">
      <me-html-produto-botao-comprar-skel></me-html-produto-botao-comprar-skel>
    </div>
  </li>
</ul>