<!-- <p>tab {{ tab | json }}</p> -->
<!-- <p>methods {{ methods }}</p> -->
<!-- <p>isMobile {{ isMobile }}</p> -->

<div class="segment mb-4">
  <ul class="nav justify-content-center nav-pills"
      *ngIf="methods?.length > 1">
    <li class="nav-item"
        (click)="tab.set('email')"
        *ngIf="methods?.includes('email')">
      <a class="nav-link"
         [class.active]="['email', 'esqueciSenha', 'registrar'].includes(tab())">
        <span class="o-30 icon-b-at me-2"
              *ngIf="!isMobile">
        </span>E-mail
      </a>
    </li>

    <li class="nav-item"
        (click)="tab.set('telefone')"
        *ngIf="methods?.includes('telefone')">
      <a class="nav-link"
         [class.active]="tab() === 'telefone'">
        <span class="o-30 icon-o-chat-full me-2"
              *ngIf="!isMobile"></span>
        Telefone
      </a>
    </li>

    <li class="nav-item"
        (click)="tab.set('google')"
        *ngIf="methods?.includes('google')">
      <a class="nav-link"
         [class.active]="tab() === 'google'">
        <span class="o-30 icon-b-google me-2"
              *ngIf="!isMobile"></span>
        Google
      </a>
    </li>

    <li class="nav-item"
        (click)="tab.set('facebook')"
        *ngIf="methods?.includes('facebook')">
      <a class="nav-link"
         [class.active]="tab() === 'facebook'">
        <span class="o-30 icon-s-facebook me-2"
              *ngIf="!isMobile">
        </span>
        Facebook
      </a>
    </li>
  </ul>
</div>

<div [ngSwitch]="tab()">
  <div *ngSwitchCase="'cadastro'">
    <cor-mat-register-email-form [isMobile]="!!isMobile"
                                 (cancel$)="tab.set('email')">
    </cor-mat-register-email-form>
  </div>

  <div *ngSwitchCase="'esqueciSenha'">
    esqueciSenha
  </div>

  <div *ngSwitchCase="'google'">
    <button mat-flat-button
            class="btn bg-danger lg w-100"
            (click)="onGoogleClick()">
      Google
      <div class="btn-icon">
        <span class="icon-b-google"></span>
      </div>
    </button>
  </div>

  <div *ngSwitchCase="'telefone'">
    <cor-mat-login-fone-form [isMobile]="!!isMobile">
    </cor-mat-login-fone-form>
  </div>

  <div *ngSwitchCase="'facebook'">
    <button mat-flat-button
            class="btn bg-secondary lg w-100"
            (click)="onFacebookClick()">
      Facebook
      <div class="btn-icon">
        <span class="icon-s-facebook"></span>
      </div>
    </button>
  </div>

  <div *ngSwitchDefault>
    <cor-mat-login-email-form [isMobile]="isMobile"
                              [buttonCaption]="emailLoginButtonCaption"
                              [showRegistrar]="showRegistrar"
                              [showEsqueciSenha]="showEsqueciSenha"
                              (esqueciSenha$)="tab.set('esqueciSenha')"
                              (registrar$)="tab.set('cadastro')">
    </cor-mat-login-email-form>
  </div>
</div>