//#region ng
import {
  NgModule,
  LOCALE_ID
} from '@angular/core';
import { registerLocaleData } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import localePt from "@angular/common/locales/pt";
registerLocaleData(localePt);
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
//#endregion

//#region 3rd
// import {  NgxMaskModule,  // IConfig} from 'ngx-mask';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask
} from 'ngx-mask';
//#endregion

//#region firebase
import {
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import {
  provideAuth,
  getAuth,
} from '@angular/fire/auth';
import {
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
//#endregion

//#region modules
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';
import { MeMatComponentsModule } from './_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
import { ComponentsModule } from './components/components.module';
import { ItemSepararModalModule } from './modals/item-separar/item-separar-modal.module';
import { ProdutoAddModalModule } from './modals/produto-add/produto-add-modal.module';
import { MeBsComponentsModule } from './_shared/_mercadeiro/_ng-ui/_bs/me-bs-components.module';
//#endregion

//#region components
import { AppComponent } from './app.component';
//#endregion

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    ItemSepararModalModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MeBsComponentsModule,
    MeMatComponentsModule,
    // NgxMaskModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    PagesModule,
    ProdutoAddModalModule,
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    RouterModule,
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // https://www.devstringx.com/firebase-cloud-messaging-push-notifications
  // https://github.com/dcneiner/Downloadify
}
