<!-- <p>pre {{ pre }}</p> -->
<!-- <p>idModulo {{ idModulo }}</p> -->
<!-- <p>valStorageKey() {{ valStorageKey() }}</p> -->

<mat-toolbar color="light"
             class="d-flex">
  <div class="row flex-1">
    <div class="col"
         *ngIf="!!vm()?.charts?.Y?.categories?.length">
      <form [formGroup]="anoForm()"
            novalidate>
        <label for="Y"
               class="form-label">
          Ano
        </label>
        <select class="form-select"
                id="Y"
                formControlName="ano">
          <option [value]="YC"
                  *ngFor="let YC of vm()?.charts?.Y?.categories">
            {{ YC }}
          </option>
        </select>
      </form>
    </div>

    <div class="col"
         *ngIf="!!vm()?.charts?.M?.categories?.length">
      <form [formGroup]="mesForm()"
            novalidate>
        <label for="M"
               class="form-label">
          Mês
        </label>
        <select class="form-select"
                id="M"
                formControlName="mes">
          <option [value]="m"
                  *ngFor="let m of vm()?.charts?.M?.categories">
            {{ m }}
          </option>
        </select>
      </form>
    </div>

    <div class="col"
         *ngIf="!!vm()?.charts?.D?.categories?.length">
      <form [formGroup]="diaForm()"
            novalidate>
        <label for="D"
               class="form-label">
          Dia
        </label>
        <select class="form-select"
                id="D"
                formControlName="dia">
          <option [value]="d"
                  *ngFor="let d of vm()?.charts?.D?.categories">
            {{ d }}
          </option>
        </select>
      </form>
    </div>

    <div class="col">
      <form [formGroup]="valForm()"
            novalidate>
        <label for="val"
               class="form-label">
          Valor
        </label>
        <select class="form-select"
                id="val"
                formControlName="val">
          <option value="QP">Qtde pedidos</option>
          <option value="QI">Qtde itens</option>
          <option value="QV">Qtde volumes</option>
          <option value="VP">Valor pedidos</option>
          <option value="TM">Ticket médio</option>
          <option value="ST">Taxas serviço total</option>
          <option value="SL">Taxas serviço loja</option>
          <option value="SC">Taxas serviço cliente</option>
          <option value="TE">Taxas entrega</option>
        </select>
      </form>
    </div>

    <!-- 
      <div class="col d-flex">
        "<div class="ms-auto">
        <mat-button-toggle-group name="chartType"
                            [(ngModel)]="chartType">
        <mat-button-toggle value="bar"
                        matTooltip="Barras">
        <div class="icon-o-graph-cols"></div>
        </mat-button-toggle>

        <mat-button-toggle value="line"
                        matTooltip="Linhas">
        <div class="icon-o-share"></div>
        </mat-button-toggle>
        </mat-button-toggle-group>
        </div>"
      </div> 
    -->
  </div>
</mat-toolbar>

<mat-stepper [linear]="true"
             orientation="vertical"
             #stepper>
  <mat-step [stepControl]="anoForm()">
    <ng-template matStepLabel>
      Ano <small class="o-50"
             *ngIf="!!anoRef.value">
        ( {{ anoRef.value }} )
      </small>
    </ng-template>

    <div id="YChart"
         *ngIf="!!vm()?.charts?.Y?.series?.length">
      <apx-chart [series]="YChartOptions()?.series"
                 [chart]="YChartOptions()?.chart"
                 [dataLabels]="YChartOptions()?.dataLabels"
                 [plotOptions]="YChartOptions()?.plotOptions"
                 [yaxis]="YChartOptions()?.yaxis"
                 [xaxis]="YChartOptions()?.xaxis"
                 [fill]="YChartOptions()?.fill"
                 [title]="YChartOptions()?.title" />
    </div>
  </mat-step>

  <mat-step [stepControl]="mesForm()">
    <ng-template matStepLabel>
      Mês <small class="o-50"
             *ngIf="!!mesRef?.value">
        ( {{ mesRef?.value }} )
      </small>
    </ng-template>

    <div id="MChart"
         *ngIf="!!vm()?.charts?.M?.series?.length">
      <apx-chart [series]="MChartOptions()?.series"
                 [chart]="MChartOptions()?.chart"
                 [dataLabels]="MChartOptions()?.dataLabels"
                 [plotOptions]="MChartOptions()?.plotOptions"
                 [yaxis]="MChartOptions()?.yaxis"
                 [xaxis]="MChartOptions()?.xaxis"
                 [fill]="MChartOptions()?.fill"
                 [title]="MChartOptions()?.title" />
    </div>
  </mat-step>

  <mat-step [stepControl]="diaForm()">
    <ng-template matStepLabel>
      Dia <small class="o-50"
             *ngIf="!!diaRef?.value">
        ( {{ diaRef?.value }} )
      </small>
    </ng-template>

    <div id="DChart"
         *ngIf="!!vm()?.charts?.D?.series?.length">
      <apx-chart [series]="DChartOptions()?.series"
                 [chart]="DChartOptions()?.chart"
                 [dataLabels]="DChartOptions()?.dataLabels"
                 [plotOptions]="DChartOptions()?.plotOptions"
                 [yaxis]="DChartOptions()?.yaxis"
                 [xaxis]="DChartOptions()?.xaxis"
                 [fill]="DChartOptions()?.fill"
                 [title]="DChartOptions()?.title" />
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>
      Pedidos <small class="o-50"
             *ngIf="!!pedidos()?.length">
        ( {{ pedidos()?.length}} )
      </small>
    </ng-template>

    <me-mat-mov-pedido-itens [pedidos]="pedidos()"
                             (select$)="onPedidoClick($event)" />
  </mat-step>
</mat-stepper>
