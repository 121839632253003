//#region ng
import {
  ChangeDetectorRef,
  inject,
  ViewRef,
} from '@angular/core';
//#endregion

//#region 3rd
import { Subject } from 'rxjs';
//#endregion

export const onDestroy = (): Subject<void> => {
  const DESTROY_ACTION$ = new Subject<void>();
  const VIEW_REF = inject(ChangeDetectorRef) as ViewRef;

  VIEW_REF?.onDestroy(
    () => {
      DESTROY_ACTION$?.next();
      DESTROY_ACTION$?.complete();
    }
  );

  return DESTROY_ACTION$
}
