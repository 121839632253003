<span class="single"
      *ngIf="offsetSel">
  <select class="form-select"
          [class.lg]="size === 'lg'"
          [(ngModel)]="offsetSel">
    <!-- (ngModelChange)="offsetSel.set($event)" -->
    <option value="1"
            *ngFor="let v of itensPorPag(); trackBy:tidn"
            [value]="v">
      {{ v }} itens
    </option>
  </select>
</span>