//#region misc
import { ICorPaginatorSort } from '../../../_core/_misc/_models/_interfaces/_misc';
// import { TOperadorRole } from './_types';
export const ID_MODULO: string = 'mer';
//#endregion

//#region storage
export const KEY_OFFSET_LOJAS_REFS: string = `KEY_OFFSET_LOJAS_REFS_${ID_MODULO}`.toUpperCase();
export const KEY_VIEWSTYLE_LOJAS_REFS: string = `KEY_VIEWSTYLE_LOJAS_REFS_${ID_MODULO}`.toUpperCase();
//#endregion

//#region Meilisearch
export const MEILISEARCH_INDICES = {
  produtos: {
    uid: 'produtos',
    primaryKey: 'id',
    filterableAttributes: [
      '_img',
      '_industrializado',
      'atacado.status',
      'ativo._consistente',
      'ativo._status',
      'ativo.online',
      'ativo.status',
      'barcode',
      'destaque',
      'estoqueCritico',
      'fracionado._status',
    ],
    searchableAttributes: [
      'barcode',
      'nome',
    ],
  },
  lojas: {
    uid: 'mer--lojas',
    primaryKey: 'id',
    filterableAttributes: [
      'ativo._status',
      '_idLocalidade',
      'rede.id',
    ],
    searchableAttributes: [
      'nome',
      'id',
      'email',
      'cpfCnpj',
      'rede._nome',
      'integradora._nome',
      'tipo._nome',
      'parceiro._nome',
      'faturamento.cod',
      'fones.celular',
      'fones.fixo',
      'fones.mensagens',
    ],
  },
};
//#endregion

//#region contas
export const OPERADORES_ROLES: string[] = [
  'admin',
  'integradora',
  'parceiro',
  'dono',
  'gerente',
  'marketing',
  'monitor',
];
//#endregion

//#region lojas
export const
  LOJA_DISTRIBUIDORA_TIPO_ROTA: any = {
    bai: 'Por bairro',
    loc: 'Por localidade',
  };
export const LOJA_ENTREGA_STATUS: any = {
  E: 'Entrega',
  R: 'Retirada',
  ER: 'Entrega + retirada',
};
//#endregion

//#region pedido
export const STATUS_PEDIDO: any = {
  aguardandoSeparacao: {
    caption: 'Aguardando separação',
    theme: 'light',
    icon: 'icon-o-clock-2',
  },
  emSeparacao: {
    caption: 'Em separação',
    theme: 'warning',
    icon: 'icon-b-shopping-cart',
  },
  aguardandoEntrega: {
    caption: 'Aguardando entrega',
    theme: 'info',
    icon: 'icon-o-clock-2',
  },
  aguardandoRetirada: {
    caption: 'Aguardando retirada',
    theme: 'info',
    icon: 'icon-o-clock-2',
  },
  emEntrega: {
    caption: 'Em entrega',
    theme: 'warning',
    icon: 'icon-o-truck',
  },
  concluido: {
    caption: 'Concluído',
    theme: 'success',
    icon: 'icon-b-check',
  },
  cancelado: {
    caption: 'Cancelado',
    theme: 'danger',
    icon: 'icon-b-cancel',
  },
};
//#endregion

//#region status item pedido
export const STATUS_ITEM_PEDIDO = {
  pendente: {
    label: 'Pendente',
    counterLabel: 'pendentes',
  },
  confirmado: {
    label: 'Confirmado',
    counterLabel: 'confirmados',
  },
  modificado: {
    label: 'Modificado',
    counterLabel: 'modificados',
  },
  naoEncontrado: {
    label: 'Não encontrado',
    counterLabel: 'naoEncontrados',
  },
  removido: {
    label: 'Removido',
    counterLabel: 'removidos',
  },
  adicionado: {
    label: 'Adicionado',
    counterLabel: 'adicionados',
  },
};
//#endregion

//#region formas-pgto
// export const TIPOS_FORMAS_PGTO: string[] = ['E', 'R', 'O'];
export const TIPOS_FORMAS_PGTO: string[] = ['din', 'pix', 'deb', 'cre', 'tic'];
export const NOMES_TIPOS_FORMAS_PGTO: any = {
  din: 'Dinheiro',
  pix: 'Pix',
  deb: 'Cartão de débito',
  cre: 'Cartão de crédito',
  tic: 'Vale alimentação',
  dis: 'Distribuidora',
};
//#endregion

//#region produtos
export const PRODUTOS_ORD: ICorPaginatorSort[] = [
  {
    id: 'r',
    caption: 'Relevância',
    icon: 'icon-b-bang',
    field: '__relevancia',
    direction: 'asc',
  },
  {
    id: 'na',
    caption: 'Nome (A-Z)',
    icon: 'icon-b-alpha-sort-asc',
    field: 'nome',
    direction: 'asc',
  },
  {
    id: 'nd',
    caption: 'Nome (Z-A)',
    icon: 'icon-b-alpha-sort-desc',
    field: 'nome',
    direction: 'desc',
  },
  {
    id: 'pa',
    caption: 'Menor preço',
    icon: 'icon-b-num-sort-asc',
    field: 'preco._liquido', // '_promocaoAutomatica.preco';
    direction: 'asc',
  },
  {
    id: 'pd',
    caption: 'Maior preço',
    icon: 'icon-b-num-sort-desc',
    field: 'preco._liquido', // '_promocaoAutomatica.preco';
    direction: 'desc',
  },
];

export const PRODUTO_LIMITE_DESCRICAO: number = 250;
export const PRODUTO_FRACIONADO_TIPOS_ALT: string[] = [
  'KG',
  'GR',
  'LT',
  'ML',
  'MT',
  'CM',
];
export const PRODUTO_FRACIONADO_TIPOS: string[] = [
  'K',
  'G',
  'L',
  'ML',
  'M',
  'CM',
];
export const PRODUTO_FRACIONADO_FRACAO_DEFAULTS: any = {
  KG: 0.5,
  K: 0.5,
  GR: 500,
  G: 500,
  LT: 0.5,
  L: 0.5,
  ML: 500,
  MT: 0.5,
  M: 0.5,
  CM: 500,
};
// export const PRODUTO_TIPOS: string[] = ['', 'livro', 'medicamento'];
//#endregion

// #region promocoes
export const PROMOCAO_TIPO_API: string[] = ['LP', 'APD'];
// #endregion

//#region integradoras
export const ID_INTEGRADORA_CORE: string = 'conecdata';
//#endregion
