export function formataCEP(cep: string): string {
  const CEP: string = (cep ? `${cep}` : '').replace(/\D/g, '');
  switch (CEP.length) {
    // case 9:
    // if (CEP[5] === '-') {
    //   return CEP;
    // } else {
    //   return '';
    // } // else

    case 8:
      return `${CEP.substr(0, 5)}-${CEP.substr(5)}`;

    default:
      return '';
  } // switch
}
