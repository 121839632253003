<ng-container *ngIf="pedidosAction$() | async as pedidos">
  <!-- <audio #audio
         src="/assets/audio/laser.mp3">
  </audio> -->

  <audio class="warningSound"
         preload="all">
    <source src="/assets/audio/long-phone-ring.mp3"
            type="audio/mpeg">
  </audio>

  <div class="box">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="my-auto">
          <mat-form-field appearance="outline">
            <mat-label>Cód pedido</mat-label>
            <button mat-button
                    matPrefix
                    mat-icon-button>
              <mat-icon class="o-50">search</mat-icon>
            </button>
            <input matInput
                   type="text"
                   class="search"
                   mask="00000"
                   [showMaskTyped]="false"
                   [focus]="searchFocusEvent$()"
                   [(ngModel)]="searchTerm">
            <button mat-button
                    *ngIf="!!(pedidoCodChangedAction$() | async)"
                    matSuffix
                    mat-icon-button
                    (click)="searchTerm = ''">
              <mat-icon class="o-50">close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="my-auto mx-4 flex-1">
          <mat-form-field appearance="outline"
                          class="w-100">
            <mat-label>Status</mat-label>
            <mat-select [(value)]="status">
              <mat-option value="">-- Todos --</mat-option>
              <mat-option value="aguardandoSeparacao">Aguardando separação</mat-option>
              <mat-option value="emSeparacao">Em separação</mat-option>
              <mat-option value="aguardandoEntrega">Aguardando entrega</mat-option>
              <mat-option value="aguardandoRetirada">Aguardando retirada</mat-option>
              <mat-option value="emEntrega">Em entrega</mat-option>
              <mat-option value="concluido">Concluídos</mat-option>
              <mat-option value="cancelado">Cancelados</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="my-auto text-center move-up">
          <cor-mat-pagination-nav [paginator]="paginator"
                                  [offsetStorageKey]="offsetStorageKey()"
                                  [isMobile]="true"
                                  size="lg"
                                  (offsetChanged$)="pagItemsPerPageAction$().next($event)" />
        </div>
      </div>
    </div>

    <!-- <p>pedidos?.length {{ pedidos?.length | json }}</p> -->
    <!-- <p>pedidos {{ pedidos | json }}</p> -->

    <div *ngIf="!!pedidos; else carregandoPedidos">
      <div *ngIf="!pedidos?.length; else pedidosEncontrados">
        <div class="alert alert-light text-center"
             role="alert">
          Nenhum pedido.
        </div>
      </div>
      <ng-template #pedidosEncontrados>
        <ul class="w-cards pedidos">
          <li *ngFor="let p of pedidos; trackBy:tid_ped">
            <me-mat-pedido-card [isMonitor]="true"
                                [isMobile]="false"
                                [pedido]="p"
                                (detalhes$)="onDetalhesClick($event)"
                                (iniciarSeparacao$)="onIniciarSeparacao(p)"
                                (concluirSeparacao$)="onConcluirSeparacao(p)"
                                (concluirPedido$)="onConcluirPedido(p)" />
          </li>
        </ul>
      </ng-template>
    </div>
    <ng-template #carregandoPedidos>
      <me-html-pedidos-list-skel />
    </ng-template>
  </div>
</ng-container>


<!--
<div class="box">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div class="my-auto move-down">
        <mat-form-field appearance="outline">
          <mat-label>Cód pedido</mat-label>
          <input matInput
                 type="text"
                 class="search"
                 mask="00000"
                 [showMaskTyped]="false"
                 [focus]="searchFocusEvent$"
                 [(ngModel)]="search">
          <button matSuffix
                  mat-icon-button
                  [disabled]="!search"
                  (click)="onSearchClearClick()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="my-auto move-down">
        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select [(value)]="status">
            <mat-option value="">-- Todos --</mat-option>
            <mat-option value="aguardandoSeparacao">Aguardando separação</mat-option>
            <mat-option value="emSeparacao">Em separação</mat-option>
            <mat-option value="aguardandoEntrega">Aguardando entrega</mat-option>
            <mat-option value="aguardandoRetirada">Aguardando retirada</mat-option>
            <mat-option value="emEntrega">Em entrega</mat-option>
            <mat-option value="concluido">Concluídos</mat-option>
            <mat-option value="cancelado">Cancelados</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="my-auto text-center">
        <cor-mat-pagination-nav [paginator]="paginator"
                               [offsetStorageKey]="storageKey"
                               [isMobile]="true"
                               size="lg"
                               (offsetChanged$)="itemsPerPage = $event">
        </cor-mat-pagination-nav>
      </div>
    </div>
  </div>

  <div *ngIf="!!loja">
    <div *ngIf="!!pedidos; else carregandoPedidos">
      <div *ngIf="!pedidos?.length; else pedidosEncontrados">
        <div class="alert alert-light text-center"
             role="alert">
          Nenhum pedido.
        </div>
      </div>
      <ng-template #pedidosEncontrados>
        <ul class="pedidos">
          <li class="pedido"
              *ngFor="let p of pedidos; trackBy:tid_ped">
            <me-mat-pedido-card [isMonitor]="true"
                                [isMobile]="false"
                                [pedido]="p"
                                (detalhes$)="onDetalhesClick($event)"
                                (iniciarSeparacao$)="onIniciarSeparacao(p)"
                                (concluirSeparacao$)="onConcluirSeparacao(p)"
                                (concluirPedido$)="onConcluirPedido(p)">
            </me-mat-pedido-card>
          </li>
        </ul>
      </ng-template>
    </div>
    <ng-template #carregandoPedidos>
      <me-html-pedidos-list-skel></me-html-pedidos-list-skel>
    </ng-template>
  </div>
</div>
 -->