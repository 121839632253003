//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
import { ITipoLojas } from '../../_misc/_models/_interfaces/_cols/tipo-lojas';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class TiposLojasService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<ITipoLojas>): ITipoLojas {
    return {
      // id
      id: row?.id || '',

      // user
      nome: row?.nome || '',

      // system
      _criadoEm: row?._criadoEm || null,

      // realtime
      __displayInPagination: !!row?.__displayInPagination,
    };
  }

  fixes(docs: Partial<ITipoLojas>[]): ITipoLojas[] {
    return (docs || []).map((doc: Partial<ITipoLojas>) => this.fix(doc));
  }
  //#endregion

  //#region R
  docs(): Observable<ITipoLojas[]> {
    const PATH: string = `_tipos-lojas/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH),) as Observable<ITipoLojas[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<any, ObservableInput<any>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando tipos de lojas.')
        )
      );
  }
  //#endregion
}