<!-- <p>pedido {{ pedido | json }}</p> -->
<!-- <p>pedido?.__total {{ pedido?.__total | json }}</p> -->
<!-- <p>isMobile {{ isMobile }}</p> -->

<div *ngIf="!!pedido">
  <ul class="list-group mb-3">
    <li class="list-group-item list-group-item-secondary d-flex">
      Solicitado

      <div class="ms-auto">
        <small class="o-60">{{ pedido?.carrinho?.__solicitado?.qtde?.itens }} itens, {{
          pedido?.carrinho?.__solicitado?.qtde?.volumes }} volumes</small>
      </div>
    </li>

    <li class="list-group-item d-flex">
      <span class="o-60">Total produtos</span>

      <div class="ms-auto">
        <!-- {{ pedido?._total?.solicitado?.valorProdutos | currency: "BRL":"symbol":"1.2-2" }} -->
        <!-- {{ pedido?.carrinho?.__solicitado?.__total?.valorProdutos | currency: "BRL":"symbol":"1.2-2" }} -->
        {{ pedido?.carrinho?.__solicitado?.total?.prod | currency: "BRL":"symbol":"1.2-2" }}
      </div>
    </li>

    <li class="list-group-item d-flex">
      <span class="o-60">Total serviços</span>

      <div class="ms-auto">
        <!-- {{ pedido?._total?.__valorTaxaServ | currency: "BRL":"symbol":"1.2-2" }} -->
        <!-- {{ pedido?.carrinho?.__solicitado?.__total?.valorTaxaServ | currency: "BRL":"symbol":"1.2-2" }} -->
        {{ pedido?.carrinho?.__loja?.faturamento?.servicos?.__valCliente | currency: "BRL":"symbol":"1.2-2" }}
      </div>
    </li>

    <li class="list-group-item d-flex">
      <div class="my-auto">
        <span class="o-60">Total entrega</span>
      </div>

      <div class="ms-auto">
        <div class="text-end">
          <div *ngIf="!!pedido?.carrinho?.__loja?.taxaEntrega?.desc">
            <small class="o-60 line-thru">{{ pedido?.carrinho?.__loja?.taxaEntrega?.val | currency:
              "BRL":"symbol":"1.2-2" }}</small>
          </div>

          <div>
            {{ pedido?.carrinho?.__loja?.taxaEntrega?._liquido | currency: "BRL":"symbol":"1.2-2" }}
          </div>
        </div>
      </div>
    </li>

    <li class="list-group-item d-flex">
      <span class="o-60">Total a pagar</span>

      <div class="ms-auto">
        <!-- <strong>{{ pedido?._total?.solicitado?.totalPagar | currency: "BRL":"symbol":"1.2-2" }}</strong> -->
        <!-- <strong>{{ pedido?.carrinho?.__solicitado?.__total?.totalPagar | currency: "BRL":"symbol":"1.2-2" }}</strong> -->
        <strong>{{ pedido?.carrinho?.__solicitado?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}</strong>
      </div>
    </li>
  </ul>

  <ul class="list-group mb-3"
      *ngIf="!!pedido?._isSeparado && pedido?._status !== 'cancelado'">
    <li class="list-group-item list-group-item-secondary d-flex">
      Separado

      <div class="ms-auto">
        <small class="o-60">{{ pedido?.carrinho?._separado?.qtde?.itens }} itens, {{
          pedido?.carrinho?._separado?.qtde?.volumes }} volumes</small>
      </div>
    </li>

    <li class="list-group-item d-flex">
      <span class="o-60">Total produtos</span>

      <div class="ms-auto">
        <!-- {{ pedido?._total?.separado?.valorProdutos | currency: "BRL":"symbol":"1.2-2" }} -->
        <!-- {{ pedido?.carrinho?._separado?.__total?.valorProdutos | currency: "BRL":"symbol":"1.2-2" }} -->
        {{ pedido?.carrinho?._separado?.total?.prod | currency: "BRL":"symbol":"1.2-2" }}
      </div>
    </li>

    <li class="list-group-item d-flex">
      <span class="o-60">Total serviços</span>

      <div class="ms-auto">
        <!-- {{ pedido?._total?.__valorTaxaServ | currency: "BRL":"symbol":"1.2-2" }} -->
        <!-- {{ pedido?.carrinho?._separado?.__total?.valorTaxaServ | currency: "BRL":"symbol":"1.2-2" }} -->
        {{ pedido?.carrinho?.__loja?.faturamento?.servicos?.__valCliente | currency: "BRL":"symbol":"1.2-2" }}
      </div>
    </li>

    <li class="list-group-item d-flex">
      <div class="my-auto">
        <span class="o-60">Total entrega</span>
      </div>

      <div class="ms-auto">
        <div class="text-end">
          <div *ngIf="!!pedido?.carrinho?.__loja?.taxaEntrega?.desc">
            <small class="o-60 line-thru">{{ pedido?.carrinho?.__loja?.taxaEntrega?.val | currency:
              "BRL":"symbol":"1.2-2" }}</small>
          </div>

          <div>
            {{ pedido?.carrinho?.__loja?.taxaEntrega?._liquido | currency: "BRL":"symbol":"1.2-2" }}
          </div>
        </div>
      </div>
    </li>

    <li class="list-group-item d-flex">
      <span class="o-60">Total a pagar</span>

      <div class="ms-auto">
        <!-- <strong>{{ pedido?._total?.separado?.totalPagar | currency: "BRL":"symbol":"1.2-2" }}</strong> -->
        <!-- <strong>{{ pedido?.carrinho?._separado?.__total?.totalPagar | currency: "BRL":"symbol":"1.2-2" }}</strong> -->
        <strong>{{ pedido?.carrinho?._separado?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}</strong>
      </div>
    </li>
  </ul>
</div>