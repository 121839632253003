//#region models
import { ILojaIdInfoRet } from '../_models/_interfaces/_rets';
//#endregion

//#region libs
import {
  desacentua,
  guid,
} from '../../../_libs/_misc/_strings';
import { splitLocalidadeId } from './enderecos';
//#endregion

export function calcLojaIdInfo(idLoja: string): ILojaIdInfoRet | null { // lojaIdInfo
  if (!!idLoja) {
    const CELLS: string[] = idLoja.split('--'); // op, rede, uf, localidade, bairro
    if (CELLS.length === 4) {
      const OP: string = CELLS[0];
      const REDE: string = CELLS[1];
      const [UF, LOCALIDADE, LOCALIDADE_ID] = splitLocalidadeId(CELLS[2], CELLS[3]);
      // const UF: string = CELLS[2].toLowerCase();
      // const LOCALIDADE_ID: string = `${CELLS[3].replace(/ /g, '-').toLowerCase()}--${UF}`;
      // const BAIRRO: string = CELLS[4].toLowerCase();
      // const LOGRADOURO: string = CELLS[5].toLowerCase();
      return {
        op: OP,
        idRede: REDE,
        uf: UF,
        localidade: LOCALIDADE,
        idLocalidade: LOCALIDADE_ID,
        // bairro: BAIRRO,
        // logradouro: LOGRADOURO,
        localidadePath: `_ufs/${UF}/localidades/${LOCALIDADE_ID}`,
        lojaPath: `_ufs/${UF}/localidades/${LOCALIDADE_ID}/lojas/${idLoja}`,
      };
    } else {
      return null;
    } // else
  } else {
    return null;
  } // else
}

export function calcLojaId(
  idRede: string,
  uf: string,
  localidade: string,
  // bairro: string,
  // logradouro: string
): string {
  // console.log('idRede', idRede);
  // console.log('uf', uf);
  // console.log('localidade', localidade);
  // console.log('bairro', bairro);
  // console.log('logradouro', logradouro);
  if (
    !!idRede
    && !!uf
    && !!localidade
    // && bairro
    // && logradouro
  ) {
    // af-supermercado--mg--uberaba--conjunto-alfredo-freire--rua-francisco-munhoz-lopes
    // const LOCALIDADE: string = desacentua(localidade.replace(/ /g, '-'));
    // const BAIRRO: string = desacentua(bairro.replace(/ /g, '-'));
    // const LOGRADOURO: string = desacentua(logradouro.replace(/ /g, '-'));
    return desacentua(
      `${guid()}--${idRede}--${uf}--${localidade}` // --${bairro}--${logradouro}
        .trim()
        .toLowerCase()
        .replace(/ {2}/g, ' ')
        .replace(/ /g, '-')
    );
  } // if
  return '';
}

export function calcMeiliNomeIndice( // meiliNomeIndice
  lojaId: string,
  indexName: string
): string {
  if (!!lojaId) {
    const LOJA_INFO: ILojaIdInfoRet | null = calcLojaIdInfo(lojaId);
    if (!!LOJA_INFO) {
      const LOJA_HASH: string = LOJA_INFO?.op || '';
      return `mer--${LOJA_HASH}-${indexName}`;
    } // if
    return '';
  } // if
  return '';
}
