//#region ng
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  computed,
  effect,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

//#region 3rd
import { get } from 'lodash';
//#endregion

//#region models
type TTipoOp = 'del' | 'set';
import {
  ItensCarrinhoService,
} from '../../_shared/_mercadeiro/_ng/_services';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import { IItemCarrinhoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import { IPedidoItemSepararParams } from '../../models/interfaces/params';
//#endregion

//#region custom validators
class CustomValidator {
  static encontrado(qtdeSolicitada: number) {
    return (control: FormControl): ValidationErrors | null => {
      const ENCONTRADO: number = Number(control.value) || 0;
      // console.log(ENCONTRADO, qtdeSolicitada);
      if (ENCONTRADO >= 0 && qtdeSolicitada >= 0) {
        return ENCONTRADO > qtdeSolicitada ? { encontrado: true } : null;
      } // if
      return null;
    }
  }

  static separado() {
    return (control: FormControl): ValidationErrors | null => {
      const FORM_GROUP: FormGroup = get(control, '_parent');
      // console.log(FORM_GROUP);
      const QTDE_ENCONTRADA: number = FORM_GROUP?.value?.encontrado || 0;
      const VAL: number = Number(control.value) || 0;
      // console.log(VAL, QTDE_ENCONTRADA, isEncontrar);
      if (VAL >= 0 && QTDE_ENCONTRADA >= 0) {
        return VAL > QTDE_ENCONTRADA ? { separado: true } : null;
      } // if
      return null;
    }
  }
}
//#endregion

@Component({
  selector: 'app-item-separar-modal',
  templateUrl: './item-separar.modal.html',
  styleUrls: ['./item-separar.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemSepararModal {
  //#region publics
  isEncontrar = signal<boolean>(null);
  item = signal<IItemCarrinhoMap>(null);
  itemForm = computed<FormGroup>(
    () => {
      const ITEM: IItemCarrinhoMap = this.item();
      // const IS_ENCONTRAR: boolean = this.isEncontrar();
      // ITEM?.__solicitado?.qtde?.fixed ? 'Confirmar / modificar' : 'Adicionar';
      return this._fb.group({
        _separado: this._fb.group({
          qtde: this._fb.group({
            val: [this.separadoDefault(), [Validators.required, CustomValidator.separado()]],
            encontrado: [this.item()?._separado?.qtde?.encontrado, [Validators.required, CustomValidator.encontrado(this.qtdeSolicitada())]],
          })
        })
      });
    }
  );
  // signal<FormGroup>(null);
  // itemForm = signal<FormGroup>(null);
  op = signal<TTipoOp>(null);
  setOpCaption = computed<string>(
    () => {
      const ITEM: IItemCarrinhoMap = this.item();
      return ITEM?.__solicitado?.qtde?.fixed ? 'Confirmar / modificar' : 'Adicionar';
    }
  );
  qtdeSolicitada = computed<number>(
    () => {
      const ITEM: IItemCarrinhoMap = this.item();
      return Number(ITEM?.__solicitado?.qtde?.fixed) || 0;
    }
  );
  fv = signal<CorFormValidation>(null);
  //#endregion

  //#region Controls getters
  get valRef(): AbstractControl { return this.itemForm()?.get('_separado.qtde.val'); }
  get encontradoRef(): AbstractControl { return this.itemForm()?.get('_separado.qtde.encontrado'); }
  //#endregion

  //#region events
  encontradoSelectEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  separadoSelectEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPedidoItemSepararParams,
    private _dialogRef: MatDialogRef<ItemSepararModal>,
    private _itensServ: ItensCarrinhoService,
    private _fb: FormBuilder,
    // private _pedidosServ: PedidosService,
  ) {
    this.fv.set(new CorFormValidation);
    effect(
      () => {
        const IS_ENCONTRAR: boolean = this.isEncontrar();
        !!IS_ENCONTRAR ? this.valRef?.disable() : this.valRef?.enable();
      }
    );

    effect(
      () => {
        if (this.op() === 'set') {
          this.valRef?.setValue(this.separadoDefault());
          this._select();
        } else {
          this.valRef?.setValue(0);
          // this.encontradoRef?.setValue(0);
        } // else
      }
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.item.set(!!this.data?.item ? this._itensServ.fix(this.data?.item) : null);
    this.isEncontrar.set(!!this.data?.isEncontrar);
  }

  ngAfterViewInit() {
    this.op.set('set');
    this._select();
  }
  //#endregion

  //#region functions
  separadoDefault(): number {
    return this.item()?._separado?.qtde?.fixed || this.item()?._separado?.qtde?.encontrado || this.item()?.__solicitado?.qtde?.fixed || 0;
  }

  private _select() {
    // console.log(this.isMobile);
    setTimeout(
      () => { this.isEncontrar() ? this.encontradoSelectEvent$().emit(true) : this.separadoSelectEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onModalCloseClick(val: number | null = null) {
    this._dialogRef.close(val);
  }

  onSubmitClick() {
    // console.log(this.valRef?.value);
    this.onModalCloseClick(
      !!this.isEncontrar()
        ? this.encontradoRef?.value ?? null
        : this.valRef?.value ?? null
    );
  }
  //#endregion
}
