//#region ng
import { NgModule } from '@angular/core';
import {
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
import {
  FormsModule,
  // ReactiveFormsModule
} from '@angular/forms';
import { RouterModule } from '@angular/router';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// import { MatListModule } from '@angular/material/list';
// import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatTreeModule } from '@angular/material/tree';
import { MatRadioModule } from '@angular/material/radio';
//#endregion

//#region 3rd
import { QRCodeModule } from 'angularx-qrcode';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  // provideNgxMask
} from 'ngx-mask';
// import { Ng2BRPipesModule } from 'ng2-brpipes';
import { NgxPaginationModule } from 'ngx-pagination';
// import { QRCodeModule } from 'angularx-qrcode';
import { AngularSplitModule } from 'angular-split';
//#endregion

//#region modules
// import { CorMatComponentsModule } from '../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
// import { CorHtmlComponentsModule } from '../_shared/_core/_ng-ui/_html/cor-html-components.module';
// import { MeMatComponentsModule } from '../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
// import { LojaInfoModalModule } from '../modals/loja-info/loja-info-modal.module';
// import { CorPipesModule } from '../_shared/_core/_ng/_pipes/cor-pipes.module';
// import { OperadorHabilitarModalModule } from '../modals/operador-habilitar/operador-habilitar-modal.module';
// import { ComponentsModule } from '../components/components.module';
// import { HorarioFormModalModule } from '../modals/horario-form/horario-form-modal.module';
// import { SetorFormModalModule } from '../modals/setor-form/setor-form-modal.module';
// import { BairroAddModalModule } from '../modals/bairro-add/bairro-add-modal.module';
// import { CorDirectivesModule } from '../_shared/_core/_ng/_directives/cor-directives.module';
// import { AppMatFiltroProdutosAddModalModule } from '../modals/filtro-produtos-add/filtro-produtos-add-modal.module';
// import { ProdutoFormModalModule } from '../modals/produto-form/produto-form-modal.module';
// import { ProdutoInfoModalModule } from '../modals/produto-info/produto-info-modal.module';
import { CorMatComponentsModule } from '../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { CorDirectivesModule } from '../_shared/_core/_ng/_directives/cor-directives.module';
import { MeHtmlComponentsModule } from '../_shared/_mercadeiro/_ng-ui/_html/me-html-components.module';
import { MeMatComponentsModule } from '../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
import { MePipesModule } from '../_shared/_mercadeiro/_ng/_pipes/me-pipes.module';
import { ComponentsModule } from '../components/components.module';
//#endregion

//#region components
import {
  HabilitaTerminalPage,
  // ConfigPage,
  // DepartamentosPage,
  // FormasPgtoPage,
  // HorariosPage,
  // LojasPage,
  ItensSeparacaoComponent,
  ItemCardComponent,
  ItensTableComponent,
  LoginPage,
  NotificacoesBloqueadasPage,
  PedidoPage,
  PedidosPage,
  // SeparacaoSolicitadoBtnComponent,
  // SeparacaoSeparadoBtnComponent,
  StageHeaderComponent,
  SeparacaoQtdeCaptionComponent,
  // MidiaPage,
  // MovimentoPage,
  // OperadoresPage,
  // PedidosPage,
  // ProdutosPage,
  // SetoresEntregaPage,
  // NotificacoesPage,
  // HorariosBottomSheetComponent,
} from '.';
//#endregion

@NgModule({
  imports: [
    AngularSplitModule,
    // AppMatFiltroProdutosAddModalModule,
    // BairroAddModalModule,
    CorDirectivesModule,
    // CorHtmlComponentsModule,
    // CorMatComponentsModule,
    // CorPipesModule,
    CorMatComponentsModule,
    CommonModule,
    ComponentsModule,
    // ComponentsModule,
    FormsModule,
    // LojaFormModalModule,
    // MatCardModule,
    // HorarioFormModalModule,
    // LojaInfoModalModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    // MatChipsModule,
    // MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    // MatMenuModule,
    MatProgressBarModule,
    MatSelectModule,
    MatRadioModule,
    // MatSlideToggleModule,
    // MatSliderModule,
    // MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    // MatTreeModule,
    MeHtmlComponentsModule,
    MeMatComponentsModule,
    MePipesModule,
    // Ng2BRPipesModule,
    NgOptimizedImage,
    // NgxMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxPaginationModule,
    // ProdutoInfoModalModule,
    // QRCodeModule,
    // ReactiveFormsModule,
    // RedeFormModalModule,
    // SetorFormModalModule,
    // OperadorHabilitarModalModule,
    // ProdutoFormModalModule,
    RouterModule,
    QRCodeModule
  ],
  declarations: [
    // ConfigPage,
    HabilitaTerminalPage,
    ItemCardComponent,
    ItensSeparacaoComponent,
    ItensTableComponent,
    LoginPage,
    NotificacoesBloqueadasPage,
    PedidoPage,
    PedidosPage,
    SeparacaoQtdeCaptionComponent,
    // SeparacaoSeparadoBtnComponent,
    // SeparacaoSolicitadoBtnComponent,
    StageHeaderComponent,
    // DepartamentosPage,
    // FormasPgtoPage,
    // HorariosBottomSheetComponent,
    // HorariosPage,
    // LojasPage,
    // MidiaPage,
    // MovimentoPage,
    // NotificacoesPage,
    // OperadoresPage,
    // PedidosPage,
    // ProdutosPage,
    // SetoresEntregaPage,
  ],
  exports: [
    // ConfigPage,
    HabilitaTerminalPage,
    // ItemCardComponent,
    // SeparacaoSeparadoBtnComponent,
    // ItensSeparacaoComponent,
    // ItensTableComponent,
    LoginPage,
    NotificacoesBloqueadasPage,
    PedidoPage,
    PedidosPage,
    // SeparacaoSolicitadoBtnComponent,
    // DepartamentosPage,
    // HorariosPage,
    // FormasPgtoPage,
    // HorariosBottomSheetComponent,
    // LojasPage,
    // MidiaPage,
    // MovimentoPage,
    // NotificacoesPage,
    // OperadoresPage,
    // PedidosPage,
    // ProdutosPage,
    // SetoresEntregaPage,
  ],
})
export class PagesModule { }
