//#region ng
import {
  Component,
  Input,
} from '@angular/core';
//#endregion

//#region models
type TSize = 'sm' | 'nm' | 'lg';
import { IItemCarrinhoMap } from '../../../_misc/_models/_interfaces/_maps';
//#endregion

@Component({
  selector: 'me-html-item-carrinho-preco',
  templateUrl: './item-carrinho-preco.component.html',
  styleUrls: ['./item-carrinho-preco.component.scss']
})
export class MeHtmlItemCarrinhoPrecoComponent {
  //#region inputs
  // item
  @Input({ required: true }) item: IItemCarrinhoMap;
  // isInline
  @Input() isInline: boolean = true;
  // isSeparado
  @Input() isSeparado: boolean = false;
  // size
  @Input() size: TSize = 'nm';
  //#endregion
}
