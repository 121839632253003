export function formataNCM(ncm: string): string {
  const NCM: string = (ncm ? `${ncm}` : '').replace(/\D/g, '');
  switch (NCM.length) {
    // case 9:
    //   if (NCM[5] === '-') {
    //     return NCM;
    //   } else {
    //     return '';
    //   } // else

    case 8:
      return `${NCM.substr(0, 4)}.${NCM.substr(4, 2)}.${NCM.substr(6)}`;

    default:
      return '';
  } // switch
}
