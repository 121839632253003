<div class="bg d-flex h-100">
  <div class="wrapper mx-auto my-5 d-flex flex-column">
    <div class="text-center">
      <img src="assets/img/notificacoes-bloqueadas.svg"
           alt="">
    </div>

    <div class="light my-5 text-center">
      <strong class="rem-20 o-80">
        Habilite as notificações do seu browser para poder habilitar esse terminal.
      </strong>
    </div>

    <div class="text-center">
      <img src="assets/img/browser-preview.svg"
           alt="">
    </div>

    <div class="text-center my-3">
      <button type="button"
              class="btn btn-block btn-lg"
              routerLink="/pedidos">
        Notificações habilitadas, tentar novamente.
      </button>
    </div>
  </div>
</div>
