//#region models
import { ICorFoneInfoRet } from '../../../_core/_misc/_models/_interfaces/_misc';
//#endregion

//#region libs
import { breakFone } from './break-fone';
//#endregion

export const fixFone = (fone: string): string => {
  if (fone) {
    const FONE: ICorFoneInfoRet | null = breakFone(fone);
    // console.log(FONE);
    return (FONE?.ddd || '') + (FONE?.number || '');
  } else {
    return '';
  } // else
};
