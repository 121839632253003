<!-- <h1>carregando pedidos</h1> -->
<div class="w-cards pedidos">
  <div class="pedido"
       *ngFor="let dummy of [].constructor(12)">
    <div class="p-4 flex-1">
      <ngx-skeleton-loader [count]="2"></ngx-skeleton-loader>
    </div>

    <div class="footer">
      <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
  </div>
</div>