//#region 3rd
// import { set } from 'lodash';
//#endregion

//#region models
import { IProduto } from '../_models/_interfaces/_cols';
import {
  ICarrinhoMap,
  IItemCarrinhoMap,
  IProdutoPromocaoMap,
} from '../_models/_interfaces/_maps';
import { TNullable } from '../../../_core/_misc/_models/_types';
// import {
//   ICarrinhoMap,
//   // IItemCarrinhoMap,
// } from '../_models/_interfaces/_maps';
//#endregion

//#region libs
// import { calcQtdeProdutoCarrinho } from './carrinho';
import { compareValues } from '../../../_libs/_misc/_arrays';
import { formataCurrency } from '../../../_libs/_misc/_formats';
//#endregion

export function calcQtdeFracionado( // ajustaQtdeFracionado
  produto: IProduto,
  qtde: number
): number {
  const FRACIONADO_CONVERTER: number = Number(produto?.fracionado?.unidade?._converter) || 0;
  const FRACIONADO_FRACAO: number = Number(produto?.fracionado?.unidade?.fracao) || 0;
  const FRACIONADO_STATUS: boolean = !!produto?.fracionado?._status;
  // console.log(FRACIONADO_CONVERTER, FRACIONADO_FRACAO, FRACIONADO_STATUS);
  if (!!FRACIONADO_STATUS) {
    return FRACIONADO_CONVERTER > 0
      ? FRACIONADO_FRACAO / FRACIONADO_CONVERTER * qtde
      : FRACIONADO_FRACAO * qtde;
  };
  return qtde;
}

// export function ajustaQtdeFracionadoAproxUn(
//   produto: Partial<IProduto>,
//   qtde: number
// ): number {
//   let ret: number = qtde;

//   if (
//     produto
//     && get(produto, 'fracionado.status')
//   ) {
//     const FRACAO: number = get(produto, '__qtdeCarrinho.tipoQtde') === 'F'
//       ? parseFloat(get(produto, 'fracionado.unidade.fracao')) || 0
//       : parseFloat(get(produto, 'fracionado.unidade.fracaoAproxUn')) || 0;
//     // const FRACAO: number = parseFloat(get(produto, 'fracionado.unidade.fracao')) || 0;
//     const CONVERTER: number = parseFloat(get(produto, 'fracionado.unidade._converter')) || 0;
//     ret = CONVERTER
//       ? FRACAO / CONVERTER * qtde
//       : FRACAO * qtde;
//   } // if
//   return parseFloat(ret.toFixed(3));
// }

export function isQtdeMin(
  produto: IProduto,
  qtde?: number
): boolean {
  const FIXED: number = calcQtdeFracionado(produto, Number(qtde) || 0);
  const QTDE_MIN: number = calcQtdeMin(produto as IProduto);
  // console.log(qtde, FIXED, QTDE_MIN);
  return FIXED === QTDE_MIN;
}

export function calcQtdeMin(produto: IProduto): number { // qtdeMin
  let ret: number = 1;
  const FRACIONADO: boolean = !!produto?.fracionado?._status;
  // const FRACAO: number = Number(get(produto, 'fracionado.unidade.fracao')) || 0;
  // const QTDE: number = !!FRACIONADO ? FRACAO : 1;
  if (!!FRACIONADO) {
    const FRACAO: number = Number(produto?.fracionado?.unidade?.fracao) || 0;
    const CONVERTER: number = Number(produto?.fracionado?.unidade?._converter) || 0;
    // console.log(FRACIONADO, FRACAO, CONVERTER);
    ret = !!CONVERTER
      ? FRACAO / CONVERTER
      : FRACAO;
  } // if
  // console.log(ret);
  return ret;
}

export function fixProdutos( // calcProdutos
  produtos: IProduto[],
  carrinho: ICarrinhoMap,
  // promocoes: Partial<IPromocao>[]
): void {
  const CARRINHO_ITENS: IItemCarrinhoMap[] = carrinho?.__solicitado?.itens || [];
  // if (!(produtos || []).length) { return []; }
  produtos
    .map(
      (p: IProduto) => {
        // console.log(p);
        if (!!p) {
          // console.log(p?.nome);
          // console.log(p?.levePague);
          const ITEM: TNullable<IItemCarrinhoMap> = CARRINHO_ITENS
            .find((i: Partial<IItemCarrinhoMap>) => i?.produto?.id === p?.id || null) || null;
          // console.log(p?.nome, ITEM);
          let promocoes: IProdutoPromocaoMap[] = [];

          const PRODUTO_PRECO: number = Number(p?.preco?.produto) || 0;

          // atacado?
          // console.log(p?.atacado);
          const ATACADO_STATUS: boolean = !!p?.atacado?.status;
          // console.log(ATACADO_STATUS);
          const ATACADO_PRECO: number = Number(p?.atacado?.preco) || 0;
          const ATACADO_QTDE: number = Number(p?.atacado?.qtde) || 0;
          // console.log(ATACADO_PRECO, ATACADO_QTDE);
          const ATACADO_IS_MIN: boolean = !!ATACADO_STATUS && isQtdeMin(p, ATACADO_QTDE);
          const ATACADO_LIQUIDO: number = !!ATACADO_IS_MIN ? PRODUTO_PRECO - ATACADO_PRECO : 0;
          const ATACADO_CAPTION: string = `${formataCurrency(ATACADO_PRECO)} a partir de ${ATACADO_QTDE} ${p?.fracionado?.unidade?._legenda || 'un'}`;
          // console.log(ATACADO_STATUS, ATACADO_QTDE, ATACADO_IS_MIN);
          let atacado: TNullable<IProdutoPromocaoMap> = !!ATACADO_STATUS
            ? {
              id: 'A',
              __aplicada: false,
              __atendida: !!ATACADO_IS_MIN,
              __caption: ATACADO_CAPTION,
              __produtoDesc: ATACADO_LIQUIDO,
              __itemDesc: 0,
              __descTot: ATACADO_LIQUIDO,
              __visivel: !ATACADO_IS_MIN,
            }
            : null;
          // console.log(atacado);

          // levePague?          
          p.__descItem = 0;
          const LP_STATUS: boolean = !!p?.levePague?.status;
          const LP_LEVE: number = Number(p?.levePague?.leve) || 0;
          // console.log(LP_LEVE);
          const LP_PAGUE: number = Number(p?.levePague?.pague) || 0;
          // console.log(LP_PAGUE);
          const LP_CAPTION: string = `Leve ${LP_LEVE}, pague ${LP_PAGUE}`;
          let levePague: TNullable<IProdutoPromocaoMap> = !!LP_STATUS
            ? {
              id: 'LP',
              __aplicada: false,
              __atendida: false,
              __caption: LP_CAPTION,
              __produtoDesc: 0,
              __itemDesc: 0,
              __descTot: 0,
              __visivel: true,
            }
            : null;

          if (!!ITEM) {
            const QTDE_CARRINHO: number = Number(ITEM?.__solicitado?.qtde?.val) || 0;
            const OBS_CARRINHO: string = ITEM?.obs || '';

            if (!!ATACADO_STATUS) {
              const ATACADO_ATENDIDA: boolean = !!ATACADO_IS_MIN || QTDE_CARRINHO >= ATACADO_QTDE;
              const ATACADO_DESC: number = PRODUTO_PRECO - ATACADO_PRECO;
              // console.log(PRODUTO_PRECO, ATACADO_PRECO, ATACADO_DESC);
              const ATACADO_PROD_DESC: number = !!ATACADO_ATENDIDA ? ATACADO_DESC : 0;
              // console.log(ATACADO_PROD_DESC);
              atacado = {
                id: 'A',
                __aplicada: false,
                __atendida: ATACADO_ATENDIDA,
                __caption: ATACADO_CAPTION,
                __produtoDesc: ATACADO_PROD_DESC,
                __itemDesc: 0,
                __descTot: ATACADO_PROD_DESC * QTDE_CARRINHO,
                __visivel: !ATACADO_IS_MIN,
              };
            } // if

            // levePague?
            // console.log(LP_STATUS);
            if (!!LP_STATUS) {
              const LP_NUM_ITENS_DESC: number = Math.trunc(LP_LEVE - LP_PAGUE);
              // console.log(LP_NUM_ITENS_DESC);          
              const LP_QTDE_DESC: number = !!LP_LEVE ? Math.trunc(QTDE_CARRINHO / LP_LEVE) : 0;
              // console.log(LP_QTDE_DESC);
              const LP_DESC: number = ((LP_NUM_ITENS_DESC * LP_QTDE_DESC) * PRODUTO_PRECO) || 0;
              levePague = {
                id: 'LP',
                __aplicada: false,
                __atendida: !!LP_QTDE_DESC,
                __caption: LP_CAPTION,
                __produtoDesc: 0,
                __itemDesc: LP_DESC,
                __descTot: LP_DESC,
                __visivel: true,
              };
            } // if

            // console.log(QTDE_CARRINHO, OBS_CARRINHO);
            p.__carrinho = {
              obs: OBS_CARRINHO,
              qtde: {
                fixed: calcQtdeFracionado(p, QTDE_CARRINHO),
                val: Number(QTDE_CARRINHO) || 0,
                isMin: isQtdeMin(p, QTDE_CARRINHO),
              },
            };
            // console.log(p?.__carrinho);
          } else {
            p.__carrinho = {
              obs: '',
              qtde: {
                val: 0,
                isMin: false,
              },
            };
          } // else

          !!atacado && promocoes.push(atacado);
          // console.table(atacado);
          !!levePague && promocoes.push(levePague);
          // console.table(levePague);
          promocoes = promocoes.sort(compareValues('__descTot', 'desc'));
          // console.log(promocoes);

          // console.table(promocoes[0]);
          let atendidaProdDesc: number = 0;
          if (
            !!promocoes
            && !!promocoes[0]?.__atendida
          ) {
            promocoes[0].__aplicada = true;
            atendidaProdDesc = promocoes?.[0]?.__produtoDesc || 0;
            p.__descItem = promocoes?.[0]?.__itemDesc || 0;
            // console.log(p.__descItem, promocoes?.[0]?.__itemDesc);
          } // if
          p.preco = {
            _liquido: PRODUTO_PRECO - atendidaProdDesc,
            desc: atendidaProdDesc,
            produto: PRODUTO_PRECO,
          };
          // console.table(p?.preco);
          // console.table(p?.__descItem);
          // console.log(promocoes);

          p.__promocoes = promocoes;
          // console.log(p?.preco);
        } // if

        return p || null;
      }
    );
}
