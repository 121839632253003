<!-- <p>pedido {{ pedido | json }}</p> -->
<!-- <p>searchTerm {{ searchTerm }}</p> -->

<ng-container *ngIf="vm$() | async as vm">

  <!-- <p>vm {{ vm | json }}</p> -->
  <!-- <p>vm?.itens {{ vm?.itens[0]?._separado?.qtde | json }}</p> -->

  <div *ngIf="!!vm?.itens?.length; else nenhumItem">
    <div *ngIf="!!isMobile; else desktopItens">
      <ul class="mobile">
        <li *ngFor="let i of vm?.itens; trackBy:tid_ite"
            class="d-flex flex-column p-relative"
            [class.off]="!!vm?.pedido?._isSeparado && !i?._separado?.qtde?.fixed">
          <div class="d-flex">
            <div class="thumb pointer">
              <img [src]="i?.produto?.__img?.thumb"
                   class="img-fluid">
            </div>

            <div class="flex-1 d-flex flex-column my-auto ms-2 info">
              <div>
                <small>{{ i?.produto?.nome }}</small>
                <small class="o-50"
                       *ngIf="i?.produto?._base?.embalagem">
                  ( {{ i?.produto?._base?.embalagem }} )
                </small>
              </div>

              <div *ngIf="!!i?.obs">
                <small class="o-50 italic mt-1">
                  Obs: {{ i?.obs }}
                </small>
              </div>

              <div>
                <small class="o-50 me-2">Solicitado</small>
                {{ i?.__solicitado?.qtde?.fixed | number : '1.0-3' }}
                <span>
                  {{ i?.produto?.fracionado?.unidade?._legenda || 'un' }}
                </span>

                <span *ngIf="!!vm?.hasSeparado">
                  <span class="mx-2 o-50">&bullet;</span>
                  <small class="o-50 me-2">Separado</small>
                  {{ i?._separado?.qtde?.fixed | number : '1.0-3' }}
                  <span>
                    {{ i?.produto?.fracionado?.unidade?._legenda || 'un' }}
                  </span>
                </span>
              </div>

              <div class="text-end">
                <me-html-item-carrinho-preco [item]="i"
                                             [isInline]="false"
                                             [isSeparado]="vm?.hasSeparado">
                </me-html-item-carrinho-preco>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <ng-template #desktopItens>
      <table class="desktop table table-striped">
        <thead>
          <tr>
            <th scope="col"
                class="row-img">
            </th>
            <th scope="col">Produto</th>
            <th scope="col"
                class="text-center">Solicitado</th>
            <th scope="col"
                class="text-center"
                *ngIf="!!vm?.hasSeparado">
              Separado
            </th>
            <th scope="col"
                class="text-end">Valor</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let i of vm?.itens; trackBy:tid_ite"
              [class.off]="!!vm?.pedido?._isSeparado && !i?._separado?.qtde?.fixed">
            <td class="row-img">
              <div class="thumb pointer">
                <img [src]="i?.produto?.__img?.thumb"
                     class="img-fluid">
              </div>
            </td>

            <td>
              <div>
                <small>{{ i?.produto?.nome }}</small>
                <small class="o-50"
                       *ngIf="i?.produto?._base?.embalagem">
                  ( {{ i?.produto?._base?.embalagem }} )
                </small>
              </div>

              <div *ngIf="!!i?.obs">
                <small class="o-50 italic mt-1">
                  Obs: {{ i?.obs }}
                </small>
              </div>
            </td>

            <td class="text-center">
              {{ i?.__solicitado?.qtde?.fixed | number : '1.0-3' }}
              <span>
                {{ i?.produto?.fracionado?.unidade?._legenda || 'un' }}
              </span>
            </td>

            <td class="text-center"
                *ngIf="!!vm?.hasSeparado">
              {{ i?._separado?.qtde?.fixed | number : '1.0-3' }}
              <span>
                {{ i?.produto?.fracionado?.unidade?._legenda || 'un' }}
              </span>
            </td>

            <td class="text-end">
              <me-html-item-carrinho-preco [item]="i"
                                           [isInline]="false"
                                           [isSeparado]="vm?.hasSeparado">
              </me-html-item-carrinho-preco>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
  <ng-template #nenhumItem>
    <div class="alert alert-light text-center"
         role="alert">
      Nenhum produto.
    </div>
  </ng-template>
</ng-container>