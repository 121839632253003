//#region ng
import {
  Component,
  EventEmitter,
  Output
} from '@angular/core';
//#endregion

@Component({
  selector: 'cor-html-mapa-brasil',
  templateUrl: './mapa-brasil.component.html',
  styleUrls: ['./mapa-brasil.component.scss']
})
export class CorHtmlMapaBrasilComponent {
  //#region outputs
  @Output() select$ = new EventEmitter<string>();
  //#endregion

  // https://raw.githubusercontent.com/felipeduardo/mapa-brasil-svg/master/index.html
}
