//#region ng
import { Injectable } from '@angular/core';
//#endregion

//#region models
import { PHONE_AVATAR } from '../../../_core/_misc/_models/consts';
import { ICorFbUserApi } from '../../_misc/_models/_interfaces/_apis';
//#endregion

//#region libs
import { avatar } from '../../../_libs/_misc/_ui-avatar';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CorFbUserService {
  //#region misc
  fix(row: Partial<ICorFbUserApi>): ICorFbUserApi {
    // console.log(row);
    const DISPLAY_NAME: string = row?.displayName || '';
    const EMAIL: string = row?.email || '';
    const PROVIDER_DATA: any = row?.providerData || null;

    return {
      // id
      uid: row?.uid || '',

      // user
      // disabled: !!row?.disabled,
      displayName: DISPLAY_NAME,
      email: EMAIL,
      emailVerified: !!row?.emailVerified,
      phoneNumber: row?.phoneNumber || '',
      photoURL: row?.photoURL || DISPLAY_NAME || EMAIL
        ? avatar({
          name: DISPLAY_NAME || EMAIL
        })
        : PHONE_AVATAR,
      providerData: PROVIDER_DATA,
      type: !!PROVIDER_DATA ? PROVIDER_DATA?.[0]?.providerId : null,
    };
  }

  fixes(docs: Partial<ICorFbUserApi>[]): ICorFbUserApi[] {
    return (docs || []).map((doc: Partial<ICorFbUserApi>) => this.fix(doc));
  }
  //#endregion
}
