//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region models
import { IProduto } from '../../../_misc/_models/_interfaces/_cols';
import { ProdutosService } from '../../../_ng/_services';
import { IProImgProdutoMap } from '../../../../_produtos/_misc/_models/_interfaces/_maps';
//#endregion

//#region services
import { TNullable } from '../../../../_core/_misc/_models/_types';
//#endregion

@Component({
  selector: 'me-mat-produto-imgs',
  templateUrl: './produto-imgs.component.html',
  styleUrls: ['./produto-imgs.component.scss']
})
export class MeMatProdutoImgsComponent {
  //#region inputs
  // produto
  #produto: TNullable<IProduto>;
  get produto(): TNullable<IProduto> { return this.#produto; }
  @Input({ required: true }) set produto(val: TNullable<IProduto>) {
    this.#produto = null;
    this.imgSel.set(null);
    // console.log(val);
    !!val && this.#produtosServ.lfix(val)
      .then((p: IProduto) => {
        // console.log(p);
        this.#produto = p;
        this.imgSel.set(p?.__imgs?.[0] || p?.__img || null);
      });
  };
  // editable
  @Input() editable: boolean = false;
  // isMobile
  @Input() isMobile: boolean = false;
  //#endregion

  //#region outputs
  @Output() add$ = new EventEmitter<void>();
  @Output() del$ = new EventEmitter<IProImgProdutoMap>();
  @Output() left$ = new EventEmitter<IProImgProdutoMap>();
  @Output() right$ = new EventEmitter<IProImgProdutoMap>();
  //#endregion

  //#region publics
  imgSel = signal<IProImgProdutoMap>(null);
  //#endregion

  //#region injects
  #produtosServ = inject(ProdutosService);
  //#endregion

  //#region functions
  tid_img(index: any, item: IProImgProdutoMap): string { return item?.id || ''; }
  //#endregion
}
