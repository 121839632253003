<ng-container *ngIf="vm$() | async as vm">
  <div class="container-fluid">
    <div class="d-flex">
      <div class="col search">
        <mat-form-field appearance="outline"
                        class="w-100">
          <mat-label>Nome</mat-label>
          <button mat-button
                  matPrefix
                  mat-icon-button>
            <mat-icon class="o-50">search</mat-icon>
          </button>
          <input matInput
                 type="text"
                 [focus]="searchFocusEvent$()"
                 [ngModel]="searchTermAction$() | async"
                 (ngModelChange)="searchTermAction$()?.next($event)">
          <button mat-button
                  *ngIf="!!(searchTermAction$() | async)"
                  matSuffix
                  mat-icon-button
                  (click)="searchTermAction$()?.next('')">
            <mat-icon class="o-50">close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.integradoras?.length && !itemsPerPage">
        <cor-mat-pag-sel-offset size="lg"
                                [storageKey]="offsetStorageKey"
                                (change$)="pagItemsPerPageAction$().next($event)" />
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.integradoras?.length">
        <cor-mat-ngx-pagination-nav pagId="integradoras"
                                    size="lg"
                                    [isMobile]="true"
                                    (pageChange$)="pagPageAction$().next($event)" />
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.integradoras?.length">
        <cor-mat-viewstyle-sel size="lg"
                               [storageKey]="viewStyleStorageKey"
                               (change$)="viewStyleAction$().next($event)" />
      </div>
    </div>

    <!-- <p>vm {{ vm | json }}</p> -->
    <!-- <p>vm {{ vm?.searchTerm | json }}</p> -->
    <!-- <p>checkBtnHint {{ checkBtnHint| json }}</p> -->
    <!-- <p>deleteBtnHint {{ deleteBtnHint| json }}</p> -->
    <!-- <p>editBtnHint {{ editBtnHint| json }}</p> -->
    <!-- <p>searchBtnHint {{ searchBtnHint| json }}</p> -->
    <!-- <p>hasButtons {{ hasButtons| json }}</p> -->
    <!-- <p>idSelected {{ idSelected| json }}</p> -->

    <div class="row">
      <div class="col">
        <div class="w-cards integradoras"
             *ngIf="vm?.viewStyle === 'cards'; else viewTable">
          <div
               *ngFor="let i of (vm?.integradoras | paginate: { id: 'integradoras', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: vm?.integradoras?.length } | integradorasLfixes) | async; trackBy:tid_int">
            <me-mat-integradora-card [integradora]="i"
                                     [isSelected]="i?.id === idSelected"
                                     [checkBtnHint]="checkBtnHint"
                                     [deleteBtnHint]="deleteBtnHint"
                                     [editBtnHint]="editBtnHint"
                                     [searchBtnHint]="searchBtnHint"
                                     (check$)="check$.emit($event)"
                                     (delete$)="delete$.emit($event)"
                                     (edit$)="edit$.emit($event)"
                                     (search$)="search$.emit($event)" />
          </div>
        </div>
        <ng-template #viewTable>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>

                <th scope="col"
                    class="text-center">
                  Ativo
                </th>

                <th scope="col">Nome / e-mail</th>

                <th scope="col"
                    *ngIf="hasButtons()">
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let i of (vm?.integradoras | paginate: { id: 'integradoras', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: vm?.integradoras?.length } | integradorasLfixes) | async; trackBy:tid_int"
                  [class.off]="!i?.ativo?._status"
                  [class.sel]="i?.id === idSelected">
                <td>
                  <img [src]="i?.__logo"
                       alt=""
                       class="img-fluid">
                </td>

                <td class="text-center">
                  <cor-html-status-checkbox [status]="i?.ativo?._status" />
                </td>

                <td>
                  {{ i?.nome }}<br>
                  <small class="o-50">{{ i?.email }}</small>
                </td>

                <td *ngIf="hasButtons()">
                  <div class="d-flex justify-content-around">
                    <button mat-icon-button
                            [matTooltip]="checkBtnHint"
                            (click)="check$.emit(i)"
                            *ngIf="!!checkBtnHint">
                      <mat-icon>check</mat-icon>
                    </button>

                    <button mat-icon-button
                            [matTooltip]="searchBtnHint"
                            (click)="search$.emit(i)"
                            *ngIf="!!searchBtnHint">
                      <mat-icon>search</mat-icon>
                    </button>

                    <button mat-icon-button
                            [matTooltip]="editBtnHint"
                            (click)="edit$.emit(i)"
                            *ngIf="!!editBtnHint">
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-icon-button
                            [matTooltip]="deleteBtnHint"
                            (click)="delete$.emit(i)"
                            *ngIf="!!deleteBtnHint">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>

        <div class="alert alert-light text-center"
             role="alert"
             *ngIf="!vm?.integradoras?.length">
          Nenhuma integradora.
        </div>
      </div>
    </div>
  </div>
</ng-container>