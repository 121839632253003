//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region libs
import { formataTelefone } from '../../../_libs/_misc/_formats';
//#endregion

@Pipe({ name: 'fone' })
export class CorFormataFone implements PipeTransform {
  transform(value: string): string {
    return formataTelefone(value);
  }
}
