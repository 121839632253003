//#region models
import { isCpf } from '../_validations';
//#endregion

export function formataCPF(cpf: string): string {
  if (isCpf(cpf)) {
    //retira os caracteres indesejados...
    const CPF: string = cpf.replace(/[^\d]/g, '');
    //realizar a formatação...
    return CPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } // if
  return '';
}
