
//#region ng
import { Injectable } from "@angular/core";
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  of
} from 'rxjs';
import {
  concatMap,
  finalize,
  tap,
} from 'rxjs';
//#endregion

@Injectable({
  providedIn: "root"
})
export class CorLoaderService {
  //#region events
  #loadingSubject$ = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.#loadingSubject$?.asObservable();
  //#endregion

  //#region privates
  // private #timeout: any;
  //#endregion

  //#region methods
  showUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null)
      .pipe(
        tap(() => {
          // console.log('showUntilCompleted.lstart();');
          this.lstart();
        }),
        concatMap(() => obs$),
        finalize(() => {
          // console.log('showUntilCompleted.lstop();');
          this.lstop();
        })
      );
  }

  lstart() {
    this.#loadingSubject$.next(true);

    // !!this.#timeout && clearTimeout(this.#timeout);
    // this.#timeout = setTimeout(
    //   () => { this.#loadingSubject$.next(true); },
    //   1000
    // );
  }

  lstop() {
    this.#loadingSubject$.next(false);

    // !!this.#timeout && clearTimeout(this.#timeout);
  }
  //#endregion
}
