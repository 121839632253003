<!-- <p>cardForm?.value {{ cardForm?.value | json }}</p> -->
<!-- <p>cardForm?.valid {{ cardForm?.valid }}</p> -->
<!-- <p>cards {{ cards | json }}</p> -->
<!-- <p>flag {{ flag }}</p> -->

<form novalidate
      [formGroup]="cardForm()"
      (ngSubmit)="submit$.emit(cardForm()?.value)"
      *ngIf="!!cardForm()">
  <div class="row">
    <div class="col pb-2">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Nome titular</mat-label>
        <input type="text"
               matInput
               formControlName="nomeTitular"
               [focus]="nomeFocusEvent$()"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(nomeTitularRef)">
          <span class="danger"> {{ fv()?.error(nomeTitularRef) }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col pb-2">
      <mat-form-field class="w-100 mb-2 p-relative nro-cartao"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Número do cartão</mat-label>
        <input type="text"
               matInput
               formControlName="nroCartao"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(nroCartaoRef) || nroCartaoRef?.errors?.['card']">
          <span class="danger"> {{ fv()?.error(nroCartaoRef) || 'Número inválido.' }} </span>
        </mat-hint>
        <img [src]="cards()[flag()].img"
             *ngIf="!!flag()">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 pb-2 col-sm-4">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Mês vencimento</mat-label>
        <mat-select formControlName="mesVcto">
          <mat-option value="">-- Informe --</mat-option>
          <mat-option value="1">Janeiro</mat-option>
          <mat-option value="2">Fevereiro</mat-option>
          <mat-option value="3">Março</mat-option>
          <mat-option value="4">Abril</mat-option>
          <mat-option value="5">Maio</mat-option>
          <mat-option value="6">Junho</mat-option>
          <mat-option value="7">Julho</mat-option>
          <mat-option value="8">Agosto</mat-option>
          <mat-option value="9">Setembro</mat-option>
          <mat-option value="10">Outubro</mat-option>
          <mat-option value="11">Novembro</mat-option>
          <mat-option value="12">Dezembro</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12 pb-2 col-sm-4">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>Ano vencimento</mat-label>
        <input type="number"
               matInput
               formControlName="anoVcto"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(anoVctoRef)">
          <span class="danger"> {{ fv()?.error(anoVctoRef) }} </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12 pb-2 col-sm-4">
      <mat-form-field class="w-100 mb-2"
                      [appearance]="isMobile ? 'fill' : 'outline'">
        <mat-label>CVC</mat-label>
        <input type="text"
               matInput
               formControlName="cvc"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(cvcRef) || cvcRef?.errors?.['cvc']">
          <span class="danger"> {{ fv()?.error(cvcRef) || 'Valor inválido.' }} </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row mt-2"
       *ngIf="!!submitButtonCaption">
    <div class="col d-flex">
      <button type="submit"
              class="pointer flex-1"
              mat-raised-button
              color="primary"
              [disabled]="!cardForm().valid">
        {{ submitButtonCaption }}
      </button>
    </div>
  </div>
</form>