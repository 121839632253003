//#region ng
import {
  Component,
  inject,
  Input,
} from '@angular/core';
//#endregion

//#region models
import { IPedido } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region models
import { PedidosService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-pedido-info-totais',
  templateUrl: './pedido-info-totais.component.html',
  styleUrls: ['./pedido-info-totais.component.scss']
})
export class MeMatPedidoInfoTotaisComponent {
  //#region inputs
  // pedido
  #pedido: IPedido;
  get pedido(): IPedido { return this.#pedido; }
  @Input({ required: true }) set pedido(val: IPedido) {
    this.#pedido = !!val ? this.#pedidosServ.fix(val) : null;
  }
  //#endregion

  //#region injects
  #pedidosServ = inject(PedidosService);
  //#endregion
}
