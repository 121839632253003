<!-- <p>{{ produto?.preco | json }}</p> -->

<div *ngIf="!!produto">
  <!-- <div *ngIf="produto?.preco !== produto?._promocaoAutomatica?.preco; else precoNormal">
    <span class="preco-normal">
      {{ produto?.preco | currency: "BRL":"symbol":"1.2-2" }}
      <small *ngIf="produto?.fracionado?.status">{{ produto?.fracionado?.unidade?._legenda }}</small>
    </span>
    <h5 [class.inline]="inline">
      {{ produto?._promocaoAutomatica?.preco | currency: "BRL":"symbol":"1.2-2" }}
      <small *ngIf="produto?.fracionado?.status">{{ produto?.fracionado?.unidade?._legenda }}</small>
    </h5>
  </div> -->
  <!-- <ng-template #precoNormal> -->
  <h5 [class.rem-20]="size === 'lg'">
    <small class="o-50 line-thru"
           *ngIf="produto?.preco?.desc">
      {{ produto?.preco?.produto | currency: "BRL":"symbol":"1.2-2" }}
    </small>
    {{ produto?.preco?._liquido | currency: "BRL":"symbol":"1.2-2" }}
    <small *ngIf="produto?.fracionado?._status">{{ produto?.fracionado?.unidade?._legenda }}</small>
  </h5>
  <!-- </ng-template> -->
</div>
