//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region models
import {
  DIAS_SEMANA_NOME_CURTO,
  DIAS_SEMANA_NOME_LONGO
} from '../../_misc/_models/consts';
//#endregion

@Pipe({ name: 'dow' })
export class CorDowPipe implements PipeTransform {
  transform(value: number, long: boolean = true): string {
    if (value >= 0 && value <= 6) {
      const A: string[] = long ? DIAS_SEMANA_NOME_LONGO : DIAS_SEMANA_NOME_CURTO;
      return A[value];
    } // if
    return '';
  }
}
