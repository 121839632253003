//#region ng
import { Injectable } from '@angular/core';
//#endregion

//#region models
import { IUf } from '../../_misc/_models/_interfaces/_cols';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class UfsService {
  //#region misc
  fix(row: Partial<IUf>): IUf {
    return {
      // id
      id: row?.id || '',

      // system
      _criadoEm: row?._criadoEm || null,
      _nome: row?._nome || '',
      _qtde: {
        localidades: Number(row?._qtde.localidades) || 0,
      },
    };
  }

  fixes(docs: Partial<IUf>[]): IUf[] {
    return (docs || []).map((doc: Partial<IUf>) => this.fix(doc));
  }
  //#endregion
  
}