//#region ng
import {
  Component,
  Input
} from "@angular/core";
//#endregion

//#region models
import { ICorViacepApi } from "../../../_misc/_models/_interfaces/_apis/viacep";
//#endregion

@Component({
  selector: "cor-html-endereco",
  templateUrl: "endereco.component.html",
  styleUrls: ["endereco.component.scss"]
})
export class CorHtmlEnderecoComponent {
  //#region comm
  @Input({ required: true }) endereco: ICorViacepApi;
  //#endregion
}
