<!-- <p>loja?.meilisearch {{ loja?.meilisearch | json }}</p> -->
<!-- <p>lojaPrivate() {{ lojaPrivate() | json }}</p> -->

<ng-container *ngIf="!!loja">
  <div class="row mt-4">
    <div class="col-6">
      <ul class="list-group">
        <li class="list-group-item list-group-item-secondary">
          Loja
        </li>

        <li class="list-group-item">
          <span class="o-50">ID loja</span><br />
          <small>{{ loja?.id || '-' }}</small>
        </li>

        <li class="list-group-item">
          <span class="o-50">Nome</span><br />
          <small>{{ loja?.nome || '-' }}</small>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <div class="o-50">Rede</div>
          <small>{{ loja?.rede?._nome || '-' }}</small>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Entrega status</span>

          <small>{{ lojaEntregaStatus()?.[loja?.entrega?.status] }}</small>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Parceiro</span>
          <small>{{ loja?.parceiro?._nome || '-' }}</small>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Integradora</span>
          <small>{{ loja?.integradora?._nome || '-' }}</small>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Tipo</span>
          <small>{{ loja?.tipo?._nome || '-' }}</small>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">CPF / CNPJ</span>
          <small>{{ loja?.cpfCnpj | cpfCnpj }}</small>
        </li>

        <li class="list-group-item list-group-item-secondary d-flex justify-content-between">
          Distribuidora

          <cor-html-status-checkbox [status]="!!loja?.distribuidora?.status"></cor-html-status-checkbox>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Tipo rota</span>

          <small>{{ lojaDistribuidoraTipoRota()?.[loja?.distribuidora?.tipoRota] }}</small>
        </li>

        <li class="list-group-item list-group-item-secondary">
          Meilisearch
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Nome</span>

          <small>{{ loja?.meilisearch?.nome || '-' }}</small>
        </li>

        <li class="list-group-item">
          <span class="o-50">Host</span><br />
          <small>{{ loja?.meilisearch?.host || '-' }}</small>
        </li>
      </ul>
    </div>

    <div class="col-6">
      <ul class="list-group">
        <li class="list-group-item list-group-item-secondary">
          Faturamento
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Código</span>
          <small>{{ loja?.faturamento?.cod || '-' }}</small>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Mensalidade</span>
          <small>{{ loja?.faturamento?.mensalidade | currency: "BRL":"symbol":"1.2-2" }}</small>
        </li>

        <li class="list-group-item list-group-item-secondary">
          Taxa serviços
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Valor</span>
          <small>{{ loja?.faturamento?.servicos?.valor | currency: "BRL":"symbol":"1.2-2" }}</small>
        </li>

        <li class="list-group-item d-flex justify-content-between">
          <span class="o-50">Piso pedidos</span>
          <small>{{ loja?.faturamento?.servicos?.piso || '-' }}</small>
        </li>

        <li class="list-group-item list-group-item-secondary">
          Contato
        </li>

        <li class="list-group-item">
          <span class="o-50">Fones</span><br />
          <small *ngIf="!!loja?.fones?.celular">
            {{ loja?.fones?.celular | fone }} <span class="o-50">( celular )</span><br />
          </small>
          <small *ngIf="!!loja?.fones?.fixo">
            {{ loja?.fones?.mensagens | fone }} <span class="o-50">( fixo )</span><br />
          </small>
          <small *ngIf="!!loja?.fones?.mensagens">
            {{ loja?.fones?.mensagens | fone }} <span class="o-50">( mensagens )</span>
          </small>
        </li>

        <li class="list-group-item list-group-item-secondary">
          Endereço
        </li>

        <li class="list-group-item">
          <small class="o-50">
            <cor-bs-endereco [endereco]="loja?.endereco"></cor-bs-endereco>
          </small>
        </li>

        <div *ngIf="!!lojaPrivate()?._token?.val">
          <li class="list-group-item list-group-item-secondary d-flex">
            <div class="my-auto">
              Token de loja
            </div>

            <div class="ms-auto">
              <button type="button"
                      matTooltip="Copiar token"
                      class="btn btn-light"
                      (click)="onCopiarTokenClick()">
                <span class="icon-o-copy"></span>
              </button>

              <button type="button"
                      matTooltip="{{ tokenVisible() ? 'Ocultar ' : 'Visualizar ' }} token"
                      class="btn btn-light"
                      (click)="onTokenVisibleSwap()">
                <span [class]="tokenVisible() ? 'icon-r-eye-blocked' : 'icon-r-eye'"></span>
              </button>
            </div>
          </li>

          <li class="list-group-item">
            <small class="o-50 token">
              {{ tokenVisible() ? lojaPrivate()?._token?.val : '*'.repeat(lojaPrivate()?._token?.val.length) }}
            </small>
          </li>
        </div>

      </ul>
    </div>
  </div>
</ng-container>