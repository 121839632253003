/*
groupBy(
  vehicle => vehicle.make,
  [
    { make: 'tesla', model: '3' },
    { make: 'tesla', model: 'y' },
    { make: 'ford', model: 'mach-e' },
  ]
);

{ 
  tesla: [...],
  ford: [...],
}
*/
export const grouptBy = (fn: any, list: any[] = []) => (
  (list || [])
    .reduce(
      (prev, next) => (
        {
          ...prev,
          [fn(next)]: [...(prev[fn(next)] || []), next],
        }
      ),
      {}
    )
);
