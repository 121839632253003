export * from './_galeria-get/galeria-get.component';
export * from './_integradora-card/integradora-card.component';
export * from './_integradora-form/integradora-form.component';
export * from './_integradoras/integradoras.component';
export * from './_loja-card/loja-card.component';
export * from './_loja-extras-form/loja-extras-form.component';
export * from './_loja-info/loja-info.component';
export * from './_lojas/lojas.component';
// export * from './_lojas-refs/lojas-refs.component'; 
export * from './_meilisearchs/meilisearchs.component';
export * from './_mov-pedido-itens/mov-pedido-itens.component';
export * from './_mov-pedidos-chart/mov-pedidos-chart.component';
export * from './_notificacoes/notificacoes.component';
export * from './_operador-actions/operador-actions.component';
// export * from './_operador-form/operador-form.component';
export * from './_pedido-card/pedido-card.component';
export * from './_pedido-info/pedido-info.component';
export * from './_pedido-info-detalhes/pedido-info-detalhes.component';
export * from './_pedido-info-totais/pedido-info-totais.component';
export * from './_produto-imgs/produto-imgs.component';
export * from './_produto-info/produto-info.component';
export * from './_rede-card/rede-card.component';
export * from './_rede-form/rede-form.component';
export * from './_redes/redes.component';
export * from './_tipos-lojas/tipos-lojas.component';
