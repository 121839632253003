//#region angular
import {
  Injectable,
} from '@angular/core';
//#endregion

//#region models
import { KEY_EMAIL_LOGIN } from '../../../_core/_misc/_models/consts';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CorStorageService {
  //#region privates
  #log: boolean = false;
  #wsOk: boolean = false;
  #key_email_login: string;
  //#endregion

  //#region constructor
  constructor() {
    // this._idModulo = idModulo;
    this.#key_email_login = `${KEY_EMAIL_LOGIN}_core`;
    this.#wsOk = typeof (Storage) !== 'undefined';
  }
  //#endregion

  //#region sessionStorage
  ss_get(
    key: string,
    defaultValue: any = null
  ): any {
    let a: any;
    if (this.#wsOk) {
      a = sessionStorage.getItem(key);
      if (a === null) {
        if (this.#log) console.log(`ss_get.${key}: ${a}`);
        return defaultValue;
      } else {
        // a = JSON.parse(a);
        // a = JSON.parse(unescape(atob(a)));
        if (this.#log) console.log(`ss_get.${key}: ${a}`);
        return a;
      } // else
    } else {
      return defaultValue;
    }; // else
  }

  ss_set(
    key: string,
    value: any
  ): boolean {
    // value = JSON.stringify(value);
    // value = btoa(escape(JSON.stringify(value)));
    if (this.#wsOk) {
      sessionStorage.setItem(key, value);
      if (this.#log) console.log(`ss_set.${key}: ${value}`);
      return true;
    } else {
      return false;
    }; // else
  }

  ss_remove(
    key: string
  ): boolean {
    if (this.#wsOk) {
      sessionStorage.removeItem(key);
      if (this.#log) console.log(`ss_remove.${key}`);
      return true;
    } else {
      return false;
    }; // else
  }
  //#endregion

  //#region localStorage
  ls_get(
    key: string,
    defaultValue: any = null
  ): any {
    let a: any;
    if (this.#wsOk) {
      a = localStorage.getItem(key);
      if (a === null) {
        if (this.#log) console.log(`ls_get.${key}: ${a}`);
        return defaultValue;
      } else {
        // a = JSON.parse(a);
        // a = JSON.parse(unescape(atob(a)));
        if (this.#log) console.log(`ls_get.${key}: ${a}`);
        return a;
      } // else
    } else {
      return defaultValue;
    }; // else
  }

  ls_set(
    key: string,
    value: any
  ): boolean {
    // value = JSON.stringify(value);
    // value = btoa(escape(JSON.stringify(value)));
    if (this.#wsOk) {
      localStorage.setItem(key, value);
      if (this.#log) console.log(`ls_set.${key}: ${value}`);
      return true;
    } else {
      return false;
    }; // else
  }

  ls_remove(
    key: string
  ): boolean {
    if (this.#wsOk) {
      localStorage.removeItem(key);
      if (this.#log) console.log(`ls_remove.${key}`);
      return true;
    } else {
      return false;
    }; // else
  }
  //#endregion

  //#region methods KEY_EMAIL_LOGIN
  emailLoginGet(): string {
    return this.ls_get(this.#key_email_login);
  }

  emailLoginSet(emailLogin: string): string {
    if (!!emailLogin) {
      this.ls_set(
        this.#key_email_login,
        emailLogin
      );
      return this.emailLoginGet();
    } else {
      return this.emailLoginRemove();
    } // else
  }

  emailLoginRemove(): string {
    this.ls_remove(this.#key_email_login);
    return this.emailLoginGet();
  }
  //#endregion
}
