//#region misc
export const HOME_ROUTE: string = '/pedidos';
export const ID_MODULO: string = 'mer_monitor_www';
export const THEME: string = 'warning';
export const PEDIDOS_POR_PAG: string = '9';
//#endregion

//#region storage
export const KEY_AUDIO_ID: string = `AUDIO_ID_${ID_MODULO}`.toLowerCase();
export const KEY_LOJA_ID: string = `LOJA_ID_${ID_MODULO}`.toLowerCase();
export const KEY_SEPARANDO_VIEWSTYLE: string = `SEPARANDO_VIEWSTYLE_${ID_MODULO}`.toLowerCase();
export const KEY_SPLIT_VERTICAL: string = `SPLIT_VERTICAL_${ID_MODULO}`.toLowerCase();
export const KEY_SHOW_SIDEBAR: string = `SHOW_SIDEBAR_${ID_MODULO}`.toLowerCase();
export const KEY_OFFSET_PEDIDOS: string = `OFFSET_PEDIDOS_${ID_MODULO}`.toLowerCase();
//#endregion

//#region audios
export interface IAudioNotification {
  id: string;
  name: string;
  src: string;
};
export const AUDIOS: IAudioNotification[] = [
  { id: 'none', name: "-- Nenhum --", src: '' },
  { id: 'short-phone-ring', name: "Telefone curto", src: 'short-phone-ring.mp3' },
  { id: 'long-phone-ring', name: "Telefone longo", src: 'long-phone-ring.mp3' },
  { id: 'air-zoom-vacuum', name: "Flash", src: 'air-zoom-vacuum.mp3' },
  { id: 'fast-transition', name: "Transição rápida", src: 'fast-transition.mp3' },
  { id: 'laser', name: "Laser", src: 'laser.mp3' },
  { id: 'laser-2', name: "Laser 2", src: 'laser-2.mp3' },
  { id: 'retro-game-over', name: "Game", src: 'retro-game-over.mp3' },
  { id: 'retro-game', name: "Game over", src: 'retro-game.mp3' },
  { id: 'toy-whistle', name: "Apito de brinquedo", src: 'toy-whistle.mp3' },
  { id: 'sad-trombone', name: "Trombone triste", src: 'sad-trombone.mp3' },
  { id: 'phone-off-hook', name: "Telefone caiu", src: 'phone-off-hook.mp3' },
  { id: 'dog-barking-twice', name: "Duplo latido", src: 'dog-barking-twice.mp3' },
];
//#endregion
