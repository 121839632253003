//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import Fuse from 'fuse.js';
import {
  combineLatest,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  integradoras: IIntegradora[];
  pag: {
    itemsPerPage: string;
    page: number;
  };
  viewStyle: TCorViewStyle;
};
import { IIntegradora } from '../../../_misc/_models/_interfaces/_cols';
import { TCorViewStyle } from '../../../../_core/_misc/_models/_types';
//#endregion

//#region libs
import { onDestroy } from '../../../../_core/_ng/_libs';
//#endregion

//#region services
import { IntegradorasService } from '../../../_ng/_services';
import { FOCUS_TIMEOUT } from '../../../../_core/_misc/_models/consts';
import { compareValues } from '../../../../_libs/_misc/_arrays';
//#endregion

@Component({
  selector: 'me-mat-integradoras',
  templateUrl: './integradoras.component.html',
  styleUrls: ['./integradoras.component.scss']
})
export class MeMatIntegradorasComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #hitsAction$ = new Subject<IIntegradora[]>();
  pagPageAction$ = signal<Subject<number>>(new Subject<number>()).asReadonly();
  pagItemsPerPageAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  searchTermAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  viewStyleAction$ = signal<Subject<TCorViewStyle>>(new Subject<TCorViewStyle>()).asReadonly();
  //#endregion

  //#region inputs
  // integradoras
  #integradoras: IIntegradora[];
  get integradoras(): IIntegradora[] { return this.#integradoras; }
  @Input({ required: true }) set integradoras(val: IIntegradora[]) {
    this.#integradoras = !!val ? this.#integradorasServ.fixes(val) : [];
    this.#refresh('');
    this.focus();
  }
  // viewStyleStorageKey
  @Input() viewStyleStorageKey: string = 'KEY_VIEWSTYLE#INTEGRADORAS';
  // offsetStorageKey
  @Input() offsetStorageKey: string = 'KEY_OFFSET#INTEGRADORAS';
  // itemsPerPage
  @Input() itemsPerPage: string = '';
  // idSelected
  @Input() idSelected: string = '';
  // checkBtnHint
  #checkBtnHint: string = '';
  get checkBtnHint(): string { return this.#checkBtnHint; }
  @Input() set checkBtnHint(val: string) {
    this.#checkBtnHint = val;
    this.#verificaBotoes();
  }
  // deleteBtnHint
  #deleteBtnHint: string = '';
  get deleteBtnHint(): string { return this.#deleteBtnHint; }
  @Input() set deleteBtnHint(val: string) {
    this.#deleteBtnHint = val;
    this.#verificaBotoes();
  }
  // editBtnHint
  #editBtnHint: string = '';
  get editBtnHint(): string { return this.#editBtnHint; }
  @Input() set editBtnHint(val: string) {
    this.#editBtnHint = val;
    this.#verificaBotoes();
  }
  // searchBtnHint
  #searchBtnHint: string = '';
  get searchBtnHint(): string { return this.#searchBtnHint; }
  @Input() set searchBtnHint(val: string) {
    this.#searchBtnHint = val;
    this.#verificaBotoes();
  }
  //#endregion

  //#region outputs
  @Output() check$ = new EventEmitter<IIntegradora>();
  @Output() delete$ = new EventEmitter<IIntegradora>();
  @Output() edit$ = new EventEmitter<IIntegradora>();
  @Output() search$ = new EventEmitter<IIntegradora>();
  //#endregion

  //#region publics
  // searchTerm = signal<string>('');
  hasButtons = signal<boolean>(false);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  //#endregion

  //#region injects
  #integradorasServ = inject(IntegradorasService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.searchTermAction$()
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchTerm: string) => { this.#refresh(searchTerm); return of(null); }),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    this.vm$.set(
      combineLatest([
        // this.searchTermAction$().pipe(startWith('')),
        this.#hitsAction$.pipe(startWith([])),
        this.viewStyleAction$().pipe(startWith('cards')),
        this.pagPageAction$().pipe(startWith(1)),
        this.pagItemsPerPageAction$().pipe(startWith(this.itemsPerPage || '12')),
      ])
        .pipe(
          map(([hits, viewStyle, pagPage, pagItemsPerPage]) => {
            // console.log(hits);
            const VM: IVm = {
              integradoras: hits || [],
              pag: {
                itemsPerPage: pagItemsPerPage,
                page: pagPage
              },
              viewStyle: viewStyle as TCorViewStyle,
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  #refresh(searchTerm: string) {
    if (!!searchTerm.trim()) {
      const FUSE: any = new Fuse(
        this.integradoras,
        {
          includeScore: false,
          keys: [
            'nome',
          ],
          // minMatchCharLength: 3,
        }
      );
      const HITS: any[] = FUSE.search(searchTerm);
      // console.log(HITS);
      this.#hitsAction$.next(
        (this.#integradorasServ.fixes((HITS || [])
          .map(h => h?.item as IIntegradora)) as IIntegradora[])
          .sort(compareValues('nome'))
      );
    } else {
      this.#hitsAction$.next(
        this.#integradorasServ.fixes(this.integradoras)
          .sort(compareValues('nome')) as IIntegradora[]
      );
    } // else
  }

  #verificaBotoes() {
    this.hasButtons.set(!!`${this.#checkBtnHint.trim()}${this.#deleteBtnHint.trim()}${this.#editBtnHint.trim()}${this.#searchBtnHint.trim()}`);
  };

  focus() {
    setTimeout(
      () => { this.searchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  tid_int(index: any, item: IIntegradora): string { return item?.id || ''; }
  //#endregion
}
