//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region models
import { IRede } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

@Component({
  selector: 'me-mat-rede-card',
  templateUrl: './rede-card.component.html',
  styleUrls: ['./rede-card.component.scss']
})
export class MeMatRedeCardComponent {
  //#region inputs
  // rede
  @Input({ required: true }) rede: IRede;
  // showTheme
  @Input() showTheme: boolean = false;
  // addBtnHint
  #addBtnHint: string = '';
  get addBtnHint(): string { return this.#addBtnHint; }
  @Input() set addBtnHint(val: string) {
    this.#addBtnHint = val;
    this.#verificaBotoes();
  }
  // checkBtnHint
  #checkBtnHint: string = '';
  get checkBtnHint(): string { return this.#checkBtnHint; }
  @Input() set checkBtnHint(val: string) {
    this.#checkBtnHint = val;
    this.#verificaBotoes();
  }
  // deleteBtnHint
  #deleteBtnHint: string = '';
  get deleteBtnHint(): string { return this.#deleteBtnHint; }
  @Input() set deleteBtnHint(val: string) {
    this.#deleteBtnHint = val;
    this.#verificaBotoes();
  }
  // editBtnHint
  #editBtnHint: string = '';
  get editBtnHint(): string { return this.#editBtnHint; }
  @Input() set editBtnHint(val: string) {
    this.#editBtnHint = val;
    this.#verificaBotoes();
  }
  // searchBtnHint
  #searchBtnHint: string = '';
  get searchBtnHint(): string { return this.#searchBtnHint; }
  @Input() set searchBtnHint(val: string) {
    this.#searchBtnHint = val;
    this.#verificaBotoes();
  }
  //#endregion

  //#region outputs
  @Output() add$ = new EventEmitter<IRede>();
  @Output() check$ = new EventEmitter<IRede>();
  @Output() delete$ = new EventEmitter<IRede>();
  @Output() edit$ = new EventEmitter<IRede>();
  @Output() search$ = new EventEmitter<IRede>();
  //#endregion

  //#region publics
  hasButtons = signal<boolean>(false);
  //#endregion

  //#region functions
  #verificaBotoes() {
    const CAPTIONS: string = `${this.#addBtnHint.trim()}${this.#checkBtnHint.trim()}${this.#deleteBtnHint.trim()}${this.#editBtnHint.trim()}${this.#searchBtnHint.trim()}`;
    // console.log(CAPTIONS);
    this.hasButtons.set(!!CAPTIONS);
  };
  //#endregion
}
