//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  // docData,
  getDoc,
  Firestore
} from '@angular/fire/firestore';
//#endregion

//#region 3rd 
import {
  BehaviorSubject,
  from,
  Observable,
  ObservableInput,
} from 'rxjs';
import {
  catchError,
  map,
  tap
} from 'rxjs/operators';
//#endregion

//#region models
import { QTDE_TAG } from '../../../_core/_misc/_models/consts';
import { IQtde } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class QtdeService {
  //#region qtdeState
  #qtdeState: IQtde;
  get qtdeState(): IQtde { return this.#qtdeState; }
  set qtdeState(val: IQtde) {
    // console.log(val);
    if (val) {
      this.#qtdeState = val;
      QtdeService.qtdeStateChanged$.next(this.#qtdeState);
    } // if
  }
  //#endregion

  //#region events
  static qtdeStateChanged$ = new BehaviorSubject<IQtde>(null);
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IQtde>): IQtde {
    return {
      contas: Number(row?.contas) || 0,
      formasPgto: Number(row?.formasPgto) || 0,
      integradoras: Number(row?.integradoras) || 0,
      localidades: Number(row?.localidades) || 0,
      lojas: Number(row?.lojas) || 0,
      meilisearchs: Number(row?.meilisearchs) || 0,
      // operadores: Number(row?.operadores) || 0,
      pedidos: Number(row?.pedidos) || 0,
      redes: Number(row?.redes) || 0,
      terminais: Number(row?.terminais) || 0,
      tiposLojas: Number(row?.tiposLojas) || 0,
      ufs: Number(row?.ufs) || 0,
    };
  }
  //#endregion

  //#region R
  qtde(): Observable<any> {
    const PATH: string = `_qtde/${QTDE_TAG}`;
    // return (docData(doc(this.#db, PATH)) as Observable<any>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
        // tap(data => console.log(data)),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<any[], ObservableInput<any[]>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro lendo _qtde.')
        )
      );
  }
  //#endregion
}