export const blobToFile = (
  blob: Blob,
  fileName: string
): File => {
  const B: any = blob;
  B.lastModifiedDate = new Date();
  B.name = fileName;

  //Cast to a File() type
  // return <File>blob;
  return <any>blob;
};
