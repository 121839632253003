//#region  models
import { ACCEPTED_CREDIT_CARDS } from '../../../_core/_misc/_models/consts';
//#endregion

/* var acceptedCreditCards = {
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  amex: /^3[47][0-9]{13}$/,
  discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
}; */

export function isCreditCard(cardNo: string): boolean {
  // remove all non digit characters
  const CARD_NO: string = cardNo.replace(/\D/g, '');
  let sum: number = 0;
  let shouldDouble: boolean = false;
  // loop through values starting at the rightmost side
  for (let i = CARD_NO.length - 1; i >= 0; i--) {
    let digit = parseInt(CARD_NO.charAt(i));

    if (!!shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    } // if

    sum += digit;
    shouldDouble = !shouldDouble;
  } // for

  const VALID: boolean = (sum % 10) == 0;
  let accepted = false;

  // loop through the keys (visa, mastercard, amex, etc.)
  Object.keys(ACCEPTED_CREDIT_CARDS).forEach((key) => {
    const REGEX = ACCEPTED_CREDIT_CARDS[key]?.regex?.validate || '';
    if (REGEX.test(CARD_NO)) {
      accepted = true;
    } // if
  });

  return !!VALID && !!accepted;
}

// https://cleilsontechinfo.netlify.app/jekyll/update/2019/12/08/um-guia-completo-para-validar-e-formatar-cartoes-de-credito.html
