//#region ng
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//#endregion

//#region pipes
import {
  MeDepartamentosLfixesPipe,
  MeFormasPgtosLfixesPipe,
  MeIntegradorasLfixesPipe,
  MeLojasLfixesPipe,
  MeNumPedidoPipe,
  MeProdutosLfixesPipe,
  MeRedesLfixesPipe,
} from '.';
//#endregion

//#endregion
@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    MeDepartamentosLfixesPipe,
    MeFormasPgtosLfixesPipe,
    MeIntegradorasLfixesPipe,
    MeLojasLfixesPipe,
    MeNumPedidoPipe,
    MeProdutosLfixesPipe,
    MeRedesLfixesPipe,
  ],
  exports: [
    MeDepartamentosLfixesPipe,
    MeFormasPgtosLfixesPipe,
    MeIntegradorasLfixesPipe,
    MeLojasLfixesPipe,
    MeNumPedidoPipe,
    MeProdutosLfixesPipe,
    MeRedesLfixesPipe,
  ],
})
export class MePipesModule { }