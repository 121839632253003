//#region ng
import {
  Component,
  inject,
  Input,
  signal
} from '@angular/core';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
//#endregion

//#region models
import {
  IConta,
  ILoja,
  ILojaP,
  IMeilisearch
} from '../../../_misc/_models/_interfaces/_cols';
import {
  LOJA_DISTRIBUIDORA_TIPO_ROTA,
  LOJA_ENTREGA_STATUS,
} from '../../../_misc/_models/consts';
//#endregion

//#region services
import {
  LojasService,
  MeilisearchService
} from '../../../_ng/_services';
import { CorLoaderService } from '../../../../_core/_ng/_services';
import { copyToClipboard } from '../../../../_libs/_www/_system/copy-2-clipboard';
//#endregion

@Component({
  selector: 'me-mat-loja-info',
  templateUrl: './loja-info.component.html',
  styleUrls: ['./loja-info.component.scss']
})
export class MeMatLojaInfoComponent {
  //#region inputs
  // isMobile
  // @Input() isMobile: boolean = false;
  // loja
  @Input() loja: ILoja;
  // conta
  @Input() conta: IConta;
  //#endregion

  //#region publics
  lojaDistribuidoraTipoRota = signal<any>(LOJA_DISTRIBUIDORA_TIPO_ROTA).asReadonly();
  lojaEntregaStatus = signal<any>(LOJA_ENTREGA_STATUS).asReadonly();
  lojaPrivate = signal<ILojaP>(null);
  // meilisearch = signal<IMeilisearch>(null);
  tokenVisible = signal<boolean>(false);
  // vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // console.log(this.conta, this.loja);
    const LOJA_ID: string = this.loja?.id || '';
    // const MEILISEARCH_ID: string = this.loja?.meilisearch?.id || '';
    const INTEGRADORA_ID: string = this.conta?.operador?.integradora?.id || '';
    // console.log(MEILISEARCH_ID);
    // console.log(INTEGRADORA_ID);

    /* {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this._meilisearchServ.doc(MEILISEARCH_ID)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          (meilisearch: IMeilisearch) => {
            // console.log(meilisearch);
            this.meilisearch.set(!!meilisearch ? this._meilisearchServ.fix(meilisearch) : null);
          }
        );
    } */

    {
      if (!!INTEGRADORA_ID) {
        const SUB: Subscription = this.#loaderServ.showUntilCompleted(
          this.#lojasServ.private(LOJA_ID || '')
            .pipe(first(), finalize(() => SUB?.unsubscribe()))
        )
          .subscribe(
            (lojaPrivate: ILojaP) => {
              // console.log(lojaPrivate);
              this.lojaPrivate.set(lojaPrivate);
            }
          );
      } // if
    }
  }
  //#endregion

  //#region methods
  onTokenVisibleSwap() {
    this.tokenVisible.update((prev: boolean) => !prev);
  }

  onCopiarTokenClick() {
    copyToClipboard(this.lojaPrivate()?._token?.val);
    this.#snackBar.open('Token copiado para Área de transferência.');
  }
  //#endregion
}
