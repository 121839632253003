export const desacentua = (text: string) => {
  // const a: string = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  // const b: string = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  if (text) {
    const a: string = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ';
    const b: string = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh';
    const p: any = new RegExp(a.split('').join('|'), 'g');
    return (text || '').toString().toLowerCase().trim()
      .replace(p, (c: string) => b.charAt(a.indexOf(c))); // Replace special chars
    // .replace(/&/g, '-and-') // Replace & with 'and'
    // .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
  } else {
    return '';
  } // else
};
