//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  // docData,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError,
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { ILoja } from '../../_misc/_models/_interfaces/_cols';
// import { environment } from 'src/environments/environment';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { LojasService } from '.';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class IntegradoraLojasService {
  //#region injects
  #db = inject(Firestore,);
  #lojasServ = inject(LojasService,);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region R
  docs(idIntegradora: string): Observable<ILoja[]> {
    if (!!idIntegradora) {
      const PATH: string = `_integradoras/${idIntegradora}/integradora-lojas/${ODOCS_TAG}`;
      // return (from(docData(doc(this.#db, PATH))) as Observable<ILoja[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((docs: any) => this.#lojasServ.fixes(Object.values(docs?.data()?._odocs || {}))),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando lojas da integradora.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma integradora indicada.');
  }
  //#endregion
}