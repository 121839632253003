<!-- <p>terminalId() {{ terminalId() }}</p> -->

<div class="bg d-flex h-100">
  <div class="wrapper mx-auto my-5 d-flex flex-column">
    <div class="m-auto qr-code">
      <qrcode [qrdata]="terminalId()"
              [width]="512"
              [errorCorrectionLevel]="'M'">
      </qrcode>

      <div class="text-center my-3">
        <button type="button"
                class="btn btn-block btn-lg"
                (click)="onCopiarClick()">
          Copiar <span class="o-50">{{ terminalId() }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
