//#region models
import { ICorViacepApi } from '../../../_core/_misc/_models/_interfaces/_apis';
import {
  IConta,
  ILoja,
} from '../../../_mercadeiro/_misc/_models/_interfaces/_cols';
import { TDistribuidoraRota } from '../_models/_types';
//#endregion

//#region libs
import { desacentua } from '../../../_libs/_misc/_strings';
import { calcLocalidadeId } from '.';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

export function fixEndereco( // calcEndereco
endereco: ICorViacepApi,
loja: TNullable<ILoja> = null,
conta: TNullable<IConta> = null
): ICorViacepApi {
// if (!!endereco) {
const CONTA_FAVORITO_ID: string = conta?._idEnderecoFav || '';
const DISTRIBUIDORA_LOCALIDADES_IDS: string[] = (loja?.distribuidora?.localidades || []).map((l: any) => l?.id);
const DISTRIBUIDORA_STATUS: boolean = !!loja?.distribuidora?.status;
const DISTRIBUIDORA_TIPO_ROTA: TDistribuidoraRota = loja?.distribuidora?.tipoRota as TDistribuidoraRota;
const ENDERECO_BAIRRO: string = endereco?.bairro || '';
const ENDERECO_ID: string = endereco?.id || '';
const LOCALIDADE_ID: string = calcLocalidadeId(endereco);
const LOJA_BAIRROS: string[] = loja?.entrega?._bairros || [];

// console.log(DISTRIBUIDORA_STATUS, DISTRIBUIDORA_TIPO_ROTA);
if (
  !!DISTRIBUIDORA_STATUS
  && DISTRIBUIDORA_TIPO_ROTA === 'loc'
) {
  return {
    ...endereco,
    __favorito: ENDERECO_ID === CONTA_FAVORITO_ID,
    __atendido: DISTRIBUIDORA_LOCALIDADES_IDS.includes(LOCALIDADE_ID),
  };
  // });
} else {
  return {
    ...endereco,
    __favorito: ENDERECO_ID === CONTA_FAVORITO_ID,
    __atendido: !!loja
      ? (LOJA_BAIRROS.includes(ENDERECO_BAIRRO) || LOJA_BAIRROS.includes('*'))
      && LOCALIDADE_ID === calcLocalidadeId(loja?.endereco)
      : true,
  };
} // else
// } // if
// return endereco;
}

export function splitLocalidadeId( // calcLocalidadeId
  uf: string,
  localidade: string
): [string, string, string] {
  const UF: string = (uf || '').trim().toLowerCase();
  const LOCALIDADE: string = desacentua((localidade || '').trim().replace(/ /g, '-').toLowerCase());
  if (!!UF && !!LOCALIDADE) {
    const LOCALIDADE_ID: string = `${LOCALIDADE}--${UF}`;
    return [UF, LOCALIDADE, LOCALIDADE_ID];
  } // if

  return ['', '', ''];
}

export function calcBairroId(bairro: string): string {
  if (!!bairro) {
    const BAIRRO: string = desacentua((bairro || '').trim().replace(/ /g, '-').toLowerCase());
    return BAIRRO;
  } // if

  return '';
}
