//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
//#endregion

//#region models
import { IConta } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { ContasService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-operador-actions',
  templateUrl: './operador-actions.component.html',
  styleUrls: ['./operador-actions.component.scss']
})
export class MeMatOperadorActionsComponent {
  //#region inputs
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  @Input() set conta(val: IConta) {
    this.#conta = !!val ? this.#contasServ.fix(val) : null;
  }
  //#endregion

  //#region outputs
  @Output() logout$ = new EventEmitter<null>();
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  //#endregion
}
