//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  DocumentReference,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CorFirestoreService {
  //#region injects
  #db = inject(Firestore);
  //#endregion

  // getPathIds('products', ['foo', 'bar', 'baz']);
  async getPathIdsDocs(
    path: string,
    ids: any[] = [],
  ): Promise<any[]> {
    return new Promise<any>(
      async (resolve) => {
        if (
          !!path
          && !!(ids || []).length
        ) {

          // const PROMISES: Promise<any>[] = [];
          const DOCS: any[] = [];
          for (const id of ids) {
            const DOC_REF: DocumentReference = doc(this.#db, path, id);
            // PROMISES.push(getDoc(DOC_REF));
            const DOC_SNAP: any = await getDoc(DOC_REF);
            DOC_SNAP?.exists
              ? DOCS.push(
                {
                  ...DOC_SNAP?.data(),
                  id: DOC_SNAP?.id,
                  _exists: true
                }
              )
              : {
                id: DOC_REF?.id,
                _exists: false,
              }
          } // for
          resolve(DOCS);
        } else {
          resolve([]);
        } // else
      }
    );
  }

  // getCollectionRefsDocs([ref1, ref2, ref3...]);
  getCollectionRefsDocs(
    refs: any[]
  ): Promise<any[]> {
    return new Promise(
      async (resolve) => {
        if (!refs) {
          resolve([]);
        } // if

        const DOCS: any[] = [];
        for (const ref of refs) {
          const DOC_SNAP: any = await getDoc(ref);
          DOC_SNAP?.exists
            ? DOCS.push(
              {
                ...DOC_SNAP?.data(),
                id: DOC_SNAP?.id,
                _exists: true
              }
            )
            : {
              id: DOC_SNAP?.id,
              _exists: false,
            }
        } // for

        resolve(DOCS);
      });
  }
}
