<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row>
      <h3>Adicionando produto</h3>

      <span class="flex-1"></span>

      <button mat-icon-button
              class="ms-3"
              (click)="onModalCloseClick(null)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-3 d-flex flex-column">
    <div class="d-flex">
      <div class="flex-1 search me-2">
        <mat-form-field appearance="outline"
                        class="w-100">
          <mat-icon matPrefix>
            search
          </mat-icon>
          <mat-label>Produto</mat-label>
          <input matInput
                 type="text"
                 [focus]="searchFocusEvent$"
                 [(ngModel)]="filter">
          <!-- (keyup.enter)="onSearchSubmit()" -->
          <button mat-button
                  *ngIf="!!filter"
                  matSuffix
                  mat-icon-button
                  (click)="filter = ''">
            <mat-icon class="o-50">close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- <div class="flex-1 p-relative"
           *ngIf="!!produtos?.length"
           style="top: 4px;">
        <cor-mat-ngx-pagination-nav pagId="produtos"
                                    size="lg"
                                    [isMobile]="true"
                                    (pageChanged$)="page = $event" />
      </div> -->
    </div>

    <div>
      <!-- <p>loja {{ loja | json }}</p> -->
      <!-- <p>produtos {{ produtos | json }}</p> -->

      <div class="produtos"
           *ngIf="produtos">
        <div class="produto pointer"
             [class.off]="p?.estoqueCritico || !p?.ativo?._status || !p?.ativo?.online"
             *ngFor="let p of produtos | paginate: { id: 'produtos', itemsPerPage: 3, currentPage: page, totalItems: produtos?.length }; trackBy:tid_pro"
             (click)="onProdutoClick(p)">
          <div class="h-100 d-flex flex-column p-2 text-center">
            <div class="m-auto p-relative">
              <img [src]="p?.__img?.thumb"
                   class="my-2 img-fluid"
                   *ngIf="!!p?.__img?.thumb">

              <span class="volume"
                    *ngIf="p?._base?.volume">
                <small>
                  {{ p?._base?.volume }}
                </small>
              </span>
            </div>

            <div class="flex-1">
              <small>{{ p?.nome }}</small>
              <span *ngIf="p?._base?.embalagem || p?._base?.dimensoes">
                <br><small class="off">
                  {{ p?._base?.embalagem }} {{ p?._base?.embalagem && p?._base?.dimensoes ? '-' : '' }} {{
                  p?._base?.dimensoes }}
                </small>
              </span>
              <p>
                <!-- <span>
                  <small class="o-50"> Cód.</small>
                  <strong>
                    {{ p?.id }}
                  </strong>
                </span>
                <br> -->
                <span *ngIf="p?.barcode">
                  <span>
                    {{ p?.barcode }}
                  </span>
                </span>
                <br>
                <small>
                  {{ p?._base?.marca?.nome }}
                  <small *ngIf="p?._base?.marca?.linha?.nome"
                         class="o-50">
                    / {{ p?._base?.marca?.linha?.nome }}
                  </small>
                </small>
              </p>
            </div>

            <div class="d-flex">
              <div class="flex-1">
                <!-- <mat-icon>edit</mat-icon> -->
                <me-html-produto-preco [produto]="p"></me-html-produto-preco>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-light text-center"
           *ngIf="!produtos?.length"
           role="alert">
        <strong> Nenhum produto.</strong>
      </div>
    </div>
  </div>
</div>
