//#region models
import { ICorViacepApi } from '../../../_core/_misc/_models/_interfaces/_apis';
//#endregion

export const endereco2Str = (
  endereco: ICorViacepApi,
  showLocalidadeUf: boolean = false
) => {
  // console.log(endereco);
  if (!!endereco) {
    return `${endereco?.bairro} - ${endereco?.logradouro}, ${endereco?.nro} ${endereco?.complemento}`.trim() + (!!endereco?.referencia ? ` - Referência: ${endereco?.referencia}` : '') + (!!showLocalidadeUf ? ` - ${endereco?.localidade}/${endereco?.uf}` : '');
  } // if
  return '';
};
