<!-- <p>conta {{ conta | json }}</p> -->

<div *ngIf="conta"
     class="h-100 conta-actions p-2 pointer">
  <div class="d-flex h-100"
       [matMenuTriggerFor]="suaContaMenu">
    <div class="d-flex">
      <div class="my-auto">
        <img [src]="conta?.__urlFoto"
             class="avatar">
      </div>

      <div class="d-flex flex-column">
        <div class="text-center my-auto">
          &nbsp;<span class="o-50">
            Olá,&nbsp;
          </span>
          {{ conta?.nome?.nome }}
          &nbsp;<span class="icon-r-chevron-d r-10 o-50"> </span>
          <br />
          <span class="pill">
            {{ conta?.operador?._role }}
          </span>
          <br />
          <small class="o-50 email">
            {{ conta?.email }}
            <span *ngIf="conta?.operador?.integradora?._nome">
              ( {{ conta?.operador?.integradora?._nome }} )
            </span>
          </small>
        </div>
      </div>
      <!--  &nbsp;<span class="o-50">
          Olá,&nbsp;
        </span>
        {{ conta?.nome?.nome }}
        &nbsp;<span class="icon-chevron-d r-10 o-50"> </span>
        <br/>
        <span class="o-50">
          ( {{ conta?.operador?._role }} )
        </span>
        <br>
        <small class="o-50 email">
          {{ conta?.email }}
          <span *ngIf="conta?.operador?.integradora?._nome">
            ( {{ conta?.operador?.integradora?._nome }} )
          </span>
        </small> -->
    </div>

    <!-- <img [src]="conta?.__urlFoto"
         class="avatar">
    &nbsp;<span class="o-50">
      Olá,&nbsp;
    </span>
    {{ conta?.nome?.nome }}
    <span class="o-50">
      ( {{ conta?.operador?._role }} )
    </span>
    &nbsp;<span class="icon-chevron-d r-10 o-50"> </span>
    <br>
    <small class="o-50 email">
      {{ conta?.email }}
      <span *ngIf="conta?.operador?.integradora?._nome">
        ( {{ conta?.operador?.integradora?._nome }} )
      </span>
    </small> -->
  </div>
</div>

<!-- Início menus -->
<mat-menu #suaContaMenu="matMenu">
  <button mat-menu-item
          (click)="logout$?.emit()">
    <span class="icon icon-r-export o-50"></span>&nbsp;
    Sair do conta
  </button>
</mat-menu>
<!-- Final menus -->