//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

@Pipe({ name: 'typeof' })
export class CorTypeofPipe implements PipeTransform {
  transform(value: any): any {
    // console.log("Pipe works ", typeof value);
    return typeof value;
  }
}
