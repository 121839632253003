//#region ng
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
//#endregion

//#region pipes
import {
  CorDowPipe,
  CorFormataFone,
  CorFormataCep,
  CorFormataCnpj,
  CorFormataCpf,
  CorFormataCpfCnpj,
  CorFormataNcm,
  CorMin2TimePipe,
  CorMinutes2HumanPipe,
  CorMonths2HumanPipe,
  CorSafeHtmlPipe,
  CorSexoPipe,
  CorTypeofPipe,
  CorMesPipe
} from '.';
//#endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    // pipes
    CorDowPipe,
    CorFormataFone,
    CorFormataCep,
    CorFormataCnpj,
    CorFormataCpf,
    CorFormataCpfCnpj,
    CorFormataNcm,
    CorMesPipe,
    CorMin2TimePipe,
    CorMinutes2HumanPipe,
    CorMonths2HumanPipe,
    CorSafeHtmlPipe,
    CorSexoPipe,
    CorTypeofPipe,
  ],
  exports: [
    // pipes
    CorDowPipe,
    CorFormataFone,
    CorFormataCep,
    CorFormataCnpj,
    CorFormataCpf,
    CorFormataCpfCnpj,
    CorFormataNcm,
    CorMesPipe,
    CorMin2TimePipe,
    CorMinutes2HumanPipe,
    CorMonths2HumanPipe,
    CorSafeHtmlPipe,
    CorSexoPipe,
    CorTypeofPipe,
  ]
})
export class CorPipesModule { }
