//#region 3rd
import { get } from 'lodash';
//#endregion 

//#region app
import { desacentua } from '../_strings'; 
//#endregion

// https://www.sitepoint.com/sort-an-array-of-objects-in-javascript
// Usage: ARRAY.sort(compareValues('nome'));
export function compareValues(key: any, order = 'asc') {
  // console.log(key, order);
  return function innerSort(a: any, b: any) {
    // console.log(get(a, key, null), get(b, key, null));
    // console.log(!a.hasOwnProperty(key), !b.hasOwnProperty(key));
    /* if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
    } // if */
    if (get(a, key, null) === null || get(b, key, null) === null) {
      // property doesn't exist on either object
      return 0;
    } // if

    const varA = (typeof a[key] === 'string')
      ? desacentua(a[key]).toUpperCase() : get(a, key, null);
    const varB = (typeof b[key] === 'string')
      ? desacentua(b[key]).toUpperCase() : get(b, key, null);
    // console.log(varA, varB);

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    } // else
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
} 
