//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region models
import { TCorSexo } from '../../../_core/_misc/_models/_types';
//#endregion

@Pipe({ name: 'sexo' })
export class CorSexoPipe implements PipeTransform {
  transform(value: TCorSexo): string {
    switch (value) {
      case 'M':
        return 'Masculino';

      case 'F':
        return 'Feminino';

      default:
        return '';
    } // switch
  }
}