//#region ng
import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { HOME_ROUTE } from './models/consts';
//#endregion

//#region 3rd
import {
  AuthGuard,
  redirectUnauthorizedTo
} from '@angular/fire/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
//#endregion

//#region models
const ROUTES: Routes = [
  {
    path: '',
    redirectTo: HOME_ROUTE,
    pathMatch: 'full'
  },
  {
    path: 'habilitaTerminal',
    // canActivate: [LojaGuard],
    // data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: HabilitaTerminalPage
  },
  {
    path: 'login',
    canActivate: [LojaGuard],
    component: LoginPage,
  },
  {
    path: 'notificacoesBloqueadas',
    component: NotificacoesBloqueadasPage,
  },
  {
    path: 'pedidos',
    canActivate: [LojaGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: PedidosPage
  },
  {
    path: 'pedido/:id',
    canActivate: [LojaGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: PedidoPage
  },
];
//#endregion

//#region guards
import { LojaGuard } from './guards';
//#endregion

//#region components
import {
  HabilitaTerminalPage,
  PedidosPage,
  LoginPage,
  PedidoPage,
  NotificacoesBloqueadasPage,
} from './pages';
//#endregion

@NgModule({
  imports: [RouterModule.forRoot(
    ROUTES,
    { useHash: true }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
