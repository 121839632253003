<!-- <p>autoHide {{ autoHide }}</p> -->
<!-- <p>maxSize {{ maxSize }}</p> -->
<pagination-template #p="paginationApi"
                     class="mt-1"
                     [id]="pagId"
                     *ngIf="pagId"
                     [maxSize]="!!isMobile ? 5 : 7"
                     (pageChange)="pageChange$.emit($event)"
                     (pageBoundsCorrection)="pageChange$.emit(1)">
  <div *ngIf="p?.pages?.length > 1 || !autoHide"
       class="d-flex"
       [class.lg]="size === 'lg'">
    <button mat-stroked-button
            class="bs"
            [disabled]="p?.isFirstPage()"
            (click)="p?.setCurrent(1)">
      <span class="icon-b-first o-30"
            matTooltip="Primeiro">
      </span>
      <span *ngIf="!isMobile">
        &nbsp;Primeiro
      </span>
    </button>

    <button mat-stroked-button
            class="bm"
            [disabled]="p?.isFirstPage()"
            (click)="p?.previous()">
      <span class="icon-r-chevron-l o-30"
            matTooltip="Anterior">
      </span>
      <span *ngIf="!isMobile">
        &nbsp;Anterior
      </span>
    </button>

    <button mat-stroked-button
            class="bm"
            [disabled]="p?.getCurrent() === page.value"
            (click)="p?.setCurrent(page.value)"
            *ngFor="let page of p?.pages">
      <span>{{ page.label }}</span>
    </button>

    <button mat-stroked-button
            class="bm"
            [disabled]="p?.isLastPage()"
            (click)="p?.next()">
      <span *ngIf="!isMobile">
        Próximo&nbsp;
      </span>
      <span class="icon-r-chevron-r o-30"
            matTooltip="Próximo">
      </span>
    </button>

    <button mat-stroked-button
            class="be"
            [disabled]="p?.isLastPage()"
            (click)="p?.setCurrent(p?.getLastPage())">
      <span *ngIf="!isMobile">
        Último&nbsp;
      </span>
      <span class="icon-b-last o-30"
            matTooltip="Último">
      </span>
    </button>
  </div>
</pagination-template>