//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
import {
  doc,
  Firestore,
  getDoc,
  setDoc,
} from '@angular/fire/firestore';
import { User } from '@angular/fire/auth';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
  throwError
} from "rxjs";
import {
  catchError,
  map,
  tap
} from "rxjs/operators";
//#endregion

//#region models
import { IConta } from '../../_misc/_models/_interfaces/_cols/conta';
import {
  // ICorFbUserApi,
  ICorViacepApi
} from '../../../_core/_misc/_models/_interfaces/_apis';
import { TOperadorRole } from '../../_misc/_models/_types';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
//#endregion

//#region libs
import { avatar } from '../../../_libs/_misc/_ui-avatar/avatar';
//#endregion

//#region services
import {
  CorCepService,
  CorFbUserService,
  CorMessagesService
} from '../../../_core/_ng/_services';
import { environment } from 'src/environments/environment';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ContasService {
  //#region injects
  #db = inject(Firestore);
  #cepServ = inject(CorCepService);
  #msgServ = inject(CorMessagesService);
  #userServ = inject(CorFbUserService);
  //#endregion

  //#region misc
  fix(
    row: Partial<IConta>,
    user: Partial<User> | null = null
  ): IConta {
    // console.log(user);
    const ENDERECO: ICorViacepApi = !!row?.endereco ? this.#cepServ.fix(row?.endereco) : null;
    const INTEGRADORA_ID: string = row?.operador?.integradora?.id || '';
    const REDE_ID: string = row?.operador?.rede?.id || '';
    const ROLE: TOperadorRole | '' = row?.operador?.role || '';
    const NOME_NOME: string = row?.nome?.nome || '';
    const NOME_SOBRENOME: string = row?.nome?.sobrenome || '';
    const NOME_COMPLETO: string = `${NOME_NOME} ${NOME_SOBRENOME}`.trim();

    return {
      // id
      id: row?.id || '',

      // user
      ativo: {
        _status: !!row?.ativo?._status,
        status: !!row?.ativo?.status, /// def true
      },
      cpf: (row?.cpf || '').replace(/[^0-9]/g, ''),
      cnpj: (row?.cnpj || '').replace(/[^0-9]/g, ''),
      // credenciado: {
      //   idsFormasPgto: row?.credenciado?.idsFormasPgto || [],
      // },
      credenciado: row?.credenciado || null,
      email: user?.email || row?.email || '',
      endereco: ENDERECO,
      fones: {
        celular: (user?.phoneNumber || row?.fones?.celular || '').replace(/[^0-9]/g, ''),
        fixo: (row?.fones?.fixo || '').replace(/[^0-9]/g, ''), /// def '',
        mensagens: (row?.fones?.mensagens || '').replace(/[^0-9]/g, ''), /// def ''
      },
      messaging: {
        push: !!row?.messaging?.push,
      },
      nascimento: row?.nascimento || '',
      nome: {
        nome: user?.displayName || row?.nome?.nome || '',
        sobrenome: row?.nome?.sobrenome || '',
      },
      operador: {
        _lojasIds: row?.operador?._lojasIds || [],
        integradora: {
          id: INTEGRADORA_ID,
          _nome: row?.operador?.integradora?._nome || '',
          __status: !!REDE_ID,
        },
        // _lojasIds: row?.operador?._lojasIds || [],
        // _lojas: {
        //   _ids: row?.operador?._lojas?._ids || [],
        //   refs: row?.operador?._lojas?.refs || [],
        // },
        rede: {
          id: REDE_ID,
          _nome: row?.operador?.rede?._nome || '',
          __status: !!REDE_ID,
        },
        role: ROLE,
        _role: row?.operador?._role || '',
      },
      sexo: row?.sexo || null,

      // system
      _criadoEm: row?._criadoEm || null,
      _exists: !!row?._exists,
      _idEnderecoFav: row?._idEnderecoFav || '',
      _modificadoEm: row?._modificadoEm || null,
      _modificadoPor: {
        id: row?._modificadoPor?.id || null,
        nome: row?._modificadoPor?.nome || ''
      },
      _user: !!user ? this.#userServ.fix(user) : null,

      // realtime
      __displayInPagination: !row?.__displayInPagination,
      __nomeCompleto: NOME_COMPLETO,
      // __movPedidoPre: `/_contas/${row?.id || ''}/`,
      __urlFoto: user?.photoURL || avatar({ name: NOME_COMPLETO }),
    };
  }

  fixes(docs: Partial<IConta>[]): IConta[] {
    return (docs || []).map((doc: Partial<IConta>) => this.fix(doc));
  }

  unfix(doc: Partial<IConta>): Partial<IConta> {
    // delete doc?.id;
    delete doc?._criadoEm;
    delete doc?._exists;
    delete doc?.__displayInPagination;
    delete doc?._modificadoEm;
    delete doc?._modificadoPor;
    return doc;
  }

  /* unfix(row: any): any {
    row = row || {};
    let R: Partial<IConta> = row;
 
    delete R._criadoEm;
    delete R._exists;
    delete R._idEnderecoFav;
    // delete R._lojasIds;
    // delete R._lojasRefs;
    delete R._modificadoEm;
    delete R._modificadoPor;
    delete R._user;
    delete R.__nomeCompleto;
    delete R.__urlFoto;
 
    return R;
  } */
  //#endregion

  //#region R
  userFromEmail(email: string): Promise<any> {
    const functions = getFunctions(
      getApp(),
      environment?.firebase?.region
    );
    const fn = httpsCallable(functions, 'onUidByEmail');
    return fn({ email });
  }

  contaFromUid(uid: string): Observable<IConta | null> {
    if (!!uid) {
      const PATH: string = `_contas/${uid}`;
      // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IConta | null>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => (!!doc.data() ? this.fix({ ...doc.data(), id: doc.id }) : null)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          // tap(doc => console.log(doc)),
          catchError<IConta | null, ObservableInput<TNullable<IConta>>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando conta.', 'warning', 'dialog')
          )
        );
    } // if
    return of(null); // throwError(() => 'Nenhuma conta indicada.');
  }

  docs(): Observable<IConta[]> {
    const PATH: string = `_contas/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH),) as Observable<IConta[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<any, ObservableInput<any>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando contas.')
        )
      );
  }

  /* lojasFromPaths(
    paths: string[]
  ): Promise<any> {
    // console.log(paths);
    return new Promise<any>(
      async (resolve) => {
        if (!paths) {
          resolve([]);
        } // if

        const DOCS: any[] = [];
        for (const p of paths) {
          const DOC_SNAP: any = await getDoc(doc(this.#db, p));
          DOC_SNAP?.exists
            ? DOCS.push(
              {
                ...DOC_SNAP?.data(),
                id: DOC_SNAP?.id,
                _exists: true
              }
            )
            : {
              id: DOC_SNAP?.id,
              _exists: false,
            }
        } // for

        resolve(DOCS);
        // resolve([]);
      }
    );
  } */
  // //#endregion

  //#region U
  update(
    idConta: string,
    changes: Partial<IConta>
  ): Observable<any> {
    if (!!idConta) {
      const PATH: string = `_contas/${idConta}`;
      return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
        .pipe(
          // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando conta.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma conta indicada.');
  }
  //#endregion
}
