//#region ng
import {
  inject,
  Injectable,
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  // docData,
  DocumentReference,
  Firestore,
  getDoc,
  setDoc
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import {
  IMG_NO_IMG,
  ODOCS_TAG
} from '../../../_core/_misc/_models/consts';
import { ICorViacepApi } from '../../../_core/_misc/_models/_interfaces/_apis';
import { IIntegradora } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { checkImg } from '../../../_libs/_misc/_imgs';
//#endregion

//#region services
import {
  CorCepService,
  CorMessagesService
} from '../../../_core/_ng/_services';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class IntegradorasService {
  //#region injedts
  #cepServ = inject(CorCepService);
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  /* async sfix(
    ws: WritableSignal<IIntegradora>,
    doc: Partial<IIntegradora>
  ) { !!doc ? ws.set(await this.pfix(doc)) : ws.set(null); } */

  /* sfixes(
    ws: WritableSignal<IIntegradora[]>,
    docs: Partial<IIntegradora>[]
  ) {
    const DOCS: IIntegradora[] = [];
    (docs || []).forEach((doc: Partial<IIntegradora>) => DOCS.push(this.fix(doc)));
    console.log(DOCS);
    ws.set(DOCS);
  } */

  /* pfix(row: Partial<IIntegradora>): Promise<IIntegradora> {
    return new Promise<IIntegradora>(
      (resolve) => {
        const ENDERECO: ICorViacepApi = !!row?.endereco ? this.#cepServ.fix(row?.endereco) : null;
        const IMG: string = `${environment.firebase.mercadeiro.storage.root}integradoras%2f${row?.id}.png?alt=media`;

        const R: IIntegradora = {
          // id
          id: row?.id || '',

          // user
          ativo: {
            status: !!row?.ativo?.status, /// def true
            _status: !!row?.ativo?._status,
          },
          conta: {
            id: row?.conta?.id || '',
            email: row?.conta?.email || '',
          },
          email: row?.email || '',
          endereco: ENDERECO,
          nome: row?.nome || '',

          // system
          _criadoEm: row?._criadoEm || null,
          _lojasRefs: row?._lojasRefs || [],
          // _qtde: any;

          // realtime
          __displayInPagination: !row?.__displayInPagination,
          __logo: ''
        };

        checkImg(
          IMG,
          () => {
            R.__logo = IMG;
            resolve(R);
          },
          () => {
            R.__logo = IMG_NO_IMG;
            resolve(R);
          },
        );
        // return R;
      }
    );
  } */

  fix(row: Partial<IIntegradora>): IIntegradora {
    const ENDERECO: ICorViacepApi = !!row?.endereco ? this.#cepServ.fix(row?.endereco) : null;

    return {
      // id
      id: row?.id || '',

      // user
      ativo: {
        status: !!row?.ativo?.status, /// def true
        _status: !!row?.ativo?._status,
      },
      conta: {
        id: row?.conta?.id || '',
        _email: row?.conta?._email || '',
      },
      email: row?.email || '',
      endereco: ENDERECO,
      nome: row?.nome || '',

      // system
      _criadoEm: row?._criadoEm || null,
      // _lojasRefs: row?._lojasRefs || [],
      _qtde: {
        pedidos: Number(row?._qtde?.pedidos) || 0,
      },

      // realtime
      __displayInPagination: !row?.__displayInPagination,
      __logo: row?.__logo || IMG_NO_IMG,
      // __movPedidoPre: `/_integradoras/${row?.id || ''}/`,
    };
  }

  lfix(row: Partial<IIntegradora>): Promise<TNullable<IIntegradora>> {
    // const R: IRede = this.fix(row);
    return new Promise(
      (resolve, reject) => {
        const INTEGRADORA_ID: string = row?.id || '';
        if (!INTEGRADORA_ID) {
          resolve(null); // )reject(new Error('Nenhuma rede indicada.'));
        } else {
          const IMG: string = `${environment.firebase.mercadeiro.storage.root}/integradoras%2f${INTEGRADORA_ID}.png?alt=media`;
          // console.log(IMG);
          checkImg(
            IMG,
            // () => { console.log(IMG); resolve(this.fix({ ...row, __logo: IMG })) },
            () => resolve(this.fix({ ...row, __logo: IMG })),
            () => resolve(this.fix({ ...row, __logo: IMG_NO_IMG })),
          );
        } // else
      }
    );
  }

  fixes(docs: Partial<IIntegradora>[]): IIntegradora[] {
    return (docs || []).map((doc: Partial<IIntegradora>) => this.fix(doc));
  }

  lfixes(docs: Partial<IIntegradora>[]): Observable<IIntegradora[]> {
    // console.log(docs);
    const PROMISES: Promise<IIntegradora>[] = (docs || [])
      .map((doc: Partial<IIntegradora>) => this.lfix(doc));
    return from(Promise.all(PROMISES));
  }
  //#endregion

  //#region R
  docs(): Observable<IIntegradora[]> {
    const PATH: string = `_integradoras/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH),) as Observable<IIntegradora[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<any, ObservableInput<any>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando integradoras.')
        )
      );
  }

  doc(idIntegradora: string): Observable<IIntegradora> {
    // console.log('idIntegradora', idIntegradora);
    if (!!idIntegradora) {
      const PATH: string = `/_integradoras/${idIntegradora}`;
      // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IIntegradora>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IIntegradora, ObservableInput<IIntegradora>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando integradora.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma integradora indicada.');
  }
  //#endregion

  //#region U
  update(
    idIntegradora: string,
    changes: Partial<IIntegradora>
  ): Observable<any> {
    // console.log(idIntegradora);
    // console.log(changes);
    if (!!idIntegradora) {
      const PATH: string = `_integradoras/${idIntegradora}`;
      return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
        .pipe(
          // map(() => { throw new Error(`Erro apagando ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando integradora.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma integradora indicada.');
  }
  //#endregion
}