//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import Fuse from 'fuse.js';
import {
  combineLatest,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  redes: IRede[];
  pag: {
    itemsPerPage: string;
    page: number;
  };
  // searchTerm: string;
  viewStyle: TCorViewStyle;
};
import { IRede } from '../../../_misc/_models/_interfaces/_cols';
import { FOCUS_TIMEOUT } from '../../../../_core/_misc/_models/consts';
import { TCorViewStyle } from '../../../../_core/_misc/_models/_types';
//#endregion

//#region libs
import { onDestroy } from '../../../../_core/_ng/_libs';
//#endregion

//#region services
import { RedesService } from '../../../_ng/_services';
import { compareValues } from '../../../../_libs/_misc/_arrays';
//#endregion

@Component({
  selector: 'me-mat-redes',
  templateUrl: './redes.component.html',
  styleUrls: ['./redes.component.scss']
})
export class MeMatRedesComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #hitsAction$ = new Subject<IRede[]>();
  pagPageAction$ = signal<Subject<number>>(new Subject<number>()).asReadonly();
  pagItemsPerPageAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  searchTermAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  viewStyleAction$ = signal<Subject<TCorViewStyle>>(new Subject<TCorViewStyle>()).asReadonly();
  //#endregion

  //#region inputs
  // redes
  #redes: IRede[];
  get redes(): IRede[] { return this.#redes; }
  @Input({ required: true }) set redes(val: IRede[]) {
    this.#redes = !!val ? this.#redesServ.fixes(val) : [];
    this.#refresh('');
    this.focus();
  }
  // viewStyleStorageKey
  @Input() viewStyleStorageKey: string = 'KEY_VIEWSTYLE#REDES';
  // offsetStorageKey
  @Input() offsetStorageKey: string = 'KEY_OFFSET#REDES';
  // showTheme
  @Input() showTheme: boolean = false;
  // addBtnHint
  #addBtnHint: string = '';
  get addBtnHint(): string { return this.#addBtnHint; }
  @Input() set addBtnHint(val: string) {
    this.#addBtnHint = val;
    this.#verificaBotoes();
  }
  // checkBtnHint
  #checkBtnHint: string = '';
  get checkBtnHint(): string { return this.#checkBtnHint; }
  @Input() set checkBtnHint(val: string) {
    this.#checkBtnHint = val;
    this.#verificaBotoes();
  }
  // deleteBtnHint
  #deleteBtnHint: string = '';
  get deleteBtnHint(): string { return this.#deleteBtnHint; }
  @Input() set deleteBtnHint(val: string) {
    this.#deleteBtnHint = val;
    this.#verificaBotoes();
  }
  // editBtnHint
  #editBtnHint: string = '';
  get editBtnHint(): string { return this.#editBtnHint; }
  @Input() set editBtnHint(val: string) {
    this.#editBtnHint = val;
    this.#verificaBotoes();
  }
  // searchBtnHint
  #searchBtnHint: string = '';
  get searchBtnHint(): string { return this.#searchBtnHint; }
  @Input() set searchBtnHint(val: string) {
    this.#searchBtnHint = val;
    this.#verificaBotoes();
  }
  //#endregion

  //#region outputs
  @Output() add$ = new EventEmitter<IRede>();
  @Output() check$ = new EventEmitter<IRede>();
  @Output() delete$ = new EventEmitter<IRede>();
  @Output() edit$ = new EventEmitter<IRede>();
  @Output() search$ = new EventEmitter<IRede>();
  //#endregion

  //#region publics
  hasButtons = signal<boolean>(false);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  //#endregion

  //#region injects
  #redesServ = inject(RedesService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.searchTermAction$()
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchTerm: string) => { this.#refresh(searchTerm); return of(null); }),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    this.vm$.set(
      combineLatest([
        this.#hitsAction$.pipe(startWith([])),
        this.viewStyleAction$().pipe(startWith('cards')),
        this.pagPageAction$().pipe(startWith(1)),
        this.pagItemsPerPageAction$().pipe(startWith('12')),
      ])
        .pipe(
          map(([hits, viewStyle, pagPage, pagItemsPerPage]) => {
            // console.log(hits?.length);
            const VM: IVm = {
              redes: hits || [],
              pag: {
                itemsPerPage: pagItemsPerPage,
                page: pagPage
              },
              viewStyle: viewStyle as TCorViewStyle,
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  #refresh(searchTerm: string) {
    // console.log(searchTerm);
    // fuse-js
    if (!!searchTerm.trim()) {
      const FUSE: any = new Fuse(
        this.redes,
        {
          includeScore: false,
          keys: [
            'nome',
          ],
          // minMatchCharLength: 3,
        }
      );
      const HITS: any[] = FUSE.search(searchTerm);
      // console.log(searchTerm, HITS);
      this.#hitsAction$.next(
        this.#redesServ.fixes((HITS || []).map(h => h?.item as IRede) as IRede[])
          .sort(compareValues('nome'))
      );
    } else {
      this.#hitsAction$.next(
        this.#redesServ.fixes(this.redes)
          .sort(compareValues('nome')) as IRede[]
      );
    } // else
  }

  #verificaBotoes() {
    const CAPTIONS: string = `${this.#addBtnHint.trim()}${this.#checkBtnHint.trim()}${this.#deleteBtnHint.trim()}${this.#editBtnHint.trim()}${this.#searchBtnHint.trim()}`;
    // console.log(CAPTIONS);
    this.hasButtons.set(!!CAPTIONS);
  };

  focus() {
    setTimeout(
      () => { this.searchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  tid_red(index: any, item: IRede): string { return item?.id || ''; }
  //#endregion
}
