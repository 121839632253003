//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  // docData,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError,
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { ICorViacepApi } from '../../../_core/_misc/_models/_interfaces/_apis';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
//#endregion

//#region services
import {
  CorCepService,
  CorMessagesService
} from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ContaEnderecosService {
  //#region injects
  #cepServ = inject(CorCepService);
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region C
  add(
    contaId: string,
    endereco: ICorViacepApi
  ): Observable<any> {
    // console.log(endereco);
    if (!!contaId) {
      if (!!endereco) {
        const PATH: string = `_contas/${contaId}/conta-enderecos`;
        return (from(addDoc(collection(this.#db, PATH), endereco)) as Observable<any>)
          .pipe(
            // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
            catchError<void, ObservableInput<void>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro adicionando endereço.')
            )
          );
      } // if
      return throwError(() => 'Nenhum endereço indicado.');
    } // if
    return throwError(() => 'Nenhuma conta indicada.');
  }
  //#endregion

  //#region R
  doc(
    contaId: string,
    enderecoId: string
  ): Observable<ICorViacepApi> {
    // console.log('idLoja', idLoja);
    if (!!contaId) {
      if (!!enderecoId) {
        // console.log(INFO);
        // console.log('PATH', PATH);
        const PATH: string = `_contas/${contaId}/conta-enderecos/${enderecoId}`
        // const PATH: string = `${lojaPath}/${idLoja}`;
        // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<ICorViacepApi>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<ICorViacepApi, ObservableInput<ICorViacepApi>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando endereço.')
            )
          );
      } // if
      return throwError(() => 'Nenhum endereco indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }

  docs(contaId: string): Observable<ICorViacepApi[]> {
    // /_integradoras/conecdata/integradora-lojas
    if (!!contaId) {
      const PATH: string = `_contas/${contaId}/conta-enderecos/${ODOCS_TAG}`;
      // return (docData(doc(this.#db, PATH)) as Observable<ICorViacepApi[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => this.#cepServ.fixes(Object.values(doc?.data()?._odocs || {}))),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<any, ObservableInput<any>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando endereços.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma conta indicada.');
  }
  //#endregion

  //#region D
  delete(
    contaId: string,
    idEndereco: string
  ): Observable<any> {
    if (!!contaId) {
      if (!!idEndereco) {
        const PATH: string = `_contas/${contaId}/conta-enderecos/${idEndereco}`;
        return from(deleteDoc(doc(this.#db, PATH)))
          .pipe(
            // map(() => { throw new Error(`Erro apagando ${PATH}.`); }),
            catchError<void, ObservableInput<void>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro apagando endereço.')
            )
          );
      } // if
      return throwError(() => 'Nenhum endereço indicado.');
    } // if
    return throwError(() => 'Nenhuma conta indicada.');
  }
  //#endregion
}
