//#region libs
import { randomRange } from "../_numbers/random-range";
//#endregion

export const getRandomNumbers = (
  qtde: number,
  min: number,
  max: number
) => {
  const RES: number[] = [];

  for (let i = 0; i < qtde; i++) {
    RES.push(randomRange(min, max));
  } // for

  return RES;
}
