//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

//#region modules
import { CorBsComponentsModule } from '../../../_core/_ng-ui/_bs/cor-bs-components.module';
//#endregion

//#region components
import {
  MeBsImgsSliderComponent,
  MerBsLojaComponent,
  MerBsProdutoPromocoesComponent,
} from '.';
//#endregion

@NgModule({
  imports: [
    CommonModule,
    CorBsComponentsModule,
  ],
  declarations: [
    MeBsImgsSliderComponent,
    MerBsLojaComponent,
    MerBsProdutoPromocoesComponent,
  ],
  exports: [
    MeBsImgsSliderComponent,
    MerBsLojaComponent,
    MerBsProdutoPromocoesComponent,
  ],
})
export class MeBsComponentsModule { }
