//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
//#endregion

//#region models
import { ICarrinhoInfoMap } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { TPedidoSeparacaoStage } from '../../../_shared/_mercadeiro/_misc/_models/_types';
import { TCorBsTheme } from 'src/app/_shared/_core/_misc/_models/_types';
//#endregion

@Component({
  selector: 'stage-header',
  templateUrl: './stage-header.component.html',
  styleUrls: ['./stage-header.component.scss']
})
export class StageHeaderComponent {
  //#region inputs
  // carrinhoInfo
  @Input({ required: true }) carrinhoInfo: ICarrinhoInfoMap;
  // stage
  @Input({ required: true }) stage: TPedidoSeparacaoStage;
  // color
  @Input() color: TCorBsTheme = 'light';
  //#endregion

  //#region outputs
  @Output() limpar$: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmar$: EventEmitter<void> = new EventEmitter<void>();
  //#endregion
}
