//#region ng
import {
  Pipe,
  PipeTransform,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
//#endregion

//#region models
import { ILoja } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { LojasService } from '../_services';
//#endregion

@Pipe({ name: 'lojasLfixes' })
export class MeLojasLfixesPipe implements PipeTransform {
  //#region injects
  #lojasServ = inject(LojasService);
  //#endregion

  transform(val: ILoja[]): Observable<ILoja[]> {
    return this.#lojasServ.lfixes(val);
  }
}
