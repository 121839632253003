//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region libs
import {
  formataCNPJ,
  formataCPF
} from '../../../_libs/_misc/_formats';
//#endregion

@Pipe({ name: 'cpfCnpj' })
export class CorFormataCpfCnpj implements PipeTransform {
  transform(value: string): string {
    return formataCPF(value) || formataCNPJ(value);
  }
}
