//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region models
import {
  MESES_NOME_CURTO,
  MESES_NOME_LONGO
} from '../../_misc/_models/consts';
//#endregion

@Pipe({ name: 'mes' })
export class CorMesPipe implements PipeTransform {
  transform(mes: number | string, long: boolean = true): string {
    const MES = Number(mes) || 0;
    // console.log(MES);
    if (MES >= 1 && MES <= 12) {
      const A: string[] = !!long ? MESES_NOME_LONGO : MESES_NOME_CURTO;
      // console.log(A[MES - 1]);
      return A[MES - 1];
    } // if
    return '';
  }
}
