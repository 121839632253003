//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  // collection,
  // CollectionReference,
  doc,
  Firestore,
  getDoc,
  setDoc
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  tap
} from 'rxjs/operators';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import {
  ILoja,
  ILojaApiQtde,
  ILojaP
} from '../../_misc/_models/_interfaces/_cols';
import { ILojaIdInfoRet } from '../../_misc/_models/_interfaces/_rets';
import {
  IMG_H_NO_IMG,
  IMG_NO_IMG,
  ODOCS_TAG,
  PRIVATE_TAG,
  QTDE_TAG
} from '../../../_core/_misc/_models/consts';
//#endregion

// //#region libs
import { checkImg } from '../../../_libs/_misc/_imgs';
import { calcLojaIdInfo } from '../../_misc/_libs/lojas';
// //#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { MeilisearchService } from '.';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class LojasService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  #meilisearchServ = inject(MeilisearchService);
  //#endregion

  //#region misc
  fix(
    row: Partial<ILoja>,
    isRetirada: boolean = true,
    // isRede: boolean
  ): ILoja {
    const PEDIDO_MIN_RETIRADA: number = Number(row?.pedidos?.minimo?.retirada) || 0;
    const PEDIDO_MIN_ENTREGA: number = Number(row?.pedidos?.minimo?.entrega) || 0;
    const PERC_SERV_CLIENTE: number = Number(row?.faturamento?.servicos?.percCliente) || 0;
    const VAL_SERV_CLIENTE: number = Number(row?.faturamento?.servicos?.valor) || 0;

    return {
      // id
      id: row?.id || '',

      // user
      ativo: {
        _rede: !!row?.ativo?._rede,
        habilitado: !!row?.ativo?.habilitado,
        status: !!row?.ativo?.status, /// def true
        _status: !!row?.ativo?._status,
      },
      cpfCnpj: (row?.cpfCnpj || '').replace(/[^0-9]/g, ''),
      config: {
        _hash: row?.config?._hash || '',
      },
      distribuidora: {
        idsCredenciados: row?.distribuidora?.idsCredenciados || [],
        localidades: row?.distribuidora?.localidades || [],
        status: !!row?.distribuidora?.status,
        tipoRota: row?.distribuidora?.tipoRota,
      },
      // dono: {
      //   email: row?.dono?.email || '',
      //   id: row?.dono?.id || '',
      //   nome: row?.dono?.nome || '',
      //   __status: !!row?.dono?.__status,
      // },
      // descricao: '', // 512
      email: row?.email || '',
      endereco: row?.endereco || null,
      entrega: {
        _bairros: row?.entrega?._bairros || [], /// def
        __atendido: row?.entrega?.__atendido,
        status: row?.entrega?.status,
        _roteiro: !!row?.entrega?._roteiro, /// def true
        taxaGeral: {
          status: !!row?.entrega?.taxaGeral?.status, /// def false
          valor: Number(row?.entrega?.taxaGeral?.valor) || 0, /// def 0
          desc: {
            status: !!row?.entrega?.taxaGeral?.desc?.status, 
            perc: Number(row?.entrega?.taxaGeral?.desc?.perc) || 0, 
            minTotalCarrinho: Number(row?.entrega?.taxaGeral?.desc?.minTotalCarrinho) || 0, 
          },
        },
      },
      /* img: {
        logo: {
          img: '',
          thumb: '',
        };
      }; */
      faturamento: {
        cod: row?.faturamento?.cod || '',
        mensalidade: Number(row?.faturamento?.mensalidade) || 0, /// def 0
        // servicos: {
        //   anistiaMeses: Number(row?.faturamento?.servicos?.anistiaMeses) || 0,
        //   percCliente: Number(row?.faturamento?.servicos?.percCliente) || 0, /// def 100
        //   piso: Number(row?.faturamento?.servicos?.piso) || 0,
        //   __valCliente: Number(row?.faturamento?.servicos?.__valCliente) || 0,
        //   valor: Number(row?.faturamento?.servicos?.valor) || 0,
        // },
        servicos: {
          anistiaMeses: Number(row?.faturamento?.servicos?.anistiaMeses) || 0,
          percCliente: PERC_SERV_CLIENTE,
          piso: Number(row?.faturamento?.servicos?.piso) || 0,
          __valCliente: PERC_SERV_CLIENTE / 100 * VAL_SERV_CLIENTE,
          valor: VAL_SERV_CLIENTE
        },
      },
      fones: {
        celular: (row?.fones?.celular || '').replace(/[^0-9]/g, ''), /// def ''
        fixo: (row?.fones?.fixo || '').replace(/[^0-9]/g, ''), /// def ''
        mensagens: (row?.fones?.mensagens || '').replace(/[^0-9]/g, ''), /// def ''
      },
      formas: {
        entrega: row?.formas?.entrega || null,
        retirada: row?.formas?.retirada || null,
      },
      horarios: {
        entrega: {
          dow0: row?.horarios?.entrega?.dow0 || [], /// def [] // dom
          dow1: row?.horarios?.entrega?.dow1 || [], /// def [] // seg
          dow2: row?.horarios?.entrega?.dow2 || [], /// def [] // ter
          dow3: row?.horarios?.entrega?.dow3 || [], /// def [] // qua
          dow4: row?.horarios?.entrega?.dow4 || [], /// def [] // qui
          dow5: row?.horarios?.entrega?.dow5 || [], /// def [] // sex
          dow6: row?.horarios?.entrega?.dow6 || [], /// def [] // sab
        },
        funcionamento: {
          dow0: row?.horarios?.funcionamento?.dow0 || [], /// def [] // dom
          dow1: row?.horarios?.funcionamento?.dow1 || [], /// def [] // seg
          dow2: row?.horarios?.funcionamento?.dow2 || [], /// def [] // ter
          dow3: row?.horarios?.funcionamento?.dow3 || [], /// def [] // qua
          dow4: row?.horarios?.funcionamento?.dow4 || [], /// def [] // qui
          dow5: row?.horarios?.funcionamento?.dow5 || [], /// def [] // sex
          dow6: row?.horarios?.funcionamento?.dow6 || [], /// def [] // sab
        },
      },
      // idMeilisearch: row?.idMeilisearch || '',
      integradora: {
        id: row?.integradora?.id || '',
        _nome: row?.integradora?._nome || '',
      },
      nome: row?.nome || '', // 40
      meilisearch: this.#meilisearchServ.fix(row?.meilisearch),
      parceiro: {
        id: row?.parceiro?.id || '',
        _nome: row?.parceiro?._nome || '',
      },
      pedidos: {
        limiteDiasAgendamento: {
          entrega: Number(row?.pedidos?.limiteDiasAgendamento?.entrega) || 0, /// def 1
          retirada: Number(row?.pedidos?.limiteDiasAgendamento?.retirada) || 0, /// def 1
        },
        minimo: {
          entrega: PEDIDO_MIN_ENTREGA, /// def 0
          retirada: PEDIDO_MIN_RETIRADA, /// def 0
        },
        valorCarrinhoEntregaGratis: Number(row?.pedidos?.valorCarrinhoEntregaGratis) || 0, /// def 0
      },
      rede: {
        id: row?.rede?.id || '',
        __logo: row?.rede?.__logo || IMG_NO_IMG,
        _nome: row?.rede?._nome || '',
        _tema: row?.rede?._tema || '',
      },
      redesSociais: {
        facebook: row?.redesSociais?.facebook || '', /// def ''
        instagram: row?.redesSociais?.instagram || '', /// def ''
        twitter: row?.redesSociais?.twitter || '', /// def ''
        youtube: row?.redesSociais?.youtube || '', /// def ''
        __some: !!row?.redesSociais?.__some, /// def '';
      },
      siteInstitucional: row?.siteInstitucional || '', /// def ''
      tipo: {
        id: row?.tipo?.id || '',
        _nome: row?.tipo?._nome || '',
      },

      // system
      _criadoEm: row?._criadoEm || null,
      _criadoPor: {
        id: row?._criadoPor?.id || '',
        nome: row?._criadoPor?.nome || '',
      },
      _modificadoEm: row?._modificadoEm || null,
      _modificadoPor: {
        id: row?._modificadoPor?.id || '',
        nome: row?._modificadoPor?.nome || '',
      },
      _exists: !!row?._exists,
      _idLocalidade: row?._idLocalidade || '',
      _op: row?._op || '',
      _qtde: {
        // bairros: number;
        // departamentos: number;
        // feriados: number;
        // formasPgto: number;
        galeria: Number(row?._qtde?.galeria) || 0,
        horariosFuncionamento: Number(row?._qtde?.horariosFuncionamento) || 0,
        horariosEntrega: Number(row?._qtde?.horariosEntrega) || 0,
        // horariosRetirada: number;
        // operadores: number;
        // produtosAtacado: number;
        pedidos: Number(row?._qtde?.pedidos) || 0,
        // produtos: number;
        // promocoes: number;
        setoresEntrega: Number(row?._qtde?.setoresEntrega) || 0,
        // _status: boolean;
        // subdepartamentos: number;
        // categoriasProdutos: number;
        // operadores: number;
      },

      // realtime
      __displayInPagination: !!row?.__displayInPagination,
      __idInfo: calcLojaIdInfo(row?.id || '') || null,
      __pedidoMinimo: !!isRetirada ? PEDIDO_MIN_RETIRADA : PEDIDO_MIN_ENTREGA,
      __qtde: {
        departamentos: Number(row?.__qtde?.departamentos) || 0,
        formasPgto: Number(row?.__qtde?.formasPgto) || 0,
        produtos: Number(row?.__qtde?.produtos) || 0,
        promocoes: Number(row?.__qtde?.promocoes) || 0,
        status: !!row?.__qtde?.status,
        subdepartamentos: Number(row?.__qtde?.subdepartamentos) || 0,
      },
      __img: {
        status: !!row?.__img?.status,
        thumb: row?.__img?.thumb || IMG_H_NO_IMG,
      },
    };

    // const REDE_LOGO: string = `${environment.firebase.mercadeiro.storage.root}redes%2f${row?.rede?.id}.png?alt=media`;
    // // console.log(REDE_LOGO);
    // checkImg(
    //   REDE_LOGO,
    //   () => { R.rede.__logo = REDE_LOGO; },
    //   () => { R.rede.__logo = IMG_H_NO_IMG; }
    // );
    // const IMG: string = `${environment.firebase.mercadeiro.storage.root}lojas%2F${row?.id}%2Fthumb.jpg?alt=media` || '';
    // // console.log(IMG);
    // checkImg(
    //   IMG,
    //   () => { R.__img = { status: true, thumb: IMG, }; },
    //   () => { R.__img = { status: false, thumb: IMG_H_NO_IMG, }; }
    // );

    // return R;

    // row = row || {};
    // let R: ILoja = row;
    // // console.log(row);

    // // id
    // // R.objectID = get(row, 'objectID') || '';
    // R.id = row?.id || '';

    // const DONO_ID: string = row?.dono?.id || '';
    // const PERC_SERV_CLIENTE: number = Number(row?.faturamento?.servicos?.percCliente) || 0;
    // const VAL_SERV_CLIENTE: number = Number(row?.faturamento?.servicos?.valor) || 0;
    // // user
    // // R.algolia = {
    // //   adminApiKey: get(row, 'algolia.adminApiKey') || '',
    // //   appId: get(row, 'algolia.appId') || '',
    // //   email: get(row, 'algolia.email') || '',
    // //   searchApiKey: get(row, 'algolia.searchApiKey') || '',
    // //   senha: get(row, 'algolia.senha') || '',
    // // };
    // R.ativo = {
    //   // _qtdeDonos: parseInt(get(row, 'ativo._qtdeDonos')) ,
    //   _rede: !!row?.ativo?._rede,
    //   habilitado: !!row?.ativo?.habilitado,
    //   status: !!row?.ativo?.status,
    //   _status: !!row?.ativo?._status,
    // };
    // /* R.cashback = {
    //     max: row.cashback && row.cashback.max || 0,
    //     min: row.cashback && row.cashback.min || 0,
    // }; */
    // R.cpfCnpj = row?.cpfCnpj || '';
    // // R.comissao = row.comissao || 0;
    // R.config = {
    //   _hash: row?.config?._hash || '',
    // };
    // R.distribuidora = {
    //   status: !!row?.distribuidora?.status,
    // };
    // R.dono = {
    //   email: row?.dono?.email || '',
    //   id: DONO_ID,
    //   nome: row?.dono?.nome || '',
    //   __status: !!DONO_ID,
    // };
    // R.email = row?.email || '';
    // R.endereco = row?.endereco || {};
    // R.entrega = {
    //   _bairros: row?.entrega?._bairros || [],
    //   // setores: this._setoresServ.fixes(row.entrega && row.entrega.setores || []),
    //   status: row?.entrega?.status,
    //   _roteiro: !!row?.entrega?._roteiro,
    //   taxaGeral: {
    //     status: !!row?.entrega?.taxaGeral?.status,
    //     valor: Number(row?.entrega?.taxaGeral?.valor) || 0
    //   }
    // };
    // R.faturamento = {
    //   cod: row?.faturamento?.cod || '',
    //   mensalidade: Number(row?.faturamento?.mensalidade) || 0,
    //   servicos: {
    //     anistiaMeses: Number(row?.faturamento?.servicos?.anistiaMeses) || 0,
    //     percCliente: PERC_SERV_CLIENTE,
    //     piso: Number(row?.faturamento?.servicos?.piso) || 0,
    //     __valCliente: PERC_SERV_CLIENTE / 100 * VAL_SERV_CLIENTE,
    //     valor: VAL_SERV_CLIENTE
    //   },
    // };
    // R.fones = {
    //   celular: row?.fones?.celular || '',
    //   fixo: row?.fones?.fixo || '',
    //   mensagens: row?.fones?.mensagens || ''
    // };
    // R.formas = row?.formas || [];
    // R.horarios = {
    //   entrega: {
    //     dow0: row?.horarios?.entrega?.dow0 || [],
    //     dow1: row?.horarios?.entrega?.dow1 || [],
    //     dow2: row?.horarios?.entrega?.dow2 || [],
    //     dow3: row?.horarios?.entrega?.dow3 || [],
    //     dow4: row?.horarios?.entrega?.dow4 || [],
    //     dow5: row?.horarios?.entrega?.dow5 || [],
    //     dow6: row?.horarios?.entrega?.dow6 || [],
    //   },
    //   funcionamento: {
    //     dow0: row?.horarios?.funcionamento?.dow0 || [],
    //     dow1: row?.horarios?.funcionamento?.dow1 || [],
    //     dow2: row?.horarios?.funcionamento?.dow2 || [],
    //     dow3: row?.horarios?.funcionamento?.dow3 || [],
    //     dow4: row?.horarios?.funcionamento?.dow4 || [],
    //     dow5: row?.horarios?.funcionamento?.dow5 || [],
    //     dow6: row?.horarios?.funcionamento?.dow6 || [],
    //   },
    // };
    // R.idMeilisearch = row?.idMeilisearch || '';
    // // R.idAgelo = get(row, 'idAgelo') || '';
    // R.integradora = {
    //   id: row?.integradora?.id || '',
    //   // idLoja: get(row, 'integradora.idLoja') || '',
    //   _nome: row?.integradora?._nome || ''
    // };
    // // console.log(get(row, 'id'));
    // R.nome = row?.nome || '';
    // R.parceiro = {
    //   id: row?.parceiro?.id || '',
    //   // idLoja: get(row, 'parceiro.idLoja') || '',
    //   _nome: row?.parceiro?._nome || ''
    // };
    // R.pedidos = {
    //   limiteDiasAgendamento: {
    //     entrega: Number(row?.pedidos?.limiteDiasAgendamento?.entrega) || 0,
    //     retirada: Number(row?.pedidos?.limiteDiasAgendamento?.retirada) || 0
    //   },
    //   minimo: {
    //     entrega: Number(row?.pedidos?.minimo?.entrega) || 0,
    //     retirada: Number(row?.pedidos?.minimo?.retirada) || 0,
    //   },
    //   // _usaDepartamentosBase: !!get(row, 'pedidos._usaDepartamentosBase'),
    //   valorCarrinhoEntregaGratis: Number(row?.pedidos?.valorCarrinhoEntregaGratis) || 0
    // };
    // R.rede = {
    //   id: row?.rede?.id || '',
    //   __logo: row?.rede?.__logo || '',
    //   _nome: row?.rede?._nome || '',
    //   _tema: row?.rede?._tema || '',
    // };
    // R.redesSociais = {
    //   facebook: row?.redesSociais?.facebook || '',
    //   instagram: row?.redesSociais?.instagram || '',
    //   twitter: row?.redesSociais?.twitter || '',
    //   youtube: row?.redesSociais?.youtube || '',
    //   __some: false
    // };
    // R.siteInstitucional = row?.siteInstitucional || '';
    // R.tipo = {
    //   id: row?.tipo?.id || '',
    //   _nome: row?.tipo?._nome || ''
    // };

    // // system
    // R._criadoEm = row?._criadoEm || null;
    // R._criadoPor = {
    //   id: row?._criadoPor?.id,
    //   nome: row?._criadoPor?.nome
    // };
    // R._exists = !!row?._exists;
    // R._idLocalidade = row?._idLocalidade;
    // // R._idRede = row?._idRede || '';
    // // R._modificadoEm = row?._modificadoEm || null;
    // R._modificadoEm = row?._modificadoEm || null;
    // R._modificadoPor = {
    //   id: row?._modificadoPor?.id,
    //   nome: row?._modificadoPor?.nome
    // };
    // R._qtde = {
    //   // bairros: parseInt(get(row, '_qtde.bairros') ) || 0,
    //   // departamentos: Number(get(row, '_qtde.departamentos') ) || 0,
    //   // feriados: Number(get(row, '_qtde.feriados') ) || 0,
    //   // formasPgto: Number(get(row, '_qtde.formasPgto') ) || 0,
    //   galeria: Number(row?._qtde?.galeria) || 0,
    //   horariosEntrega: Number(row?._qtde?.horariosEntrega) || 0,
    //   horariosFuncionamento: Number(row?._qtde?.horariosFuncionamento) || 0,
    //   // horariosRetirada: Number(row?._qtde?.horariosRetirada ) || 0,
    //   // operadores: Number(row?._qtde?.operadores ) || 0,
    //   // produtosAtacado: Number(row?._qtde?.produtosAtacado ) || 0,
    //   pedidos: Number(row?._qtde?.pedidos) || 0,
    //   // produtos: Number(row?._qtde?.produtos ) || 0,
    //   // promocoes: Number(row?._qtde?.promocoes ) || 0,
    //   setoresEntrega: Number(row?._qtde?.setoresEntrega) || 0,
    //   // _status: !!row?._qtde?._status,
    //   // subdepartamentos: Number(get(row, '_qtde.subdepartamentos') ) || 0
    // };
    // // R._qtde.horariosEntrega = [
    // //   ...get(R, 'horarios.entrega.dow0', []),
    // //   ...get(R, 'horarios.entrega.dow1', []),
    // //   ...get(R, 'horarios.entrega.dow2', []),
    // //   ...get(R, 'horarios.entrega.dow3', []),
    // //   ...get(R, 'horarios.entrega.dow4', []),
    // //   ...get(R, 'horarios.entrega.dow5', []),
    // //   ...get(R, 'horarios.entrega.dow6', [])
    // // ].length;
    // // R._qtde.horariosFuncionamento = [
    // //   ...get(R, 'horarios.funcionamento.dow0', []),
    // //   ...get(R, 'horarios.funcionamento.dow1', []),
    // //   ...get(R, 'horarios.funcionamento.dow2', []),
    // //   ...get(R, 'horarios.funcionamento.dow3', []),
    // //   ...get(R, 'horarios.funcionamento.dow4', []),
    // //   ...get(R, 'horarios.funcionamento.dow5', []),
    // //   ...get(R, 'horarios.funcionamento.dow6', [])
    // // ].length;
    // // R._opms = get(row, '_opms') || [];
    // // R._triggers = {
    // //   bairrosSync: get(row, '_triggers.bairrosSync') || null,
    // //   bairrosDel: get(row, '_triggers.bairrosDel') || null,
    // // };

    // // realtime
    // // R.__carrinho = get(row, '__carrinho') || null;
    // R.__displayInPagination = !!row?.__displayInPagination;
    // R.__idInfo = lojaIdInfo(R?.id || '') || null;
    // // // R.__integradoraPropria = R.integradora.id !== ID_INTEGRADORA_core;
    // // if (isRede !== undefined) {
    // //   R.__isRede = isRede;
    // // } // if
    // // R.redesSociais.__some = !!R.redesSociais.facebook
    // //   || !!R.redesSociais.instagram
    // //   || !!R.redesSociais.twitter
    // //   || !!R.redesSociais.youtube;
    // // R.__limiteDiasAgendamento = isRetirada
    // //   ? R.config.limiteDiasAgendamento.retirada
    // //   : R.config.limiteDiasAgendamento.entrega;
    // // R.__pedidoMinimo = isRetirada ? R.pedidoMinimo.retirada : R.pedidoMinimo.entrega;
    // // R.__redeLogo = `${environment.firebase.mercadeiro.storage.root}redes%2f${R._idRede}.png?alt=media` || '';
    // // R.__servicos = {
    // //   valorCliente: R.config.servicos._valor * R.config.servicos.percCliente / 100,
    // //   valorLoja: R.config.servicos._valor * (100 - R.config.servicos.percCliente) / 100
    // // };
    // // R.__setores = this._setoresServ.fixes(get(row, '__setores'));
    // // // console.log(R.id);
    // // R.__idInfo = lojaIdInfo(R.id);
    // R.__qtde = {
    //   departamentos: Number(row?.__qtde?.departamentos) || 0,
    //   formasPgto: Number(row?.__qtde?.formasPgto) || 0,
    //   produtos: Number(row?.__qtde?.produtos) || 0,
    //   promocoes: Number(row?.__qtde?.promocoes) || 0,
    //   subdepartamentos: Number(row?.__qtde?.subdepartamentos) || 0,
    //   status: !!row?.__qtde?.status,
    // };
    // R.rede.__logo = `${environment.firebase.mercadeiro.storage.root}redes%2f${R.rede.id}.png?alt=media` || 'R.rede.__logo';
    // // console.log(R.rede.__logo);
    // checkImg(
    //   R.rede.__logo,
    //   () => {
    //     R.rede.__logo = R.rede.__logo;
    //   },
    //   () => {
    //     R.rede.__logo = IMG_H_NO_IMG;
    //   }
    // );
    // const IMG: string = `${environment.firebase.mercadeiro.storage.root}lojas%2F${R.id}%2Fthumb.jpg?alt=media` || '';
    // // console.log(IMG);
    // checkImg(
    //   IMG,
    //   () => {
    //     R.__img = {
    //       status: true,
    //       thumb: IMG,
    //     };
    //   },
    //   () => {
    //     R.__img = {
    //       status: false,
    //       thumb: IMG_H_NO_IMG,
    //     };
    //   }
    // );

    // return R;
  }

  // const REDE_LOGO: string = `${environment.firebase.mercadeiro.storage.root}redes%2f${row?.rede?.id}.png?alt=media`;
  // // console.log(REDE_LOGO);
  // checkImg(
  //   REDE_LOGO,
  //   () => { R.rede.__logo = REDE_LOGO; },
  //   () => { R.rede.__logo = IMG_NO_IMG; }
  // );
  // const IMG: string = `${environment.firebase.mercadeiro.storage.root}lojas%2F${row?.id}%2Fthumb.jpg?alt=media` || '';
  // // console.log(IMG);
  // checkImg(
  //   IMG,
  //   () => { R.__img = { status: true, thumb: IMG, }; },
  //   () => { R.__img = { status: false, thumb: IMG_H_NO_IMG, }; }
  // );

  lfix(row: Partial<ILoja>): Promise<[TNullable<ILoja>, TNullable<ILoja>]> {
    const LOJA_ID: string = row?.id || '';
    const REDE_ID: string = row?.rede?.id || '';
    if (!REDE_ID || !LOJA_ID) {
      return Promise.resolve([null, null]); // reject(new Error('Nenhuma rede ou loja indicada.'));
    } // if

    const REDE_IMG_PROMISE: Promise<any> = new Promise(
      resolve => {
        const IMG: string = `${environment.firebase.mercadeiro.storage.root}/redes%2f${REDE_ID}.png?alt=media`;
        // console.log(IMG);
        checkImg(
          IMG,
          // () => { console.log(IMG); resolve(this.fix({ ...row, __logo: IMG })) },
          () => resolve(this.fix({ ...row, rede: { ...row?.rede, __logo: IMG } })),
          () => resolve(this.fix({ ...row, rede: { ...row?.rede, __logo: IMG_NO_IMG } })),
        );
      }
    );

    const LOJA_IMG_PROMISE: Promise<any> = new Promise(
      resolve => {
        const IMG: string = `${environment.firebase.mercadeiro.storage.root}/lojas%2F${LOJA_ID}%2Fthumb.jpg?alt=media` || '';
        // console.log(IMG);
        checkImg(
          IMG,
          // () => { console.log(IMG); resolve(this.fix({ ...row, __logo: IMG })) },
          () => resolve(this.fix({ ...row, __img: { ...row?.__img, status: true, thumb: IMG } })),
          () => resolve(this.fix({ ...row, __img: { ...row?.__img, status: false, thumb: IMG_H_NO_IMG } })),
        );
      }
    );

    return Promise.all([REDE_IMG_PROMISE, LOJA_IMG_PROMISE]);
  }

  fixes(
    docs: any[],
    isRetirada: boolean = true,
  ): ILoja[] {
    return (docs || []).map((doc: Partial<ILoja>) => this.fix(doc, isRetirada));
  }

  lfixes(docs: Partial<ILoja>[]): Observable<ILoja[]> {
    // console.log(docs);
    const PROMISES: Promise<[TNullable<ILoja>, TNullable<ILoja>]>[] = (docs || [])
      .map((doc: Partial<ILoja>) => this.lfix(doc));
    return from(Promise.all(PROMISES))
      .pipe(
        // tap((docs: any[]) => console.log(docs)),
        map(
          (lojas: [TNullable<ILoja>, TNullable<ILoja>][]) => (lojas || [])
            .map(
              (lojas: [TNullable<ILoja>, TNullable<ILoja>]) => {
                const [L1, L2] = lojas;
                delete L1?.__img;
                delete L2?.rede;
                // console.log(this.fix({ ...L1, ...L2 }));
                return this.fix({ ...L1, ...L2 });
              }
            )
        ),
        // shareReplay() 
      );
  }
  //#endregion

  //#region R
  doc(lojaId: string): Observable<TNullable<ILoja>> {
    // console.log('lojaId', lojaId);
    // console.log(lojaId);
    if (!!lojaId) {
      const INFO: TNullable<ILojaIdInfoRet> = calcLojaIdInfo(lojaId);
      // console.log(INFO);
      const PATH: string = INFO?.lojaPath || '';
      // console.log('PATH', PATH);
      if (!!PATH) {
        // const PATH: string = `${lojaPath}/${lojaId}`;
        // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<TNullable<ILoja>>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<TNullable<ILoja>, ObservableInput<TNullable<ILoja>>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando loja.')
            )
          );
      } // if
      return throwError(() => 'Identificador de loja inválido.');
    } // if
    return of(null); // throwError(() => 'Nenhuma loja indicada.');
  }

  docs(): Observable<ILoja[]> {
    const PATH: string = `/_lojas/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH)) as Observable<ILoja[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<ILoja[], ObservableInput<ILoja[]>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando lojas.')
        )
      );
  }

  private(idLoja: string): Observable<ILojaP> {
    // console.log('idLoja', idLoja);
    if (!!idLoja) {
      const INFO: TNullable<ILojaIdInfoRet> = calcLojaIdInfo(idLoja);
      // console.log(INFO);
      const LOJA_PATH: string = INFO?.lojaPath || '';
      // console.log('PATH', PATH);
      if (!!LOJA_PATH) {
        const PATH: string = `${LOJA_PATH}/private/${PRIVATE_TAG}`;
        // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<ILojaP>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando área privada da loja.')
            )
          );
      } // if
      return throwError(() => 'Identificador de loja inválido.');
    } // if
    return of(null); // throwError(() => 'Nenhuma loja indicada.');
  }

  apiQtde(lojaPath: string): Observable<ILojaApiQtde> {
    // console.log(lojaPath);
    if (!!lojaPath) {
      const PATH: string = `${lojaPath}/api/${QTDE_TAG}`;
      // return (docData(doc(this.#db, PATH)) as Observable<ILojaApiQtde>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => doc?.data()?._qtde || null),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<ILojaApiQtde, ObservableInput<ILojaApiQtde>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando contadores de api da loja.')
          )
        );
    } // if
    return of(null); // throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region U
  update(
    idLoja: string,
    changes: Partial<ILoja>
  ): Observable<any> {
    // console.log(idLoja);
    // console.log(changes);
    if (!!idLoja) {
      const INFO: TNullable<ILojaIdInfoRet> = calcLojaIdInfo(idLoja);
      // console.log(INFO);
      const PATH: string = INFO?.lojaPath || '';
      // console.log('PATH', PATH);
      if (!!PATH) {
        return from(
          setDoc(
            doc(this.#db, PATH),
            changes,
            { merge: true }
          )
        )
          .pipe(
            // map(() => { throw new Error(`Erro apagando ${PATH}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando loja.')
            )
          );
      } // if
      return throwError(() => 'Identificador de loja inválido.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}