//#region guid
/*
 * Generates a GUID string.
 * @returns {String} The generated GUID.
 * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
 * @author Slavik Meltser (slavik@meltser.info).
 * @link http://slavik.meltser.info/?p=142
 */
export const guid = (size: number = 8, base: number = 16): string => {
  let s: string = '';
  while (s.length < size) {
    s += Math.random().toString(base).substr(2);
  } // while
  return s.substr(0, size);
  // var p = (Math.random().toString(base) + '0'.repeat(length)).substr(2, 8);
  // return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
};
