//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from "@angular/core";
//#endregion

//#region models
import { PAG_ITENS_POR_PAG } from "../../../_misc/_models/consts";
import { CorAngularFirePaginator } from "../../../_ng/_models/_classes";
//#endregion

//#region services
import { CorStorageService } from "../../../_ng/_services";
//#endregion

@Component({
  selector: "cor-mat-pagination-nav",
  templateUrl: "pagination-nav.component.html",
  styleUrls: ["pagination-nav.component.scss"]
})
export class CorMatPaginationNavComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // offsetMax
  @Input() offsetMax: any = 0;
  // paginator
  @Input() paginator: CorAngularFirePaginator<any>;
  // offsetStorageKey
  #offsetStorageKey: string;
  get offsetStorageKey(): string { return this.#offsetStorageKey; }
  @Input() set offsetStorageKey(val: string) {
    this.#offsetStorageKey = val;
    this.offsetSel = this.#storageServ.ls_get(val) || '12';
  }
  // size
  @Input() size: 'sm' | 'lg' = 'sm';
  //#endregion

  //#region outputs
  @Output() offsetChanged$ = new EventEmitter<string>();
  //#endregion

  //#region publics
  itensPorPag = signal<number[]>([]);
  //#endregion

  //#region offsetSel
  #offsetSel: string;
  get offsetSel(): string { return this.#offsetSel; }
  set offsetSel(val: string) {
    // console.log(val);
    this.#offsetSel = val;
    this.offsetStorageKey && this.#storageServ.ls_set(
      this.offsetStorageKey,
      val
    );
    this.offsetChanged$.emit(val);
  }
  //#endregion

  //#region injects
  #storageServ = inject(CorStorageService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    const MAX: number = Number(this.offsetMax) || 0;
    this.itensPorPag.set(PAG_ITENS_POR_PAG.filter((i: number) => MAX === 0 || i <= MAX));
  }
  //#endregion

  //#region functions
  tidn(index: any, item: number): number { return item || 0; }
  //#endregion
}
