//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region libs
import { min2time } from '../../../_libs/_misc/_convert';
//#endregion

@Pipe({ name: 'min2time' })
export class CorMin2TimePipe implements PipeTransform {
  transform(value: number, hours: boolean = true): string {
    return min2time(value, hours);
  }
}
