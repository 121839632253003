//#region ng
import {
  Pipe,
  PipeTransform
} from '@angular/core';
//#endregion

//#region models
import { formataNumPedido } from '../../_misc/_libs';
//#endregion

@Pipe({ name: 'numPedido' })
export class MeNumPedidoPipe implements PipeTransform {
  transform(value: string): string {
    return formataNumPedido(value);
  };
}
