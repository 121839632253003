//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  signal,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
//#endregion

//#region models
import { ICorViacepApi } from "../../../_misc/_models/_interfaces/_apis/viacep";
import { ICorAlgoliaMap } from "../../../_misc/_models/_interfaces/_maps/algolia";
import { CorFormValidation } from "../../../_ng/_models/_classes/form-validation";
//#endregion

@Component({
  selector: "cor-mat-algolia-form",
  templateUrl: "algolia-form.component.html",
  styleUrls: ["algolia-form.component.scss"]
})
export class CorMatAlgoliaFormComponent {
  //#region inputs
  // algolia
  #algolia: ICorAlgoliaMap;
  get algolia(): ICorAlgoliaMap { return this.#algolia }
  @Input({ required: true }) set algolia(val: ICorAlgoliaMap) {
    this.#algolia = val;
    this.algoliaForm.set(
      this.#fb.group({
        algolia: this.#fb.group({
          adminApiKey: [val?.adminApiKey, [Validators.required]],
          appId: [val?.appId, [Validators.required]],
          email: [val?.email, [Validators.required]],
          searchApiKey: [val?.searchApiKey, [Validators.required]],
          senha: [val?.senha, [Validators.required]],
        }),
      })
    );
  }

  // endereco
  @Input() endereco: ICorViacepApi;
  // isMobile
  @Input() isMobile: boolean = false;
  // isWideForm
  #isWideForm: boolean = false;
  get isWideForm(): boolean { return this.#isWideForm; }
  @Input() set isWideForm(val: boolean) {
    this.#isWideForm = val;
    this.halfSize.set(!!val ? 'col-6' : 'col-12');
  }
  // showDelete
  @Input() showDelete: boolean = false;
  // submitCaption
  @Input() submitCaption: string = '';
  // apiErrors
  #apiErrors: any;
  get apiErrors(): any { return this.#apiErrors; }
  @Input() set apiErrors(val: any) {
    // console.log(val);
    this.#apiErrors = val;
    this.fv().setApiErrors(val);
  }
  //#endregion

  // outputs
  @Output() submit$ = new EventEmitter<ICorViacepApi>();
  //#endregion

  //#region publics
  algoliaForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  halfSize = signal<string>('');
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation(this.apiErrors));
  }
  //#endregion

  //#region Controls getters
  get adminApiKeyRef(): AbstractControl { return this.algoliaForm()?.get('algolia.adminApiKey'); }
  get appIdRef(): AbstractControl { return this.algoliaForm()?.get('algolia.appId'); }
  get emailRef(): AbstractControl { return this.algoliaForm()?.get('algolia.email'); }
  get searchApiKeyRef(): AbstractControl { return this.algoliaForm()?.get('algolia.searchApiKey'); }
  get senhaRef(): AbstractControl { return this.algoliaForm()?.get('algolia.senha'); }
  //#endregion
}
