//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map,
  tap
} from 'rxjs/operators';
//#endregion

//#region models
// import { environment } from 'src/environments/environment';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
import { IMovPedidoTotMap } from '../../_misc/_models/_interfaces/_maps';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { lpad, paddy } from '../../../_libs/_misc/_strings';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class MovPedidosTotService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IMovPedidoTotMap>): IMovPedidoTotMap {
    return {
      // id
      id: row?.id || '',

      // user
      qtde: {
        pedidos: Number(row?.qtde?.pedidos) || 0,
        itens: Number(row?.qtde?.itens) || 0,
        volumes: Number(row?.qtde?.volumes) || 0,
      },
      servicos: {
        cliente: Number(row?.servicos?.cliente) || 0,
        loja: Number(row?.servicos?.loja) || 0,
        val: Number(row?.servicos?.val) || 0,
      },
      taxaEntrega: Number(row?.taxaEntrega) || 0,
      valor: {
        pagar: Number(row?.valor?.pagar) || 0,
        prod: Number(row?.valor?.prod) || 0,
        // ticketMedio: Number(row?.qtde?.pedidos) || 0,
      },

      // system
      _criadoEm: row?._criadoEm || '',

      // system
      __pos: Number(row?.id) || 0,
    }
  }

  fixes(docs: Partial<IMovPedidoTotMap>[]): IMovPedidoTotMap[] {
    return (docs || []).map((doc: Partial<IMovPedidoTotMap>) => this.fix(doc?._total || doc));
  }
  //#endregion

  //#region R
  // /_ => mov-pedidos
  // /_ufs/mg/localidades/uberaba--mg/lojas/699fc369--modelo--mg--uberaba/ => mov-pedidos
  // /_integradoras/modelo/ => mov-pedidos
  // /_contas/sYe94fW9AdOoZZXCqTdJbr5XGn93/ => mov-pedidos

  docsY(pre: string): Observable<IMovPedidoTotMap[]> {
    if (!!pre) {
      const PATH: string = `${pre}mov-pedidos/${ODOCS_TAG}`;
      // console.log(PATH);
      // return (docData(doc(this.#db, PATH)) as Observable<IMovPedidoTotMap[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          // tap((doc: any) => console.log(Object.values(doc?._odocs || {}))),
          map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
          // tap((doc: any) => console.log(doc)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IMovPedidoTotMap[], ObservableInput<IMovPedidoTotMap[]>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando movimentos de pedidos.')
          )
        );
      /* <IMovPedidoTotMap[], ObservableInput<IMovPedidoTotMap[]>> */
    } // if
    return of(null); // throwError(() => 'Nenhuma localização indicada.');
  }

  docsM(pre: string, ano: string): Observable<IMovPedidoTotMap[]> {
    if (!!pre) {
      if (!!ano) {
        // /_mov-pedidos/_2023/mp-meses/{{ODOCS}}
        const PATH: string = `${pre}mov-pedidos/_${ano}/mp-meses/${ODOCS_TAG}`;
        // console.log(PATH);
        // return (docData(doc(this.#db, PATH)) as Observable<IMovPedidoTotMap[]>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            // tap((doc: any) => console.log(Object.values(doc?._odocs || {}))),
            map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
            // tap((doc: any) => console.log(doc)),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<IMovPedidoTotMap[], ObservableInput<IMovPedidoTotMap[]>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando movimentos de pedidos.')
            )
          );
      } else {
        return of(null); // throwError(() => 'Nenhum ano indicado.');
      } // if
    } // if
    return of(null); // throwError(() => 'Nenhuma localização indicada.');
  }

  docsD(pre: string, ano: string, mes: string): Observable<IMovPedidoTotMap[]> {
    if (!!pre) {
      if (!!ano) {
        if (!!mes) {
          // const MES: string = paddy(mes, 2, '0');
          // /_mov-pedidos/_2023/mp-meses/_06/mp-dias/{{ODOCS}}
          const PATH: string = `${pre}mov-pedidos/_${ano}/mp-meses/_${mes}/mp-dias/${ODOCS_TAG}`;
          // console.log(PATH);
          // return (docData(doc(this.#db, PATH)) as Observable<IMovPedidoTotMap[]>)
          return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
            .pipe(
              // tap((doc: any) => console.log(Object.values(doc?._odocs || {}))),
              map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
              // tap((doc: any) => console.log(doc)),
              // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
              catchError<IMovPedidoTotMap[], ObservableInput<IMovPedidoTotMap[]>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando movimentos de pedidos.')
              )
            );
        } else {
          return of(null); // throwError(() => 'Nenhum mês indicado.');
        } // if
      } else {
        return of(null); // throwError(() => 'Nenhum ano indicado.');
      } // if
    } // if
    return of(null); // throwError(() => 'Nenhuma localização indicada.');
  }
  //#endregion
}
