<!-- <p>rede {{ rede | json }}</p> -->

<div class="w-card rede d-flex flex-column"
     [class.off]="!rede?.ativo?._status">
  <div class="w-card--thumb p-relative px-1 pt-1">
    <img [src]="rede?.__logo"
         [alt]="rede?.nome"
         class="img-fluid"
         *ngIf="!!rede?.__logo">

    <!-- <img [ngSrc]="rede?.__logo"
         [width]="256"
         [height]="256"
         [alt]="rede?.nome"
         class="img-fluid"
         *ngIf="!!rede?.__logo"> -->

    <div class="color-sample bg-{{rede?.tema}}"
         *ngIf="!!showTheme">
    </div>
  </div>

  <!-- <p>checkBtnHint {{ checkBtnHint| json }}</p> -->
  <!-- <p>deleteBtnHint {{ deleteBtnHint| json }}</p> -->
  <!-- <p>editBtnHint {{ editBtnHint| json }}</p> -->
  <!-- <p>searchBtnHint {{ searchBtnHint| json }}</p> -->
  <!-- <p>hasButtons {{ hasButtons| json }}</p> -->

  <div class="w-card--content">
    <h5 class="my-2"> {{ rede?.nome }} </h5>
  </div>

  <div class="w-card--footer"
       *ngIf="!!hasButtons()">
    <div class="d-flex justify-content-around">
      <button mat-icon-button
              [matTooltip]="addBtnHint"
              (click)="add$?.emit(rede)"
              *ngIf="!!addBtnHint">
        <mat-icon>add</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="checkBtnHint"
              (click)="check$?.emit(rede)"
              *ngIf="!!checkBtnHint">
        <mat-icon>check</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="searchBtnHint"
              (click)="search$?.emit(rede)"
              *ngIf="!!searchBtnHint">
        <mat-icon>search</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="editBtnHint"
              (click)="edit$?.emit(rede)"
              *ngIf="!!editBtnHint">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="deleteBtnHint"
              (click)="delete$?.emit(rede)"
              *ngIf="!!deleteBtnHint">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>