export const base64toFile = (
  base64: string,
  filename: string
): File => {
  const ARR = base64.split(',');
  // const MIME = (ARR[0].match(/:(.*?);/)[1]) || undefined;
  let mime: string = '';
  if (ARR && ARR.length) {
    mime = ((ARR[0].match(/:(.*?);/) || [])[1]) || '';
  } // if
  const BSTR = atob(ARR[1]);
  let n = BSTR.length;
  const U8ARR = new Uint8Array(n);
  while (n--) {
    U8ARR[n] = BSTR.charCodeAt(n);
  } // while
  return new File([U8ARR], filename, { type: mime });
};
