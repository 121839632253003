//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
//#endregion

//#region models
import { IItemCarrinhoMap } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { TItemPedidoTipoQtde } from '../../../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

@Component({
  selector: 'separacao-qtde-caption',
  templateUrl: './separacao-qtde-caption.component.html',
  styleUrls: ['./separacao-qtde-caption.component.scss']
})
export class SeparacaoQtdeCaptionComponent {
  //#region inputs
  // item
  @Input({ required: true }) item: IItemCarrinhoMap;
  // off
  @Input() off: boolean = false;
  // tipoQtde
  @Input({ required: true }) tipoQtde: TItemPedidoTipoQtde;
  //#endregion

  ngOnInit() {
    // console.log('SeparacaoQtdeCaptionComponent', this.item?._separado?.qtde);
  }
}
