<!-- <p>integradora {{ integradora | json }}</p> -->

<div class="w-card integradora d-flex flex-column"
     [class.off]="!integradora?.ativo?._status"
     [class.sel]="!!isSelected">
  <div class="w-card--thumb p-relative px-1 pt-1">
    <img [ngSrc]="integradora?.__logo"
         [width]="256"
         [height]="256"
         [alt]="integradora?.nome"
         class="img-fluid"
         *ngIf="!!integradora?.__logo">
  </div>

  <!-- <p>checkBtnHint {{ checkBtnHint| json }}</p> -->
  <!-- <p>deleteBtnHint {{ deleteBtnHint| json }}</p> -->
  <!-- <p>editBtnHint {{ editBtnHint| json }}</p> -->
  <!-- <p>searchBtnHint {{ searchBtnHint| json }}</p> -->
  <!-- <p>hasButtons {{ hasButtons| json }}</p> -->

  <div class="w-card--content">
    <h5 class="my-2">{{ integradora?.nome }}</h5>

    <small class="o-50">{{ integradora?.conta?._email }}</small>
  </div>

  <div class="w-card--footer"
       *ngIf="!!hasButtons()">
    <div class="d-flex justify-content-around">
      <button mat-icon-button
              [matTooltip]="checkBtnHint"
              (click)="check$.emit(integradora)"
              *ngIf="!!checkBtnHint">
        <mat-icon>check</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="searchBtnHint"
              (click)="search$.emit(integradora)"
              *ngIf="!!searchBtnHint">
        <mat-icon>search</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="editBtnHint"
              (click)="edit$.emit(integradora)"
              *ngIf="!!editBtnHint">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-icon-button
              [matTooltip]="deleteBtnHint"
              (click)="delete$.emit(integradora)"
              *ngIf="!!deleteBtnHint">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>