//#region ng
import {
  Component,
  inject,
  Input,
  signal,
} from '@angular/core';
//#endregion

//#region firebase
import { Firestore } from '@angular/fire/firestore';
//#endregion

//#region mat
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import {
  Subject,
  Subscription,
} from 'rxjs';
import {
  first,
  finalize
} from 'rxjs/operators';
//#endregion

//#region models
import {
  IConta,
  INotificacao
} from '../../../_misc/_models/_interfaces/_cols';
import { CorAngularFirePaginator } from '../../../../_core/_ng/_models/_classes';
//#endregion

//#region services
import {
  ContasService,
  NotificacoesService
} from '../../../_ng/_services';
import { CorLoaderService } from '../../../../_core/_ng/_services';
import { compareValues } from '../../../../_libs/_misc/_arrays';
//#endregion

@Component({
  selector: 'me-mat-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})
export class MeMatNotificacoesComponent {
  //#region inputs
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  @Input({ required: true }) set conta(val: IConta) {
    this.#conta = !!val ? this.#contasServ.fix(val) : null;
    // console.log(CONTA_ID);
    if (!!this.conta?.id) {
      const NOTIFICACOES_PATH: string = `/_notificacoes`;
      this.paginator = new CorAngularFirePaginator(
        this._db,
        NOTIFICACOES_PATH,
        6, // Number(this.itemsPerPage || '12'),
        [{ field: '_criadoEm', direction: 'desc' }],
        [
          { field: 'target.idConta', op: '==', val: this.conta?.id },
          // { field: '_lido', op: '==', val: false }
        ],
        (snaps: INotificacao[]) => {
          // console.log({ snaps });
          this.#refresh(snaps);
          // this.notificacoes.set(snaps);
          // if (!!this.notificacoes()?.length) {

          //   this.#refresh(this._notificacoesServ.fixes(snaps));
          // } // if
          // this.#refresh(NOTIFICACOES);

          /* (snaps || [])
            .forEach(
              (s: INotificacao) => {
                let item: INotificacao = (this.notificacoes || [])
                  .find((n: INotificacao) => n?.id === s?.id);
                if (!!item) {
                  item = { ...item, ...s };
                } else {
                  (this.notificacoes || []).push(this._notificacoesServ.fix(s));
                } // else
              }
            ); */
        }
      );

      this.#notificacoesUnsub?.unsubscribe();
      this.#notificacoesUnsub = this.paginator.items$
        .subscribe(
          (notificacoes: INotificacao[]) => {
            // console.log({ notificacoes });

            const NOTIFICACOES: INotificacao[] = this._notificacoesServ.fixes(notificacoes)
              .filter((e: INotificacao) => !!e?.__displayInPagination);
            this.#refresh(NOTIFICACOES);
            // console.log(JSON.stringify(this.notificacoes));
          }
        );
    } // if
  };
  //#endregion

  //#region publics
  notificacoes = signal<INotificacao[]>(null);
  paginator: CorAngularFirePaginator<INotificacao>;
  //#endregion

  //#region privates
  #notificacoesUnsub: Subscription;
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  _db = inject(Firestore);
  _notificacoesServ = inject(NotificacoesService);
  //#endregion

  //#region lifecycles
  ngOnDestroy(): void {
    this.#notificacoesUnsub?.unsubscribe();
    this.paginator?.unsub();
  }
  //#endregion

  //#region functions
  tid_not(index: any, item: INotificacao): string { return item?.id || ''; }

  #refresh(notificacoes: INotificacao[]) {
    this.notificacoes.update(
      (prev: INotificacao[]) => {
        // console.log({ prev });
        // console.log({ notificacoes });

        let ret: INotificacao[] = [];
        (notificacoes || [])
          .concat(prev || [])
          .forEach(
            (i: INotificacao) => {
              const N: INotificacao = ret.find((n: INotificacao) => n?.id === i?.id) || null;
              !N && ret.push(i);
              // !!N && console.log({ N })
            }
          );

        // console.log({ ret });
        return this._notificacoesServ.fixes(
          ret
            // .filter((e: INotificacao) => !!e?.__displayInPagination)
            .sort(compareValues('_criadoEm', 'desc'))
        );
        // (prev || []).concat(notificacoes)
      }
    );
  }
  //#endregion
}
