//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  BehaviorSubject,
  Observable,
  ObservableInput,
  throwError
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { ITerminal } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class TerminaisService {
  //#region terminalState
  // #terminalState: ITerminal;
  // setState(val: ITerminal) {
  //   // console.log(val);
  //   this.#terminalState = !!val ? this.fix(val) : null;
  //   TerminaisService.terminalStateChanged$.next(this.#terminalState);
  // }
  // getState(): ITerminal {
  //   return this.#terminalState;
  // }
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<ITerminal>): ITerminal {
    return {
      // id
      id: row?.id || '',

      // system
      _criadoEm: row?._criadoEm || null,
      _loja: {
        id: row?._loja?.id || '',
        nome: row?._loja?.nome || '',
      },
      _parceiro: {
        id: row?._parceiro?.id || '',
        nome: row?._parceiro?.nome || '',
      },
    };
  }

  fixes(docs: Partial<ITerminal>[]): ITerminal[] {
    return (docs || []).map((doc: Partial<ITerminal>) => this.fix(doc));
  }
  //#endregion

  //#region R
  doc(terminalId: string): Observable<ITerminal> {
    // console.log('terminalId', terminalId);
    if (!!terminalId) {
      const PATH: string = `_terminais/${terminalId}`;
      // console.log('PATH', PATH);
      if (!!PATH) {
        // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<ITerminal>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            map((doc: any) => (!!doc.data() ? this.fix({ ...doc.data(), id: doc.id }) : null)),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<ITerminal, ObservableInput<ITerminal>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando terminal.')
            )
          );
      } // if
      return throwError(() => 'Identificador de loja inválido.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}