//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
//#endregion

//#region meilisearch
// import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { MeiliSearch } from 'meilisearch';
//#endregion

//#region models
import { IMeilisearch } from '../../../_misc/_models/_interfaces/_cols';
import { FOCUS_TIMEOUT } from '../../../../_core/_misc/_models/consts';
import { IProGaleria } from '../../../../_produtos/_misc/_models/_interfaces/_cols';
import { IProImgProdutoMap } from '../../../../_produtos/_misc/_models/_interfaces/_maps';
//#endregion

//#region services
import { ProGaleriaService } from '../../../../_produtos/_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-galeria-get',
  templateUrl: './galeria-get.component.html',
  styleUrls: ['./galeria-get.component.scss']
})
export class MeMatGaleriaGetComponent {
  /*
  //#region inputs
  // ids
  @Input() ids: string[] = [];
  // sugestao
   #sugestao: string = '';
  get sugestao(): string { return this.#sugestao; }
  @Input() set sugestao(val: string) {
    this.#sugestao = val;
    this.search = val;
    this.onSearchSubmit();
  }
  // itemsPerPage
   #itemsPerPage: string = '10';
  get itemsPerPage(): string { return this.#itemsPerPage; }
  @Input() set itemsPerPage(val: string) {
    const CHANGED: boolean = val !== this.#itemsPerPage;
    this.#itemsPerPage = val;
    if (CHANGED) this.page = 1;
  }
  // meilisearch
  #meilisearch: Partial<IMeilisearch>;
  get meilisearch(): Partial<IMeilisearch> { return this.#meilisearch; }
  @Input() set meilisearch(val: Partial<IMeilisearch>) {
    this.#meilisearch = val;
    // console.log(this.meilisearch);    
    this.#meilisearchClient = new MeiliSearch(
      // { host: 'http://127.0.0.1:7700' }
      {
        host: this.meilisearch?.host || '',
        apiKey: this.meilisearch?.searchKey || ''
      }
    );
    this.#refresh();
  }
  //#endregion

  //#region outputs
  @Output() selected$: EventEmitter<IProImgProdutoMap> = new EventEmitter<IProImgProdutoMap>();
  //#endregion

  //#region publics
  filter: string;
  hits: IProGaleria[];
  page: number = 1;
  search: string;
  //#endregion

  //#region privates
  private #meilisearchClient: MeiliSearch;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region constructor
  constructor(
    private #galeriaServ: ProGaleriaService
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#refresh();
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
   focus() {
    setTimeout(
      () => { this.searchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  tid_gal(index: any, item: IProGaleria): string { return item?.id || ''; }

   #refresh() {
    this.#meilisearchClient && this.#meilisearchClient
      .index('galeria')
      .search(
        this.filter,
        {
          limit: 1000
        }
      )
      .then(
        (res) => {
          // console.log(res.hits);
          this.hits = this.#galeriaServ.fixes(res?.hits || []);
          this.page = 1;
          // console.log(this.hits);
        }
      );
  }
  //#endregion

  //#region methods
  onItemClick(h: IProGaleria) {
    // console.log(h);
    this.selected$.emit(
      {
        id: h?.id || '',
        _res: Number(h?._res) || 0,
        img: h?.__img?.img || '',
        thumb: h?.__img?.thumb || '',
      }
    );
  }

  onSearchSubmit() {
    this.filter = this.search;
    this.#refresh();
  }

  onSearchClearClick() {
    this.filter = '';
    this.#refresh();
  }
  //#endregion
  */
}
