//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors
} from "@angular/forms";
//#endregion

//#region models
import { ICorViacepApi } from "../../../_misc/_models/_interfaces/_apis/viacep";
import { CorFormValidation } from "../../../_ng/_models/_classes/form-validation";
import { FOCUS_TIMEOUT } from "../../../_misc/_models/consts";
//#endregion

//#region custom validators
class CustomValidator {
  static nroPos() {
    return (control: FormControl): ValidationErrors | null => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO <= 0 ? { nro: true } : null;
    }
  }
}
//#endregion

@Component({
  selector: "cor-mat-endereco-form",
  templateUrl: "endereco-form.component.html",
  styleUrls: ["endereco-form.component.scss"]
})
export class CorMatEnderecoFormComponent {
  //#region inputs
  // endereco
  @Input({ required: true }) endereco: ICorViacepApi;
  // isMobile
  @Input() isMobile: boolean = false;
  // showDelete
  @Input() showDelete: boolean = false;
  // showApelido
  @Input() showApelido: boolean = true;
  // submitCaption
  @Input() submitCaption: string = '';
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<ICorViacepApi>();
  @Output() delete$ = new EventEmitter<boolean>();
  //#endregion

  //#region publics
  enderecoForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  //#endregion

  //#region events
  nroFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.enderecoForm.set(
      this.#fb.group({
        apelido: [this.endereco?.apelido || '', [Validators.maxLength(40)]],
        bairro: [this.endereco?.bairro || '', [Validators.required]],
        cep: [this.endereco?.cep || '', [Validators.required]],
        complemento: [this.endereco?.complemento || '', [Validators.maxLength(40)]],
        localidade: [this.endereco?.localidade || '', [Validators.required]],
        logradouro: [this.endereco?.logradouro || '', [Validators.required]],
        referencia: [this.endereco?.referencia || '', [Validators.maxLength(120)]],
        nro: [this.endereco?.nro ?? null, [Validators.required, CustomValidator.nroPos()]],
        uf: [this.endereco?.uf || '', [Validators.required]]
      })
    );
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region Controls getters
  get nroRef(): AbstractControl { return this.enderecoForm()?.get('nro'); }
  //#endregion

  //#region functions
  focus() {
    // console.log('focus');
    !this.isMobile && setTimeout(
      () => { this.nroFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
