import { onlyNumbers } from './only-numbers';

export const lzpadStr = (text: string) => {
  const NRO: any = +onlyNumbers(text);
  if (!isNaN(NRO) || NRO > 0) {
    return `${NRO}`;
  } // if

  return '';
};
