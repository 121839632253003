//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
//#endregion

//#region models
import { ILocalidade } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class LocalidadesService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<ILocalidade>): ILocalidade {
    return {
      // id
      // objectID?: string;
      id: row?.id || '',

      // system
      _criadoEm: row?._criadoEm || null,
      _nome: row?._nome || '',
      _qtde: {
        localidades: Number(row?._qtde?.localidades) || 0,
      },
      _uf: row?._uf || '',
    }
  }

  fixes(docs: Partial<ILocalidade>[]): ILocalidade[] {
    return (docs || []).map((doc: Partial<ILocalidade>) => this.fix(doc));
  }
  //#endregion

  //#region R
  doc(localidadeId: string): Observable<ILocalidade> {
    // console.log('localidadeId', localidadeId);
    if (!!localidadeId) {
      const CELLS: string[] = (localidadeId || '').split('--');
      if (CELLS.length === 2) {
        const UF_ID: string = CELLS[1];
        if (!!UF_ID) {
          const PATH: string = `/_ufs/${UF_ID}/localidades/${localidadeId}`;
          // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<ILocalidade>)
          return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
            .pipe(
              map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
              // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
              catchError<ILocalidade, ObservableInput<ILocalidade>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando localidade.')
              )
            );
        }
        return throwError(() => 'Localidade inválida.');
      }
      return throwError(() => 'Localidade inválida.');
    } // if
    return throwError(() => 'Nenhuma localidade indicada.');
  }
  //#endregion
}
