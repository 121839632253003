//#region ng
import {
  Pipe,
  PipeTransform,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
//#endregion

//#region models
import { IIntegradora } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { IntegradorasService } from '../_services';
//#endregion

@Pipe({ name: 'integradorasLfixes' })
export class MeIntegradorasLfixesPipe implements PipeTransform {
  //#region injects
  #integradorasServ = inject(IntegradorasService);
  //#endregion

  transform(val: IIntegradora[]): Observable<IIntegradora[]> {
    return this.#integradorasServ.lfixes(val);
  }
}
