//#region firebase
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
import {
  // deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
//#endregion

export class CorFbMessaging {
  //#region privates
  _vapidKey: string;
  _dbRegion: string;
  //#endregion

  //#region constructor
  constructor(
    vapidKey: string,
    dbRegion: string
  ) {
    this._vapidKey = vapidKey;
    this._dbRegion = dbRegion;
  }
  //#endregion

  //#region methods
  getTokenConta(
    uid: string,
    nomeConta: string
  ): Promise<boolean> { // false = Acesso negado.
    return new Promise(
      (resolve, reject) => {
        // console.log('#getToken.uid', uid);
        if (!uid) {
          reject('UID não indicado.')
        } else {
          this.#getToken()
            .then(
              async (currentToken) => {
                if (!!currentToken) {
                  // console.log(currentToken);
                  // this._verificaToken(currentToken, uid, nomeConta);
                  const functions = getFunctions(
                    getApp(),
                    this._dbRegion
                  );
                  const fn = httpsCallable(functions, 'onVerificaTokenConta');
                  const RET: any = await fn({
                    data: {
                      token: currentToken,
                      uid,
                      nomeConta
                    }
                  });
                  // console.log(RET);

                  const ERROR: any = RET?.error;
                  if (!!ERROR) {
                    reject(ERROR);
                  } else {
                    this.#onMessage(
                      (payload: any) => {
                        // console.log(payload);
                        // const NOTIFICATION = 
                        new Notification(
                          payload?.notification?.title || '',
                          {
                            ...payload?.notification,
                            icon: 'assets/icon/favicon.png'
                          }
                        );
                        resolve(true);
                        // NOTIFICATION.addEventListener("close", e => console.log(e));

                        /*
                        notification.onclick = (event) => {
                          event.preventDefault(); // prevent the browser from focusing the Notification's tab
                          window.open('http://www.mozilla.org', '_blank');
                        }
                        */

                      });
                  } // else
                } else {
                  // Notification.requestPermission()
                  //   .then(
                  //     status => { console.log(status); }
                  //   );
                  resolve(false);
                } // else
              })
            .catch((err) => {
              console.log('Erro buscando token. ', err?.message);
            });
        } // else
      });
  }

  getToken(): Promise<string> { // false = Acesso negado.
    return new Promise(
      (resolve, reject) => {
        // console.log('#getToken.uid', uid);
        this.#getToken()
          .then(
            async (currentToken) => {
              resolve(currentToken);
            })
          .catch((err) => {
            console.log('Erro buscando token. ', err?.message);
            reject(err?.message);
          });
      });
  }
  //#endregion

  //#region api
   #getToken(): Promise<string> {
    const MESSAGING = getMessaging();
    return getToken(
      MESSAGING,
      { vapidKey: this._vapidKey }
    );
  }

   #onMessage(cb: any): void {
    const MESSAGING = getMessaging();
    onMessage(
      MESSAGING,
      (payload: any) => { cb && cb(payload); }
    );
  }

  //  #deleteToken(): Promise<boolean> {
  //   const MESSAGING = getMessaging();
  //   return deleteToken(MESSAGING);
  // }
  //#endregion
}
