//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
import { HttpClient } from "@angular/common/http";
//#endregion

//#region firebase
import {
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  setDoc
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  combineLatest,
  from,
  Observable,
  ObservableInput,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
import { get } from 'lodash';
import * as moment from 'moment';
//#endregion

//#region models
// interface IPedidoDeptoInfoMap {
//   id: string;
//   idD1: string;
//   idD2: string;
//   idD3: string;
//   nomeD1: string;
//   nomeD2: string;
//   nomeD3: string;
//   qtdeItens: number;
//   qtdeVols: number;
// };
import {
  IFormaPgto,
  IPedido
} from '../../_misc/_models/_interfaces/_cols';
import { TItemCarrinhoStatus, TPedidoStatus } from '../../_misc/_models/_types';
import {
  ICarrinhoInfoTotMap,
  ICarrinhoMap,
  IItemCarrinhoMap,
  IPedidoDeptoInfoMap
} from '../../_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
import { formataNumPedido } from '../../_misc/_libs/pedidos';
import {
  formataCpfCnpj,
  formataCurrency,
  formataTelefone
} from '../../../_libs/_misc/_formats';
import {
  mapPedidoDeptoItens,
  mapPedidoDeptosInfo
} from '../../_misc/_libs/itens-carrinho';
// import { compareValues } from '../../../_libs/_misc/_arrays';
//#endregion

//#region services
import {
  CarrinhoService,
  ContasService,
  FormasPgtoService,
  ItensCarrinhoService,
  PedidosSeparacaoService
} from '.';
import {
  CorCepService,
  CorMessagesService
} from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class PedidosService {
  //#region injects
  #db = inject(Firestore);
  #carrinhoServ = inject(CarrinhoService);
  #cepServ = inject(CorCepService);
  #contasServ = inject(ContasService);
  #formasPgtoServ = inject(FormasPgtoService);
  #http = inject(HttpClient);
  #itensServ = inject(ItensCarrinhoService);
  #msgServ = inject(CorMessagesService);
  #separacaoServ = inject(PedidosSeparacaoService);
  //#endregion

  //#region misc
  fix(row: Partial<IPedido>): IPedido {
    const CARRINHO: ICarrinhoMap = this.#carrinhoServ.fix(row?.carrinho as Partial<ICarrinhoMap>);
    // console.log(CARRINHO);
    const PERC_SEPARADO: number = Number(row?._percSeparado) || 0;
    const STATUS: TPedidoStatus | '' = row?._status || '';
    const CARRINHO_SEPARADO_QTDE_VOLS: number = Number(CARRINHO?._separado?.qtde?.volumes) || 0;
    // const IS_SEPARADO: boolean = !!row?._separado?.status;

    return {
      // id
      id: row?.id || '',

      // user
      carrinho: CARRINHO,
      confirmado: !!row?.confirmado,
      conta: this.#contasServ.fix(row?.conta),
      contato: {
        nome: row?.contato?.nome || '',
        fones: {
          celular: (row?.contato?.fones?.celular || '').replace(/[^0-9]/g, ''),
          fixo: (row?.contato?.fones?.fixo || '').replace(/[^0-9]/g, ''),
        }
      },
      // cpfNota: !!row?.cpfNota,
      docNota: {
        status: !!row?.docNota?.status,
        _tipo: row?.docNota?._tipo || '',
        _val: row?.docNota?._val || '',
      },
      endereco: this.#cepServ.fix(row?.endereco),
      formaPgto: this.#formasPgtoServ.fix(row?.formaPgto as Partial<IFormaPgto>),
      horario: {
        dia: row?.horario?.dia || null,
        hora: row?.horario?.hora || null,
      },
      obs: row?.obs || '',
      obsSeparacao: row?.obsSeparacao || '',
      // isRetirada: !!row?.isRetirada,

      // system
      _changes: {
        aguardandoSeparacao: row?._changes?.aguardandoSeparacao || null,
        emSeparacao: row?._changes?.emSeparacao || null,
        aguardandoEntrega: row?._changes?.aguardandoEntrega || null,
        aguardandoRetirada: row?._changes?.aguardandoRetirada || null,
        emEntrega: row?._changes?.emEntrega || null,
        concluido: row?._changes?.concluido || null,
        cancelado: row?._changes?.cancelado || null,
      },
      _criadoEm: row?._criadoEm || null,
      _cod: row?._cod || '',
      _info: row?._info || null,
      _isSeparado: !!row?._isSeparado,
      _isModificado: !!row?._isModificado,
      _percSeparado: PERC_SEPARADO,
      _qtdeItens: {
        pendentes: Number(row?._qtdeItens?.pendentes) || 0,
        confirmados: Number(row?._qtdeItens?.confirmados) || 0,
        modificados: Number(row?._qtdeItens?.modificados) || 0,
        adicionados: Number(row?._qtdeItens?.adicionados) || 0,
        removidos: Number(row?._qtdeItens?.removidos) || 0,
        naoEncontrados: Number(row?._qtdeItens?.naoEncontrados) || 0,
        total: Number(row?._qtdeItens?.total) || 0,
      },
      // _separado: {
      //   percDone: Number(row?._separado?.percDone) || 0,
      //   status: !!IS_SEPARADO,
      //   qtdeItens: {
      //     pendentes: Number(row?._separado?.qtdeItens?.pendentes) || 0,
      //     confirmados: Number(row?._separado?.qtdeItens?.confirmados) || 0,
      //     modificados: Number(row?._separado?.qtdeItens?.modificados) || 0,
      //     adicionados: Number(row?._separado?.qtdeItens?.adicionados) || 0,
      //     removidos: Number(row?._separado?.qtdeItens?.removidos) || 0,
      //     total: Number(row?._separado?.qtdeItens?.total) || 0,
      //   },
      // },
      _status: STATUS,
      // _total: {
      //   valorTaxaServ: Number(row?._total?.valorTaxaServ) || 0,
      //   valorTaxaEntrega: Number(row?._total?.valorTaxaEntrega) || 0,
      //   solicitado: {
      //     valorProdutos: Number(row?._total?.solicitado?.valorProdutos) || 0,
      //     totalPagar: Number(row?._total?.solicitado?.totalPagar) || 0,
      //   },
      //   separado: {
      //     valorProdutos: Number(row?._total?.separado?.valorProdutos) || 0,
      //     totalPagar: Number(row?._total?.separado?.totalPagar) || 0,
      //   },
      // },

      // realtime
      __actions: {
        canCancelarPedido: !['concluido', 'cancelado'].includes(STATUS),
        canConcluirPedido: !!row?._isSeparado && !['concluido', 'cancelado'].includes(STATUS),
        canConcluirSeparacao: STATUS === 'emSeparacao' && PERC_SEPARADO >= 100 && !!CARRINHO_SEPARADO_QTDE_VOLS,
        canIniciarSeparacao: STATUS === 'aguardandoSeparacao',
        canRelatorioModificacoes: !!row?._isModificado,
        canRelatorioSeparacao: STATUS !== 'aguardandoSeparacao',
        canRelatorioSeparado: !!row?._isSeparado,
      },
      __displayInPagination: !!row?.__displayInPagination,
      // __isSeparado: !!IS_SEPARADO,
      __statusInfo: get(row, `_changes.${STATUS}`) || null,
      // __total: {
      //   valorTaxaServ: Number(row?._total?.valorTaxaServ) || 0,
      //   valorTaxaEntrega: Number(row?._total?.valorTaxaEntrega) || 0,
      //   ...(get(row, `_total.${!!IS_SEPARADO ? 'separado' : 'solicitado'}`) || {}),
      // },
      // __total: {
      //   totalPagar: Number(row?.__total?.totalPagar) || 0,
      //   valorProdutos: Number(row?.__total?.valorProdutos) || 0,
      //   valorTaxaServ: Number(row?.__total?.valorTaxaServ) || 0,
      //   valorTaxaEntrega: Number(row?.__total?.valorTaxaEntrega) || 0,
      // },
      // __total: (!!row?._isSeparado ? CARRINHO?._separado?.__total : CARRINHO?.__solicitado?.__total) as ICarrinhoInfoTotMap,
    };
  }

  fixes(docs: Partial<IPedido>[]): IPedido[] {
    return (docs || []).map((doc: Partial<IPedido>) => this.fix(doc));
  }

  unfix(doc: Partial<IPedido>): Partial<IPedido> {
    delete doc?.id;
    delete doc?.confirmado;
    delete doc?.__displayInPagination;
    delete doc?.__statusInfo;
    return doc;
  }
  //#endregion

  //#region C
  add(
    lojaPath: string,
    changes: Partial<IPedido>,
    id: string
  ): Observable<any> {
    // console.log(JSON.stringify(changes));
    if (!!lojaPath) {
      if (!!changes) {
        if (!!id) {
          const PATH: string = `${lojaPath}/pedidos`;
          return from(setDoc(doc(this.#db, PATH, id), changes))
            .pipe(
              // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro adicionando pedido.')
              )
            );
        } // if
        return throwError(() => 'Nenhum id indicado.');
      } // if
      return throwError(() => 'Nenhum pedido indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region R
  doc(
    lojaPath: string,
    idPedido: string
  ): Observable<IPedido> {
    // console.log('idLoja', idLoja);
    if (!!lojaPath) {
      if (!!idPedido) {
        const PATH: string = `${lojaPath}/pedidos/${idPedido}`;
        // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IPedido>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            map((doc: any) => this.fix((!!doc.data() ? { ...doc.data(), id: doc.id } : null))),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<IPedido, ObservableInput<IPedido>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando pedido.')
            )
          );
      } // if
      return throwError(() => 'Nenhum pedido indicado.');
    } // if
    return of(null); // throwError(() => 'Nenhuma loja indicada.');
  }

  docFromRef(
    ref: DocumentReference
  ): Observable<IPedido> {
    if (!!ref) {
      // return (docData(ref, { idField: 'id' }) as Observable<IPedido>)
      return (from(getDoc(ref)) as Observable<any>)
        .pipe(
          map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
          // map(() => { throw new Error(`Erro lendo ${ref?.id}.`); }),
          catchError<IPedido, ObservableInput<IPedido>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando pedido.')
          )
        );
    } // if
    return of(null); // throwError(() => 'Nenhuma loja indicada.');
  }

  // https://jsfiddle.net/v7d6d1ps/
  relatorioSeparacao(
    pedido: IPedido,
    solicitadoPor: string = ''
  ): Observable<string> {
    // console.log(JSON.stringify(pedido?.carrinho?.itens));
    const PEDIDO_ITENS: IItemCarrinhoMap[] = pedido?.carrinho?.__solicitado?.itens || [];
    // console.log(PEDIDO_ITENS);
    const PEDIDO$: Observable<string> = this.#http.get('assets/relatorios/pedidos/separacao/index.html', { responseType: 'text' });
    const DEPARTAMENTO_TABLE$: Observable<string> = this.#http.get('assets/relatorios/pedidos/separacao/departamento-table.html', { responseType: 'text' });
    const DEPARTAMENTO_ITEM$: Observable<string> = this.#http.get('assets/relatorios/pedidos/separacao/departamento-item.html', { responseType: 'text' });

    return combineLatest([PEDIDO$, DEPARTAMENTO_TABLE$, DEPARTAMENTO_ITEM$])
      .pipe(
        map(([PEDIDO_HTML, DEPARTAMENTO_TABLE_HTML, DEPARTAMENTO_ITEM_HTML]) => {
          // departamentos
          let departamentosTableHtml: string = '';
          const DEPTOS: IPedidoDeptoInfoMap[] = mapPedidoDeptosInfo(PEDIDO_ITENS);

          DEPTOS.forEach(
            (d: IPedidoDeptoInfoMap) => {
              const DEPTO_ITENS: IItemCarrinhoMap[] = mapPedidoDeptoItens(PEDIDO_ITENS, d);
              // departamento itens
              let departamentoItensHtml: string = '';
              DEPTO_ITENS
                .forEach(
                  (i: IItemCarrinhoMap) => {
                    // console.log(i);
                    const IMG: string = !!i?.produto?._img ? `<img class="img-fluid" src=${i?.produto?.__img?.thumb}>` : '';
                    const OBS: string = !i?.obs ? '' : `&nbsp;<small class="o-50">Obs: ${i?.obs}</small>`;

                    // const POS: string = String(i?._pos);
                    const PRODUTO: string = i?.produto?.nome + OBS;
                    const BARCODE: string = i?.produto?.barcode || '';
                    const BARCODE_LEN: number = BARCODE.length;
                    const TAIL_LEN: number = BARCODE_LEN === 8 ? 5 : 6;
                    const BARCODE_1: string = !BARCODE_LEN
                      ? ''
                      : BARCODE.slice(0, BARCODE_LEN - TAIL_LEN);
                    const BARCODE_2: string = !BARCODE_LEN
                      ? ''
                      : BARCODE.slice(-TAIL_LEN);
                    const CODIGO: string = String(i?.produto?.id);
                    const QTDE: string = String(i?.__solicitado?.qtde?.fixed);
                    departamentoItensHtml += DEPARTAMENTO_ITEM_HTML
                      .replace(/{{IMG}}/g, IMG)
                      .replace(/{{PRODUTO}}/g, PRODUTO)
                      .replace(/{{BARCODE_1}}/g, BARCODE_1)
                      .replace(/{{BARCODE_2}}/g, BARCODE_2)
                      .replace(/{{CODIGO}}/g, CODIGO)
                      .replace(/{{QTDE}}/g, QTDE)
                  }
                );

              const BREADCRUMBS: string = `${d?.nomeD1}<span class="o-50"> > </span>${d?.nomeD2}<span class="o-50"> > </span>${d?.nomeD3}`;
              // console.log(BREADCRUMBS);
              departamentosTableHtml += (DEPARTAMENTO_TABLE_HTML || '')
                .replace(/{{BREADCRUMBS}}/g, BREADCRUMBS)
                .replace(/{{DEPARTAMENTO_ITENS}}/g, departamentoItensHtml)
            }
          );
          // console.log(departamentosTableHtml);

          const PEDIDO_CRIADO_EM: any = pedido?._criadoEm || null;
          const CONTA_FONE_CEL: string = formataTelefone(pedido?.conta?.fones?.celular || '');
          const CONTA_FONE_FIXO: string = formataTelefone(pedido?.conta?.fones?.fixo || '');
          const CONTA_NOME_COMPLETO: string = pedido?.conta?.__nomeCompleto || '';
          const DOC_NOTA_TIPO: string = pedido?.docNota?._tipo || 'Sem doc ';
          const DOC_NOTA_STATUS: boolean = !!pedido?.docNota?.status;
          const DOC_NOTA_VAL: string = !!DOC_NOTA_STATUS ? formataCpfCnpj(pedido?.docNota?._val) : '';
          const LOJA_NOME: string = pedido?.carrinho?.__loja?.nome || '';
          const PEDIDO_RETIRADA: boolean = !!pedido?.carrinho?.__loja?.isRetirada;
          const PEDIDO_ENTREGA_RETIRADA: string = !!PEDIDO_RETIRADA ? 'RETIRADA' : 'ENTREGA';
          const PEDIDO_ID_PLAIN: string = pedido?.id || '';
          const PEDIDO_INFO_CONTATO: string = pedido?._info?.contato?.val || '';
          const PEDIDO_INFO_FORMA_PGTO: string = pedido?._info?.formaPgto?.val || '';
          const PEDIDO_INFO_HORARIO: string = pedido?._info?.horario?.val || '';
          const PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA: string = pedido?._info?.retiradaEntrega?.legenda || '';
          const PEDIDO_INFO_RETIRADA_ENTREGA_VAL: string = pedido?._info?.retiradaEntrega?.val || '';
          const PEDIDO_OBS_SEPARACAO: string = !pedido?.obsSeparacao ? '' : `[<small class="o-50">${pedido?.obsSeparacao}</small>]`;
          const PEDIDO_OBS: string = PEDIDO_OBS_SEPARACAO + ' ' + pedido?.obs || '';
          const TIMESTAMP: any = moment(PEDIDO_CRIADO_EM.toDate()).format('DD/MM/yyy hh:mm');
          const SOLICITADO_POR: string = solicitadoPor || pedido?._changes?.emSeparacao?._operador?.nome || '';

          return (PEDIDO_HTML || '')
            .replace(/{{CONTA_FONE_CEL}}/g, CONTA_FONE_CEL)
            .replace(/{{CONTA_FONE_FIXO}}/g, CONTA_FONE_FIXO)
            .replace(/{{CONTA_NOME_COMPLETO}}/g, CONTA_NOME_COMPLETO)
            .replace(/{{DOC_NOTA_TIPO}}/g, DOC_NOTA_TIPO)
            .replace(/{{DOC_NOTA_VAL}}/g, DOC_NOTA_VAL)
            .replace(/{{LOJA_NOME}}/g, LOJA_NOME)
            .replace(/{{PEDIDO_ENTREGA_RETIRADA}}/g, PEDIDO_ENTREGA_RETIRADA)
            .replace(/{{PEDIDO_ID_PLAIN}}/g, PEDIDO_ID_PLAIN)
            .replace(/{{PEDIDO_ID}}/g, formataNumPedido(PEDIDO_ID_PLAIN))
            .replace(/{{PEDIDO_INFO_CONTATO}}/g, PEDIDO_INFO_CONTATO)
            .replace(/{{PEDIDO_INFO_FORMA_PGTO}}/g, PEDIDO_INFO_FORMA_PGTO)
            .replace(/{{PEDIDO_INFO_HORARIO}}/g, PEDIDO_INFO_HORARIO)
            .replace(/{{PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA}}/g, PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA)
            .replace(/{{PEDIDO_INFO_RETIRADA_ENTREGA_VAL}}/g, PEDIDO_INFO_RETIRADA_ENTREGA_VAL)
            .replace(/{{PEDIDO_OBS}}/g, PEDIDO_OBS)
            .replace(/{{TIMESTAMP}}/g, TIMESTAMP)
            .replace(/{{SOLICITADO_POR}}/g, SOLICITADO_POR)
            .replace(/{{DEPARTAMENTOS_TABLE}}/g, departamentosTableHtml)
        }
        )
      );
  }

  /* relatorioModificacoes(
    pedido: IPedido,
    solicitadoPor: string = ''
  ): Observable<string> {
    // console.log(JSON.stringify(pedido?.carrinho?.itens));
    // console.log(pedido?.carrinho?._separado?.itens || []);
    const PEDIDO_ITENS: IItemCarrinhoMap[] = this.#itensServ.fixes(
      (pedido?.carrinho?._separado?.itens || [])
        .filter((i: IItemCarrinhoMap) => !['confirmado', 'pendente'].includes(i?._status as TPedidoStatus))
    );
    console.log(PEDIDO_ITENS);
    const PEDIDO$: Observable<string> = this.#http.get('assets/relatorios/pedidos/modificacoes/index.html', { responseType: 'text' });
    const DEPARTAMENTO_TABLE$: Observable<string> = this.#http.get('assets/relatorios/pedidos/modificacoes/departamento-table.html', { responseType: 'text' });
    const DEPARTAMENTO_ITEM$: Observable<string> = this.#http.get('assets/relatorios/pedidos/modificacoes/departamento-item.html', { responseType: 'text' });

    return combineLatest([PEDIDO$, DEPARTAMENTO_TABLE$, DEPARTAMENTO_ITEM$])
      .pipe(
        map(([PEDIDO_HTML, DEPARTAMENTO_TABLE_HTML, DEPARTAMENTO_ITEM_HTML]) => {
          // departamentos
          let departamentosTableHtml: string = '';
          const DEPTOS: IPedidoDeptoInfoMap[] = mapPedidoDeptosInfo(PEDIDO_ITENS);

          DEPTOS.forEach(
            (d: IPedidoDeptoInfoMap) => {
              const DEPTO_ITENS: IItemCarrinhoMap[] = mapPedidoDeptoItens(PEDIDO_ITENS, d);
              // departamento itens
              let departamentoItensHtml: string = '';
              DEPTO_ITENS
                .forEach(
                  (i: IItemCarrinhoMap) => {
                    // console.log(i);
                    const IMG: string = !!i?.produto?._img ? `<img class="img-fluid" src=${i?.produto?.__img?.thumb}>` : '';
                    const OBS: string = !i?.obs ? '' : `&nbsp;<small class="o-50">Obs: ${i?.obs}</small>`;

                    // const POS: string = String(i?._pos);
                    const PRODUTO: string = i?.produto?.nome + OBS;
                    const BARCODE: string = i?.produto?.barcode || '';
                    const BARCODE_LEN: number = BARCODE.length;
                    const TAIL_LEN: number = BARCODE_LEN === 8 ? 5 : 6;
                    const BARCODE_1: string = !BARCODE_LEN
                      ? ''
                      : BARCODE.slice(0, BARCODE_LEN - TAIL_LEN);
                    const BARCODE_2: string = !BARCODE_LEN
                      ? ''
                      : BARCODE.slice(-TAIL_LEN);
                    const CODIGO: string = String(i?.produto?.id);
                    const QTDE_SOL: string = String(i?.__solicitado?.qtde?.fixed);
                    const QTDE_ENC: string = String(i?._separado?.qtde?.encontrado);
                    const QTDE_SEP: string = String(i?._separado?.qtde?.val);
                    const QTDE_DIF: string = String(i?._separado?.qtde?.__dif);
                    const STATUS: string = i?._status || '';
                    departamentoItensHtml += DEPARTAMENTO_ITEM_HTML
                      .replace(/{{IMG}}/g, IMG)
                      .replace(/{{PRODUTO}}/g, PRODUTO)
                      .replace(/{{BARCODE_1}}/g, BARCODE_1)
                      .replace(/{{BARCODE_2}}/g, BARCODE_2)
                      .replace(/{{CODIGO}}/g, CODIGO)
                      .replace(/{{QTDE_SOL}}/g, QTDE_SOL)
                      .replace(/{{QTDE_ENC}}/g, QTDE_ENC)
                      .replace(/{{QTDE_SEP}}/g, QTDE_SEP)
                      .replace(/{{QTDE_DIF}}/g, QTDE_DIF)
                      .replace(/{{STATUS}}/g, STATUS)
                  }
                );

              const BREADCRUMBS: string = `${d?.nomeD1}<span class="o-50"> > </span>${d?.nomeD2}<span class="o-50"> > </span>${d?.nomeD3}`;
              // console.log(BREADCRUMBS);
              departamentosTableHtml += (DEPARTAMENTO_TABLE_HTML || '')
                .replace(/{{BREADCRUMBS}}/g, BREADCRUMBS)
                .replace(/{{DEPARTAMENTO_ITENS}}/g, departamentoItensHtml)
            }
          );
          // console.log(departamentosTableHtml);

          const PEDIDO_CRIADO_EM: any = pedido?._criadoEm || null;
          const CONTA_FONE_CEL: string = formataTelefone(pedido?.conta?.fones?.celular || '');
          const CONTA_FONE_FIXO: string = formataTelefone(pedido?.conta?.fones?.fixo || '');
          const CONTA_NOME_COMPLETO: string = pedido?.conta?.__nomeCompleto || '';
          const DOC_NOTA_TIPO: string = pedido?.docNota?._tipo || 'Sem doc ';
          const DOC_NOTA_STATUS: boolean = !!pedido?.docNota?.status;
          const DOC_NOTA_VAL: string = !!DOC_NOTA_STATUS ? formataCpfCnpj(pedido?.docNota?._val) : '';
          const LOJA_NOME: string = pedido?.carrinho?.__loja?.nome || '';
          const PEDIDO_RETIRADA: boolean = !!pedido?.carrinho?.__loja?.isRetirada;
          const PEDIDO_ENTREGA_RETIRADA: string = !!PEDIDO_RETIRADA ? 'RETIRADA' : 'ENTREGA';
          const PEDIDO_ID_PLAIN: string = pedido?.id || '';
          const PEDIDO_INFO_CONTATO: string = pedido?._info?.contato?.val || '';
          const PEDIDO_INFO_FORMA_PGTO: string = pedido?._info?.formaPgto?.val || '';
          const PEDIDO_INFO_HORARIO: string = pedido?._info?.horario?.val || '';
          const PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA: string = pedido?._info?.retiradaEntrega?.legenda || '';
          const PEDIDO_INFO_RETIRADA_ENTREGA_VAL: string = pedido?._info?.retiradaEntrega?.val || '';
          const PEDIDO_OBS_SEPARACAO: string = !pedido?.obsSeparacao ? '' : `[<small class="o-50">${pedido?.obsSeparacao}</small>]`;
          const PEDIDO_OBS: string = PEDIDO_OBS_SEPARACAO + ' ' + pedido?.obs || '';
          const TIMESTAMP: any = moment(PEDIDO_CRIADO_EM.toDate()).format('DD/MM/yyy hh:mm');
          const SOLICITADO_POR: string = solicitadoPor || pedido?._changes?.emSeparacao?._operador?.nome || '';

          return (PEDIDO_HTML || '')
            .replace(/{{CONTA_FONE_CEL}}/g, CONTA_FONE_CEL)
            .replace(/{{CONTA_FONE_FIXO}}/g, CONTA_FONE_FIXO)
            .replace(/{{CONTA_NOME_COMPLETO}}/g, CONTA_NOME_COMPLETO)
            .replace(/{{DOC_NOTA_TIPO}}/g, DOC_NOTA_TIPO)
            .replace(/{{DOC_NOTA_VAL}}/g, DOC_NOTA_VAL)
            .replace(/{{LOJA_NOME}}/g, LOJA_NOME)
            .replace(/{{PEDIDO_ENTREGA_RETIRADA}}/g, PEDIDO_ENTREGA_RETIRADA)
            .replace(/{{PEDIDO_ID_PLAIN}}/g, PEDIDO_ID_PLAIN)
            .replace(/{{PEDIDO_ID}}/g, formataNumPedido(PEDIDO_ID_PLAIN))
            .replace(/{{PEDIDO_INFO_CONTATO}}/g, PEDIDO_INFO_CONTATO)
            .replace(/{{PEDIDO_INFO_FORMA_PGTO}}/g, PEDIDO_INFO_FORMA_PGTO)
            .replace(/{{PEDIDO_INFO_HORARIO}}/g, PEDIDO_INFO_HORARIO)
            .replace(/{{PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA}}/g, PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA)
            .replace(/{{PEDIDO_INFO_RETIRADA_ENTREGA_VAL}}/g, PEDIDO_INFO_RETIRADA_ENTREGA_VAL)
            .replace(/{{PEDIDO_OBS}}/g, PEDIDO_OBS)
            .replace(/{{TIMESTAMP}}/g, TIMESTAMP)
            .replace(/{{SOLICITADO_POR}}/g, SOLICITADO_POR)
            .replace(/{{DEPARTAMENTOS_TABLE}}/g, departamentosTableHtml)
        }
        )
      );
  } */

  relatorioSeparado(
    pedido: IPedido,
    lojaPath: string,
    solicitadoPor: string = ''
  ): Observable<string> {
    // const TOT = { qtdeItens: 0, qtdeVolumes: 0, valorPagar: 0 };
    const PEDIDO$: Observable<string> = this.#http.get('assets/relatorios/pedidos/separado/index.html', { responseType: 'text' });
    const PEDIDO_ITEM$: Observable<string> = this.#http.get('assets/relatorios/pedidos/separado/pedido-item.html', { responseType: 'text' });
    const DEVOLUCAO_ITEM$: Observable<string> = this.#http.get('assets/relatorios/pedidos/separado/devolucao-item.html', { responseType: 'text' });
    const PEDIDO_ITENS$: Observable<IItemCarrinhoMap[]> = this.#separacaoServ.docs(lojaPath, String(pedido?.id));

    return combineLatest([PEDIDO$, PEDIDO_ITEM$, DEVOLUCAO_ITEM$, PEDIDO_ITENS$])
      .pipe(
        map(
          ([pedidoHtml, pedidoItemHtml, devolucaoItemHtml, pedidoItens]) => {
            // console.log(pedidoHtml);
            // console.log(pedidoItemHtml);
            // console.log(typeof pedidoItens, pedidoItens);
            console.log(devolucaoItemHtml);

            const PEDIDO_ITENS: IItemCarrinhoMap[] = this.#itensServ.fixes(
              (pedidoItens || [])
                .filter((i: IItemCarrinhoMap) => !['removido', 'naoEncontrado'].includes(i?._status as TItemCarrinhoStatus))
            );
            // console.log(PEDIDO_ITENS);

            const DEVOLUCAO_ITENS: IItemCarrinhoMap[] = this.#itensServ.fixes(
              (pedidoItens || [])
                .filter((i: IItemCarrinhoMap) => (i?._separado?.qtde?.__dif || 0) < 0)
            );
            // console.log(DEVOLUCAO_ITENS);

            // departamentos
            let pedidoItensCupomHtml: string = '';
            let devolucaoItensCupomHtml: string = !DEVOLUCAO_ITENS?.length ? 'Nenhum' : '';
            const SEP: string = ' ';
            const QTDE_LEN: number = 12;
            const CODIGO_LEN: number = 12;
            const PRODUTO_LEN: number = 36;
            const TOTAL_LIQ_LEN: number = 12;
            const TOTAL_DESC_LEN: number = 12;
            const PEDIDO_ITENS_CUPOM_HEADER: string = 'QTDE'.padStart(QTDE_LEN, SEP)
              + ' x ' + 'CÓDIGO'.padEnd(CODIGO_LEN, SEP)
              + ' ' + 'PRODUTO'.padEnd(PRODUTO_LEN, SEP)
              + ' ' + 'DESC. TOTAL'.padStart(TOTAL_DESC_LEN, SEP)
              + ' ' + 'TOTAL LÍQ.'.padStart(TOTAL_LIQ_LEN, SEP);
            // 'QTDE CÓDIGO PRODUTO TOTAL LÍQUIDO DESCONTO TOTAL';
            const DEVOLUCAO_ITENS_CUPOM_HEADER: string = !!DEVOLUCAO_ITENS?.length
              ? 'QTDE'.padStart(QTDE_LEN, SEP) + ' ' + 'PRODUTO'.padEnd(PRODUTO_LEN, SEP)
              : '';

            PEDIDO_ITENS?.forEach(
              (i: IItemCarrinhoMap) => {
                const QTDE_ITENS_SEPARADO: number = Number(i?._separado?.qtde?.fixed);
                // const QTDE_VOLS_SEPARADO: number = Number(i?._separado?.qtde?.fixed);
                if (!!QTDE_ITENS_SEPARADO) {
                  const QTDE: string = formataCurrency(QTDE_ITENS_SEPARADO || 0, false).slice(0, QTDE_LEN).padStart(QTDE_LEN, SEP);
                  const CODIGO: string = String(i?.produto?.id).slice(0, CODIGO_LEN).padEnd(CODIGO_LEN, SEP);
                  const PRODUTO: string = (i?.produto?.nome || '').replace(/ /g, ' ').slice(0, PRODUTO_LEN).padEnd(PRODUTO_LEN, SEP);
                  const TOTAL_DESC: string = formataCurrency(Number(i?._separado?.valor?.total?.desc) || 0, false).slice(0, TOTAL_DESC_LEN).padStart(TOTAL_DESC_LEN, SEP);
                  const TOTAL_LIQ: string = formataCurrency(Number(i?._separado?.valor?.total?.liq) || 0, false).slice(0, TOTAL_LIQ_LEN).padStart(TOTAL_LIQ_LEN, SEP);
                  // const BARCODE: string = i?.produto?.barcode || '';

                  pedidoItensCupomHtml += pedidoItemHtml
                    .replace(/{{PRODUTO_QTDE}}/g, QTDE)
                    .replace(/{{PRODUTO_TOTAL_LIQ}}/g, TOTAL_LIQ)
                    .replace(/{{PRODUTO_TOTAL_DESC}}/g, TOTAL_DESC)
                    .replace(/{{PRODUTO_ID}}/g, CODIGO)
                    .replace(/{{PRODUTO_NOME}}/g, PRODUTO)

                  // .replace(/{{PRODUTO_BARCODE}}/g, BARCODE)
                } // if
              }
            );

            DEVOLUCAO_ITENS?.forEach(
              (i: IItemCarrinhoMap) => {
                const QTDE_ITENS_DIF: number = Number(i?._separado?.qtde?.__dif);
                // console.log(i?.produto);                
                if (QTDE_ITENS_DIF < 0) {
                  const QTDE: string = formataCurrency(Math.abs(QTDE_ITENS_DIF) || 0, false).slice(0, QTDE_LEN).padStart(QTDE_LEN, SEP);
                  const PRODUTO: string = (i?.produto?.nome || '').replace(/ /g, ' ');
                  const D1: string = (i?.produto?.departamentos?.d1?.nome || '').replace(/ /g, ' ');
                  const D2: string = (i?.produto?.departamentos?.d2?.nome || '').replace(/ /g, ' ');
                  const D3: string = (i?.produto?.departamentos?.d3?.nome || '').replace(/ /g, ' ');
                  const DEPTOS: string = `${D1}/${D2}/${D3} `
                  
                  .replace(/ /g, ' ').slice(0, PRODUTO_LEN).padEnd(PRODUTO_LEN, SEP);
                  devolucaoItensCupomHtml += devolucaoItemHtml
                    .replace(/{{PRODUTO_QTDE}}/g, QTDE)
                    .replace(/{{PRODUTO_NOME}}/g, PRODUTO)
                    .replace(/{{DEPTOS}}/g, DEPTOS);
                } // if
              }
            );

            const PEDIDO_CRIADO_EM: any = pedido?._criadoEm || null;
            const CONTA_FONE_CEL: string = formataTelefone(pedido?.conta?.fones?.celular || '');
            const CONTA_FONE_FIXO: string = formataTelefone(pedido?.conta?.fones?.fixo || '');
            const CONTA_NOME_COMPLETO: string = pedido?.conta?.__nomeCompleto || '';
            const DOC_NOTA_TIPO: string = pedido?.docNota?._tipo || '';
            const DOC_NOTA_STATUS: boolean = !!pedido?.docNota?.status;
            const DOC_NOTA_VAL: string = !!DOC_NOTA_STATUS ? formataCpfCnpj(pedido?.docNota?._val) : '';
            const LOJA_NOME: string = pedido?.carrinho?.__loja?.nome || '';
            const PEDIDO_RETIRADA: boolean = !!pedido?.carrinho?.__loja?.isRetirada;
            const PEDIDO_ENTREGA_RETIRADA: string = !!PEDIDO_RETIRADA ? 'RETIRADA' : 'ENTREGA';
            const PEDIDO_ID_PLAIN: string = pedido?.id || '';
            const PEDIDO_INFO_CONTATO: string = pedido?._info?.contato?.val || '';
            const PEDIDO_INFO_FORMA_PGTO: string = pedido?._info?.formaPgto?.val || '';
            const PEDIDO_INFO_HORARIO: string = pedido?._info?.horario?.val || '';
            const PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA: string = pedido?._info?.retiradaEntrega?.legenda || '';
            const PEDIDO_INFO_RETIRADA_ENTREGA_VAL: string = pedido?._info?.retiradaEntrega?.val || '';
            const PEDIDO_OBS_SEPARACAO: string = !pedido?.obsSeparacao ? '' : `[<small class="o-50">${pedido?.obsSeparacao}</small>]`;
            const PEDIDO_OBS: string = PEDIDO_OBS_SEPARACAO + ' ' + pedido?.obs || '';
            const TIMESTAMP: any = moment(PEDIDO_CRIADO_EM.toDate()).format('DD/MM/yyy hh:mm');
            const SOLICITADO_POR: string = solicitadoPor || pedido?._changes?.emSeparacao?._operador?.nome || '';

            return (pedidoHtml || '')
              .replace(/{{CONTA_FONE_CEL}}/g, CONTA_FONE_CEL)
              .replace(/{{CONTA_FONE_FIXO}}/g, CONTA_FONE_FIXO)
              .replace(/{{CONTA_NOME_COMPLETO}}/g, CONTA_NOME_COMPLETO)
              .replace(/{{DOC_NOTA_TIPO}}/g, DOC_NOTA_TIPO)
              .replace(/{{DOC_NOTA_VAL}}/g, DOC_NOTA_VAL)
              .replace(/{{LOJA_NOME}}/g, LOJA_NOME)
              .replace(/{{PEDIDO_ENTREGA_RETIRADA}}/g, PEDIDO_ENTREGA_RETIRADA)
              .replace(/{{PEDIDO_ID_PLAIN}}/g, PEDIDO_ID_PLAIN)
              .replace(/{{PEDIDO_ID}}/g, formataNumPedido(PEDIDO_ID_PLAIN))
              .replace(/{{PEDIDO_INFO_CONTATO}}/g, PEDIDO_INFO_CONTATO)
              .replace(/{{PEDIDO_INFO_FORMA_PGTO}}/g, PEDIDO_INFO_FORMA_PGTO)
              .replace(/{{PEDIDO_INFO_HORARIO}}/g, PEDIDO_INFO_HORARIO)
              .replace(/{{PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA}}/g, PEDIDO_INFO_RETIRADA_ENTREGA_LEGENDA)
              .replace(/{{PEDIDO_INFO_RETIRADA_ENTREGA_VAL}}/g, PEDIDO_INFO_RETIRADA_ENTREGA_VAL)
              .replace(/{{PEDIDO_OBS}}/g, PEDIDO_OBS)
              .replace(/{{TIMESTAMP}}/g, TIMESTAMP)
              .replace(/{{SOLICITADO_POR}}/g, SOLICITADO_POR)
              .replace(/{{PEDIDO_ITENS_CUPOM}}/g, pedidoItensCupomHtml)
              .replace(/{{PEDIDO_ITENS_CUPOM_HEADER}}/g, PEDIDO_ITENS_CUPOM_HEADER)
              .replace(/{{DEVOLUCAO_ITENS_CUPOM}}/g, devolucaoItensCupomHtml)
              .replace(/{{DEVOLUCAO_ITENS_CUPOM_HEADER}}/g, DEVOLUCAO_ITENS_CUPOM_HEADER);
          }
        ),
      );
  }
  //#endregion

  //#region U
  update(
    lojaPath: string,
    changes: Partial<IPedido>,
    id
  ): Observable<any> {
    if (!!lojaPath) {
      if (!!changes) {
        if (!!id) {
          const PATH: string = `${lojaPath}/pedidos/${id}`;
          return from(
            setDoc(
              doc(this.#db, PATH),
              changes,
              { merge: true }
            )
          )
            .pipe(
              // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
              catchError<any, ObservableInput<any>>(
                (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando endereço.')
              )
            );
        } // if
        return throwError(() => 'Nenhuma id indicado.');
      } // if
      return throwError(() => 'Nenhum pedido indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}
