//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
//#endregion

//#region 3rd
import {
  finalize,
  first
} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
//#endregion

//#region models
import {
  FOCUS_TIMEOUT,
  NO_COLOR
} from '../../../_misc/_models/consts';
import { ICorViacepApi } from '../../../_misc/_models/_interfaces/_apis';
//#endregion

//#region services
import { CorCepService } from '../../../_ng/_services/cep.service';
//#endregion

@Component({
  selector: 'cor-bs-cep-form',
  templateUrl: 'cep-form.component.html',
  styleUrls: ['cep-form.component.scss']
})
export class CorBsCepFormComponent {
  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // placeholder
  @Input() placeholder: string = 'Seu CEP';
  //#endregion

  //#region outputs
  @Output() submit$ = new EventEmitter<ICorViacepApi>();
  //#endregion

  //#region publics
  cepForm: FormGroup;
  //#endregion

  //#region events
  cepFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #cepServ = inject(CorCepService);
  #fb = inject(FormBuilder);
  //#endregion

  //#region constructor
  constructor() {
    this.cepForm = this.#fb.group({
      cep: ['', [Validators.required]]
    });
  }
  //#endregion

  //#region Controls getters
  get cepRef(): AbstractControl { return this.cepForm?.get('cep'); }
  //#endregion

  //#region lifecycles
  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  focus() {
    // console.log('cep focus', this.isMobile);
    !this.isMobile && setTimeout(
      () => { this.cepFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  #submitError() {
    this.cepForm.reset();
    Swal.fire({
      icon: "error",
      title: "Ooops...",
      html: "<h2>CEP inválido ou não encontrado!</h2>",
      backdrop: false,
      confirmButtonColor: NO_COLOR,
      timer: 3000
    })
      .then((result: any) => this.submit$.emit(null));
  }
  //#endregion

  //#region methods
  onSubmitClick() {
    // this._loaderServ.start();
    const CEP = this.cepRef?.value || '';
    // console.log(CEP);
    const SUB: Subscription = this.#cepServ.cep(CEP)
      .pipe(first(), finalize(() => SUB?.unsubscribe()))
      .subscribe(
        {
          next: (viacep: ICorViacepApi) => {
            // console.log(viacep);
            const ERRO: boolean = !!viacep?.erro;
            if (!!viacep) {
              this.cepForm.reset();
              if (!!ERRO) {
                this.#submitError();
              } else {
                // console.log(viacep);
                viacep.complemento = '';
                this.submit$.emit(viacep);
              } // else
            } else {
              this.#submitError();
            } // else
          },
          error: (error: any) => {
            !!error && console.error(error);
            this.#submitError();
          }
        }
      );
  }
  //#endregion
}
