//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
//#endregion

@Component({
  selector: 'me-html-produto-botao-comprar-skel',
  templateUrl: './produto-botao-comprar-skel.component.html',
  styleUrls: ['./produto-botao-comprar-skel.component.scss']
})
export class MeHtmlProdutoBotaoComprarSkelComponent { }
