<div *ngIf="!!pedido">
  <ul class="list-group">
    <li class="list-group-item d-flex">
      <div class="my-auto">
        <span class="icon-o-chat-empty o-50 me-2"></span>
      </div>

      <div class="my-auto">
        <span class="o-50">
          {{ pedido?._info?.contato?.legenda }}
        </span><br>
        <small>
          {{ pedido?._info?.contato?.val }}
        </small>
      </div>
    </li>

    <li class="list-group-item d-flex">
      <div class="my-auto">
        <span class="icon-ob-pin o-50 me-2"></span>
      </div>

      <div class="my-auto">
        <span class="o-50">
          {{ pedido?._info?.retiradaEntrega?.legenda }}
        </span><br>
        <small>
          {{ pedido?._info?.retiradaEntrega?.val }}
        </small>
      </div>
    </li>

    <li class="list-group-item d-flex">
      <div class="my-auto">
        <span class="icon-o-clock-2 o-50 me-2"></span>
      </div>

      <div class="my-auto">
        <span class="o-50">
          {{ pedido?._info?.horario?.legenda }}
        </span><br>
        <small>
          {{ pedido?._info?.horario?.val }}
        </small>
      </div>
    </li>

    <li class="list-group-item d-flex">
      <div class="my-auto">
        <span class="icon-b-dolar o-50 me-2"></span>
      </div>

      <div class="my-auto">
        <span class="o-50">
          {{ pedido?._info?.formaPgto?.legenda }}
        </span><br>
        <small>
          {{ pedido?._info?.formaPgto?.val }}
        </small>
      </div>
    </li>

    <li class="list-group-item d-flex"
        *ngIf="!!pedido?.obs">
      <span class="o-50 me-2"> Obs: </span>
      <small>
        {{ pedido?.obs }}
      </small>
    </li>

    <li class="list-group-item d-flex">
      <div class="form-check">
        <input class="form-check-input"
               type="checkbox"
               id="cpfNota"
               [checked]="!!pedido?.docNota?.status">
        <label class="form-check-label"
               for="cpfNota">
          CPF na nota.
        </label>
      </div>
    </li>
  </ul>
</div>