//#region ng
import {
  Injectable
} from '@angular/core';
//#endregion

//#region models
import {
  KEY_AUDIO_ID,
  KEY_LOJA_ID,
  KEY_SEPARANDO_VIEWSTYLE,
  KEY_SHOW_SIDEBAR,
  KEY_SPLIT_VERTICAL,
} from '../models/consts';
import { TCorViewStyle } from '../_shared/_core/_misc/_models/_types';
//#endregion

//#region services
import { CorStorageService } from '../_shared/_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  //#region constructor
  constructor(
    private _storageServ: CorStorageService,
  ) { }
  //#endregion

  //#region methods KEY_SEPARANDO_VIEWSTYLE
  separandoViewstyleGet(): TCorViewStyle {
    const VAL: any = this._storageServ.ls_get(KEY_SEPARANDO_VIEWSTYLE);
    return VAL;
  }

  separandoViewstyleSet(separandoViewstyle: TCorViewStyle): TCorViewStyle {
    if (!!separandoViewstyle) {
      this._storageServ.ls_set(
        KEY_SEPARANDO_VIEWSTYLE,
        separandoViewstyle
      );
      return this.separandoViewstyleGet();
    } else {
      return this.separandoViewstyleRemove();
    } // else
  }

  separandoViewstyleRemove(): TCorViewStyle {
    this._storageServ.ls_remove(KEY_SEPARANDO_VIEWSTYLE);
    return this.separandoViewstyleGet();
  }
  //#endregion

  //#region methods KEY_SHOW_SIDEBAR
  /* showSidebarGet(): boolean {
    const VAL: any = this._storageServ.ls_get(KEY_SHOW_SIDEBAR);
    return VAL === '1';
  }

  showSidebarSet(showSidebar: boolean): boolean {
    if (!!showSidebar) {
      this._storageServ.ls_set(
        KEY_SHOW_SIDEBAR,
        !!showSidebar ? '1' : '0'
      );
      return this.showSidebarGet();
    } else {
      return this.showSidebarRemove();
    } // else
  }

  showSidebarRemove(): boolean {
    this._storageServ.ls_remove(KEY_SHOW_SIDEBAR);
    return this.showSidebarGet();
  } */
  //#endregion

  //#region methods KEY_AUDIO_ID
  audioIdGet(): string {
    const VAL: any = this._storageServ.ls_get(KEY_AUDIO_ID);
    return VAL;
  }

  audioIdSet(audio: string): string {
    if (!!audio) {
      this._storageServ.ls_set(
        KEY_AUDIO_ID,
        audio
      );
      return this.audioIdGet();
    } else {
      return this.audioIdRemove();
    } // else
  }

  audioIdRemove(): string {
    this._storageServ.ls_remove(KEY_AUDIO_ID);
    return this.audioIdGet();
  }
  //#endregion

  //#region methods KEY_LOJA_ID
  lojaIdGet(): string {
    const VAL: any = this._storageServ.ls_get(KEY_LOJA_ID);
    return VAL;
  }

  lojaIdSet(lojaId: string): string {
    if (!!lojaId) {
      this._storageServ.ls_set(
        KEY_LOJA_ID,
        lojaId
      );
      return this.lojaIdGet();
    } else {
      return this.lojaIdRemove();
    } // else
  }

  lojaIdRemove(): string {
    this._storageServ.ls_remove(KEY_LOJA_ID);
    return this.lojaIdGet();
  }
  //#endregion

  //#region methods KEY_SPLIT_VERTICAL
  splitVerticalGet(): boolean {
    const VAL: any = this._storageServ.ls_get(KEY_SPLIT_VERTICAL);
    return VAL === 'true';
  }

  splitVerticalSet(splitVertical: boolean): boolean {
    // if (splitVertical ?? false) {
    this._storageServ.ls_set(
      KEY_SPLIT_VERTICAL,
      !!splitVertical ? 'true' : 'false'
    );
    return this.splitVerticalGet();
    // } else {
    //   return this.splitVerticalRemove();
    // } // else
  }

  splitVerticalRemove(): boolean {
    this._storageServ.ls_remove(KEY_SPLIT_VERTICAL);
    return this.splitVerticalGet();
  }
  //#endregion
}
