<h2>Galeria get works!!!</h2>

<!-- 
<div class="container">
  <div class="row">
    <div class="col search">
      <mat-form-field appearance="outline"
                      class="w-100">
        <mat-label>Legenda</mat-label>
        <button mat-button
                matPrefix
                mat-icon-button
                (click)="onSearchSubmit()">
          <mat-icon class="o-50">search</mat-icon>
        </button>
        <input matInput
               type="text"
               [focus]="searchFocusEvent$()"
               [(ngModel)]="search"
               (keyup.enter)="onSearchSubmit()">
        <button mat-button
                *ngIf="search"
                matSuffix
                mat-icon-button
                (click)="onSearchClearClick()">
          <mat-icon class="o-50">close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="col ms-3"
         *ngIf="!!hits?.length">
      <cor-mat-ngx-pagination-nav pagId="galeria-sel"
                                  size="lg"
                                  [isMobile]="true"
                                  (pageChanged$)="page = $event">
      </cor-mat-ngx-pagination-nav>
    </div>
  </div>
</div>

<ul class="ms-3 mt-1 galeria"
    *ngIf="hits">
  <li *ngFor="let h of hits | paginate: { id: 'galeria-sel', itemsPerPage, currentPage: page, totalItems: hits?.length }; trackBy:tid_gal"
      class="pointer"
      (click)="onItemClick(h)"
      [class.off]="ids.includes(h?.id)">
    <img [src]="h?.__img?.thumb"
         class="img-fluid"
         alt="h?.nome">
    <p>
      <small class="o-50">
        {{ h?.nome }}
      </small>
    </p>
  </li>

  <div class="text-center"
       *ngIf="!hits.length">
    <span class="icon-r-search warn-icon"></span>
    <br>
    <p>Nenhum item encontrado :(</p>
  </div>
</ul> 
-->