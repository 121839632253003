//#region ng
import {
  // ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
//#endregion

//#region services
import { CorMessagesService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'cor-mat-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorMatMessagesComponent {
  //#region inputs
  // isMain
  @Input() isMain: boolean = false;
  //#endregion

  //#region publics
  icons = signal<any>(
    {
      primary: 'icon-o-chat-full',
      secondary: 'icon-o-chat-full',
      success: 'icon-o-chat-check',
      danger: 'icon-o-chat-cancel',
      warning: 'icon-o-chat-bang',
      info: 'icon-o-chat-info',
      light: 'icon-o-chat-full',
      dark: 'icon-o-chat-full'
    }
  );
  //#endregion

  //#region constructor
  constructor(
    public msgServ: CorMessagesService,
  ) { }
  //#endregion

  //#region methods
  onFecharClick() {
    this.msgServ.clear();
  }
  //#endregion
}
