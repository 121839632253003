//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
} from 'rxjs';
import {
  catchError,
  map,
  tap
} from 'rxjs/operators';
//#endregion

//#region models
// import { environment } from 'src/environments/environment';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
import { IMovPedidoPedido } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { MovPedidosTotService } from '.';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class MovPedidosPedidosService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  #movPedidosTotServ = inject(MovPedidosTotService);
  //#endregion

  //#region misc
  fix(row: Partial<IMovPedidoPedido>): IMovPedidoPedido {
    const ID: string = row?.id || '';

    return {
      // id
      id: ID,

      // user
      // ativo: {
      //   status: !row?.ativo?.status,
      //   _status: !row?.ativo?._status,
      // },
      // nome: row?.nome || '',
      // pos: Number(row?.pos) || 0,
      // target: row?.target || '', // [0] = _ marca else produto /// def ''

      // system
      _criadoEm: row?._criadoEm,
      _conta: {
        id: row?._conta?.id || '',
        nomeCompleto: row?._conta?.nomeCompleto || '',
      },
      _formaPgto: {
        id: row?._formaPgto?.id || '',
        nome: row?._formaPgto?.nome || '',
        tipo: row?._formaPgto?.tipo || '',
      },
      _integradora: {
        id: row?._integradora?.id || '',
        nome: row?._integradora?.nome || '',
      },
      _isRetirada: !!row?._isRetirada,
      _loja: {
        id: row?._loja?.id || '',
        nome: row?._loja?.nome || '',
      },
      // _obs: row?.
      // _obsSeparacao: row?.
      _parceiro: {
        id: row?._parceiro?.id || '',
        nome: row?._parceiro?.nome || '',
      },
      _ref: row?._ref || null,
      _total: this.#movPedidosTotServ.fix(row?._total || null)
    };
  }

  fixes(docs: Partial<IMovPedidoPedido>[]): IMovPedidoPedido[] {
    return (docs || []).map((doc: Partial<IMovPedidoPedido>) => this.fix(doc));
  }
  //#endregion

  //#region R

  // /_ => mov-pedidos
  // /_ufs/mg/localidades/uberaba--mg/lojas/699fc369--modelo--mg--uberaba/ => mov-pedidos
  // /_integradoras/modelo/ => mov-pedidos
  // /_contas/sYe94fW9AdOoZZXCqTdJbr5XGn93/ => mov-pedidos
  docs(pre: string, ano: string, mes: string, dia: string): Observable<IMovPedidoPedido[]> {
    if (!!pre) {
      if (!!ano) {
        if (!!mes) {
          if (!!dia) {
            // const MES: string = paddy(mes, 2, '0');
            // /_mov-pedidos/_2023/mp-meses/_6/mp-dias/_25/mp-pedidos/{{ODOCS}}
            const PATH: string = `${pre}mov-pedidos/_${ano}/mp-meses/_${mes}/mp-dias/_${dia}/mp-pedidos/${ODOCS_TAG}`;
            // console.log(PATH);
            // return (docData(doc(this.#db, PATH)) as Observable<IMovPedidoPedido[]>)
            return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
              .pipe(
                // tap((doc: any) => console.log(Object.values(doc?._odocs || {}))),
                map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
                // tap((doc: any) => console.log(doc)),
                // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
                catchError<IMovPedidoPedido[], ObservableInput<IMovPedidoPedido[]>>(
                  (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando detalhes de pedidos.')
                )
              );
          } else {
            return of(null); // throwError(() => 'Nenhum dia indicado.');
          } // if
        } else {
          return of(null); // throwError(() => 'Nenhum mês indicado.');
        } // if
      } else {
        return of(null); // throwError(() => 'Nenhum ano indicado.');
      } // if
    } // if
    return of(null); // throwError(() => 'Nenhuma localização indicada.');
  }
  //#endregion
}
