//#region 3rd
import * as moment from 'moment';
//#endregion

export const time2min = (S: string): number => {
  if (typeof S === 'string') {
    const T: any = moment(S, 'HH:mm');
    return T.hours() * 60 + T.minutes();
  } else {
    return 0;
  } // else
};
