<!-- <p>idPedido {{ idPedido }}</p> -->
<!-- <p>lojaPath {{ lojaPath }}</p> -->
<!-- <p>pedido()?._status {{ pedido()?._status }}</p> -->
<!-- <p>statusPedido {{ statusPedido | json }}</p> -->
<!-- <p>pedido()?.retirada {{ pedido()?.retirada }}</p> -->
<!-- <p>pedido()?._changes?.aguardandoSeparacao._operador.id {{ pedido()?._changes?.aguardandoSeparacao._timestamp }}</p> -->

<div class='wrapper'
     *ngIf="!!pedido()">
  <div class='steps'
       id='steps'>
    <div class='step'
         [class.completed]="!!pedido()?._changes?.aguardandoSeparacao?._timestamp">
      <div class='pos'>
        <span class="icon {{ statusPedido()?.aguardandoSeparacao?.icon }}">
        </span>
      </div>
      <div class='info'>
        <p class='title'>{{ statusPedido()?.aguardandoSeparacao?.caption }}</p>
        <p class='text'>{{ pedido()?._changes?.aguardandoSeparacao?._timestamp?.toDate() | date: 'dd/MM/yyyy HH:mm' }}
        </p>
      </div>
    </div>

    <div class='step'
         [class.completed]="!!pedido()?._changes?.emSeparacao?._timestamp"
         *ngIf="pedido()?._status !== 'cancelado' || !!pedido()?._changes?.emSeparacao?._timestamp">
      <div class='pos'>
        <span class="icon {{ statusPedido()?.emSeparacao?.icon }}">
        </span>
      </div>
      <div class='info'>
        <p class='title'>{{ statusPedido()?.emSeparacao?.caption }}</p>
        <p class='text'>{{ pedido()?._changes?.emSeparacao?._timestamp?.toDate() | date: 'dd/MM/yyyy HH:mm' }}</p>
        <p class='text'
           *ngIf="!!showOperadores">{{ pedido()?._changes?.emSeparacao?._operador?.nome }}</p>
      </div>
    </div>

    <div class='step'
         [class.completed]="!!pedido()?._changes?.aguardandoEntrega?._timestamp"
         *ngIf="!pedido()?.carrinho?.__loja?.isRetirada && (pedido()?._status !== 'cancelado' || !!pedido()?._changes?.aguardandoEntrega?._timestamp)">
      <div class='pos'>
        <span class="icon {{ statusPedido()?.aguardandoEntrega?.icon }}">
        </span>
      </div>
      <div class='info'>
        <p class='title'>{{ statusPedido()?.aguardandoEntrega?.caption }}</p>
        <p class='text'>{{ pedido()?._changes?.aguardandoEntrega?._timestamp?.toDate() | date: 'dd/MM/yyyy HH:mm' }}</p>
        <p class='text'
           *ngIf="!!showOperadores">{{ pedido()?._changes?.aguardandoEntrega?._operador?.nome }}</p>
      </div>
    </div>

    <div class='step'
         [class.completed]="!!pedido()?._changes?.aguardandoRetirada?._timestamp"
         *ngIf="!!pedido()?.carrinho?.__loja?.isRetirada && (pedido()?._status !== 'cancelado' || !!pedido()?._changes?.aguardandoRetirada?._timestamp)">
      <div class='pos'>
        <span class="icon {{ statusPedido()?.aguardandoRetirada?.icon }}">
        </span>
      </div>
      <div class='info'>
        <p class='title'>{{ statusPedido()?.aguardandoRetirada?.caption }}</p>
        <p class='text'>{{ pedido()?._changes?.aguardandoRetirada?._timestamp?.toDate() | date: 'dd/MM/yyyy HH:mm' }}
        </p>
        <p class='text'
           *ngIf="!!showOperadores">{{ pedido()?._changes?.aguardandoRetirada?._operador?.nome }}</p>
      </div>
    </div>

    <div class='step'
         [class.completed]="!!pedido()?._changes?.emEntrega?._timestamp"
         *ngIf="!pedido()?.carrinho?.__loja?.isRetirada && (pedido()?._status !== 'cancelado' || !!pedido()?._changes?.emEntrega?._timestamp)">
      <div class='pos'>
        <span class="icon {{ statusPedido()?.emEntrega?.icon }}">
        </span>
      </div>
      <div class='info'>
        <p class='title'>{{ statusPedido()?.emEntrega?.caption }}</p>
        <p class='text'>{{ pedido()?._changes?.emEntrega?._timestamp?.toDate() | date: 'dd/MM/yyyy HH:mm' }}</p>
        <p class='text'
           *ngIf="!!showOperadores">{{ pedido()?._changes?.emEntrega?._operador?.nome }}</p>
      </div>
    </div>

    <div class='step'
         [class.completed]="!!pedido()?._changes?.concluido?._timestamp"
         *ngIf="pedido()?._status !== 'cancelado' || !!pedido()?._changes?.concluido?._timestamp">
      <div class='pos'>
        <span class="icon {{ statusPedido()?.concluido?.icon }}">
        </span>
      </div>
      <div class='info'>
        <p class='title'>{{ statusPedido()?.concluido?.caption }}</p>
        <p class='text'>{{ pedido()?._changes?.concluido?._timestamp?.toDate() | date: 'dd/MM/yyyy HH:mm' }}</p>
        <p class='text'
           *ngIf="!!showOperadores">{{ pedido()?._changes?.concluido?._operador?.nome }}</p>
      </div>
    </div>

    <div class='step cancelled'
         *ngIf="!!pedido()?._changes?.cancelado?._timestamp">
      <div class='pos'>
        <span class="icon {{ statusPedido()?.cancelado?.icon }}">
        </span>
      </div>
      <div class='info'>
        <p class='title'>{{ statusPedido()?.cancelado?.caption }}</p>
        <p class='text'>{{ pedido()?._changes?.cancelado?._timestamp?.toDate() | date: 'dd/MM/yyyy HH:mm' }}</p>
        <p class='text'
           *ngIf="!!showOperadores">{{ pedido()?._changes?.cancelado?._operador?.nome }}</p>
      </div>
    </div>
  </div>
</div>

<!-- <ol class="v-timeline">
    <li class="v-timeline__item">
      <div class="v-timeline__content finished">
        <div class="v-icon {{statusPedido()?.aguardandoSeparacao?.icon}}"></div>
        <p class="v-timeline__title">Aguardando separação</p>
        <p class="v-timeline__desc">In Progress</p>
      </div>
      <time><small class="o-50">05/01</small> 10:03</time>
    </li>

    <li class="v-timeline__item">
      <div class="v-timeline__content finished">
        <div class="v-icon icon-trolley"></div>
        <p class="v-timeline__title">Em separação</p>
        <small class="v-timeline__desc">Operador 1</small>
      </div>
      <time><small class="o-50">05/01</small> 10:12</time>
    </li>

    <li class="v-timeline__item">
      <div class="v-timeline__content">
        <div class="v-icon icon-shopping-cart"></div>
        <p class="v-timeline__title">Separado</p>
        <small class="v-timeline__desc">Operador 1</small>
      </div>
      <time><small class="o-50">05/01</small> 10:43</time>
    </li>

    <li class="v-timeline__item">
      <div class="v-timeline__content">
        <div class="v-icon icon-truck"></div>
        <p class="v-timeline__title">Em entrega</p>
        <small class="v-timeline__desc">Operador 1</small>
      </div>
      <time><small class="o-50">05/01</small> 12:05</time>
    </li>

    <li class="v-timeline__item">
      <div class="v-timeline__content">
        <div class="v-icon icon-check"></div>
        <p class="v-timeline__title">Concluído</p>
        <small class="v-timeline__desc">Operador 1</small>
      </div>
      <time><small class="o-50">06/01</small> 12:00</time>
    </li>
  </ol> -->

<!-- https://ishadeed.com/article/stepper-component-html-css/ -->