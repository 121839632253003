//#region models
import { ICorFoneInfoRet } from '../../../_core/_misc/_models/_interfaces/_misc';
//#endregion

//#region libs
import { breakFone } from '../_convert/break-fone';
//#endregion

export function formataTelefone(
  val: string,
  removeCountry: boolean = false
) {
  // console.log(breakFone('12345678')); // 8
  // console.log(breakFone('999249134')); // 9
  // console.log(breakFone('3412345678')); // 10
  // console.log(breakFone('34123456789')); // 11
  // console.log(breakFone('+553412345678')); // 12
  // console.log(breakFone('+5534123456789')); // 13
  const FI: ICorFoneInfoRet | null = breakFone(val);
  if (FI) {
    const DDD: string = FI.ddd ? `(${FI.ddd}) ` : '';
    const RET: string = `${removeCountry ? '' : FI.country} ${DDD}${FI.n1}-${FI.n2}`.trim();
  
    return RET;
  } else {
    return '';
  } // else
}
