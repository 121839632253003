export function formataCurrency(
  num: number,
  currency: boolean = true
): string {
  // const NUM: number = parseFloat(num.toFixed(2));
  return (new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL',
    })
    .format(num)
    .replace('R$', !!currency ? 'R$ ' : '')
    .split('.')
    .join(',')
    .trim()
  );
}
