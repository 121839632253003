//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import Fuse from 'fuse.js';
import {
  combineLatest,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  meilis: IMeilisearch[];
  searchTerm: string;
};
import { IMeilisearch } from '../../../_misc/_models/_interfaces/_cols';
import { FOCUS_TIMEOUT } from '../../../../_core/_misc/_models/consts';
//#endregion

//#region services
import { MeilisearchService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-meilisearchs',
  templateUrl: './meilisearchs.component.html',
  styleUrls: ['./meilisearchs.component.scss']
})
export class MeMatMeilisearchsComponent {
  //#region inputs
  // meilis
  #meilis: IMeilisearch[];
  get meilis(): IMeilisearch[] { return this.#meilis; }
  @Input({ required: true }) set meilis(val: IMeilisearch[]) {
    this.#meilis = !!val ? this.#meilisServ.fixes(val) : [];
    this.searchTermAction$().next('');
    this.focus();
  }
  // idSelected
  @Input() idSelected: string = '';
  //#endregion

  //#region outputs
  @Output() click$ = new EventEmitter<IMeilisearch>();
  //#endregion

  //#region actions
  searchTermAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region s
  #hits$: Observable<IMeilisearch[]>;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  // #loaderServ = inject(CorLoaderService);
  #meilisServ = inject(MeilisearchService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#hits$ = this.searchTermAction$()
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(
          (searchTerm: string) => {
            // console.log(searchTerm);

            // fuse-js
            if (!!searchTerm.trim()) {
              const FUSE: any = new Fuse(
                this.#meilis,
                {
                  includeScore: false,
                  keys: [
                    'nome',
                    'host',
                  ],
                  // minMatchCharLength: 3,
                }
              );
              const HITS: any[] = FUSE.search(searchTerm);
              // console.log(HITS);
              return of(
                this.#meilisServ.fixes(
                  (HITS || [])
                    .map(h => h?.item as IMeilisearch)
                ) as IMeilisearch[]
              );
            } // if
            return of(this.#meilisServ.fixes(this.#meilis) as IMeilisearch[]);
          }
        )
      );

    this.vm$.set(
      combineLatest([
        this.searchTermAction$().pipe(startWith('')),
        this.#hits$.pipe(startWith(this.#meilis)),
      ])
        .pipe(
          map(([searchTerm, hits,]) => {
            // console.log(this._lojas);
            // console.log(lojas);
            const VM: IVm = {
              meilis: hits || [],
              searchTerm,
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  tid_mei(index: any, item: IMeilisearch): string { return item?.id || ''; }

  focus() {
    setTimeout(
      () => { this.searchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
