//#region ng
import {
  Component,
  Input,
} from '@angular/core';
//#endregion

//#region models
import { IHorarioAgendamentoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

@Component({
  selector: 'app-horario2str',
  templateUrl: './horario2str.component.html',
  styleUrls: ['./horario2str.component.scss']
})
export class Horario2strComponent {
  //#region props
  @Input({ required: true }) horario: IHorarioAgendamentoMap;
  //#endregion
}
