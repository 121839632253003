//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  signal,
  Output
} from "@angular/core";
//#endregion

//#region models
import { PAG_ITENS_POR_PAG } from "../../../_misc/_models/consts";
//#endregion

//#region services
import { CorStorageService } from "../../../_ng/_services";
//#endregion

@Component({
  selector: "cor-mat-pag-sel-offset",
  templateUrl: "pag-sel-offset.component.html",
  styleUrls: ["pag-sel-offset.component.scss"]
})
export class CorMatPagSelOffsetComponent {
  //#region inputs
  // max
  @Input() max: any = 0;
  // size
  @Input() size: 'sm' | 'lg' = 'sm';
  // storageKey
  #storageKey: string;
  get storageKey(): string { return this.#storageKey; }
  @Input() set storageKey(val: string) {
    this.#storageKey = val;
    // console.log(val);    
    this.offsetSel = this.#storageServ.ls_get(val) || '12';
  }
  //#endregion

  //#region outputs
  @Output() change$: EventEmitter<string> = new EventEmitter<string>();
  //#endregion

  //#region publics
  itensPorPag = signal<number[]>([]);
  // offsetSel = signal<string>('');
  //#endregion

  //#region offsetSel
  #offsetSel: string;
  get offsetSel(): string { return this.#offsetSel; }
  set offsetSel(val: string) {
    // console.log(val);
    this.#offsetSel = val;
    // console.log(val);    
    this.storageKey && this.#storageServ.ls_set(
      this.storageKey,
      val
    );
    this.change$.emit(val);
  }
  //#endregion

  //#region injects
  #storageServ = inject(CorStorageService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    const MAX: number = parseInt(this.max) || 0;
    this.itensPorPag.set(PAG_ITENS_POR_PAG.filter((i: number) => MAX === 0 || i <= MAX));
  }
  //#endregion

  //#region functions
  tidn(index: any, item: any): number { return item || 0; }
  //#endregion
}
