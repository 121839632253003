//#region ng
import {
  Component,
  Input
} from "@angular/core";
//#endregion

@Component({
  selector: "cor-html-status-checkbox",
  templateUrl: "status-checkbox.component.html",
  styleUrls: ["status-checkbox.component.scss"]
})
export class CorHtmlStatusCheckboxComponent {
  //#region inputs
  @Input() status: boolean;
  //#endregion
}
