//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  // docData,
  DocumentReference,
  Firestore,
  getDoc,
  setDoc
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError,
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import { IDepartamento } from '../../_misc/_models/_interfaces/_cols';
import {
  ODOCS_TAG,
  ID_DEPTO_INDEFINIDO,
  IMG_OPEN_FOLDER
} from '../../../_core/_misc/_models/consts';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { TNullable } from '../../../_core/_misc/_models/_types';
import { checkImg } from '../../../_libs/_misc/_imgs';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class DepartamentosService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IDepartamento>): IDepartamento {
    const SUBS: Partial<IDepartamento>[] = (row?._subs || []);
    // console.log(SUBS);

    return {
      // id
      id: row?.id || '',

      // user
      ativo: {
        status: !!row?.ativo?.status, /// def true
        online: !!row?.ativo?.online, /// def true
        _status: !!row?.ativo?._status,
      },
      img: {
        _res: Number(row?.img?._res) || 0,
        __img: row?.img?.__img || '',
        custom: row?.img?.custom || '',
      },
      nivel: row?.nivel || null,
      nome: row?.nome || '',
      oculto: !!row?.oculto,

      // system
      _criadoEm: row?._criadoEm || null,
      _fromBase: !!row?._fromBase,
      _refresh: row?._refresh || '',
      _subs: SUBS.map(
        (sub: Partial<IDepartamento>) => {
          // console.log(sub);
          return {
            ...this.fix(sub),
            ativo: {
              ...sub?.ativo,
              online: row?.ativo?.online
            }
          }
        }),

      // realtime
      __parent: row?.__parent || null,
      // __parent: {
      //   id: row?.__parent?.id,
      //   nome: row?.__parent?.nome,
      // },
    };
  }

  lfix(row: Partial<IDepartamento>): Promise<TNullable<IDepartamento>> {
    // const R: IDepartamento = this.fix(row);
    return new Promise(
      (resolve, reject) => {
        // console.log(row);
        if (!row?._fromBase) {
          resolve(
            this.fix({
              ...row,
              img: {
                ...row?.img,
                __img: row?.img?.custom || IMG_OPEN_FOLDER
              }
            })
          );
        } else {
          const DEPARTAMENTO_ID: string = row?.id || '';
          if (!DEPARTAMENTO_ID) {
            resolve(null); // )reject(new Error('Nenhum departamento indicado.'));
          } else {
            const IMG: string = `${environment.firebase.conecdata.storage.root}/departamentos%2f${DEPARTAMENTO_ID}.png?alt=media`;
            // console.log(IMG);
            checkImg(
              IMG,
              // () => { console.log(IMG); resolve(this.fix({ ...row, __logo: IMG })) },
              () => resolve(
                this.fix({
                  ...row,
                  img: {
                    ...row?.img,
                    __img: IMG
                  }
                })
              ),
              () => resolve(
                this.fix({
                  ...row,
                  img: {
                    ...row?.img,
                    __img: IMG_OPEN_FOLDER
                  }
                })
              ),
            );
          } // else
        } // else
      } // else
    );
  }
  // https://firebasestorage.googleapis.com/v0/b/produtos-d5cd0.appspot.com/o/departamentos%2Fl06kR3Kqc7l9nw9rVVpo.png?alt=media

  fixes(docs: Partial<IDepartamento>[]): IDepartamento[] {
    // console.log(docs);
    return (docs || []).map((doc: Partial<IDepartamento>) => this.fix(doc));
  }

  lfixes(docs: Partial<IDepartamento>[]): Observable<IDepartamento[]> {
    // console.log(docs);
    const PROMISES: Promise<IDepartamento>[] = (docs || [])
      .map((doc: Partial<IDepartamento>) => this.lfix(doc));
    return from(Promise.all(PROMISES));
  }

  geraMenu(
    docs: IDepartamento[] = [],
    cb: any
  ): void {
    const RET: IDepartamento[] = [];
    const DEPTOS: IDepartamento[] = this.fixes(docs)
      .filter((d: Partial<IDepartamento>) => d?.id !== ID_DEPTO_INDEFINIDO);
    DEPTOS
      .forEach(
        (d: IDepartamento) => {
          // console.log(d?._nivel, JSON.stringify(d));
          if (!!d?.ativo?.online) {
            if (/* d?.nivel === 1 && */ d?.oculto) {
              const SUBS: IDepartamento[] = this.fixes(d?._subs || []);
              !!SUBS.length && RET.push(...SUBS);
            } else {
              RET.push(d);
            } // if
          } // if
          (!!cb && typeof cb === 'function') && cb(RET);
        } // forEach
      );
  }
  //#endregion

  //#region R
  docs(lojaPath: string): Observable<IDepartamento[]> {
    if (!!lojaPath) {
      const PATH: string = `${lojaPath}/d1/${ODOCS_TAG}`;
      // return (docData(doc(this.#db, PATH)) as Observable<IDepartamento[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<Partial<IDepartamento[]>, ObservableInput<Partial<IDepartamento[]>>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando departamentos.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }

  doc(
    lojaPath: string,
    idDepartamento: string
  ): Observable<IDepartamento> {
    // console.log('idLoja', idLoja);
    if (!!lojaPath) {
      if (!!idDepartamento) {
        const PATH: string = `${lojaPath}/departamentos/${idDepartamento}`;
        // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IDepartamento>)
        return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
          .pipe(
            map((doc: any) => (!!doc.data() ? { ...doc.data(), id: doc.id } : null)),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<IDepartamento, ObservableInput<IDepartamento>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando departamento.')
            )
          );
      } // if
      return throwError(() => 'Nenhum departamento indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region U
  update(
    lojaPath: string,
    changes: Partial<IDepartamento>,
    idDepartamento1: string,
    idDepartamento2: string = '',
    idDepartamento3: string = '',
  ): Observable<any> {
    if (!!lojaPath) {
      if (!!idDepartamento1) {
        let path: string = `${lojaPath}/d1/${idDepartamento1}`;
        if (!!idDepartamento2) path += `/d2/${idDepartamento2}`;
        if (!!idDepartamento3) path += `/d3/${idDepartamento3}`;

        return from(setDoc(doc(this.#db, path), changes, { merge: true }))
          .pipe(
            // map(() => { throw new Error(`Erro modificando ${path}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando departamentos.')
            )
          );
      } // if
      return throwError(() => 'Departamento nível 1 não indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}
