//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal
} from "@angular/core";
//#endregion

//#region models
import { THEMES } from "../../../_misc/_models/consts";
//#endregion

@Component({
  selector: "cor-bs-theme-sel",
  templateUrl: "theme-sel.component.html",
  styleUrls: ["theme-sel.component.scss"]
})
export class CorBsThemeSelComponent {
  //#region inputs
  @Input() tema: string = '';
  //#endregion

  //#region outputs
  @Output() select$ = new EventEmitter<string>();
  //#endregion

  //#region publics
  temas = signal<string[]>(null);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.temas.set(THEMES.filter((t: string) => !['primary', 'tertiary'].includes(t)));
    // console.log(this.temas());
  }
  //#endregion

  //#region functions
  tid_the(index: any, item: any): string { return item || ''; }
  //#endregion
}
