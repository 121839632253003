//#region ng
import {
  Component,
  ViewChild,
  signal
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
//#endregion

//#region firebase
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  Unsubscribe as AuthUnsubscribe,
} from '@angular/fire/auth';
// import {
//   doc,
//   Firestore,
//   onSnapshot,
//   Unsubscribe as FbUnsubscribe,
// } from '@angular/fire/firestore';
import { serverTimestamp } from 'firebase/firestore';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import Swal from 'sweetalert2';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  first,
  finalize,
  switchMap,
  tap,
  takeUntil,
  map,
  filter,
  // filter
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  isLoading: boolean;
  conta: IConta | null;
  // loja: ILoja | null;
};
import { environment } from 'src/environments/environment';
import {
  AUDIOS,
  HOME_ROUTE,
  IAudioNotification,
  THEME
} from './models/consts';
import {
  IConta,
  ILoja,
  IPedido,
  ITerminal
} from './_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  TOperadorRole,
  TPedidoStatus
} from './_shared/_mercadeiro/_misc/_models/_types';
import { NO_COLOR } from './_shared/_core/_misc/_models/consts';
import { ICorFbUserApi } from './_shared/_core/_misc/_models/_interfaces/_apis';
import { TNullable } from './_shared/_core/_misc/_models/_types';
//#endregion

//#region libs
import { setIon4Theme } from './_shared/_libs/_www/_theme';
import { printDialog } from './_shared/_libs/_www/_printing';
import { onDestroy } from './_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  AppService,
  StorageService
} from './services';
import { CorFbMessaging } from './_shared/_core/_ng/_models/_classes';
import {
  ContasService,
  LojasService,
  PedidosService,
  TerminaisService
} from './_shared/_mercadeiro/_ng/_services';
import {
  CorFbAuthService,
  CorFbUserService,
  CorLoaderService,
  CorMessagesService
} from './_shared/_core/_ng/_services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore,
  TerminaisStore
} from './_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import { PedidosPage } from './pages';
import { ICorRota } from './_shared/_core/_misc/_models/_interfaces/_misc';
//#endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  //#region actions
  private _destroyAction$: Subject<void> = onDestroy();
  private _userAction$: Subject<TNullable<ICorFbUserApi>> = new Subject<TNullable<ICorFbUserApi>>();
  //#endregion

  //#region publics
  loja = signal<ILoja>(null);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  private _authUnsub: AuthUnsubscribe;
  // private _user: any;
  private _messaging: CorFbMessaging;
  // private _subs: Subscription[] = [];
  private _vm: IVm;
  //#endregion

  //#region constructor
  constructor(
    private _authServ: CorFbAuthService,
    private _contasServ: ContasService,
    private _contasStore: ContasStore,
    private _fireAuth: Auth,
    private _loaderServ: CorLoaderService,
    private _lojasServ: LojasService,
    private _lojasStore: LojasStore,
    private _msgServ: CorMessagesService,
    // private _snackBar: MatSnackBar,
    private _pedidosServ: PedidosService,
    // private _route: ActivatedRoute,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _terminaisStore: TerminaisStore,
  ) {
    this._messaging = new CorFbMessaging(
      environment?.firebase?.config?.vapidKey || '',
      environment?.firebase?.region || ''
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    setIon4Theme(THEME);

    AppService.logout$.pipe(takeUntil(this._destroyAction$)).subscribe((goto: string) => this._logout(goto));
    // AppService.lojaStateChanged$.pipe(takeUntil(this._destroyAction$)).subscribe((loja: ILoja) => this.loja = loja);
    AppService.pedidoCancelarPedido$.pipe(takeUntil(this._destroyAction$)).subscribe((p: IPedido) => this._pedidoCancelarPedido(p));
    AppService.pedidoConcluirPedido$.pipe(takeUntil(this._destroyAction$)).subscribe((p: IPedido) => this._pedidoConcluirPedido(p));
    AppService.pedidoConcluirSeparacao$.pipe(takeUntil(this._destroyAction$)).subscribe((p: IPedido) => this._pedidoConcluirSeparacao(p));
    AppService.pedidoIniciaSeparacao$.pipe(takeUntil(this._destroyAction$)).subscribe((p: IPedido) => this._pedidoIniciaSeparacao(p));
    AppService.relatorioSeparacao$.pipe(takeUntil(this._destroyAction$)).subscribe((p: IPedido) => this._relatorioSeparacao(p));
    // AppService.relatorioModificacoes$.pipe(takeUntil(this._destroyAction$)).subscribe((p: IPedido) => this._relatorioModificacoes(p));
    AppService.relatorioSeparado$.pipe(takeUntil(this._destroyAction$)).subscribe((p: IPedido) => this._relatorioSeparado(p));

    this._userAction$
      .pipe(
        // filter((user: ICorFbUserApi) => !!user?.uid),
        switchMap(
          (user: ICorFbUserApi) => this._contasServ.contaFromUid(user?.uid)
            .pipe(
              tap(
                (conta: IConta) => {
                  const CONTA: IConta | null = !!conta ? this._contasServ.fix(conta, user) : null;
                  if (!!user?.uid) {
                    // if (!!user?.uid) {
                    if (!!CONTA) {
                      const ROLE: TOperadorRole = CONTA?.operador?.role;
                      // console.log(ROLE);
                      if (['dono', 'gerente', 'monitor'].includes(ROLE)) {
                        // TODO: Verificar se é operador dessa loja.
                        const NOME_COMPLETO: string = CONTA?.__nomeCompleto || '';
                        this._contasStore.setState(CONTA);
                        this._snackBar.open(`Olá, ${NOME_COMPLETO}.`);
                        this._router.url === '/login' && this._router.navigateByUrl(HOME_ROUTE);
                      } else {
                        this._badUser('Conta inválida.');
                      } // else
                    } else {
                      this._badUser('Conta não encontrada.');
                    } // else
                  } else {
                    this._contasStore.setState(null);
                  } // else
                }
              ),
              takeUntil(this._destroyAction$)
            )
        )
      )
      .subscribe();

    this._terminaisStore?.terminalStateChanged$
      .pipe(
        filter((terminal: ITerminal) => !!terminal?._loja?.id),
        switchMap(
          (terminal: ITerminal) => this._lojasServ.doc(terminal?._loja?.id)
            .pipe(
              tap(
                (loja: ILoja) => {
                  // console.log(loja);
                  const LOJA: ILoja = !!loja ? this._lojasServ.fix(loja) : null;
                  this.loja.set(LOJA);
                  this._lojasStore.setState(LOJA, THEME);
                  // const CONTA: IConta | null = !!conta ? this._contasServ.fix(conta, user) : null;
                  // if (!!user?.uid) {
                  //   // if (!!user?.uid) {
                  //   if (!!CONTA) {
                  //     const ROLE: TOperadorRole = CONTA?.operador?.role;
                  //     // console.log(ROLE);
                  //     if (['dono', 'gerente', 'monitor'].includes(ROLE)) {
                  //       // TODO: Verificar se é operador dessa loja.
                  //       const NOME_COMPLETO: string = CONTA?.__nomeCompleto || '';
                  //       this._contasStore.setState(CONTA);
                  //       this._snackBar.open(`Olá, ${NOME_COMPLETO}.`);
                  //       this._router.url === '/login' && this._router.navigateByUrl(HOME_ROUTE);
                  //     } else {
                  //       this._badUser('Conta inválida.');
                  //     } // else
                  //   } else {
                  //     this._badUser('Conta não encontrada.');
                  //   } // else
                  // } else {
                  //   this._contasStore.setState(null);
                  // } // else
                }
              ),
              takeUntil(this._destroyAction$)
            )
        )
      )
      .subscribe();

    this.vm$.set(
      combineLatest(
        [
          this._loaderServ.isLoading$,
          this._contasStore.contaStateChanged$,
          // this._lojasStore.lojaStateChanged$
        ]
      )
        .pipe(
          map(
            ([isLoading, conta]) => {
              this._vm = {
                isLoading,
                conta,
              };
              // console.log(this._vm);
              return this._vm;
            }
          )
        ),
    );

    !!this._authUnsub && this._authUnsub();
    this._authUnsub = onAuthStateChanged(
      getAuth(),
      (user: any) => {/*  console.log(user); */ this._userAction$.next(user); }
    );
  }

  ngOnDestroy() {
    !!this._authUnsub && this._authUnsub();
  }
  //#endregion

  //#region functions
  private _badUser(text: string = '') {
    !!text && this._msgServ.send(text, 'warning', 'dialog');
    this._logout('/login');
  }

  private async _logout(goto: string = '') {
    // console.log(getAuth()?.currentUser);
    // this._appLojasStore.setState(null, THEME);
    this._lojasStore.setState(null, THEME);
    this._contasStore.setState(null);
    // if (!!getAuth()?.currentUser) {
    const NOME: string = this._contasStore.getState()?.nome?.nome || 'Visitante';
    this._snackBar.open(`${NOME} saiu.`);
    await this._authServ.logout();
    // } // if

    if (!!goto) {
      if (goto === 'home') {
        this._router.navigateByUrl(HOME_ROUTE);
      } else {
        this._router.navigateByUrl(goto);
      } // else
    } // if
  }
  //#endregion

  //#region functions
  private _pedidoConcluirSeparacao(p: IPedido) {
    // this.pedidosRef?.mute();
    // console.log(p);
    const PEDIDO_STATUS: string = p?._status;
    const PEDIDO_SEPARADO_PERCDONE: number = Number(p?._percSeparado) || 0;
    const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
    const PEDIDO_RETIRADA_ENTREGA: TPedidoStatus = !!p?.carrinho?.__loja?.isRetirada ? 'aguardandoRetirada' : 'aguardandoEntrega';
    // console.log(PEDIDO_STATUS, PEDIDO_SEPARADO_PERCDONE, PEDIDO_RETIRADA_ENTREGA, LOJA_PATH);
    if (
      PEDIDO_STATUS === 'emSeparacao'
      && PEDIDO_SEPARADO_PERCDONE >= 100
    ) {
      // console.log(this.conta);
      const CHANGES: Partial<IPedido> = {
        _changes: {
          [PEDIDO_RETIRADA_ENTREGA]: {
            _timestamp: serverTimestamp(),
            _operador: {
              id: this._vm?.conta?.id || '',
              nome: this._vm?.conta?.__nomeCompleto || ''
            }
          },
        },
        _status: PEDIDO_RETIRADA_ENTREGA
      };
      // console.log(p);
      // console.log(JSON.stringify(CHANGES));
      // ? this._relatorioModificacoes(p, this._vm?.conta?.__nomeCompleto || '')

      const SUB: Subscription = this._loaderServ.showUntilCompleted(
        this._pedidosServ.update(
          LOJA_PATH,
          CHANGES,
          p?.id || ''
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          async () => {
            this._relatorioSeparado(p, this._vm?.conta?.__nomeCompleto || '');
            this._snackBar.open(
              'Separação concluída.',
              ''
            );
          }
        );
    } // if
  }

  private _pedidoConcluirPedido(p: IPedido) {
    // console.log(p);
    // this.pedidosRef?.mute();
    const PEDIDO_STATUS: string = p?._status;
    const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
    // console.log(PEDIDO_STATUS, LOJA_PATH);
    if (!['concluido', 'cancelado'].includes(PEDIDO_STATUS)) {
      // console.log(this.conta);
      const CHANGES: Partial<IPedido> = {
        _changes: {
          concluido: {
            _timestamp: serverTimestamp(),
            _operador: {
              id: this._vm?.conta?.id || '',
              nome: this._vm?.conta?.__nomeCompleto || ''
            }
          },
        },
        _status: 'concluido'
      };
      // console.log(JSON.stringify(CHANGES));

      const SUB: Subscription = this._loaderServ.showUntilCompleted(
        this._pedidosServ.update(LOJA_PATH, CHANGES, p?.id || '')
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          async () => {
            this._snackBar.open(
              'Pedido concluído.',
              ''
            );
          }
        );
    } // if
  }

  private _relatorioSeparado(
    p: IPedido,
    solicitadoPor: string = ''
  ) {
    // this.pedidosRef?.mute();
    // console.log(p);
    const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
    if (!!p && !!LOJA_PATH) {
      const SUB: Subscription = this._pedidosServ.relatorioSeparado(this._pedidosServ.fix(p), LOJA_PATH, solicitadoPor)
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .subscribe((html: string) => { /* console.log(html); */ printDialog(html); });
    } // if
  }

  // private _relatorioModificacoes(
  //   p: IPedido,
  //   solicitadoPor: string = ''
  // ) {
  //   // this.pedidosRef?.mute();
  //   // console.log(p);
  //   const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
  //   if (!!p && !!LOJA_PATH) {
  //     const SUB: Subscription = this._pedidosServ.relatorioModificacoes(this._pedidosServ.fix(p), solicitadoPor)
  //       .pipe(first(), finalize(() => SUB?.unsubscribe()))
  //       .subscribe((html: string) => { /* console.log(html); */ printDialog(html); });
  //   } // if
  // }

  private _relatorioSeparacao(
    p: IPedido,
    solicitadoPor: string = ''
  ) {
    // console.log(p);
    // this.pedidosRef?.mute();
    if (!!p) {
      const SUB: Subscription = this._pedidosServ.relatorioSeparacao(this._pedidosServ.fix(p), solicitadoPor)
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .subscribe((html: string) => { /* console.log(html); */ printDialog(html); });
    } // if
  }

  private _pedidoIniciaSeparacao(p: IPedido) {
    // this.pedidosRef?.mute(5000);
    // this._relatorioSeparacao(p, get(this.conta, '__nomeCompleto') || '');
    const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
    // console.log(LOJA_PATH, p?.id, p?._status, this.loja());
    if (
      !!LOJA_PATH
      && !!p?.id
      && p?._status === 'aguardandoSeparacao'
    ) {
      // console.log(this.conta);
      const CHANGES: Partial<IPedido> = {
        _changes: {
          emSeparacao: {
            _timestamp: serverTimestamp(),
            _operador: {
              id: this._vm?.conta?.id || '',
              nome: this._vm?.conta?.__nomeCompleto || ''
            }
          },
        },
        _status: 'emSeparacao'
      };
      // console.log(JSON.stringify(CHANGES));

      const SUB: Subscription = this._loaderServ.showUntilCompleted(
        this._pedidosServ.update(
          LOJA_PATH,
          CHANGES,
          p?.id || ''
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          async () => {
            this._relatorioSeparacao(p, this._vm?.conta?.__nomeCompleto || '');
            this._snackBar.open('Pedido em separação.', '');
          }
        );
    } // if
  }

  private _pedidoCancelarPedido(p: IPedido) {
    // console.log(p);
    // this.pedidosRef?.mute();
    const PEDIDO_STATUS: string = p?._status;
    const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
    // console.log(PEDIDO_STATUS, LOJA_PATH);
    if (!['concluido', 'cancelado'].includes(PEDIDO_STATUS)) {
      // console.log(this.conta);
      const CHANGES: Partial<IPedido> = {
        _changes: {
          cancelado: {
            _timestamp: serverTimestamp(),
            _operador: {
              id: this._vm?.conta?.id || '',
              nome: this._vm?.conta?.__nomeCompleto || ''
            }
          },
        },
        _status: 'cancelado'
      };
      // console.log(JSON.stringify(CHANGES));

      const SUB: Subscription = this._loaderServ.showUntilCompleted(
        this._pedidosServ.update(LOJA_PATH, CHANGES, p?.id || '')
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          async () => {
            this._loaderServ.lstop();
            this._snackBar.open(
              'Pedido cancelado.',
              ''
            );
          }
        );
    } // if
  }
  //#endregion

  //#region methods
  onLogoutClick() {
    this._logout('/login');
  }



  //#endregion

  /*
    //#region viewchilds
    @ViewChild(PedidosPage) pedidosRef: PedidosPage;
    //#endregion

    //#region publics
    audioList: IAudioNotification[] = AUDIOS;
    isLoading: boolean;
    // rotas: ICdRota[] = [];
    // loja: ILoja;
    //#endregion

    //#region loja
    private _loja: ILoja;
    get loja(): ILoja { return this._loja; }
    set loja(val: ILoja) {
      this._loja = !!val ? this._lojasServ.fix(val) : null;
      // console.log(this.conta);
      const ROLE: TOperadorRole = this?.conta?.operador?.role as TOperadorRole;
      if (!!ROLE) {
        const CONTA_OK: boolean = ROLE === 'dono'
          ? this.loja?.rede?.id === this?.conta?.operador?.rede?.id
          : this.conta?.operador?._lojasIds?.includes(this.loja?.id || '')
        // console.log(ROLE, CONTA_OK);
        // console.log(this.loja?.rede?.id, this.conta?.operador?._rede?.id);
        !CONTA_OK && this._badUser('Conta inválida.');
      } // if
    }
    //#endregion

    //#region audioSel
    private _audioSel: IAudioNotification;
    set audioSel(val: IAudioNotification) {
      this._audioSel = val;
      console.log(val);
      this._storageServ.audioIdSet(val?.id)
    }
    get audioSel(): IAudioNotification {
      return this._audioSel;
    }
    //#endregion

    //#region conta
    private _conta: IConta;
    set conta(val: IConta) {
      this._conta = val;
      this._contasStore.setState(val);
    }
    get conta(): IConta {
      return this._conta;
    }
    //#endregion

    //#region lifecycles
    ngOnInit() {

      this._subs.push(

        /*
        TerminaisService.terminalStateChanged$
          .subscribe(
            (terminal: ITerminal) => {
              // console.log(terminal);
              const LOJA_ID: string = terminal?._loja?.id || '';
              // console.log(LOJA_ID);
              if (!!LOJA_ID) {
                const SUB: Subscription = this._lojasServ.doc(LOJA_ID)
                  .pipe(
                    first(),
                    finalize(() => SUB && SUB.unsubscribe())
                  )
                  .subscribe(
                    async (loja: ILoja) => {
                      // console.log(loja);
                      // this._appServ.setLojaState(loja);
                      this.loja = !!loja ? this._lojasServ.fix(loja) : null;
                      this._lojasServ.setLojaState(this.loja, THEME);
                    });
              } // if
              // console.log(this._router?.url);
              // console.log(this._authServ.getFirebaseUser);
              // !!terminal && this._router.navigateByUrl(HOME_ROUTE);
            }
          ),
          * /
      );

      // try {
      //   this._messaging.getToken()
      //     .then(
      //       (token: string) => {
      //         console.log(token);
      //       }
      //     )
      //     .catch(() => {
      //       Notification.requestPermission()
      //         .then(
      //           status => {
      //             console.log(status);
      //             // https://www.digitalcitizen.life/wp-content/uploads/2018/12/chrome_notifications.png
      //           }
      //         );
      //     });
      //   // this._router.navigateByUrl(HOME_ROUTE);
      // } catch (error) {
      //   console.error(error);
      // } // catch

      // const LOJA_ID: string = this._storageServ.lojaIdGet() || '';
      // // console.log(LOJA_ID);
      // if (LOJA_ID) {
      //   const SUB: Subscription = this._lojasServ.loja(LOJA_ID)
      //     .pipe(
      //       first(),
      //       finalize(() => SUB && SUB.unsubscribe())
      //     )
      //     .subscribe(
      //       async (loja: ILoja) => {
      //         // console.log(loja);
      //         this._appServ.setLojaState(loja);
      //       });
      // } // if
    }

    ngOnDestroy() {
      this._authUnsub && this._authUnsub();
      this._subs.forEach((s: Subscription) => s && s.unsubscribe());
    }
    //#endregion

    



    private _badUser(
      // logout: boolean,
      text: string = '',
    ) {
      // this.lojas = null;
      // this._integradoraLojasServ.setState(null);
      this.conta = null;
      this._appServ.setLojaState(null);
      this._storageServ.lojaIdRemove();
      /* logout && * / this._logout('/login');
      text && Swal.fire({
        icon: "error",
        title: "Ooops...",
        html: `<h2>${text}</h2>`,
        backdrop: false,
        confirmButtonColor: NO_COLOR,
      })
    }

    private async _logout(goto: string = '') {
      // const NOME: string = get(
      //   this._contasServ.getState(),
      //   'nome.nome'
      // ) || 'Visitante';
      // this._snackBar.open(`${NOME} saiu.`);
      // this._operadoresServ.setState(null);
      await this._authServ.logout();
      // this._snackBar.open(`${NOME} saiu.`);
      // // this._storageServ.on
      // setTimeout(() => {
      //   this.conta = null;
      //   this._contasServ.setState(null);
      // }, 100
      // );

      // // console.log(this._url);
      // if (this._url.startsWith('/sua-conta/')) {
      //   goto = 'home';
      // };

      if (goto) {
        if (goto === 'pedidos') {
          this._router.navigateByUrl(HOME_ROUTE);
        } else {
          this._router.navigateByUrl(goto);
        } // else
      } // if
    }

    private _buscaConta(user: ICorFbUserApi) {
      // console.log(this.loja, this._user);
      const UID: string = user?.uid || '';
      if (!!UID) {
        const SUB: Subscription = this._contasServ.contaFromUid(UID)
          .pipe(
            first(),
            finalize(() => SUB?.unsubscribe())
          )
          .subscribe({
            next: (conta: IConta) => {
              // console.log(conta);
              if (!!conta) {
                const ROLE: TOperadorRole = conta?.operador?.role as TOperadorRole;
                if (['dono', 'gerente', 'monitor'].includes(ROLE)) {
                  this.conta = conta ? this._contasServ.fix(conta) : null;
                  // const NOME_COMPLETO: string = get(this.conta, '__nomeCompleto') || '';
                  this._router.navigateByUrl(HOME_ROUTE);
                } else {
                  this._badUser('Conta inválida.');
                } // else
              } else {
                this._badUser('Conta não encontrada.');
              } // else
            },
            error: (error: any) => {
              error && console.error(error)
              this._badUser();
            }
          });
      } else {
        this._badUser();
      } // else
    }
    //#endregion

    //#region methods
    onLogoutClick() {
      this._logout('/login');
    }

    onConfigClick() {
      alert("Config!");
    }
    //#endregion
  */
}
