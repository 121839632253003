//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
//#endregion

//#region models
import { IItemCarrinhoMap } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { TPedidoSeparacaoStage } from 'src/app/_shared/_mercadeiro/_misc/_models/_types';
//#endregion

@Component({
  selector: 'itens-table',
  templateUrl: './itens-table.component.html',
  styleUrls: ['./itens-table.component.scss']
})
export class ItensTableComponent {
  //#region inputs
  // itens
  @Input({ required: true }) itens: IItemCarrinhoMap[];
  // stage
  @Input({ required: true }) stage: TPedidoSeparacaoStage;
  // qtdeSel
  @Input() qtdeSel: number = 0;
  //#endregion

  //#region outputs
  @Output() separado$: EventEmitter<IItemCarrinhoMap> = new EventEmitter<IItemCarrinhoMap>();
  @Output() encontrado$: EventEmitter<IItemCarrinhoMap> = new EventEmitter<IItemCarrinhoMap>();
  @Output() solicitado$: EventEmitter<IItemCarrinhoMap> = new EventEmitter<IItemCarrinhoMap>();
  @Output() remove$: EventEmitter<IItemCarrinhoMap> = new EventEmitter<IItemCarrinhoMap>();
  //#endregion

  //#region functions
  tid_ite(index: any, item: IItemCarrinhoMap): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onSolicitadoClick(i: IItemCarrinhoMap) {
    // console.log(i);    
    !!i && this.solicitado$.emit(i);
  }

  onEncontradoClick(i: IItemCarrinhoMap) {
    !!i && this.encontrado$.emit(i);
  }

  onSeparadoClick(i: IItemCarrinhoMap) {
    !!i && this.separado$.emit(i);
  }

  onRemoverClick(i: IItemCarrinhoMap) {
    !!i && this.remove$.emit(i);
  }
  //#endregion
}
