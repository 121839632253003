<!-- <p>entregaStatusRef.value {{ entregaStatusRef.value }}</p> -->
<!-- <p>foneCelRef.errors {{ foneCelRef?.errors | json }}</p> -->
<!-- <p>extrasForm().value {{ extrasForm().value | json }}</p> -->

<form [formGroup]="extrasForm()">
  <!-- <div class="row">
    <div class="col"
         formGroupName="dono">
      <mat-form-field class="w-100 mb-2"
                      appearance="outline">
        <mat-label>E-mail dono</mat-label>
        <input type="text"
               matInput
               formControlName="email"
               [focus]="emailFocusEvent"
               onReturn>
        <mat-hint *ngIf="fv()?.lerror(emailRef, 'email')">
          <span class="danger">
            {{ fv()?.error(emailRef) }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div> -->

  <div formGroupName="faturamento">
    <div class="row">
      <div class="col">
        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>Cód. faturamento</mat-label>
          <input type="text"
                 matInput
                 class="text-end"
                 formControlName="cod"
                 [focus]="codFocusEvent$()"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(codFaturamentoRef) || codFaturamentoRef?.errors?.['codFaturamento']">
            <span class="danger">
              {{ fv()?.error(codFaturamentoRef) || 'Valor inválido.' }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>Mensalidade</mat-label>
          <input type="number"
                 class="text-end"
                 matInput
                 formControlName="mensalidade"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(valorTsRef) || mensalidadeRef?.errors?.['mensalidade']">
            <span class="danger">
              {{ fv()?.error(mensalidadeRef) || 'Valor inválido.' }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <mat-card formGroupName="servicos">
      <mat-card-title>Taxa de serviços</mat-card-title>

      <div class="row">
        <div class="col">
          <mat-form-field class="w-100 mb-2"
                          appearance="outline">
            <mat-label>Valor</mat-label>
            <input type="number"
                   class="text-end"
                   matInput
                   formControlName="valor"
                   onReturn>
            <mat-hint *ngIf="fv()?.lerror(valorTsRef) || valorTsRef?.errors?.['valorTS']">
              <span class="danger">
                {{ fv()?.error(valorTsRef) || 'Valor inválido.' }}
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field class="w-100 mb-2"
                          appearance="outline">
            <mat-label>Piso</mat-label>
            <input type="number"
                   class="text-end"
                   matInput
                   formControlName="piso"
                   onReturn>
            <mat-hint *ngIf="fv()?.lerror(pisoTsRef) || pisoTsRef?.errors?.['pisoTS']">
              <span class="danger">
                {{ fv()?.error(pisoTsRef) || 'Valor inválido.' }}
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="col">
        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>Anistia (meses)</mat-label>
          <input type="number"
                 class="text-end"
                 matInput
                 formControlName="anistiaMeses"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(anistiaTsRef) || anistiaTsRef?.errors?.['anistiaTS']">
            <span class="danger">
              {{ fv()?.error(anistiaTsRef) || 'Valor inválido.' }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </mat-card>
  </div>
</form>