//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
//#endregion

//#region 3rd
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask
} from 'ngx-mask';
//#endregion

//#region modules
import { CorDirectivesModule } from '../../_ng/_directives/cor-directives.module';
import { CorPipesModule } from '../../_ng/_pipes/cor-pipes.module';
//#endregion

//#region components 
import {
  CorBsCepFormComponent,
  CorBsEnderecoComponent,
  CorBsPagSelOffsetComponent,
  CorBsThemeSelComponent
} from '.';
//#endregion

@NgModule({
  imports: [
    CorDirectivesModule,
    CorPipesModule,
    CommonModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
  ],
  declarations: [
    CorBsCepFormComponent,
    CorBsEnderecoComponent,
    CorBsPagSelOffsetComponent,
    CorBsThemeSelComponent,
  ],
  exports: [
    CorBsCepFormComponent,
    CorBsEnderecoComponent,
    CorBsPagSelOffsetComponent,
    CorBsThemeSelComponent,
  ],
  providers: [
    provideNgxMask(),
  ]
})
export class CorBsComponentsModule { }
