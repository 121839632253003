<ng-container *ngIf="vm$() | async as vm">
  <!-- <p>vm {{ vm | json }}</p> -->
  <!-- <p>vm?.stages {{ vm?.stages | json }}</p> -->
  <!-- <p>vm?.qtdeVolsSel {{ vm?.qtdeVolsSel }}</p> -->
  <!-- <p>vm?.tabItensSel {{ vm?.tabItensSel }}</p> -->
  <!-- <p>viewStyle {{ viewStyle }}</p> -->
  <!-- <p>pedido?._percSeparado {{ pedido?._percSeparado }}</p> -->
  <!-- <p>vm?.stages?.separados?.itens {{ vm?.stages?.separados?.itens | json }}</p> -->

  <div *ngIf="!!pedido"
       class="h-100">

    <div class="w3-light-grey mb-1">
      <div class="w3-container w3-round w3-lime w3-padding w3-center"
           style="width:{{pedido?._percSeparado}}%">{{ pedido?._percSeparado | number : '1.0-2' }}%
      </div>
    </div>

    <as-split [direction]="!!splitVertical ? 'vertical' : 'horizontal'">
      <as-split-area [size]="50"
                     *ngIf="pedido?._percSeparado < 100">
        <div class="segment my-2"
             *ngIf="pedido?._status === 'emSeparacao'">
          <ul class="nav nav-pills justify-content-center">
            <li class="nav-item my-auto">
              <a class="nav-link active">
                Pendentes <small class="o-50">( {{ pedido?._qtdeItens?.pendentes }} )</small>
              </a>
            </li>
          </ul>
        </div>

        <stage-header [carrinhoInfo]="vm?.stages?.pendentes"
                      stage="pendentes" />

        <div *ngIf="viewStyle === 'table'; else notTablePendentes">
          <itens-table [itens]="vm?.stages?.pendentes?.itens"
                       [qtdeSel]="vm?.stages?.separando?.itens?.length"
                       stage="pendentes"
                       (solicitado$)="onSolicitadoSelClick($event)"
                       (encontrado$)="onEncontradoSelClick($event)"
                       (separado$)="onSeparadoSelClick($event)" />
        </div>
        <ng-template #notTablePendentes>
          <ul class="produtos">
            <li *ngFor="let i of vm?.stages?.pendentes?.itens; trackBy:tid_ite"
                class="produto d-flex flex-column">
              <item-card [item]="i"
                         [off]="!!i?.__sel"
                         [qtdeSel]="vm?.stages?.separando?.itens?.length"
                         stage="pendentes"
                         (solicitado$)="onSolicitadoSelClick($event)"
                         (encontrado$)="onEncontradoSelClick($event)"
                         (separado$)="onSeparadoSelClick($event)" />
            </li>
          </ul>
        </ng-template>

        <div class="alert alert-light text-center"
             role="alert"
             *ngIf="!vm?.stages?.pendentes?.itens?.length">
          Nenhum item.
        </div>
      </as-split-area>

      <as-split-area [size]="50">
        <div *ngIf="!!vm?.stages?.separando?.itens?.length; else nenhumaSeparacao">
          <div class="segment my-2"
               *ngIf="pedido?._status === 'emSeparacao'">
            <ul class="nav nav-pills justify-content-center">
              <li class="nav-item my-auto">
                <a class="nav-link active">
                  Separando <small class="o-50">( {{ vm?.stages?.separando?.itens?.length }} )</small>
                </a>
              </li>
            </ul>
          </div>

          <stage-header [carrinhoInfo]="vm?.stages?.separando"
                        stage="separando"
                        color="primary"
                        (limpar$)="onLimparSelecionandoClick()"
                        (confirmar$)="onConfirmarSelClick()" />

          <div *ngIf="viewStyle === 'table'; else notTableSeparando">
            <itens-table [itens]="vm?.stages?.separando?.itens"
                         stage="separando"
                         (remove$)="onSelRemoveClick($event)" />
          </div>
          <ng-template #notTableSeparando>
            <ul class="produtos">
              <li *ngFor="let i of vm?.stages?.separando?.itens; trackBy:tid_ite"
                  class="produto d-flex flex-column">
                <item-card [item]="i"
                           (remove$)="onSelRemoveClick($event)"
                           stage="separando" />
              </li>
            </ul>
          </ng-template>

          <div class="alert alert-light text-center"
               role="alert"
               *ngIf="!vm?.stages?.separando?.itens?.length">
            Nenhum item.
          </div>
        </div>
        <ng-template #nenhumaSeparacao>
          <div class="segment my-2"
               *ngIf="pedido?._status === 'emSeparacao'">
            <ul class="nav nav-pills justify-content-center d-flex">
              <li class="nav-item my-auto"
                  (click)="tabItensSelAction$().next('')">
                <a class="nav-link"
                   [class.active]="vm?.tabItensSel === ''">
                  Todos resolvidos <small class="o-50">
                    ( {{ vm?.stages?.separados?.itens?.length }} )
                  </small>
                </a>
              </li>

              <li class="nav-item my-auto"
                  (click)="tabItensSelAction$().next('adicionado')">
                <a class="nav-link"
                   [class.active]="vm?.tabItensSel === 'adicionado'">
                  Adicionados <small class="o-50">
                    ( {{ vm?.pedido?._qtdeItens?.adicionados }} )
                  </small>
                </a>
              </li>

              <li class="nav-item my-auto"
                  (click)="tabItensSelAction$().next('confirmado')">
                <a class="nav-link"
                   [class.active]="vm?.tabItensSel === 'confirmado'">
                  Confirmados <small class="o-50">
                    ( {{ vm?.pedido?._qtdeItens?.confirmados }} )
                  </small>
                </a>
              </li>

              <li class="nav-item my-auto"
                  (click)="tabItensSelAction$().next('modificado')">
                <a class="nav-link"
                   [class.active]="vm?.tabItensSel === 'modificado'">
                  Modificados <small class="o-50">
                    ( {{ vm?.pedido?._qtdeItens?.modificados }} )
                  </small>
                </a>
              </li>

              <li class="nav-item my-auto"
                  (click)="tabItensSelAction$().next('removido')">
                <a class="nav-link"
                   [class.active]="vm?.tabItensSel === 'removido'">
                  Removidos <small class="o-50">
                    ( {{ vm?.pedido?._qtdeItens?.removidos }} )
                  </small>
                </a>
              </li>

              <li class="nav-item my-auto"
                  (click)="tabItensSelAction$().next('naoEncontrado')">
                <a class="nav-link"
                   [class.active]="vm?.tabItensSel === 'naoEncontrado'">
                  Não encontrados <small class="o-50">
                    ( {{ vm?.pedido?._qtdeItens?.naoEncontrados }} )
                  </small>
                </a>
              </li>

              <!-- <li class="ms-auto">
                <button mat-icon-button
                        matTooltip="Adicionar produto."
                        color="light"
                        (click)="onAddProdutoClick()">
                  <mat-icon>add</mat-icon>
                </button>
              </li> -->
            </ul>
          </div>

          <stage-header [carrinhoInfo]="vm?.stages?.separados"
                        stage="separados" />
          <div *ngIf="viewStyle === 'table'; else notTableResolvidos">
            <itens-table [itens]="vm?.stages?.separados?.itens"
                         stage="separados"
                         (separado$)="onSeparadoSelClick($event)" />
          </div>
          <ng-template #notTableResolvidos>
            <ul class="produtos">
              <li *ngFor="let i of vm?.stages?.separados?.itens; trackBy:tid_ite"
                  class="produto d-flex flex-column">
                <item-card [item]="i"
                           stage="separados"
                           (separado$)="onSeparadoSelClick($event)" />
              </li>
            </ul>
          </ng-template>

          <div class="alert alert-light text-center"
               role="alert"
               *ngIf="!vm?.stages?.separados?.itens?.length">
            Nenhum item.
          </div>
        </ng-template>
      </as-split-area>
    </as-split>
  </div>
</ng-container>