export const lpad = (
  text: string, 
  size: number = 2,
  char: string = '0' 
  ) => {
  let txt: string = text || '';
  while (txt.length < size) {
    txt = char + txt;
  } // while
  return txt;
};
