export function fixBarcode(
  barcode: string,
  cod: any = ''
): string {
  const BARCODE: string = String(Number(barcode));
  return (
    BARCODE.length < 8
    || BARCODE === String(cod)
  )
    ? ''
    : BARCODE;
}
