//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
//#endregion

//#region firebase
// import { Firestore } from '@angular/fire/firestore';
//#endregion

//#region 3rd
// import { get } from 'lodash';
//#endregion

//#region models
import { ILoja } from '../../../_misc/_models/_interfaces/_cols';
import { CorFormValidation } from '../../../../_core/_ng/_models/_classes';
import { FOCUS_TIMEOUT } from '../../../../_core/_misc/_models/consts';
// import { CorFormValidation } from '../../../_shared/_core/_ng/_models/_classes';
// import { ILoja } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { FOCUS_TIMEOUT } from '../../../_shared/_core/_misc/_models/consts';
//#endregion

//#region custom validators
class CustomValidator {
  static codFaturamentoValid() {
    return (control: FormControl): ValidationErrors | null => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO < 0 ? { codFaturamento: true } : null;
    }
  }

  static valorTSValid() {
    return (control: FormControl): ValidationErrors | null => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO < 0 ? { valorTS: true } : null;
    }
  }

  static pisoTSValid() {
    return (control: FormControl): ValidationErrors | null => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO < 0 ? { pisoTS: true } : null;
    }
  }

  static anistiaTSValid() {
    return (control: FormControl): ValidationErrors | null => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO < 0 ? { anistiaTS: true } : null;
    }
  }

  static mensalidadeValid() {
    return (control: FormControl): ValidationErrors | null => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO < 0 ? { mensalidade: true } : null;
    }
  }
}
//#endregion

//#region services
import { LojasService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-loja-extras-form',
  templateUrl: './loja-extras-form.component.html',
  styleUrls: ['./loja-extras-form.component.scss']
})
export class MeMatLojaExtrasFormComponent {
  //#region inputs
  #loja: ILoja;
  get loja(): ILoja { return this.#loja; };
  @Input() set loja(val: ILoja) {
    this.#loja = !!val ? this.#lojasServ.fix(val) : null;
  }
  //#endregion

  //#region publics
  // caption = signal<string>('');
  extrasForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  //#endregion

  //#region methods
  codFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  #lojasServ = inject(LojasService);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation());
  }
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    // console.log(this.loja);
    // this.loja = this.#lojasServ.fix(this.loja);

    this.extrasForm.set(
      this.#fb.group({
        // dono: this.#fb.group({
        //   email: [this.loja?.email || '', [Validators.required, Validators.email]],
        // }),
        faturamento: this.#fb.group({
          cod: [this.loja?.faturamento?.cod || '', [CustomValidator.codFaturamentoValid()]],
          mensalidade: [this.loja?.faturamento?.mensalidade || '', [CustomValidator.mensalidadeValid()]],
          servicos: this.#fb.group({
            anistiaMeses: [this.loja?.faturamento?.servicos?.anistiaMeses || '', [CustomValidator.anistiaTSValid()]],
            piso: [this.loja?.faturamento?.servicos?.piso || '', [CustomValidator.pisoTSValid()]],
            valor: [this.loja?.faturamento?.servicos?.valor || '', [CustomValidator.valorTSValid()]],
          }),
        }),
      })
    );
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region Controls Getters
  get emailRef(): AbstractControl { return this.extrasForm().get('dono.email'); }
  get codFaturamentoRef(): AbstractControl { return this.extrasForm().get('faturamento.cod'); }
  get mensalidadeRef(): AbstractControl { return this.extrasForm().get('faturamento.mensalidade'); }
  get valorTsRef(): AbstractControl { return this.extrasForm().get('faturamento.servicos.valor'); }
  get pisoTsRef(): AbstractControl { return this.extrasForm().get('faturamento.servicos.piso'); }
  get anistiaTsRef(): AbstractControl { return this.extrasForm().get('faturamento.servicos.anistiaMeses'); }
  //#endregion

  //#region functions
  focus() {
    // console.log(this.isMobile);
    setTimeout(
      () => { this.codFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion
}
