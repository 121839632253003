<!-- <p>itemStatus {{ itemStatus }}</p> -->

<span class="badge"
      [class.pendente]="itemStatus === 'pendente'"
      [class.confirmado]="itemStatus === 'confirmado'"
      [class.modificado]="itemStatus === 'modificado'"
      [class.removido]="itemStatus === 'removido'"
      [class.naoEncontrado]="itemStatus === 'naoEncontrado'"
      [class.adicionado]="itemStatus === 'adicionado'"
      *ngIf="!!itemStatus">
  {{ statusItemPedido[itemStatus]?.label }}
</span>
