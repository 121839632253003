//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import { get } from 'lodash';
//#endregion

//#region models
import { IPedido } from '../../../_misc/_models/_interfaces/_cols';
import { STATUS_PEDIDO } from '../../../_misc/_models/consts';
import { IPedidoStatusDetailMap } from '../../../_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
// import { fixPedido } from '../../../_misc/_libs';
//#endregion

//#region services
import { PedidosService } from '../../../_ng/_services';
import { TNullable } from '../../../../_core/_misc/_models/_types';
//#endregion

@Component({
  selector: 'me-mat-pedido-card',
  templateUrl: './pedido-card.component.html',
  styleUrls: ['./pedido-card.component.scss']
})
export class MeMatPedidoCardComponent {
  //#region inputs
  //isMobile
  @Input() isMonitor: boolean = false;
  //isMobile
  @Input() isMobile: boolean = false;
  //pedido
  #pedido: TNullable<IPedido>;
  get pedido(): TNullable<IPedido> | null { return this.#pedido; }
  @Input({ required: true }) set pedido(val: TNullable<IPedido> | null) {
    // this.#pedido = !!val ? this.#pedidosServ.fix(fixPedido(val)) : null;
    this.#pedido = !!val ? this.#pedidosServ.fix(val) : null;
    // console.log(this.pedido?.carrinho?.__solicitado?.total);
    // console.log(this.pedido?.carrinho?._separado?.total);
    // console.log(val?._status, STATUS_PEDIDO);
    this.statusPedido.set(get(STATUS_PEDIDO, val?._status));
  }
  //#endregion

  //#region outputs
  @Output() concluirPedido$ = new EventEmitter<IPedido>();
  @Output() concluirSeparacao$ = new EventEmitter<IPedido>();
  @Output() detalhes$ = new EventEmitter<IPedido>();
  @Output() iniciarSeparacao$ = new EventEmitter<IPedido>();
  @Output() repetirPedido$ = new EventEmitter<IPedido>();
  //#endregion

  //#region publics
  statusPedido = signal<IPedidoStatusDetailMap>(null);
  //#endregion

  //#region injects
  #pedidosServ = inject(PedidosService);
  //#endregion
}
