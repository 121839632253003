//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

//#region 3rd
import { CorPipesModule } from '../../_ng/_pipes/cor-pipes.module';
//#endregion

//#region componetns
import {
  CorHtmlEnderecoComponent,
  CorHtmlStatusCheckboxComponent,
  CorHtmlMapaBrasilComponent,
} from '.';
//#endregion

@NgModule({
  imports: [
    CommonModule,
    CorPipesModule,
  ],
  declarations: [
    CorHtmlEnderecoComponent,
    CorHtmlStatusCheckboxComponent,
    CorHtmlMapaBrasilComponent,
  ],
  exports: [
    CorHtmlEnderecoComponent,
    CorHtmlStatusCheckboxComponent,
    CorHtmlMapaBrasilComponent,
  ],
})
export class CorHtmlComponentsModule { }
