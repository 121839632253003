//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
import { IBanner } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(
    row: Partial<IBanner>,
    idLoja: string = ''
  ): IBanner {
    const ID: string = row?.id || '';

    return {
      // id
      id: ID,

      // user
      ativo: {
        status: !row?.ativo?.status,
        _status: !row?.ativo?._status,
      },
      nome: row?.nome || '',
      pos: Number(row?.pos) || 0,
      target: row?.target || '', // [0] = _ marca else produto /// def ''

      // system
      _criadoEm: row?._criadoEm,
      _mobile: !!row?._mobile,

      // realtime
      __url: !!idLoja
        ? (`${environment.firebase.mercadeiro.storage.root}/banners%2F${idLoja}%2F${ID}.png?alt=media`)
        : '',
    };
  }

  fixes(
    docs: Partial<IBanner>[],
    idLoja: string = ''
  ): IBanner[] {
    return (docs || []).map((doc: Partial<IBanner>) => this.fix(doc, idLoja));
  }
  //#endregion

  //#region R
  docs(lojaPath: string): Observable<IBanner[]> {
    if (!!lojaPath) {
      const PATH: string = `${lojaPath}/banners/${ODOCS_TAG}`;
      // return (docData(doc(this.#db, PATH)) as Observable<IBanner[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}) || [])),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IBanner[], ObservableInput<IBanner[]>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando banners.')
          )
        );
      /* <IBanner[], ObservableInput<IBanner[]>> */
    } // if
    return of(null); // throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}
