//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

//#region 3rd
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
//#endregion

//#region components
import {
  MeHtmlDepartamentoCardComponent,
  MeHtmlDepartamentosSkelComponent,
  MeHtmlItemCarrinhoPrecoComponent,
  MeHtmlPedidoItensComponent,
  MeHtmlPedidosListSkelComponent,
  MeHtmlPedidoTimelineComponent,
  MeHtmlProdutoBotaoComprarSkelComponent,
  MeHtmlProdutoPrecoComponent,
  MeHtmlProdutosSkelComponent
} from '.';
//#endregion

@NgModule({
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    MeHtmlDepartamentoCardComponent,
    MeHtmlDepartamentosSkelComponent,
    MeHtmlItemCarrinhoPrecoComponent,
    MeHtmlPedidoItensComponent,
    MeHtmlPedidosListSkelComponent,
    MeHtmlPedidoTimelineComponent,
    MeHtmlProdutoBotaoComprarSkelComponent,
    MeHtmlProdutoPrecoComponent,
    MeHtmlProdutosSkelComponent,
  ],
  exports: [
    MeHtmlDepartamentoCardComponent,
    MeHtmlDepartamentosSkelComponent,
    MeHtmlItemCarrinhoPrecoComponent,
    MeHtmlPedidoItensComponent,
    MeHtmlPedidosListSkelComponent,
    MeHtmlPedidoTimelineComponent,
    MeHtmlProdutoBotaoComprarSkelComponent,
    MeHtmlProdutoPrecoComponent,
    MeHtmlProdutosSkelComponent
  ],
})
export class MeHtmlComponentsModule { }
