//#region ng
import {
  Component,
  Input,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region mat
import { MatDialog } from '@angular/material/dialog';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  filter,
  first,
  map,
} from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  stages: {
    pendentes: ICarrinhoInfoMap;
    separando: ICarrinhoInfoMap;
    separados: ICarrinhoInfoMap;
  },
  pedido: IPedido;
  qtdeVolsSel: number;
  searchTerm: string;
  tabItensSel: TItemCarrinhoStatus;
};
import {
  ILoja,
  IPedido
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  ICarrinhoInfoMap,
  ICarrinhoMap,
  IItemCarrinhoMap
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { ItemSepararModal } from '../../../modals';
import { ODOCS_TAG } from '../../../_shared/_core/_misc/_models/consts';
import { TCorViewStyle } from '../../../_shared/_core/_misc/_models/_types';
import { TItemCarrinhoStatus } from '../../../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

//#region libs
import { mapCarrinhoInfo } from '../../../_shared/_mercadeiro/_misc/_libs';
import { desacentua } from '../../../_shared/_libs/_misc/_strings';
import {
  calcItemCarrinhoStatus,
  mapItemCarrinhoInfo
} from '../../../_shared/_mercadeiro/_misc/_libs/itens-carrinho';
//#endregion

//#region services
import {
  CarrinhoService,
  ItensCarrinhoService,
  PedidosService
} from '../../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../../_shared/_mercadeiro/_ng/_stores';
import { IPedidoItemSepararParams } from 'src/app/models/interfaces/params';
import { environment } from 'src/environments/environment';
//#endregion

@Component({
  selector: 'itens-separacao',
  templateUrl: './itens-separacao.component.html',
  styleUrls: ['./itens-separacao.component.scss']
})
export class ItensSeparacaoComponent {
  //#region actions
  // private _destroyAction$: Subject<void> = onDestroy();
  // private _itensAction$: Subject<IItemCarrinhoMap[]> = new Subject<IItemCarrinhoMap[]>();
  #itensPedidoAction$: BehaviorSubject<IItemCarrinhoMap[]> = new BehaviorSubject<IItemCarrinhoMap[]>(null);
  itensSeparandoAction$ = signal<BehaviorSubject<IItemCarrinhoMap[]>>(new BehaviorSubject<IItemCarrinhoMap[]>(null)).asReadonly();
  #pedidoAction$: BehaviorSubject<Partial<IPedido>> = new BehaviorSubject<Partial<IPedido>>(null);
  #searchTermAction$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  tabItensSelAction$ = signal<BehaviorSubject<TItemCarrinhoStatus>>(new BehaviorSubject<TItemCarrinhoStatus>('')).asReadonly();
  //#endregion

  //#region inputs
  // splitVertical
  @Input() splitVertical: boolean = false;
  // viewStyle
  @Input() viewStyle: TCorViewStyle;
  // searchTerm
  private _searchTerm: string;
  get searchTerm(): string { return this._searchTerm; }
  @Input() set searchTerm(val: string) {
    this._searchTerm = val;
    this.#searchTermAction$.next(this.searchTerm);
  }
  // pedido
  private _pedido: IPedido;
  get pedido(): IPedido { return this._pedido; }
  @Input() set pedido(val: IPedido) {
    this._pedido = !!val ? this.#pedidosServ.fix(val) : null;
    // console.log(this._pedido);
    const LOJA: ILoja = this.#lojasStore?.getState() || null;
    const PEDIDO_ID: string = val?.id || '';
    this.#lojaPath = LOJA?.__idInfo?.lojaPath || '';
    // console.log(this.#lojaPath);
    !!this.#pedidoUnsub && this.#pedidoUnsub();
    !!this.#itensPedidoUnsub && this.#itensPedidoUnsub();
    if (!!PEDIDO_ID && !!this.#lojaPath) {
      const PATH: string = `${this.#lojaPath}/pedidos/${PEDIDO_ID}`;
      // console.log(PATH);
      this.#pedidoUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          // console.log(snap.data());
          this.#pedidoAction$.next(this.#pedidosServ.fix({ ...snap.data(), id: snap.id }));
        }
      );

      // /_ufs/mg/localidades/uberaba--mg/lojas/699fc369--modelo--mg--uberaba/pedidos/99127959c1012/separacao/{{ODOCS}}
      const PATH_SEPARACAO: string = `${this.#lojaPath}/pedidos/${PEDIDO_ID}/separacao/${ODOCS_TAG}`;
      // console.log(PATH);
      this.#itensPedidoUnsub = onSnapshot(
        doc(this.#db, PATH_SEPARACAO),
        snap => {
          // console.log(snap.get('_odocs'));
          // (Object.values(snap.get('_odocs') || {}))?.forEach((i: IItemCarrinhoMap) => console.log(i?._separado?.qtde));
          this.#itensPedidoAction$.next(Object.values(snap.get('_odocs') || {}));
        }
      );
    } // if
  }
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #itensPedidoUnsub: FbUnsubscribe;
  #lojaPath: string;
  #pedidoUnsub: FbUnsubscribe;
  #vm: IVm;
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #carrinhoServ = inject(CarrinhoService);
  #itensServ = inject(ItensCarrinhoService);
  #loaderServ = inject(CorLoaderService);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #pedidosServ = inject(PedidosService);
  // #separacaoServ = inject(PedidosSeparacaoService);
  //#endregion

  /* //#region constructor
  constructor(
    private #db: Firestore,
    private #carrinhoServ: CarrinhoService,
    // private #itensServ: ItensCarrinhoService,
    private #itensServ: ItensCarrinhoService,
    private #loaderServ: CorLoaderService,
    private #lojasStore: LojasStore,
    private #modal: MatDialog,
    private #pedidosServ: PedidosService,
    private #separacaoServ: PedidosSeparacaoService,
  ) { }
  //#endregion */

  //#region lifecycles
  ngOnInit() {

    /* combineLatest([
      this.#pedidoAction$,
      this.#lojasStore?.lojaStateChanged$,
    ])
      .pipe(
        tap(([pedido, loja]) => {
          console.log(pedido, loja);

          const PEDIDO_ID: string = pedido?.id || '';
          this.#lojaPath = loja?.__idInfo?.lojaPath || '';
          // console.log(this.#lojaPath);
          !!this.#itensPedidoUnsub && this.#itensPedidoUnsub();
          if (!!PEDIDO_ID && !!this.#lojaPath) {
            const PATH: string = `${this.#lojaPath}/pedidos/${PEDIDO_ID}/separacao/${ODOCS_TAG}`;
            // console.log(PATH);
            const SEPARACAO_REF = doc(this.#db, PATH);
            this.#itensPedidoUnsub = onSnapshot(
              SEPARACAO_REF,
              snap => {
                // console.log(snap.data());
                this._itensAction$.next(
                  !!snap?.data()?.['_odocs']
                    ? this.#itensServ.fixes(
                      (Object.values(snap?.data()?.['_odocs']) || [])
                        .filter((i: any) => !!i)
                        .sort(compareValues('_pos', 'asc'))
                    )
                    : []
                );
                // this._itens = !!snap?.data()?.['_odocs']
                //   ? this.#itensServ.fixes(
                //     (Object.values(snap?.data()?.['_odocs']) || [])
                //       .filter((i: any) => !!i)
                //       .sort(compareValues('_pos', 'asc'))
                //   )
                //   : [];


              }
            );

            // this.#loaderServ.lstart();
            // const PATH: string = `${this.#lojaPath}/pedidos/${PEDIDO_ID}/separacao`;
            // // console.log(PATH);
            // const Q: Query = query(
            //   collection(
            //     this.#db,
            //     PATH
            //   )
            // );
            // this.#itensPedidoUnsub = onSnapshot(
            //   Q,
            //   snaps => {
            //     this._itens = [];
            //     snaps.forEach(
            //       snap => {
            //         // console.log(snap.data());
            //         this._itens
            //           .push(this.#itensServ.fix({
            //             ...snap.data(),
            //             id: snap.id,
            //           }));
            //         this._refilter();
            //       }
            //     );
            //   });
          } // if
        }),
        takeUntil(this._destroyAction$)
      )
      .subscribe(); */

    this.vm$.set(
      combineLatest([
        this.#searchTermAction$,
        this.#pedidoAction$,
        this.#itensPedidoAction$,
        this.itensSeparandoAction$(),
        this.tabItensSelAction$(),
      ])
        .pipe(
          map(([searchTerm, pedido, itensPedido, itensSeparando, tabItensSel]) => {
            // console.log(pedido);
            const SEARCH_TERM: string = desacentua((searchTerm || '').trim().toLowerCase());
            // console.log(pedido);
            // console.log(this.#itensServ.fixes(itensPedido));
            // (itensPedido || [])?.forEach(i => console.log(i?._separado?.qtde));
            // console.log(itensSeparando);

            // console.log(pedido?.carrinho?._separado?.itens);
            // console.log(pedido?.carrinho?.__solicitado?.itens);

            // if (!!PEDIDO_ID && !!this.#lojaPath) {
            //   const PATH: string = `${this.#lojaPath}/pedidos/${PEDIDO_ID}/separacao/${ODOCS_TAG}`;
            //   // console.log(PATH);
            //   const SEPARACAO_REF = doc(this.#db, PATH);
            //   this.#itensPedidoUnsub = onSnapshot(
            //     SEPARACAO_REF,
            //     snap => {
            //       // console.log(snap.data());
            //       this._itensAction$.next(
            //         !!snap?.data()?.['_odocs']
            //           ? this.#itensServ.fixes(
            //             (Object.values(snap?.data()?.['_odocs']) || [])
            //               .filter((i: any) => !!i)
            //               .sort(compareValues('_pos', 'asc'))
            //           )
            //           : []
            //       );
            //       // this._itens = !!snap?.data()?.['_odocs']
            //       //   ? this.#itensServ.fixes(
            //       //     (Object.values(snap?.data()?.['_odocs']) || [])
            //       //       .filter((i: any) => !!i)
            //       //       .sort(compareValues('_pos', 'asc'))
            //       //   )
            //       //   : [];
            //     }
            //   );

            // const SEPARADO_ITENS: IItemCarrinhoMap[] = pedido?.carrinho?._separado?.itens || [];
            // const ITENS: IItemCarrinhoMap[] = this.#itensServ.fixes(
            //   !!SEPARADO_ITENS?.length
            //     ? SEPARADO_ITENS
            //     : pedido?.carrinho?.__solicitado?.itens || []
            // )
            //   .filter(
            //     (i: IItemCarrinhoMap) => {
            //       // console.log(i);
            //       const ITEM_NOME: string = (i?.produto?.nome || '').trim().toLowerCase()
            //       // console.log(ITEM_NOME, FILTER);
            //       return desacentua(ITEM_NOME).includes(searchTerm);
            //     }
            //   );

            const ITENS_SEPARANDO: IItemCarrinhoMap[] = this.#itensServ.fixes(itensSeparando);
            // console.log(ITENS_SEPARANDO);
            const ITENS_SEPARADOS: IItemCarrinhoMap[] = this.#itensServ.fixes(itensPedido)
              // (itensPedido || [])
              .filter((i: IItemCarrinhoMap) => !!tabItensSel ? i?._status === tabItensSel : true)
              .filter((i: IItemCarrinhoMap) => i?._status !== 'pendente');
            // (ITENS_SEPARADOS || [])?.forEach(i => console.log(i?._separado?.qtde));
            const ITENS_PENDENTES: IItemCarrinhoMap[] = this.#itensServ.fixes(
              // (pedido?.carrinho?.__solicitado?.itens)
              (itensPedido || [])
                .filter(
                  (ip: IItemCarrinhoMap) => !pedido?.carrinho?._separado?.itens
                    ?.map((is: IItemCarrinhoMap) => is?.id)
                    ?.includes(ip?.id)
                )
                .map(
                  (i: IItemCarrinhoMap) => ({
                    ...i, __sel: (itensSeparando || [])
                      ?.map((is: IItemCarrinhoMap) => is?.id)
                      ?.includes(i?.id)
                  })
                )
            );
            // console.log(ITENS_PENDENTES);

            this.#vm = {
              searchTerm: SEARCH_TERM,
              pedido: !!pedido ? this.#pedidosServ.fix(pedido) : null,
              // hasSeparado: !!SEPARADO_ITENS?.length,
              stages: {
                pendentes: mapCarrinhoInfo(this.#carrinhoServ.fix(pedido?.carrinho), false, ITENS_PENDENTES),
                separando: mapCarrinhoInfo(this.#carrinhoServ.fix(pedido?.carrinho), false, ITENS_SEPARANDO),
                separados: mapCarrinhoInfo(this.#carrinhoServ.fix(pedido?.carrinho), true, ITENS_SEPARADOS),
              },
              /* itens: {
                pendentes: this.#itensServ.fixes(
                  (pedido?.carrinho?.__solicitado?.itens || [])
                    .filter((ip: IItemCarrinhoMap) => !pedido?.carrinho?._separado?.itens.map((is: IItemCarrinhoMap) => is?.id).includes(ip?.id))
                    .map((i: IItemCarrinhoMap) => ({ ...i, __sel: (itensSeparando || []).map((is: IItemCarrinhoMap) => is?.id).includes(i?.id) }))
                ),
                separando: this.#itensServ.fixes(itensSeparando),
                separados: this.#itensServ.fixes(pedido?.carrinho?._separado?.itens),
              }, */
              qtdeVolsSel: (itensSeparando || []).reduce((acc, i) => acc + (Number(i.__solicitado?.qtde?.fixed) || 0), 0),
              tabItensSel
            };
            // console.log(this.#vm);
            // console.log((this.#vm?.stages?.separados?.itens || []).length);
            // console.log((this.#vm?.stages?.separados?.itens || [])?.forEach((i: IItemCarrinhoMap) => console.log(i?._separado?.qtde)));
            return this.#vm;
          })
        )
    );
  }

  ngOnDestroy(): void {
    !!this.#pedidoUnsub && this.#pedidoUnsub();
    this.#itensPedidoUnsub && this.#itensPedidoUnsub();
  }
  //#endregion

  //#region functions
  tid_ite(index: any, item: IItemCarrinhoMap): string { return item?.id || ''; }

  private _confirmaItem(
    item: IItemCarrinhoMap,
    qtdeEncontrada: number,
    qtdeSeparada: number,
  ) {
    const QTDE_SOLICITADA: number = Number(item?.__solicitado?.qtde?.fixed) || 0;
    const PEDIDO_ID: string = this.pedido?.id || '';
    const ITEM_ID: string = String(item?.id);
    // console.log({ QTDE_SOLICITADA, qtdeEncontrada, qtdeSeparada });
    if (
      !!item
      && !!PEDIDO_ID
      && !!ITEM_ID
    ) {
      const CHANGES: Partial<IItemCarrinhoMap> = {
        _status: calcItemCarrinhoStatus(QTDE_SOLICITADA, qtdeEncontrada, qtdeSeparada),
        _separado: mapItemCarrinhoInfo(
          item,
          qtdeSeparada,
          true,
          qtdeSeparada
        ),
      };
      // console.log(JSON.stringify(CHANGES));

      const SUB: Subscription = this.#loaderServ?.showUntilCompleted(
        this.#itensServ.update(
          CHANGES,
          this.#lojaPath,
          PEDIDO_ID,
          ITEM_ID
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          () => { this.onLimparSelecionandoClick() }
        );
    } // if
  }
  //#endregion

  //#region methods
  onSolicitadoSelClick(i: IItemCarrinhoMap) {
    // console.log('solicitado', i);
    if (
      !!i
      && !i?._separado?.qtde?.encontrado
    ) {
      this.itensSeparandoAction$().next((
        this.#vm?.stages?.separando?.itens || [])
        .filter((is: IItemCarrinhoMap) => is?.id !== i?.id)
        .concat([
          {
            ...i,
            _separado: i?.__solicitado
          }
        ])
      );
    } // if
  }

  onEncontradoSelClick(item: IItemCarrinhoMap) {
    const DIALOG_EXISTS = this.#modal.getDialogById('item-encontrado');
    // console.log(DIALOG_EXISTS, item);
    if (!!item && !DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        ItemSepararModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'item-encontrado',
          data: {
            item,
            isEncontrar: true
            // pedido: this.pedido
          } as IPedidoItemSepararParams
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(filter(resp => resp !== null))
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .subscribe(async (resp: any) => {
          // console.log({ resp });
          const PEDIDO_ID: string = this.pedido?.id || '';
          const ITEM_ID: string = String(item?.id);
          // console.log(PEDIDO_ID, ITEM_ID);
          if (
            !!item
            && !!PEDIDO_ID
            && ITEM_ID
          ) {
            if (resp > 0) {
              const CHANGES: Partial<IItemCarrinhoMap> = {
                _separado: mapItemCarrinhoInfo(
                  item,
                  Number(resp) || 0,
                  true,
                  null,
                ),
              };
              // console.log(JSON.stringify(CHANGES));
              const SUB: Subscription = this.#loaderServ?.showUntilCompleted(
                this.#itensServ.update(
                  CHANGES,
                  this.#lojaPath,
                  PEDIDO_ID,
                  ITEM_ID
                )
                  .pipe(first(), finalize(() => SUB?.unsubscribe()))
              )
                .subscribe();
            } else {
              this._confirmaItem(
                item,
                Number(resp) || 0,
                Number(resp) || 0
              );
            } // else
          } // if
        });
    } // if
  }

  onSeparadoSelClick(i: IItemCarrinhoMap) {
    // console.log('separado', i);    
    const DIALOG_EXISTS = this.#modal.getDialogById('item-separar');
    if (
      !!i
      && !DIALOG_EXISTS
    ) {
      const DIALOG_REF: any = this.#modal.open(
        ItemSepararModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'item-separar',
          data: {
            item: i,
            isEncontrar: false
            // pedido: this.pedido
          } as IPedidoItemSepararParams
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .pipe(filter(resp => resp !== null))
        .subscribe(async (resp: any) => {
          // console.log({ resp });
          this._confirmaItem(
            i,
            i?._separado?.qtde?.encontrado ?? null,
            resp
          );
        });
    } // if 

  }

  /* onSolicitadoDelClick(i: IItemCarrinhoMap) {
    // console.log('solicitado', i);
    if (!!i) {
      this.itensSeparandoAction$().next((
        this.#vm?.itens?.separando || [])
        .filter((is: IItemCarrinhoMap) => is?.id !== i?.id)
      );
    } // if
  } */

  onLimparSelecionandoClick() {
    this.itensSeparandoAction$().next([]);
  }

  async onConfirmarSelClick() {
    // for (const i of (this.#vm?.stages?.separando?.itens || [])) {
    //   this._confirmaItem(i, i?._separado?.qtde?.fixed, i?.onSeparadoSelClick?.qtde?.fixed);
    // } // for
    const PEDIDO_ID: string = this.pedido?.id || '';
    const ITENS_IDS: string[] = (this.#vm?.stages?.separando?.itens || [])
      .map((i: IItemCarrinhoMap) => i?.id || '')
      .filter((id: string) => !!id?.trim());
    // console.log(this.#lojaPath, PEDIDO_ID, ITENS_IDS);
    if (
      !!this.#lojaPath
      && !!PEDIDO_ID
      && !!ITENS_IDS?.length
    ) {
      try {
        this.#loaderServ.lstart();
        const functions = getFunctions(getApp(), environment?.firebase?.region);
        const fn = httpsCallable(functions, 'onConfirmaItensPendentes');
        const RET: any = await fn({
          data: {
            lojaPath: this.#lojaPath,
            pedidoId: PEDIDO_ID,
            itensIds: ITENS_IDS
          }
        });
        // console.log(RET);
        this.onLimparSelecionandoClick();
      } finally {
        this.#loaderServ.lstop();
      } // try-finally
    } // if
  }

  onSelRemoveClick(i: IItemCarrinhoMap) {
    // console.log(i);
    if (!!i) {
      this.itensSeparandoAction$().next((
        this.#vm?.stages?.separando?.itens || [])
        .filter((is: IItemCarrinhoMap) => is?.id !== i?.id)
      );
    } // if
  }

  /*  onRemovePendenteClick(i: IItemCarrinhoMap) {
     // console.log(i);
     this._confirmaItem(i, 0, Number(i.__solicitado?.qtde?.fixed) || 0);
   } */
  //#endregion
}

/*
//#region inputs
// splitVertical
@Input() splitVertical: boolean = true;
// filter
private _filter: string;
get filter(): string { return this._filter; }
@Input() set filter(val: string) {
  this._filter = val;
  this._refilter();
}
// pedido
private _pedido: IPedido;
get pedido(): IPedido { return this._pedido; }
@Input({ required: true }) set pedido(val: IPedido) {
  this._pedido = !!val ? this.#pedidosServ.fix(val) : null;
  const PEDIDO_ID: string = val?.id || '';
  this.#lojaPath = calcLojaIdInfo(val?.carrinho?._loja?.id || '')?.lojaPath || '';
  // console.log(this.#lojaPath);
  this.#itensPedidoUnsub && this.#itensPedidoUnsub();
  if (!!PEDIDO_ID && !!this.#lojaPath) {
    const PATH: string = `${this.#lojaPath}/pedidos/${PEDIDO_ID}/separacao/${ODOCS_TAG}`;
    // console.log(PATH);
    const SEPARACAO_REF = doc(
      this.#db,
      PATH
    );
    this.#itensPedidoUnsub = onSnapshot(
      SEPARACAO_REF,
      snap => {
        // console.log(snap.data());
        this._itens = !!snap?.data()?.['_odocs']
          ? this.#itensServ.fixes(
            (Object.values(snap?.data()?.['_odocs']) || [])
              .filter((i: any) => !!i)
              .sort(compareValues('_pos', 'asc'))
          )
          : [];
        // this._itens = this.#itensServ.fixes(snap?.data()?.['_docs'] || []);
        // .map((e: IViacepApi) => {
        //   const ENDERECO_ID: string = e?.id || '';
        //   const BAIRRO: string = e?.bairro || '';
        //   return {
        //     ...e,
        //     __favorito: ENDERECO_ID === CONTA_FAVORITO_ID,
        //     __atendido: !!this.conta ? CONTA_BAIRRO === BAIRRO : true
        //   };
        // });
        // this._montaEnderecos();
        // this.#loaderServ.lstop();
        // this.tab = 'enderecos';
        this._refilter();
      }
    );

    // this.#loaderServ.lstart();
    // const PATH: string = `${this.#lojaPath}/pedidos/${PEDIDO_ID}/separacao`;
    // // console.log(PATH);
    // const Q: Query = query(
    //   collection(
    //     this.#db,
    //     PATH
    //   )
    // );
    // this.#itensPedidoUnsub = onSnapshot(
    //   Q,
    //   snaps => {
    //     this._itens = [];
    //     snaps.forEach(
    //       snap => {
    //         // console.log(snap.data());
    //         this._itens
    //           .push(this.#itensServ.fix({
    //             ...snap.data(),
    //             id: snap.id,
    //           }));
    //         this._refilter();
    //       }
    //     );
    //   });
  } // if
}
// statusItens
// @Input() statusItens: TItemCarrinhoStatus;
// viewStyle
@Input() viewStyle: TCorViewStyle;
// loja
@Input({ required: true }) loja: ILoja;
//#endregion

//#region tabItens
private _tabItens: TItemCarrinhoStatus;
get tabItens(): TItemCarrinhoStatus { return this._tabItens; }
set tabItens(val: TItemCarrinhoStatus) {
  this._tabItens = val;
  // console.log(val);
  this._refilter();
}
//#endregion

//#region publics
counters: {
  pendente: number;
  confirmado: number;
  modificado: number;
  removido: number;
  adicionado: number;
} = {
    pendente: 0,
    confirmado: 0,
    modificado: 0,
    removido: 0,
    adicionado: 0,
  };
itens: {
  pendentes: IItemCarrinhoMap[];
  resolvidos: IItemCarrinhoMap[];
};
selecao: {
  vols: {
    pendentes: number;
    selecionados: number;
  }
} = {
    vols: {
      pendentes: 0,
      selecionados: 0,
    }
  };
resolvidos: {
  qtde: {
    itens: number;
    vols: number;
  };
  valor: number;
} = {
    qtde: {
      itens: 0,
      vols: 0,
    },
    valor: 0,
  };
carrinhoItens: ICarrinhoMap;
//#endregion

//#region itensPendentesSel
private _itensPendentesSel: IItemCarrinhoMap[];
get itensPendentesSel(): IItemCarrinhoMap[] { return this._itensPendentesSel; }
set itensPendentesSel(val: IItemCarrinhoMap[]) {
  this._itensPendentesSel = val;
  if (!!val) {
    this.carrinhoItens = this.#carrinhoServ.fix(
      {
        __solicitado: {
          itens: val
        }
      }
    );
  } // if
}
//#endregion

//#region privates
private _itens: IItemCarrinhoMap[];
private #itensPedidoUnsub: FbUnsubscribe;
private #lojaPath: string;
//#endregion

//#region constructor
constructor(
  private #db: Firestore,
  private #carrinhoServ: CarrinhoService,
  private #itensServ: ItensCarrinhoService,
  private #itensServ: ItensCarrinhoService,
  private #modal: MatDialog,
  private #pedidosServ: PedidosService,
  private #separacaoServ: PedidosSeparacaoService
) { }
//#endregion

//#region functions

private _refilter() {
  this.counters = {
    pendente: 0,
    confirmado: 0,
    modificado: 0,
    removido: 0,
    adicionado: 0,
  };
  const FILTER: string = desacentua((this.filter || '').trim().toLowerCase())
  // console.log(this.filter);
  const ITENS: IItemCarrinhoMap[] = (
    !FILTER
      ? (this._itens || [])
      : (this._itens || [])
        .filter(
          (i: IItemCarrinhoMap) => desacentua((i?.produto?.nome || '').trim().toLowerCase()).includes(FILTER)
        )
  )
    .sort(compareValues('_pos'));

  ITENS
    .forEach((i: IItemCarrinhoMap) => {
      const ITEM_STATUS: TItemCarrinhoStatus = i?._status;
      // console.log(ITEM_STATUS);
      this.counters[ITEM_STATUS] += 1;
    });

  this.itens = {
    pendentes: ITENS
      .filter((i: IItemCarrinhoMap) => (i?._status === 'pendente')),
    resolvidos: ITENS
      .filter(
        (i: IItemCarrinhoMap) => {
          const ITEM_STATUS: any = i?._status;
          return !this.tabItens
            ? ITEM_STATUS !== 'pendente'
            : ITEM_STATUS === this.tabItens;
        }
      ),
  };

  this.resolvidos = {
    qtde: {
      itens: 0,
      vols: 0,
    },
    valor: 0,
  };
  this.itens?.resolvidos?.forEach(
    (i: IItemCarrinhoMap) => {
      // console.log(i);
      this.resolvidos.qtde.itens = this.resolvidos?.qtde?.itens + 1;
      this.resolvidos.qtde.vols = this.resolvidos?.qtde?.vols + i?._separado?.qtde?.fixed;
      this.resolvidos.valor = this.resolvidos?.valor + i?._separado?.valor?.total?.liq;
    }
  );
}

private _reselect() {
  this.selecao.vols.pendentes = 0;
  this.itensPendentesSel = (this.itens?.pendentes || [])
    .filter((i: IItemCarrinhoMap) => !!i?.__sel);
  this.selecao.vols.pendentes = this.itensPendentesSel.reduce(
    (acc: number, val: IItemCarrinhoMap) => acc += Number(val?.__solicitado?.qtde?.fixed) || 0,
    0
  );
}
//#endregion

//#region lifecycles
ngOnInit() {
  this.tabItens = '';
}
//#endregion

//#region functions
private _confirmaItem(
  item: IItemCarrinhoMap,
  qtde: number
) {
  // console.log(qtde, item);
  const QTDE_SOLICITADO: number = item?.__solicitado?.qtde?.fixed || 0;
  const PEDIDO_ID: string = this.pedido?.id || '';
  const ITEM_ID: string = String(item?.id);
  if (
    !!item
    && qtde >= 0
    && !!PEDIDO_ID
    && ITEM_ID
  ) {
    const SUB: Subscription = this.#itensServ.update(
      {
        _status: !qtde
          ? 'removido'
          : !QTDE_SOLICITADO
            ? 'adicionado'
            : QTDE_SOLICITADO === qtde
              ? 'confirmado'
              : 'modificado',
        _separado: {
          qtde: { val: qtde ?? item?.__solicitado?.qtde?.val }
        }
      },
      this.#lojaPath,
      PEDIDO_ID,
      ITEM_ID
    )
      .pipe(
        first(),
        finalize(() => SUB && SUB.unsubscribe())
      )
      .subscribe(
        () => { this.onLimparSelClick(); }
      );
  } // if
}
//#endregion

//#region methods
onSolicitadoClick(i: IItemCarrinhoMap) {
  // console.log('solicitado', i);
  if (!!i) {
    i.__sel = !i?.__sel;
    this._reselect();
  } // if
}

onSolicitadoDefaultClick(i: IItemCarrinhoMap) {
  // console.log(i);
  this._confirmaItem(i, i?.__solicitado?.qtde?.fixed);
}

onConfirmarSelClick(/* val: number | null = null * /) {
  // console.log(this.#lojaPath);
  if (!!this.#lojaPath) {
    const ITENS: IItemCarrinhoMap[] = (this.itens?.pendentes || [])
      .filter((i: IItemCarrinhoMap) => !!i?.__sel);
    for (const i of ITENS) {
      this._confirmaItem(i, i?.__solicitado?.qtde?.fixed);
    } // for
  } // if
}

onLimparSelClick() {
  (this.itens?.pendentes || [])
    .forEach((i: IItemCarrinhoMap) => i.__sel = false);
  this._reselect();
}

onAddProdutoClick() {
  // console.log('separado', i);
  // console.log(i);
  const DIALOG_EXISTS = this.#modal.getDialogById('produto-add');
  if (!DIALOG_EXISTS) {
    const DIALOG_REF: any = this.#modal.open(
      ProdutoAddModal,
      {
        panelClass: 'no-border-dialog-container',
        id: 'produto-add',
        data: {
          loja: this.loja
        }
      }
    );

    const SUB: Subscription = DIALOG_REF
      .afterClosed()
      .pipe(
        first(),
        finalize(() => SUB && SUB.unsubscribe())
      )
      .subscribe(async (resp: any) => {
        console.log(resp);
        const PROD_ID: string = resp?.id || '';
        const PEDIDO_ID: string = this.pedido?.id || '';
        const LOJA_PATH: string = this.loja?.__idInfo?.lojaPath || '';
        console.log(PROD_ID);
        // this._confirmaItem(i, resp);
        if (!!LOJA_PATH && !!PEDIDO_ID && !!PROD_ID) {
          this.#separacaoServ.item(
            LOJA_PATH,
            PEDIDO_ID,
            PROD_ID
          )
            .pipe(
              first(),
              finalize(() => SUB && SUB.unsubscribe())
            )
            .subscribe(
              (i: IItemCarrinhoMap) => {
                console.log(i);
                if (!!i) {
                  this.onSeparadoClick(i);
                } else {
                  const ITEM: IItemCarrinhoMap | null = this.#itensServ?.produto2Item(
                    resp,
                    0, // ajustaQtdeFracionado(resp, 1)
                  );
                  ITEM.id = ITEM?.produto?.id || '';
                  ITEM._status = 'adicionado';
                  console.log(ITEM);
                  this.#separacaoServ.add(
                    LOJA_PATH,
                    PEDIDO_ID,
                    ITEM
                  )
                    .pipe(
                      first(),
                      finalize(() => SUB && SUB.unsubscribe())
                    )
                    .subscribe(
                      () => {
                        this.onSeparadoClick(ITEM);
                      }
                    );
                } // else
              }
            );
        } // if
      });
  } // if
}
//#endregion
*/

