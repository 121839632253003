//#region ng
import { Injectable } from "@angular/core";
//#endregion

//#region 3rd
import { BehaviorSubject } from 'rxjs';
//#endregion

//#region models
import { ICorSwalLoader } from "../../_misc/_models/_interfaces/_misc";
import { TCorGridSize } from '../../_misc/_models/_types';
import {
  GRID_WIDTH_LG,
  GRID_WIDTH_MD,
  GRID_WIDTH_SM,
  GRID_WIDTH_XL,
  GRID_WIDTH_XXL
} from "../../_misc/_models/consts";
//#endregion

@Injectable({
  providedIn: "root"
})
export class CoreService {
  //#region events
  static gridSizeChanged$ = new BehaviorSubject<TCorGridSize | null>(null);
  static swalLoaderEvent$ = new BehaviorSubject<ICorSwalLoader | null>(null);
  //#endregion

  static updateGridSize(resolution: number) {
    let size: TCorGridSize;
    if (resolution >= GRID_WIDTH_XXL) {
      size = 'xxl';
    } else if (resolution >= GRID_WIDTH_XL) {
      size = 'xl';
    } else if (resolution >= GRID_WIDTH_LG) {
      size = 'lg';
    } else if (resolution >= GRID_WIDTH_MD) {
      size = 'md';
    } else if (resolution >= GRID_WIDTH_SM) {
      size = 'sm';
    } else {
      size = 'xs';
    } // else
    // console.log(size);
    this.gridSizeChanged$.next(size);
  }
}