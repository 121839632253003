//#region ng
import { Injectable } from '@angular/core';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CorWindowService {
  //#region window
  get windowRef() {
    return window
  }
  //#endregion
}
