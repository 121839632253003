//#region ng
import {
  Pipe,
  PipeTransform,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
//#endregion

//#region models
import { IFormaPgto } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { FormasPgtoService } from '../_services';
//#endregion

@Pipe({ name: 'formasPgtosLfixes' })
export class MeFormasPgtosLfixesPipe implements PipeTransform {
  //#region injects
  #formasServ = inject(FormasPgtoService);
  //#endregion

  transform(val: IFormaPgto[]): Observable<IFormaPgto[]> {
    return this.#formasServ.lfixes(val);
  }
}
