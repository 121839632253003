export const checkImg = (
  imgSrc: string,
  onSuccess: any,
  onError: any = null,
): Promise<boolean> => {
  return new Promise(
    (resolve) => {
      const IMG = new Image();
      if (onSuccess && typeof onSuccess === 'function') {
        IMG.onload = onSuccess;
        resolve(true);
      } // if 
      if (onError && typeof onError === 'function') {
        IMG.onerror = onError;
        resolve(false);
      } // if
      IMG.src = imgSrc;
    }
  );
};
