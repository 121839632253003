//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  // docData,
  Firestore,
  getDoc,
  setDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { ODOCS_TAG } from '../../../_core/_misc/_models/consts';
import { IPTag } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class PTagsService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(
    row: Partial<IPTag>,
    idLoja: string = ''
  ): IPTag {
    const ID: string = row?.id || '';

    return {
      // id
      id: ID,

      // user
      caption: row?.caption || '',
      theme: row?.theme,

      // system
      _criadoEm: row?._criadoEm || null,
      _isSistema: !!row?._isSistema,
    };
  }

  fixes(
    docs: Partial<IPTag>[],
    idLoja: string = ''
  ): IPTag[] {
    return (docs || []).map((doc: Partial<IPTag>) => this.fix(doc, idLoja));
  }
  //#endregion

  //#region C
  add(
    lojaPath: string,
    changes: Partial<IPTag>,
    // id
  ): Observable<any> {
    // console.log(endereco);
    if (!!lojaPath) {
      if (!!changes) {
        const PATH: string = `${lojaPath}/p-tags`;
        return from(addDoc(collection(this.#db, PATH), changes)
        )
          .pipe(
            // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
            catchError<Partial<IPTag>, ObservableInput<Partial<IPTag>>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro adicionando tag de produto.')
            )
          );
      } // if
      return throwError(() => 'Nenhuma tag de produto indicada.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region R
  docs(lojaPath: string): Observable<IPTag[]> {
    if (!!lojaPath) {
      const PATH: string = `${lojaPath}/p-tags/${ODOCS_TAG}`;
      // return (docData(doc(this.#db, PATH)) as Observable<IPTag[]>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}) || [])),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IPTag[], ObservableInput<IPTag[]>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando tags de produtos.')
          )
        );
    } // if
    return of(null); // throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region U
  update(
    lojaPath: string,
    idTag: string,
    changes: Partial<IPTag>,
  ): Observable<any> {
    // console.log(idTag);
    // console.log(changes);
    if (!!lojaPath) {
      if (!!idTag) {
        const PATH: string = `${lojaPath}/p-tags/${idTag}`;
        return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
          .pipe(
            // map(() => { throw new Error(`Erro apagando ${PATH}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando tag de produto.')
            )
          );
      } // if
      return throwError(() => 'Nenhuma tag de produto indicada.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region D
  delete(
    lojaPath: string,
    idTag: string
  ): Observable<any> {
    if (!!lojaPath) {
      if (!!idTag) {
        const PATH: string = `${lojaPath}/p-tags/${idTag}`;
        return from(deleteDoc(doc(this.#db, PATH)))
          .pipe(
            // map(() => { throw new Error(`Erro apagando ${PATH}.`); }),
            catchError<void, ObservableInput<void>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro apagando tag de produto.')
            )
          );
      } // if
      return throwError(() => 'Nenhuma tag indicada.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}
