export function isCpf(cpf: string): boolean {
  const CPF: string = `${cpf}`.replace(/[^\d]+/g, '');
  let digitosIguais: boolean = true;

  if (!CPF) return false;

  if (CPF.length !== 11) {
    return false;
  } // if

  for (let i = 0; i < CPF.length - 1; i++) {
    if (CPF.charAt(i) !== CPF.charAt(i + 1)) {
      digitosIguais = false;
      break;
    } // if
  } // for

  if (!digitosIguais) {
    let numeros: string = CPF.substring(0, 9);
    const DIGITOS: string = CPF.substring(9);
    let soma: number = 0;

    for (let i = 10; i > 1; i--) {
      soma += +numeros.charAt(10 - i) * i;
    } // for

    let resultado: number = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== +DIGITOS.charAt(0)) {
      return false;
    } // if

    numeros = CPF.substring(0, 10);
    soma = 0;
    for (let i = 11; i > 1; i--) {
      soma += +numeros.charAt(11 - i) * i;
    } // for

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== +DIGITOS.charAt(1)) {
      return false;
    } // if
    return true;
  } // if

  return false;
}
