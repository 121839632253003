<div class="d-flex flex-column">
  <ul class="temas d-flex p-2">
    <li *ngFor="let t of temas(); trackBy:tid_the"
        (click)="select$.emit(t)"
        class="bg-{{t}} flex-1 pointer"
        [class.active]="t === tema">
      &nbsp;
    </li>
  </ul>
</div>
