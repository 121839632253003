import { isCnpj } from '../_validations';

export function formataCNPJ(cnpj: string): string {
  if (isCnpj(cnpj)) {
    //retira os caracteres indesejados...
    const CNPJ: string = cnpj.replace(/[^\d]/g, '');
    //realizar a formatação...
    return CNPJ.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } // if

  return '';
}
