<ng-container *ngIf="vm$() | async as vm">
  <div class="container-fluid">
    <div class="d-flex">
      <div class="col search">
        <mat-form-field appearance="outline"
                        class="w-100">
          <mat-label>Nome</mat-label>
          <button mat-button
                  matPrefix
                  mat-icon-button>
            <mat-icon class="o-50">search</mat-icon>
          </button>
          <input matInput
                 type="text"
                 [focus]="searchFocusEvent$()"
                 [ngModel]="searchTermAction$() | async"
                 (ngModelChange)="searchTermAction$()?.next($event)">
          <button mat-button
                  *ngIf="!!vm?.searchTerm"
                  matSuffix
                  mat-icon-button
                  (click)="searchTermAction$()?.next('')">
            <mat-icon class="o-50">close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.lojas?.length && !itemsPerPage">
        <cor-mat-pag-sel-offset size="lg"
                                [storageKey]="offsetStorageKey"
                                (change$)="pagItemsPerPageAction$().next($event)" />
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.lojas?.length">
        <cor-mat-ngx-pagination-nav pagId="lojas"
                                    size="lg"
                                    [isMobile]="true"
                                    (pageChange$)="pagPageAction$().next($event)" />
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.lojas?.length">
        <cor-mat-viewstyle-sel size="lg"
                               [storageKey]="viewStyleStorageKey"
                               (change$)="viewStyleAction$().next($event)" />
      </div>
    </div>
  </div>

  <!-- <p>idSelected {{ idSelected }}</p> -->
  <!-- <p>vm {{ vm | json }}</p> -->
  <!-- <p>vm?.pag {{ vm?.pag | json }}</p> -->
  <!-- <p>checkBtnHint {{ checkBtnHint| json }}</p> -->
  <!-- <p>deleteBtnHint {{ deleteBtnHint| json }}</p> -->
  <!-- <p>editBtnHint {{ editBtnHint| json }}</p> -->
  <!-- <p>searchBtnHint {{ searchBtnHint| json }}</p> -->
  <!-- <p>hasButtons {{ hasButtons| json }}</p> -->
  <!-- <p>itemsPerPage {{ itemsPerPage | json }}</p> -->

  <div class="row">
    <div class="col">
      <div class="w-cards lojas px-3"
           *ngIf="vm?.viewStyle === 'cards'; else viewTable">
        <div
             *ngFor="let l of (vm?.lojas | paginate: { id: 'lojas', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: vm?.lojas?.length } | lojasLfixes) | async; trackBy:tid_loj">
          <me-mat-loja-card [loja]="l"
                            [isSelected]="l?.id === idSelected"
                            [checkBtnHint]="checkBtnHint"
                            [deleteBtnHint]="deleteBtnHint"
                            [editBtnHint]="editBtnHint"
                            [searchBtnHint]="searchBtnHint"
                            (search$)="search$.emit($event)"
                            (check$)="check$.emit($event)" />
        </div>
      </div>
      <ng-template #viewTable>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col"></th>

              <th scope="col"
                  class="text-center">
                Ativo
              </th>

              <th scope="col">Nome / Rede</th>

              <th scope="col">Integradora / Parceiro </th>

              <th scope="col"
                  *ngIf="!!hasButtons()">
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let l of (vm?.lojas | paginate: { id: 'lojas', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: vm?.lojas?.length } | lojasLfixes) | async; trackBy:tid_loj"
                [class.off]="!l?.ativo?._status"
                [class.sel]="l?.id === idSelected">
              <td>
                <img [src]="l?.__img?.thumb"
                     alt=""
                     class="img-fluid">
              </td>

              <td class="text-center">
                <cor-html-status-checkbox [status]="l?.ativo?._status" />
              </td>

              <td>
                {{ l?.nome }}<br>
                <small class="o-50">{{ l?.rede?._nome }}</small>
              </td>

              <td>
                {{ l?.integradora?._nome }} <br />
                <small class="o-50">{{ l?.parceiro?._nome }}</small>
              </td>

              <td *ngIf="!!hasButtons()">
                <div class="d-flex justify-content-around">
                  <button mat-icon-button
                          [matTooltip]="checkBtnHint"
                          (click)="check$.emit(l)"
                          *ngIf="!!checkBtnHint">
                    <mat-icon>check</mat-icon>
                  </button>

                  <button mat-icon-button
                          [matTooltip]="searchBtnHint"
                          (click)="search$.emit(l)"
                          *ngIf="!!searchBtnHint">
                    <mat-icon>search</mat-icon>
                  </button>

                  <button mat-icon-button
                          [matTooltip]="editBtnHint"
                          (click)="edit$.emit(l)"
                          *ngIf="!!editBtnHint">
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button mat-icon-button
                          [matTooltip]="deleteBtnHint"
                          (click)="delete$.emit(l)"
                          *ngIf="!!deleteBtnHint">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>

      <div class="alert alert-light text-center"
           role="alert"
           *ngIf="!vm?.lojas?.length">
        Nenhuma loja.
      </div>
    </div>
  </div>
</ng-container>