// import { IUIAvatarConfig } from '../../';
//#region models
const UI_AVATARS_API = 'https://ui-avatars.com/api';
//#endregion

export function avatar(config: any): string {
  let prefix = '?'; // reset prefix
  let options = '';
  [
    'size',
    'fontSize',
    'length',
    'name',
    'rounded',
    'bold',
    'background',
    'color',
    'uppercase',
  ]
    .forEach(key => {
      const VAL = config[key];
      // console.log(VAL);
      if (VAL) {
        options += `${prefix}${key}=${VAL}`;
        prefix = '&'; // update prefix
      } // if
    });
  // console.log(options);

  const URL = encodeURI(`${UI_AVATARS_API}/${options}`);
  return URL;
}
