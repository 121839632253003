//#region ng
import {
  Component,
  inject,
  Input,
  signal,
} from "@angular/core";

//#region models
export type TTab = 'email' | 'cadastro' | 'esqueciSenha' | 'telefone' | 'google' | 'facebook' | '';
//#endregion

//#region services
import { CorFbAuthService } from "../../../_ng/_services/fb-auth.service";
import { CorStorageService } from "../../../_ng/_services/storage.service";
//#endregion

@Component({
  selector: "cor-mat-manage-identificacao",
  templateUrl: "manage-identificacao.component.html",
  styleUrls: ["manage-identificacao.component.scss"]
})
export class CorMatManageIdentificacaoComponent {
  //#region inputs
  // emailLoginButtonCaption
  @Input() emailLoginButtonCaption = 'Entrar';
  //methods
  @Input() methods: any[] = [];
  // isMobile
  @Input() isMobile: boolean = false;
  // showRegistrar
  @Input() showRegistrar: boolean = true;
  // showEsqueciSenha
  @Input() showEsqueciSenha: boolean = false;
  //#endregion

  //#region publics
  emailSugerido = signal<string>('');
  tab = signal<TTab>('');
  //#endregion

  //#region injects
  #corStorageServ = inject(CorStorageService);
  #authServ = inject(CorFbAuthService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.tab.set(this.methods?.[0]);
    this.emailSugerido.set(this.#corStorageServ.emailLoginGet() || '');
  }
  //#endregion

  //#region methods
  async onGoogleClick() {
    await this.#authServ.signInWithGooglePopup();
  }

  async onFacebookClick() {
    await this.#authServ.signInWithGooglePopup();
  }
  //#endregion
}