//#region ng
import {
  AbstractControl,
  NgModel
} from "@angular/forms";
//#endregion

//#region 3rd
import { get } from 'lodash';
//#endregion

//#region libs
import { isEmptyObject } from '../../../../_libs/_misc/_data-types';
//#endregion

export class CorFormValidation {
  //#region publics
  apiOk: boolean = false;
  //#endregion

  //#region errosApi
  #errosApi: any;
  get errosApi(): any { return this.#errosApi; }
  set errosApi(val: any) {
    this.#errosApi = val || {};
    // console.log(this.#errosApi);
    this.#verificaApiOk();
  }
  //#endregion

  //#region constructor
  constructor(errosApi: any = null) {
    // console.log(errosApi);    
    this.errosApi = errosApi || {};
  }
  //#endregion

  //#region functions
  #verificaApiOk() {
    this.apiOk = isEmptyObject(this.errosApi);
    // console.log(this.apiOk);
  }
  //#endregion

  //#region methods
  /* badTouch(campo: AbstractControl | NgModel | null): boolean {
    if (campo) {
      return !!get(campo, 'invalid') && !!get(campo, 'touched');
    } else {
      return false;
    } // else
  } */

  /* submittedError(campo: NgModel, apiId = ''): string {
    // console.log(campo);
    if (this._submitted) {
      console.log(this.error(campo, apiId));
      return this.error(campo, apiId);
    } else {
      return '';
    } // else
  } */

  apiError(apiId: string) {
    if (!!apiId && !!this.errosApi) {
      // Retorna msg da API (ou vazia).
      return this.errosApi?.[apiId] || '';
    } else {
      // Se não há verificação da API, não há erros.
      return '';
    } // if
  }

  /* serror(
    submitted: boolean,
    campo: AbstractControl | NgModel | null,
    apiId: string = '',
    erroMascara: boolean = false
  ): string {
    // console.log(this._submitted);
    if (submitted) {
      return this.error(campo, apiId, erroMascara);
    } else {
      return '';
    } // else
  } */

  lerror(
    campo: AbstractControl | NgModel | null,
    apiId: string = '',
    erroMascara: boolean = false
  ): string {
    // console.log(campo);
    // console.log(this._submitted);
    const ERROR_API: string = this.errosApi?.[apiId] || '';
    if (this.errosApi?.[apiId]) {
      return this.error(campo, apiId, erroMascara);
    } else if (!!campo?.touched || !!campo?.dirty) {
      return this.error(campo, apiId, erroMascara);
    } else {
      return '';
    } // else
  }

  error(
    control: AbstractControl | NgModel | null,
    apiId: string = '',
    erroMascara: boolean = false
  ): string {
    // console.log(control);
    // console.log(control.name, apiId);
    if (!!control) {
      if (!!control?.valid) {
        // Se não há erros de validação do form, verifica retorno da API.
        if (!!apiId && !!this.errosApi) {
          // Retorna msg da API (ou vazia).
          return this.errosApi?.[apiId] || '';
        } else {
          // Se não há verificação da API, não há erros.
          return '';
        } // if
      } else {
        let S = '';
        // Retorna lista de problemas na validação do form.
        if (get(control, 'errors.required')) {
          S += "Valor é obrigatório.\n";
        } else if (get(control, 'errors.minlength')) {
          S += "Largura mínima não atingida.\n";
        } else if (get(control, 'errors.maxlength')) {
          S += "Largura máxima atingida.\n";
        } else if (get(control, 'errors.min')) {
          S += "Valor mínimo não atingido.\n";
        } else if (get(control, 'errors.max')) {
          S += "Valor máximo atingido.\n";
        } else if (get(control, 'errors.rangeLength')) {
          S += "Valor fora do intervalo válido.\n";
        } else if (get(control, 'errors.gt')) {
          S += "Valor superior ao válido.\n";
        } else if (get(control, 'errors.gte')) {
          S += "Valor superior ao válido.\n";
        } else if (get(control, 'errors.lt')) {
          S += "Valor inferior ao válido.\n";
        } else if (get(control, 'errors.lte')) {
          S += "Valor inferior ao válido.\n";
        } else if (get(control, 'errors.url')) {
          S += "Não é uma URL válida.\n";
        } else if (get(control, 'errors.email')) {
          S += "Não é um email válido.\n";
        } else if (get(control, 'errors.number')) {
          S += "Não é um número válido.\n";
        } else if (get(control, 'errors.date')) {
          S += "Não é uma data válida.\n";
        } else if (get(control, 'errors.minDate')) {
          S += "Data abaixo do limite.\n";
        } else if (get(control, 'errors.maxDate')) {
          S += "Data acima do limite.\n";
        } else if (get(control, 'errors.dateISO')) {
          S += "Formato da data inválido.\n";
        } else if (get(control, 'errors.uuid')) {
          S += "Identificador inválido.\n";
        } else if (get(control, 'errors.mask') && erroMascara) {
          S += "Formato inválido.\n";
        } // if
        return S;
      } // else
    } else {
      return '';
    } // else
  }

  setApiErrors(errors: any): void {
    // console.log(errors); 
    this.errosApi = errors || {};
  }

  /* setApiError(id: string, error: string): void {
    this.errosApi[id] = error;
    this.#verificaApiOk();
  } */

  /* removeApiError(id: string): void {
    if (id && this.errosApi[id]) {
      delete this.errosApi[id];
      this.#verificaApiOk();
    } // if
  } */
  //#endregion
}
