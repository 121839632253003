//#region ng
import {
  inject,
  Injectable,
  WritableSignal,
} from '@angular/core';
// import { HttpClient } from '@angular/common/http';
//#endregion

//#region firebase
import {
  collection,
  collectionData,
  doc,
  Firestore,
  getDocs,
  query,
  Query,
  setDoc,
  where,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  Observable,
  ObservableInput,
  from,
  throwError,
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';
//#endregion

//#region models
import { IProduto } from '../../_misc/_models/_interfaces/_cols';
import { IProImgProdutoMap } from '../../../_produtos/_misc/_models/_interfaces/_maps';
import {
  IMG_NO_IMG,
  PRODUTOS_STORAGE_ROOT,
  THUMB_NO_IMG
} from '../../../_core/_misc/_models/consts';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

//#region libs
import { checkImg } from '../../../_libs/_misc/_imgs';
import {
  calcQtdeFracionado,
  isQtdeMin
} from '../../_misc/_libs/produtos';
import { guid } from '../../../_libs/_misc/_strings';
import { formataCurrency } from '../../../_libs/_misc/_formats';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { uniqueListBy } from '../../../_libs/_misc/_arrays';
import { ICarrinhoMap } from '../../_misc/_models/_interfaces/_maps';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ProdutosService {
  //#region injects
  _db = inject(Firestore);
  _msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IProduto>): IProduto {
    // console.log(BASE_IMG_RES);
    // const BASE_IMG_RES: number = Number(row?._base?.img?._res) || 0;
    const BASE_IMGS: IProImgProdutoMap[] = row?._base?._imgs || [];
    // const QTDE_CARRINHO: number = Number(row?.__carrinho?.qtde?.val) || 0;
    const IMGS: IProImgProdutoMap[] = !!BASE_IMGS?.length ? BASE_IMGS : row?.imgs || [];
    let vol: string = !!row?._base?._vol?.val || (row?.fracionado?._status && row?.fracionado?.unidade?.fracao)
      ? !!row?._base?._vol?.val
        ? row?._base?._vol?.val
        : !!row?.fracionado?._status
          ? !!row?.fracionado?.unidade?.fracao && !!row?.fracionado?.unidade?._converter
            ? formataCurrency(row?.fracionado?.unidade?.fracao / row?.fracionado?.unidade?._converter, false)
            : formataCurrency(row?.fracionado?.unidade?.fracao || 0, false)
          : ''
      : '';
    if (!!row?.fracionado?._status) { vol += row?.fracionado?.unidade?._legenda; }

    return {
      // id
      id: row?.id || '',

      // user
      atacado: {
        _isMin: !!row?.atacado?._isMin,
        preco: Number(row?.atacado?.preco) || 0,
        qtde: Number(row?.atacado?.qtde) || 0,
        status: !!row?.atacado?.status,
        _valDesc: Number(row?.atacado?._valDesc) || 0,
      },
      ativo: {
        _consistente: !!row?.ativo?._consistente,
        _d1: !!row?.ativo?._d1,
        _d2: !!row?.ativo?._d2,
        _d3: !!row?.ativo?._d3,
        online: !!row?.ativo?.online,
        status: !!row?.ativo?.status,
        _status: !!row?.ativo?._status,
        // _subdepartamento: boolean;
      },
      barcode: row?.barcode || '',
      departamentos: {
        d1: {
          id: row?.departamentos?.d1?.id || '',
          nome: row?.departamentos?.d1?.nome || '',
        },
        d2: {
          id: row?.departamentos?.d2?.id || '',
          nome: row?.departamentos?.d2?.nome || '',
        },
        d3: {
          id: row?.departamentos?.d3?.id || '',
          nome: row?.departamentos?.d3?.nome || '',
        },
      },
      // destaque: boolean; /// def false
      estoqueCritico: !!row?.estoqueCritico,
      fracionado: {
        _status: !!row?.fracionado?._status,
        // percDescPromocaoAutomatica: number; /// def 0
        unidade: {
          _converter: Number(row?.fracionado?.unidade?._converter) || 0,
          fracao: Number(row?.fracionado?.unidade?.fracao) || 0,
          fracaoAproxUn: Number(row?.fracionado?.unidade?.fracaoAproxUn) || 0,
          _legenda: row?.fracionado?.unidade?._legenda,
          tipo: row?.fracionado?.unidade?.tipo || '',
        },
      },
      imgs: IMGS,
      levePague: {
        leve: Number(row?.levePague?.leve) || 0,
        pague: Number(row?.levePague?.pague) || 0,
        status: !!row?.levePague?.status,
      },
      limiteVenda: {
        max: Number(row?.limiteVenda?.max) || 0,
        min: Number(row?.limiteVenda?.min) || 0,
      },
      ncm: row?.ncm || '',
      nome: row?.nome || '',
      // nomeOriginal: row?.nomeOriginal || '',
      // preco: Number(row?.preco) || 0,
      preco: {
        _liquido: Number(row?.preco?._liquido) || 0,
        desc: Number(row?.preco?.desc) || 0,
        produto: Number(row?.preco?.produto) || 0,
      },
      tags: row?.tags || [],
      // usaNomesProdutosBase: row?.usaNomesProdutosBase ?? true,
      // triggers: {
      //   addImgs: row?.triggers?.addImgs || [],
      //   delImgs: row?.triggers?.delImgs || [],
      // },

      // system
      // _atacado: string;
      _api: row?._api || null,
      _base: row?._base || null,
      _criadoEm: row?._criadoEm || null,
      _exists: !!row?._exists,
      _gtin: row?._gtin || null,
      _img: !!row?._img,
      _industrializado: !!row?._industrializado,
      // _nome: row?._nome || row?.nome || '',

      // realtime
      /* __carrinho: {
        obs: row?.__carrinho?.obs || '',
        qtde: {
          fixed: calcQtdeFracionado(
            row as IProduto,
            Number(row?.__carrinho?.qtde?.val) || 0
          ),
          val: QTDE_CARRINHO,
          isMin: isQtdeMin(
            row as IProduto,
            QTDE_CARRINHO
          ),
          // sel: number;
          // step: number;
          // tipoQtde: TProdutoTipoQtde;
        }
      }, */

      __carrinho: row?.__carrinho,
      __descItem: Number(row?.__descItem) || 0,
      __displayInPagination: !!row?.__displayInPagination,
      __img: {
        id: row?.__img?.id || row?._base?.img?.id || row?._base?.id || '',
        // img: row?.__img?.img || row?._base?.img?.img || IMG_NO_IMG,
        // _res: row?.__img?._res || BASE_IMG_RES,
        // thumb: row?.__img?.thumb || row?._base?.img?.thumb || THUMB_NO_IMG,
        // id: row?.__img?.id || '',
        img: row?.__img?.img || IMG_NO_IMG,
        _res: row?.__img?._res || 0,
        thumb: row?.__img?.thumb || THUMB_NO_IMG,
      },
      __imgs: row?.__imgs || [],
      __promocoes: row?.__promocoes || [],
      __relevancia: Number(row?.__relevancia) || 0,
      __sel: !!row?.__sel,
      __vol: vol,
    };

    // // console.log(row?.barcode, BASE_IMG_RES);
    // if (!BASE_IMG_RES) {
    //   if (!!(R?.__imgs || []).length) {
    //     // console.log(R.__imgs);
    //     const IMG_ID: string = R.__imgs?.[0]?.id || guid() || '';
    //     R.__img = {
    //       id: IMG_ID,
    //       img: R.__imgs?.[0]?.img || '',
    //       thumb: R.__imgs?.[0]?.thumb || '',
    //       _res: R.__imgs?.[0]?._res || 0,
    //     };
    //     // R.__imgs = (R.__imgs || []).filter((img: any) => img?.id !== IMG_ID);
    //   } else if (!!MARCA_ID) {
    //     // console.log(R.id, MARCA_ID);
    //     const IMG: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fimg__${MARCA_ID}.jpg?alt=media`;
    //     const THUMB: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fthumb__${MARCA_ID}.jpg?alt=media`;
    //     // console.log(R.id, IMG);
    //     checkImg(
    //       IMG,
    //       () => {
    //         set(R, '__img.id', MARCA_ID);
    //         set(R, '__img._res', 1280);
    //         set(R, '__img.img', IMG);
    //         set(R, '__img.thumb', THUMB);
    //         // R.__imgs.unshift(R?.__img)
    //         set(
    //           R,
    //           '__imgs',
    //           [R?.__img, ...(IMGS || [])]
    //         );
    //       },
    //       () => {
    //         R.__img = {
    //           id: '',
    //           img: IMG_NO_IMG,
    //           _res: 0,
    //           thumb: THUMB_NO_IMG,
    //         };
    //       }
    //     );
    //   } // if
    // } else {
    //   set(
    //     R,
    //     '__imgs',
    //     [
    //       {
    //         ...R?._base?.img,
    //         id: R?._base?.img?.id || guid() || ''
    //       },
    //       ...(IMGS || [])
    //     ]
    //   );
    //   // console.log(R?._base?.img, IMGS, R?.__imgs);
    // } // else

    // return R;

    // row = row || {};
    // let R: IProduto = row;
    // // console.log(row);

    // const BASE_IMG_RES: number = Number(row?._base?.img?._res) || 0;
    // const BASE_IMGS: IProImgProdutoMap[] = R?._base?._imgs || [];
    // const BASE_NCM: string = row?._base?.ncm || '';
    // const BASE_REF: string = row?._base?.ref || '';
    // const BASE_VOLUME: string = row?._base?.volume || '';
    // const MARCA_ID: string = R?._base?.marca?._id || '';
    // const NOME: string = row?.nome || '';
    // const QTDE_CARRINHO: number = Number(R?.__carrinho?.qtde?.val) || 0;
    // // const RELEVANCIA: any = get(row, '__relevancia');

    // // id
    // R.id = row?.id || '';

    // // user
    // R.atacado = {
    //   qtde: Number(row?.atacado?.qtde) || 0,
    //   preco: Number(row?.atacado?.preco) || 0,
    //   status: !!row?.atacado?.status,
    // };
    // R.ativo = {
    //   _consistente: !!row?.ativo?._consistente,
    //   _d1: !!row?.ativo?._d1,
    //   _d2: !!row?.ativo?._d2,
    //   _d3: !!row?.ativo?._d3,
    //   online: !!row?.ativo?.online,
    //   status: !!row?.ativo?.status,
    //   _status: !!row?.ativo?._status,
    // };
    // R.barcode = row?.barcode || '';
    // R.departamentos = {
    //   d1: {
    //     id: row?.departamentos?.d1?.id || '',
    //     nome: row?.departamentos?.d1?.nome || '',
    //   },
    //   d2: {
    //     id: row?.departamentos?.d2?.id || '',
    //     nome: row?.departamentos?.d2?.nome || '',
    //   },
    //   d3: {
    //     id: row?.departamentos?.d3?.id || '',
    //     nome: row?.departamentos?.d3?.nome || '',
    //   },
    // };
    // // R.destaque = !!get(row, 'destaque');
    // R.estoqueCritico = !!row?.estoqueCritico;
    // R.fracionado = {
    //   _status: !!row?.fracionado?._status,
    //   unidade: {
    //     _converter: Number(row?.fracionado?.unidade?._converter) || 0,
    //     fracao: Number(row?.fracionado?.unidade?.fracao) || 0,
    //     fracaoAproxUn: Number(row?.fracionado?.unidade?.fracaoAproxUn) || 0,
    //     _legenda: row?.fracionado?.unidade?._legenda || '',
    //     tipo: row?.fracionado?.unidade?.tipo || ''
    //   }
    // };
    // R.imgs = !!BASE_IMGS.length
    //   ? BASE_IMGS
    //   : row?.imgs || [];
    // R.limiteVenda = {
    //   max: Number(row?.limiteVenda?.max) || 0,
    //   min: Number(row?.limiteVenda?.min) || 0,
    // };
    // R.ncm = row?.ncm || BASE_NCM;
    // R.nome = NOME;
    // R.preco = Number(row?.preco) || 0;
    // R.triggers = {
    //   addImgs: row?.triggers?.addImgs || [],
    //   delImgs: row?.triggers?.delImgs || [],
    // };
    // // R.usaDepartamentoBase = !!get(row, 'usaDepartamentoBase');

    // // system
    // R._base = {
    //   // id: get(row, '_base.id') || '',
    //   // atacado: get(row, '_base.atacado') || '',
    //   atacado: row?._base?.atacado || '',
    //   apresentacao: row?._base?.apresentacao || '',
    //   complemento: row?._base?.complemento || '',
    //   cor: row?._base?.cor || '',
    //   // departamentos: {
    //   //   d1: {
    //   //     _id: get(row, 'departamentos.d1._id') || '',
    //   //     nome: get(row, 'departamentos.d1._nome') || '',
    //   //   },
    //   //   d2: {
    //   //     _id: get(row, 'departamentos.d2._id') || '',
    //   //     nome: get(row, 'departamentos.d2._nome') || '',
    //   //   },
    //   //   d3: {
    //   //     _id: get(row, 'departamentos.d3._id') || '',
    //   //     nome: get(row, 'departamentos.d3._nome') || '',
    //   //   },
    //   // },
    //   // departamentos: {
    //   //   id: get(row, '_base.departamento.id') || '',
    //   //   nome: get(row, '_base.departamento.nome') || '',
    //   //   subdepartamento: {
    //   //     id: get(row, '_base.departamento.subdepartamento.id') || '',
    //   //     nome: get(row, '_base.departamento.subdepartamento.nome') || '',
    //   //   },
    //   // },
    //   dimensoes: row?._base?.dimensoes || '',
    //   embalagem: row?._base?.embalagem || '',
    //   idade: row?._base?.idade || '',
    //   formato: row?._base?.formato || '',
    //   img: {
    //     img: row?._base?.img?.img || '',
    //     thumb: row?._base?.img?.thumb || '',
    //     _res: Number(row?._base?.img?._res) || 0,
    //   },
    //   info: row?._base?.info || '',
    //   // imgs: get(row, 'imgs') || [],
    //   // marca: get(row, 'marca') || null,
    //   marca: {
    //     _id: row?._base?.marca?._id || '',
    //     linha: {
    //       _id: row?._base?.marca?.linha?._id || '',
    //       nome: row?._base?.marca?.linha?.nome || '',
    //     },
    //     nome: row?._base?.marca?.nome || '',
    //   },
    //   material: row?.material || '',
    //   modelo: row?.modelo || '',
    //   nacionalidade: row?.nacionalidade || '',
    //   ncm: row?.ncm || '',
    //   // nome: row?._base?.nome || '',
    //   produto: row?._base?.produto || '',
    //   qtde: row?._base?.qtde || '',
    //   ref: BASE_REF,
    //   sabor: row?._base?.sabor || '',
    //   tamanho: row?._base?.tamanho || '',
    //   verificado: !!row?.verificado,
    //   _ver: Number(row?._base?._ver) || 0,
    //   volume: BASE_VOLUME,
    // };
    // R._criadoEm = row?._criadoEm || null;
    // // R.departamento = {
    // //   id: get(row, 'departamento.id') || '',
    // //   nome: get(row, 'departamento.nome') || '',
    // //   subdepartamento: {
    // //     id: get(row, 'departamento.subdepartamento.id') || '',
    // //     nome: get(row, 'departamento.subdepartamento.nome') || '',
    // //   },
    // // };
    // R._exists = row?._exists ?? true;
    // R._industrializado = !!row?._industrializado;

    // // realtime
    // R.__displayInPagination = !!row?.__displayInPagination;
    // // R.__qtdeCarrinho = {
    // //   calc: Number(get(row, '__qtdeCarrinho.calc')) || 0,
    // //   sel: Number(get(row, '__qtdeCarrinho.sel')) || 0,
    // //   step: Number(get(row, '__qtdeCarrinho.step') || 1),
    // //   tipoQtde: get(row, '__qtdeCarrinho.tipoQtde') || '',
    // // };
    // /* if (get(R.fracionado, '_status')) {
    //   const FRAC_APROX_UN: number = Number(get(R, 'fracionado.unidade.fracaoAproxUn')) || 0;
    //   // const FRACAO: number = get(R.__qtdeCarrinho, 'tipoQtde') === 'Q' && FRAC_APROX_UN
    //   //   ? FRAC_APROX_UN
    //   //   : Number(get(R, 'fracionado.unidade.fracao')) || 0;

    //   const CONVERTER: number = Number(get(R, 'fracionado.unidade._converter')) || 0;
    //   // R.__qtdeCarrinho.step = CONVERTER
    //   //   ? FRACAO / CONVERTER * 1
    //   //   : FRACAO * 1;
    // } // if */
    // // R.__qtdeCarrinho.calc = R.__qtdeCarrinho.sel * R.__qtdeCarrinho.step;
    // R.__sel = !!row?.__sel
    // R.__imgs = [...R.imgs];
    // // console.log(R.id, R.__imgs);
    // // console.log(BASE_IMG_RES);
    // // console.log(MARCA_ID);
    // R.__img = {
    //   id: row?._base?.img?.id || row?._base?.id || '',
    //   img: row?._base?.img?.img || IMG_NO_IMG,
    //   _res: BASE_IMG_RES,
    //   thumb: row?._base?.img?.thumb || THUMB_NO_IMG,
    // };
    // if (!BASE_IMG_RES) {
    //   if (!!(R?.__imgs || []).length) {
    //     // console.log(R.__imgs);
    //     const IMG_ID: string = R.__imgs[0].id || '';
    //     R.__img = {
    //       id: IMG_ID,
    //       img: R.__imgs[0].img || '',
    //       thumb: R.__imgs[0].thumb || '',
    //       _res: R.__imgs[0]._res || 0,
    //     };
    //     R.__imgs = R.__imgs.filter((img: any) => img?.id !== IMG_ID);
    //   } else if (!!MARCA_ID) {
    //     // console.log(R.id, MARCA_ID);
    //     const IMG: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fimg__${MARCA_ID}.jpg?alt=media`;
    //     const THUMB: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fthumb__${MARCA_ID}.jpg?alt=media`;
    //     // console.log(R.id, IMG);
    //     checkImg(
    //       IMG,
    //       () => {
    //         set(R, '__img.id', MARCA_ID);
    //         set(R, '__img._res', 1280);
    //         set(R, '__img.img', IMG);
    //         set(R, '__img.thumb', THUMB);
    //       },
    //       () => {
    //         R.__img = {
    //           id: '',
    //           img: IMG_NO_IMG,
    //           _res: 0,
    //           thumb: THUMB_NO_IMG,
    //         };
    //       }
    //     );
    //   } // if
    // } // ifQTDE_CARRINHO

    // // R.__nome = `${NOME} ${!!BASE_REF ? 'Ref. ' + BASE_REF : ''} ${BASE_VOLUME}`
    // //   .trim()
    // //   .replace(/ {2}/g, ' ');
    // R._img = !!row?._img;
    // R.__carrinho = {
    //   obs: R?.__carrinho?.obs || '',
    //   qtde: {
    //     fixed: calcQtdeFracionado(row, Number(R?.__carrinho?.qtde?.val) || 0),
    //     val: QTDE_CARRINHO,
    //     isMin: isQtdeMin(row, QTDE_CARRINHO), // !!get(R, '__carrinho.qtde.isMin')
    //     // sel: Number(get(R, '__carrinho.qtde.sel')) || 0,
    //     // step: Number(get(R, '__carrinho.qtde.step')) || 0,
    //     // tipoQtde: get(R, '__carrinho.qtde.tipoQtde') || 'Q'
    //   }
    // };
    // R.__relevancia = Number(row?.__relevancia) || 0;

    // // const QTDE_CARRINHO: number = Number(get(R, '__qtdeCarrinho.val')) || 0;
    // /*  R.__qtdeCarrinho = {
    //    val: Number(get(R, '__qtdeCarrinho.val')) || 0,
    //    isMin: !!get(R, '__qtdeCarrinho.isQtde')
    //    // sel: Number(get(R, '__qtdeCarrinho.sel')) || 0,
    //    // step: Number(get(R, '__qtdeCarrinho.step')) || 0,
    //    // tipoQtde: get(R, '__qtdeCarrinho.tipoQtde') || 'Q'
    //  }; */

    // // if (!get(R, '__qtdeCarrinho.tipoQtde')) {
    // //   R.__qtdeCarrinho.tipoQtde = R.fracionado._status ? 'F' : 'Q';
    // //   if (!R.fracionado.status) {
    // //     R.__qtdeCarrinho.tipoQtde = 'Q';
    // //   } else {
    // //     R.__qtdeCarrinho.tipoQtde = 'Q';
    // //   } // else
    // // } // if

    // // console.log(R);
    // return R;
  }

  lfix(row: Partial<IProduto>): Promise<TNullable<IProduto>> {
    // console.log(row);    
    // console.log(JSON.stringify(row?.__carrinho));
    const BASE: boolean = !!row?._base;
    const BASE_IMG_RES: number = Number(row?._base?.img?._res) || 0;
    const MARCA_ID: string = row?._base?.marca?._id || '';
    const BASE_IMGS: IProImgProdutoMap[] = row?._base?._imgs || [];
    const MARCA_IMG: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fimg__${MARCA_ID}.jpg?alt=media`;
    const MARCA_THUMB: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fthumb__${MARCA_ID}.jpg?alt=media`;
    let imgs: IProImgProdutoMap[] = !!BASE_IMGS?.length ? BASE_IMGS : row?.imgs || [];

    return new Promise(
      (resolve, reject) => {
        if (!BASE) {
          resolve(
            this.fix(
              {
                ...row,
                __img: {
                  ...row?.__img,
                  id: imgs?.[0]?.id || guid() || '',
                  img: imgs?.[0]?.img || IMG_NO_IMG,
                  thumb: imgs?.[0]?.thumb || THUMB_NO_IMG,
                  _res: imgs?.[0]?._res || 0,
                },
                __imgs: imgs || []
              }
            )
          );
        } else if (!!BASE_IMG_RES) {
          const IMG: IProImgProdutoMap = {
            ...row?.__img,
            id: row?._base?.id || '',
            img: row?._base?.img?.img || IMG_NO_IMG,
            thumb: row?._base?.img?.thumb || THUMB_NO_IMG,
            _res: BASE_IMG_RES,
          };

          if (!!MARCA_ID) {
            checkImg(
              MARCA_THUMB,
              () => {
                resolve(
                  this.fix(
                    {
                      ...row,
                      __img: IMG,
                      __imgs: [
                        IMG,
                        ...uniqueListBy(
                          (imgs || []).concat(
                            {
                              id: MARCA_ID,
                              img: MARCA_IMG,
                              thumb: MARCA_THUMB
                            }
                          ),
                          'id'
                        )
                      ]
                    }
                  )
                );
              },
              () => {
                resolve(
                  this.fix(
                    {
                      ...row,
                      __img: IMG,
                      __imgs: [IMG, ...(imgs || [])]
                    }
                  )
                );
              }
            );
          } // if
        } else if (!!MARCA_ID) {
          // console.log(row.id, MARCA_ID);
          // console.log(row.id, IMG);
          checkImg(
            MARCA_THUMB,
            () => {
              const IMG: IProImgProdutoMap = {
                ...row?.__img,
                id: MARCA_ID,
                img: MARCA_IMG,
                thumb: MARCA_THUMB,
                _res: 1280,
              };
              resolve(
                this.fix(
                  {
                    ...row,
                    __img: IMG,
                    __imgs: [IMG]
                  }
                )
              );
            },
            () => {
              resolve(
                this.fix(
                  {
                    ...row,
                    __img: {
                      id: '',
                      img: IMG_NO_IMG,
                      _res: 0,
                      thumb: THUMB_NO_IMG,
                    },
                    __imgs: imgs
                  }
                )
              );
            }
          );
        } else {
          resolve(
            this.fix(
              {
                ...row,
                __img: {
                  id: '',
                  img: IMG_NO_IMG,
                  _res: 0,
                  thumb: THUMB_NO_IMG,
                },
                __imgs: imgs
              }
            )
          );
        } // else  
      }
    );
  }

  fixes(docs: Partial<IProduto>[]): IProduto[] {
    return (docs || []).map((doc: Partial<IProduto>) => this.fix(doc));
  }

  lfixes(docs: Partial<IProduto>[]): Observable<IProduto[]> {
    // console.log(docs);
    const PROMISES: Promise<IProduto>[] = (docs || [])
      .map((doc: Partial<IProduto>) => this.lfix(doc));
    return from(Promise.all(PROMISES));
  }

  // unfix(row: IProduto): IProduto {
  //   // const RET: IProduto = { ...row };
  //   // const RET: IProduto = JSON.parse(JSON.stringify(row));
  //   const RET: IProduto = cloneDeep(row);

  //   delete RET.triggers;
  //   delete RET._criadoEm;
  //   delete RET._exists;
  //   delete RET.__displayInPagination;
  //   delete RET.__img;
  //   delete RET.__imgs;
  //   // delete RET.__nome;
  //   delete RET.__carrinho;
  //   delete RET.__sel;

  //   return RET;
  // }
  //#endregion

  //#region R
  produtosNcm(
    lojaPath: string,
    ncm: string,
  ): Observable<IProduto[]> {
    if (!!lojaPath) {
      if (!!ncm) {
        // afs.collection('items', ref => ref.where('size', '==', 'large'))
        const PATH: string = `${lojaPath}/produtos`;
        const Q: Query = query(
          collection(this._db, PATH),
          where('ativo._status', '==', true),
          where('ncm', '==', (ncm || '').trim()),
          // limit(1)
        );
        // return (collectionData(Q, { idField: 'id' }) as Observable<IProduto[]>)
        return (from(getDocs(Q)) as Observable<any>)
          .pipe(
            map((colSnap: any) => {
              // console.log(colSnap);
              const PRODUTOS: IProduto[] = [];
              colSnap.forEach((doc: any) => PRODUTOS.push(this.fix({ ...doc?.data(), id: doc?.id })));
              // console.log(PRODUTOS);
              return PRODUTOS;
            }),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this._msgServ.onCatchError(err, 'Erro carregando produtos por NCM.', 'danger', 'dialog')
            )
          );
      } // if
      return throwError(() => 'Nenhum NCM indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion

  //#region U
  update(
    lojaPath: string,
    idProduto: string,
    changes: Partial<IProduto>,
  ): Observable<any> {
    if (!!lojaPath) {
      if (!!idProduto) {
        const PATH: string = `${lojaPath}/produtos/${idProduto}`;
        return from(setDoc(doc(this._db, PATH), changes, { merge: true }))
          .pipe(
            // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
            catchError<any, ObservableInput<any>>(
              (err: any) => this._msgServ.onCatchError(err, 'Erro modificando produto.')
            )
          );
      } // if
      return throwError(() => 'Nenhum produto indicado.');
    } // if
    return throwError(() => 'Nenhuma loja indicada.');
  }
  //#endregion
}
