//#region ng
import {
  Component,
  Input
} from "@angular/core";
//#endregion

//#region models
import { ICorViacepApi } from "../../../_misc/_models/_interfaces/_apis";
//#endregion

@Component({
  selector: "cor-bs-endereco",
  templateUrl: "endereco.component.html",
  styleUrls: ["endereco.component.scss"]
})
export class CorBsEnderecoComponent {
  //#region inputs
  // endereco
  @Input({ required: true }) endereco: ICorViacepApi;
  // small  
  @Input() small: boolean = false;
  //#endregion
}
