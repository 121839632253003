//#region ng
import { NgModule } from '@angular/core';
import {
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
//#endregion

//#region mat
// import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
//#endregion

//#region 3rd
// import { CarouselModule } from 'ngx-owl-carousel-o';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask
} from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
// import { PinchZoomModule } from 'ngx-pinch-zoom';
// import { SwiperModule } from 'swiper/angular';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgApexchartsModule } from "ng-apexcharts";

//#endregion

//#region modules
import { CorBsComponentsModule } from '../../../_core/_ng-ui/_bs/cor-bs-components.module';
import { CorDirectivesModule } from '../../../_core/_ng/_directives/cor-directives.module';
import { CorHtmlComponentsModule } from '../../../_core/_ng-ui/_html/cor-html-components.module';
import { CorPipesModule } from '../../../_core/_ng/_pipes/cor-pipes.module';
import { MePipesModule } from '../../_ng/_pipes/me-pipes.module';
import { CorMatComponentsModule } from '../../../_core/_ng-ui/_mat/cor-mat-components.module';
import { MeHtmlComponentsModule } from '../_html/me-html-components.module';
//#endregion

//#region components
import {
  MeMatGaleriaGetComponent,
  MeMatIntegradoraCardComponent,
  MeMatIntegradoraFormComponent,
  MeMatIntegradorasComponent,
  MeMatLojaCardComponent,
  MeMatLojaInfoComponent,
  MeMatLojasComponent,
  // MeMatLojasRefsComponent,
  MeMatMeilisearchsComponent,
  MeMatNotificacoesComponent,
  MeMatOperadorActionsComponent,
  // MeMatOperadorFormComponent,
  MeMatRedeCardComponent,
  MeMatRedeFormComponent,
  MeMatRedesComponent,
  MeMatPedidoCardComponent,
  MeMatPedidoInfoComponent,
  MeMatPedidoInfoDetalhesComponent,
  MeMatPedidoInfoTotaisComponent,
  MeMatProdutoImgsComponent,
  MeMatProdutoInfoComponent,
  MeMatTiposLojasComponent,
  MeMatLojaExtrasFormComponent,
  MeMatMovPedidoItensComponent,
  MeMatMovPedidosChartComponent,
} from '.';
//#endregion

@NgModule({
  imports: [
    NgApexchartsModule,
    CorBsComponentsModule,
    CorDirectivesModule,
    CorHtmlComponentsModule,
    CorPipesModule,
    CorMatComponentsModule,
    // MeMatManageEnderecoModalModule,
    CommonModule,
    FormsModule,
    // GalleryModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    MeHtmlComponentsModule,
    MePipesModule,
    NgOptimizedImage,
    NgxImageZoomModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxPaginationModule,
    // PinchZoomModule,
    ReactiveFormsModule,
    // SliderCarouselModule,
    // SlickCarouselModule,
    // SwiperModule,
  ],
  declarations: [
    MeMatGaleriaGetComponent,
    MeMatIntegradoraCardComponent,
    MeMatIntegradoraFormComponent,
    MeMatIntegradorasComponent,
    MeMatLojasComponent,
    MeMatLojaCardComponent,
    MeMatLojaExtrasFormComponent,
    MeMatLojaInfoComponent,
    // MeMatLojasRefsComponent,
    MeMatMeilisearchsComponent,
    MeMatMovPedidoItensComponent,
    MeMatMovPedidosChartComponent,
    MeMatNotificacoesComponent,
    MeMatOperadorActionsComponent,
    // MeMatOperadorFormComponent,
    MeMatRedeFormComponent,
    MeMatRedesComponent,
    MeMatPedidoCardComponent,
    MeMatPedidoInfoComponent,
    MeMatPedidoInfoDetalhesComponent,
    MeMatPedidoInfoTotaisComponent,
    MeMatProdutoImgsComponent,
    MeMatProdutoInfoComponent,
    MeMatRedeCardComponent,
    MeMatTiposLojasComponent,
  ],
  exports: [
    MeMatGaleriaGetComponent,
    MeMatIntegradoraCardComponent,
    MeMatIntegradoraFormComponent,
    MeMatIntegradorasComponent,
    MeMatLojasComponent,
    MeMatLojaCardComponent,
    MeMatLojaExtrasFormComponent,
    MeMatLojaInfoComponent,
    // MeMatLojasRefsComponent,
    MeMatMeilisearchsComponent,
    MeMatMovPedidoItensComponent,
    MeMatMovPedidosChartComponent,
    MeMatNotificacoesComponent,
    MeMatOperadorActionsComponent,
    // MeMatOperadorFormComponent,
    MeMatRedeFormComponent,
    MeMatRedesComponent,
    MeMatPedidoCardComponent,
    MeMatPedidoInfoComponent,
    MeMatPedidoInfoDetalhesComponent,
    MeMatPedidoInfoTotaisComponent,
    MeMatProdutoImgsComponent,
    MeMatProdutoInfoComponent,
    MeMatRedeCardComponent,
    MeMatTiposLojasComponent,
  ],
  providers: [
    provideNgxMask(),
  ],
})
export class MeMatComponentsModule { }
