//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors
} from '@angular/forms';
//#endregion

//#region mat
import { MatStepper } from '@angular/material/stepper';
//#endregion

//#region 3rd
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexLegend,
} from "ng-apexcharts";
import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  filter,
  finalize,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  charts: {
    Y: IChartInfoMap;
    M: IChartInfoMap;
    D: IChartInfoMap;
  };
};
interface IChartInfoMap {
  series?: number[];
  tots: IMovPedidoTotMap[];
  categories: string[];
};
type TVal = 'QP' | 'VP' | 'TM' | 'QI' | 'QV' | 'ST' | 'SL' | 'SC' | 'TE';
import { IMovPedidoTotMap } from '../../../_misc/_models/_interfaces/_maps';
import {
  IPedido,
  IMovPedidoPedido
} from '../../../_misc/_models/_interfaces/_cols';
import { MESES_NOME_LONGO } from '../../../../_core/_misc/_models/consts';
/* import {
  CHART_CONFIG,
  TChartOptions
} from '../../../_misc/_models/consts'; */
//#endregion

//#region charts
export type TChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
};

export const CHART_CONFIG: Partial<TChartOptions> = {
  series: [
    {
      name: '',
      data: []
    }
  ],
  // series: [
  //   {
  //     name: "Inflation",
  //     data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
  //   }
  // ],
  yaxis: {
    axisBorder: {
      show: true
    },
    axisTicks: {
      show: false
    },
    // labels: {
    //   show: true,
    //   formatter: (val) => val + "%",
    // }
  },
  xaxis: {
    // categories: [],
    categories: [],
    position: "top",
    labels: {
      // offsetY: -18,
      style: {
        fontSize: "12px",
        colors: "#444"
      }
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      fill: {
        type: "gradient",
        gradient: {
          colorFrom: "#D8E3F0",
          colorTo: "#BED1E6",
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5
        }
      }
    },
    // tooltip: {
    //   enabled: true,
    //   // offsetY: -35
    //   offsetY: -100
    // }
  },
  legend: {
    show: false,
  },
  // chart: {
  //   width: 500,
  //   height: 350,
  //   type: "bar", // bar, line
  //   // events: {
  //   //   dataPointSelection(event, chartContext, config) {
  //   //     console.log(chartContext, config);
  //   //   }
  //   // },
  // },
  plotOptions: {
    bar: {
      // distributed: true,
      dataLabels: {
        position: "top" // top, center, bottom
      }
    }
  },
  dataLabels: {
    enabled: true,
    // formatter: (val) => val + "%",
    offsetY: -20,
    style: {
      fontSize: "12px",
      colors: ["gray"] // "#304758"
    }
  },
  fill: {
    // colors: ["red"],
    opacity: .8,
    // type: "gradient",
    // gradient: {
    //   shade: "light",
    //   type: "horizontal",
    //   shadeIntensity: 0.25,
    //   gradientToColors: undefined,
    //   inverseColors: true,
    //   opacityFrom: 1,
    //   opacityTo: 1,
    //   stops: [50, 0, 100, 100]
    // }
  },
  // title: {
  //   text: "Qtde pedidos",
  //   floating: false,
  //   // offsetY: 320,
  //   offsetY: -20,
  //   align: "center",
  //   style: {
  //     color: "#444"
  //   }
  // }
}
//#endregion

//#region libs
import { compareValues } from '../../../../_libs/_misc/_arrays';
import { formataCurrency } from '../../../../_libs/_misc/_formats';
import { onDestroy } from '../../../../_core/_ng/_libs';
//#endregion

//#region services
import {
  MovPedidosPedidosService,
  MovPedidosTotService,
} from '../../../_ng/_services';
import { CorStorageService } from '../../../../_core/_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-mov-pedidos-chart',
  templateUrl: './mov-pedidos-chart.component.html',
  styleUrls: ['./mov-pedidos-chart.component.scss'],
})
export class MeMatMovPedidosChartComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #preAction$ = new BehaviorSubject<string>('');
  #chartYAction$ = new BehaviorSubject<IChartInfoMap>(null);
  #chartMAction$ = new BehaviorSubject<IChartInfoMap>(null);
  #chartDAction$ = new BehaviorSubject<IChartInfoMap>(null);
  // #docsP$ = new BehaviorSubject<IMovPedidoPedido[]>(null);
  //#endregion

  //#region viewchilds
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild('stepper', { static: true }) _stepperRef: MatStepper;
  //#endregion

  //#region inputs
  // pre
  #pre: string;
  get pre(): string { return this.#pre; }
  @Input({ required: true }) set pre(val: string) {
    this.#pre = val;
    this.#preAction$.next(val);
  }
  // idModulo
  #idModulo: string;
  get idModulo(): string { return this.#idModulo; }
  @Input({ required: true }) set idModulo(val: string) {
    this.#idModulo = val;
    this.valStorageKey.set(`VAL_MOV_PED_${val}`.toLowerCase());
    this.valRef?.setValue(this.#chartValGet() || 'QP');
  }
  //#endregion

  //#region outputs
  @Output() pedido$ = new EventEmitter<IPedido>();
  //#endregion

  //#region publics
  mesesNomeLongo = signal<string[]>(MESES_NOME_LONGO).asReadonly();
  anoForm = signal<FormGroup>(null);
  mesForm = signal<FormGroup>(null);
  diaForm = signal<FormGroup>(null);
  valForm = signal<FormGroup>(null);
  // ano = signal<number>(null);
  YChartOptions = signal<Partial<TChartOptions>>(null);
  MChartOptions = signal<Partial<TChartOptions>>(null);
  DChartOptions = signal<Partial<TChartOptions>>(null);
  pedidos = signal<IMovPedidoPedido[]>(null);
  vm$ = signal<Observable<IVm>>(null);
  vm = signal<IVm>(null);
  valStorageKey = signal<string>('');
  //#endregion

  //#region injectables
  #fb = inject(FormBuilder);
  #movPedidosTotServ = inject(MovPedidosTotService);
  #movPedidosPedServ = inject(MovPedidosPedidosService);
  #cdStorageServ = inject(CorStorageService);
  //#endregion

  //#region constructor
  constructor() {
    this.anoForm.set(this.#fb?.group({ ano: [null, [Validators.required]] }));
    // this.mesForm.set(this.#fb?.group({ mes: [MONTH, [Validators.required]] }));
    // this.diaForm.set(this.#fb?.group({ dia: [DAY, [Validators.required]] }));
    this.mesForm.set(this.#fb?.group({ mes: [null, [Validators.required]] }));
    this.diaForm.set(this.#fb?.group({ dia: [null, [Validators.required]] }));
    this.valForm.set(
      this.#fb?.group({
        val: ['QP', [Validators.required]],
        caption: ['Qtde pedidos', [Validators.required]],
      })
    );

    // console.log(this);
    this.YChartOptions.set(
      {
        ...CHART_CONFIG,
        yaxis: {
          ...CHART_CONFIG?.yaxis,
          labels: {
            show: true,
            formatter: (val) => formataCurrency((Number(val) || 0) /* / 1000 */, false) /* + 'K' */,
          }
        },
        dataLabels: {
          ...CHART_CONFIG?.dataLabels,
          formatter: (val) => formataCurrency((Number(val) || 0) /* / 1000 */, false) /* + 'K' */,
        },
        chart: {
          width: 700,
          height: 350,
          type: "bar", // bar, line
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const YEAR = config?.w?.config?.xaxis?.categories?.at(config?.dataPointIndex);
              this.anoRef.setValue(YEAR);
            },
          },
        }
      }
    );

    this.MChartOptions.set(
      {
        ...CHART_CONFIG,
        yaxis: {
          ...CHART_CONFIG?.yaxis,
          labels: {
            show: true,
            formatter: (val) => formataCurrency((Number(val) || 0) /* / 1000 */, false) /* + 'K' */,
          }
        },
        dataLabels: {
          ...CHART_CONFIG?.dataLabels,
          formatter: (val) => formataCurrency((Number(val) || 0) /* / 1000 */, false) /* + 'K' */,
        },
        chart: {
          width: 700,
          height: 350,
          type: "bar", // bar, line
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const MONTH = config?.w?.config?.xaxis?.categories?.at(config?.dataPointIndex);
              // this.mesRef.setValue(MESES_NOME_LONGO.indexOf(MONTH));
              this.mesRef.setValue(MONTH);
            },
          },
        }
      }
    );

    this.DChartOptions.set(
      {
        ...CHART_CONFIG,
        yaxis: {
          ...CHART_CONFIG?.yaxis,
          labels: {
            show: true,
            formatter: (val) => formataCurrency((Number(val) || 0) /* / 1000 */, false) /* + 'K' */,
          }
        },
        dataLabels: {
          ...CHART_CONFIG?.dataLabels,
          formatter: (val) => formataCurrency((Number(val) || 0) /* / 1000 */, false) /* + 'K' */,
        },
        chart: {
          width: 700,
          height: 350,
          type: "bar", // bar, line
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const DAY = config?.w?.config?.xaxis?.categories?.at(config?.dataPointIndex);
              this.diaRef.setValue(DAY);
            },
          },
        }
      }
    );
  }
  //#endregion

  //#region  Controls Getters
  get anoRef(): AbstractControl { return this.anoForm()?.get('ano'); }
  get mesRef(): AbstractControl { return this.mesForm()?.get('mes'); }
  get diaRef(): AbstractControl { return this.diaForm()?.get('dia'); }
  get valRef(): AbstractControl { return this.valForm()?.get('val'); }
  get valCaptionRef(): AbstractControl { return this.valForm()?.get('caption'); }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.anoRef?.valueChanges
      .pipe(
        switchMap(
          (ano: string) => this.#movPedidosTotServ.docsM(this.pre, ano)
            .pipe(
              tap((docsM: IMovPedidoTotMap[]) => {
                const DOCS_M: IMovPedidoTotMap[] = this.#movPedidosTotServ.fixes(docsM)
                  .sort(compareValues('__pos'));
                // console.log(DOCS_M);
                const TOTS_M: IMovPedidoTotMap[] = [];
                const CATEGORIES_M: string[] = [];
                // const SERIES_M: number[] = [];
                (DOCS_M || [])
                  .forEach(
                    (totM: IMovPedidoTotMap) => {
                      // console.log(totY);
                      CATEGORIES_M.push(this.mesesNomeLongo()?.at(totM?.id - 1));
                      TOTS_M.push(totM);
                      // SERIES_M.push()
                    }
                  );
                const chartM: IChartInfoMap = {
                  tots: TOTS_M,
                  categories: CATEGORIES_M,
                  // vals: CATEGORIES_M,
                  // series: SERIES_M,
                };
                // this.mesRef.setValue(null);
                this.#chartMAction$.next(chartM);
                if (!!this._stepperRef && !!ano) {
                  this._stepperRef.selectedIndex = 1;
                } // if
              })
            )
        ),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    this.mesRef?.valueChanges
      .pipe(
        // tap(mes => console.log(mes)),
        map((mes: string) => this.#mes2Val(mes)),
        // tap(mes => console.log(mes)),
        filter((mes: string) => Number(mes) > 0 && Number(mes) <= 12),
        switchMap(
          (mes: string) => this.#movPedidosTotServ.docsD(this.pre, this.anoRef?.value, mes)
            .pipe(
              tap((docsD: IMovPedidoTotMap[]) => {
                // console.log(docsD);
                const TOTS_D: IMovPedidoTotMap[] = [];
                const CATEGORIES_D: string[] = [];
                // const SERIES_D: number[] = [];
                (docsD || [])
                  .forEach(
                    (totD: IMovPedidoTotMap) => {
                      // console.log(totY);
                      CATEGORIES_D.push(totD?.id);
                      TOTS_D.push(totD);
                      // SERIES_D.push()
                    }
                  );
                const chartD: IChartInfoMap = {
                  tots: TOTS_D,
                  categories: CATEGORIES_D,
                  // series: SERIES_M,
                };
                this.#chartDAction$.next(chartD);
                // this.diaRef.setValue(null);
                if (!!this._stepperRef && !!mes) {
                  this._stepperRef.selectedIndex = 2;
                } // if
              })
            )
        ),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    this.diaRef?.valueChanges
      .pipe(
        // tap(mes => console.log(mes)),
        // map((mes: string) => this.#mes2Val(mes)),
        // tap(mes => console.log(mes)),
        // filter((mes: string) => Number(mes) > 0 && Number(mes) <= 12),
        switchMap(
          (dia: string) => this.#movPedidosPedServ.docs(
            this.pre,
            this.anoRef?.value,
            this.#mes2Val(this.mesRef?.value),
            dia
          )
            .pipe(
              tap((docsP: IMovPedidoPedido[]) => {
                // console.log(docsP);
                this.pedidos.set(this.#movPedidosPedServ.fixes(docsP));
                /* const TOTS_D: IMovPedidoTotMap[] = [];
                const CATEGORIES_D: string[] = [];
                // const SERIES_D: number[] = [];
                (docsD || [])
                  .forEach(
                    (totD: IMovPedidoTotMap) => {
                      // console.log(totY);
                      CATEGORIES_D.push(totD?.id);
                      TOTS_D.push(totD);
                      // SERIES_D.push()
                    }
                  );
                const chartD: IChartInfoMap = {
                  tots: TOTS_D,
                  categories: CATEGORIES_D,
                  // series: SERIES_M,
                };
                this.#chartDAction$.next(chartD);
                // this.diaRef.setValue(null);
                */
                if (!!this._stepperRef && !!dia) {
                  this._stepperRef.selectedIndex = 3;
                } // if 
              })
            )
        ),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    this.#preAction$
      .pipe(
        filter((pre: string) => !!pre),
        // tap((pre: string) => console.log(pre)),
        switchMap((pre: string) => this.#movPedidosTotServ.docsY(pre))
      )
      .pipe(takeUntil(this.#destroyAction$))
      // .pipe(first(), finalize(() => UNSUB?.unsubscribe()))
      .subscribe((docsY: IMovPedidoTotMap[]) => {
        // console.log(docsY);
        const TOTS_Y: IMovPedidoTotMap[] = [];
        const CATEGORIES_Y: string[] = [];
        // const SERIES_Y: number[] = [];
        (docsY || [])
          .forEach(
            (totY: IMovPedidoTotMap) => {
              // console.log(totY);
              CATEGORIES_Y.push(totY?.id);
              TOTS_Y.push(totY);
              // SERIES_Y.push()
            }
          );
        const chartY: IChartInfoMap = {
          tots: TOTS_Y,
          categories: CATEGORIES_Y,
          // series: SERIES_Y,
        };
        this.#chartYAction$.next(chartY);
      });

    this.vm$.set(
      combineLatest([
        this.#chartYAction$,
        this.#chartMAction$,
        this.#chartDAction$,
        this.valRef?.valueChanges.pipe(startWith(this.valRef?.value)),
      ])
        .pipe(map(([chartY, chartM, chartD, val]) => {
          this.#chartValSet(val);
          // console.log(chartY, chartM, chartD, val);
          // console.log(chartY);
          // console.log(chartM);
          // console.log(chartD);
          // console.log(val);

          // Y
          // const CHART_Y: IChartInfoMap[] = [];
          const SERIES_Y: number[] = [];
          chartY?.tots?.forEach(
            (tot: IMovPedidoTotMap) => {
              // console.log(tot);
              SERIES_Y.push(this.#tot2Serie(tot, val));
            }
          );

          this.YChartOptions.update(
            (config: Partial<TChartOptions>) => {
              // console.log(config);
              return {
                ...config,
                series: [
                  {
                    data: SERIES_Y,
                    name: '', // this.valCaptionRef?.value
                  }
                ],

                xaxis: {
                  ...CHART_CONFIG?.xaxis,
                  categories: chartY?.categories,
                },
              };
            }
          );

          // M
          // const CHART_M: IChartInfoMap[] = [];
          const SERIES_M: number[] = [];
          chartM?.tots?.forEach(
            (tot: IMovPedidoTotMap) => {
              // console.log(tot);
              SERIES_M.push(this.#tot2Serie(tot, val));
            }
          );

          this.MChartOptions.update(
            (config: Partial<TChartOptions>) => {
              // console.log(config);
              return {
                ...config,
                series: [
                  {
                    data: SERIES_M,
                    name: '', // this.valCaptionRef?.value
                  }
                ],

                xaxis: {
                  ...CHART_CONFIG?.xaxis,
                  categories: chartM?.categories,
                },
              };
            }
          );

          // D
          // const CHART_D: IChartInfoMap[] = [];
          const SERIES_D: number[] = [];
          chartD?.tots?.forEach(
            (tot: IMovPedidoTotMap) => {
              // console.log(tot);
              SERIES_D.push(this.#tot2Serie(tot, val));
            }
          );

          this.DChartOptions.update(
            (config: Partial<TChartOptions>) => {
              // console.log(config);
              return {
                ...config,
                series: [
                  {
                    data: SERIES_D,
                    name: '', // this.valCaptionRef?.value
                  }
                ],

                xaxis: {
                  ...CHART_CONFIG?.xaxis,
                  categories: chartD?.categories,
                },
              };
            }
          );

          // console.log(SERIES_Y);
          // console.log(SERIES_M);
          // console.log(SERIES_D);
          const VM: IVm = {
            charts: {
              Y: {
                ...chartY,
                series: SERIES_Y,
              },
              M: {
                ...chartM,
                series: SERIES_M,
              },
              D: {
                ...chartD,
                series: SERIES_D,
              }
            }
          };
          // console.log(VM);
          return VM;
        }))
    );
    this.vm$()
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((vm: IVm) => this.vm.set(vm));

    const NOW: any = moment();
    const YEAR: number = Number(NOW.format('YYYY')) || 0;
    const MONTH: number = Number(NOW.format('MM')) || 0;
    const DAY: number = Number(NOW.format('DD')) || 0;
    // console.log(MONTH, YEAR, DAY);  
    this.anoRef?.setValue(YEAR);
    // this.mesRef?.setValue(MONTH);
    // this.diaRef?.setValue(DAY);
  }
  //#endregion

  //#region functions
  #mes2Val(mes: string): string {
    const POS: number = MESES_NOME_LONGO.indexOf(mes);
    // console.log(POS);
    return POS >= 0 ? String(POS + 1) : mes;
    // return !Number(mes) ? String(MESES_NOME_LONGO.indexOf(mes + 1)) : mes;
  }

  #tot2Serie(tot: IMovPedidoTotMap, val: TVal): number {
    // console.log(JSON.stringify(tot));
    let ret: number = 0;

    const QP: number = Number(tot?.qtde?.pedidos) || 0;
    const VP: number = Number(tot?.valor?.prod) || 0;
    const TM: number = VP / QP;

    const QI: number = Number(tot?.qtde?.itens) || 0;
    const QV: number = Number(tot?.qtde?.volumes) || 0;

    const ST: number = Number(tot?.servicos?.val) || 0;
    const SL: number = Number(tot?.servicos?.loja) || 0;
    const SC: number = Number(tot?.servicos?.cliente) || 0;

    const TE: number = Number(tot?.taxaEntrega) || 0;

    switch (val) {
      case 'QP':
        ret = QP;
        break;

      case 'VP':
        ret = VP;
        break;

      case 'TM':
        ret = TM;
        break;

      case 'QI':
        ret = QI;
        break;

      case 'QV':
        ret = QV;
        break;

      case 'ST':
        ret = ST;
        break;

      case 'SL':
        ret = SL;
        break;

      case 'SC':
        ret = SC;
        break;

      case 'TE':
        ret = TE;
        break;
    } // switch

    return ret;
  }
  //#endregion

  //#region methods
  onPedidoClick(p: IPedido) {
    this.pedido$.emit(p);
  }
  //#endregion

  //#region methods valStorageKey()
  #chartValGet(): TVal {
    return this.#cdStorageServ?.ls_get(this.valStorageKey());
  }

  #chartValSet(chartVal: TVal): TVal {
    if (!!chartVal) {
      this.#cdStorageServ?.ls_set(
        this.valStorageKey(),
        chartVal
      );
      return this.#chartValGet();
    } else {
      return this.#chartValRemove();
    } // else
  }

  #chartValRemove(): TVal {
    this.#cdStorageServ?.ls_remove(this.valStorageKey());
    return this.#chartValGet();
  }
  //#endregion
}
