//#region ng
import {
  Component,
  Input,
  signal
} from '@angular/core';
//#endregion

//#region models
// import { THUMB_NO_IMG } from '../../../../../_core/_misc/_models/consts';
import { THUMB_NO_IMG } from '../../../../_core/_misc/_models/consts';
//#endregion

@Component({
  selector: 'me-html-departamentos-skel',
  templateUrl: './departamentos-skel.component.html',
  styleUrls: ['./departamentos-skel.component.scss']
})
export class MeHtmlDepartamentosSkelComponent {
  //#region inputs
  // itemsPerPage
  @Input() itemsPerPage: number = 24;
  //#endregion

  //#region publics
  imgPlaceholder = signal<string>(THUMB_NO_IMG).asReadonly();
  //#endregion

  //#region functions
  tid(index: number, item: any): number { return index; }
  //#endregion
}
