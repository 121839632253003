//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import Fuse from 'fuse.js';
import {
  combineLatest,
  Observable,
  of,
  Subscription,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  map,
  startWith,
  switchMap
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  lojas: ILoja[];
  pag: {
    itemsPerPage: string;
    page: number;
  };
  searchTerm: string;
  viewStyle: TCorViewStyle;
};
import { FOCUS_TIMEOUT } from '../../../../_core/_misc/_models/consts';
import { TCorViewStyle } from '../../../../_core/_misc/_models/_types';
import { ILoja } from '../../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { LojasService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'me-mat-lojas',
  templateUrl: './lojas.component.html',
  styleUrls: ['./lojas.component.scss']
})
export class MeMatLojasComponent {
  //#region inputs
  // redes
  #lojas: ILoja[];
  get lojas(): ILoja[] { return this.#lojas; }
  @Input({ required: true }) set lojas(val: ILoja[]) {
    this.#lojas = !!val ? this.#lojasServ.fixes(val) : [];
    // console.log(this.lojas);
    this.searchTermAction$().next('');
    this.focus();
  }
  // idSelected
  @Input() idSelected: string = '';
  // viewStyleStorageKey
  @Input() viewStyleStorageKey: string = 'KEY_VIEWSTYLE#LOJAS';
  // offsetStorageKey
  @Input() offsetStorageKey: string = 'KEY_OFFSET#LOJAS';
  // itemsPerPage
  @Input() itemsPerPage: string = '';
  // checkBtnHint
  #checkBtnHint: string = '';
  get checkBtnHint(): string { return this.#checkBtnHint; }
  @Input() set checkBtnHint(val: string) {
    this.#checkBtnHint = val;
    this.#verificaBotoes();
  }
  // deleteBtnHint
  _deleteBtnHint: string = '';
  get deleteBtnHint(): string { return this._deleteBtnHint; }
  @Input() set deleteBtnHint(val: string) {
    this._deleteBtnHint = val;
    this.#verificaBotoes();
  }
  // editBtnHint
  #editBtnHint: string = '';
  get editBtnHint(): string { return this.#editBtnHint; }
  @Input() set editBtnHint(val: string) {
    this.#editBtnHint = val;
    this.#verificaBotoes();
  }
  // searchBtnHint
  #searchBtnHint: string = '';
  get searchBtnHint(): string { return this.#searchBtnHint; }
  @Input() set searchBtnHint(val: string) {
    this.#searchBtnHint = val;
    this.#verificaBotoes();
  }
  //#endregion

  //#region outputs
  @Output() check$: EventEmitter<ILoja> = new EventEmitter<ILoja>();
  @Output() delete$: EventEmitter<ILoja> = new EventEmitter<ILoja>();
  @Output() edit$: EventEmitter<ILoja> = new EventEmitter<ILoja>();
  @Output() search$: EventEmitter<ILoja> = new EventEmitter<ILoja>();
  //#endregion

  //#region actions
  pagPageAction$ = signal<Subject<number>>(new Subject<number>()).asReadonly();
  pagItemsPerPageAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  searchTermAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  viewStyleAction$ = signal<Subject<TCorViewStyle>>(new Subject<TCorViewStyle>()).asReadonly();
  //#endregion

  //#region publics
  hasButtons = signal<boolean>(false);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #hits$: Observable<ILoja[]>;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #lojasServ = inject(LojasService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#hits$ = this.searchTermAction$()
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(
          (searchTerm: string) => {
            // console.log(searchTerm);

            // fuse-js
            if (!!searchTerm.trim()) {
              const FUSE: any = new Fuse(
                this.lojas,
                {
                  includeScore: false,
                  // findAllMatches: true,
                  keys: [
                    // '_op',
                    'nome',
                  ],
                  // minMatchCharLength: 3,
                }
              );
              const HITS: any[] = FUSE.search(searchTerm);
              console.log(HITS);
              return of(
                this.#lojasServ.fixes(
                  (HITS || [])
                    .map(h => h?.item as ILoja)
                ) as ILoja[]
              );
            } // if
            return of(this.#lojasServ.fixes(this.lojas) as ILoja[]);
          }
        )
      );

    this.vm$.set(
      combineLatest([
        this.searchTermAction$().pipe(startWith('')),
        this.#hits$.pipe(startWith(this.lojas)),
        this.viewStyleAction$().pipe(startWith('cards')),
        this.pagPageAction$().pipe(startWith(1)),
        this.pagItemsPerPageAction$().pipe(startWith(this.itemsPerPage || '12')),
      ])
        .pipe(
          map(([searchTerm, hits, viewStyle, pagPage, pagItemsPerPage]) => {
            // console.log(this.#lojas);
            // console.log(pagItemsPerPage);
            const VM: IVm = {
              lojas: hits || [],
              pag: {
                itemsPerPage: pagItemsPerPage,
                page: pagPage
              },
              searchTerm,
              viewStyle: viewStyle as TCorViewStyle,
            };
            // console.log(VM);
            return VM;
          }),
        )
    );
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  #verificaBotoes() {
    this.hasButtons.set(
      !!`${this.#checkBtnHint.trim()}${this._deleteBtnHint.trim()}${this.#editBtnHint.trim()}${this.#searchBtnHint.trim()}`
    );
  }

  focus() {
    setTimeout(
      () => { this.searchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  tid_loj(index: any, item: ILoja): string { return item?.id || ''; }
  //#endregion
}
