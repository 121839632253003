//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
//#endregion

//#region models
import { ITerminal } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { TerminaisService } from '../_services';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class TerminaisStore {
  // #region terminalState
  #terminalState: ITerminal;
  setState(val: ITerminal) {
    // console.log(val);
    this.#terminalState = !!val ? this.#terminaisServ?.fix(val) : null;
    this.#terminalStateChanged$.next(this.#terminalState);
  }
  getState(): ITerminal {
    return this.#terminalState;
  }
  // #endregion

  //#region events
  // terminalStateChanged$
  #terminalStateChanged$ = new BehaviorSubject<ITerminal | null>(null);
  terminalStateChanged$: Observable<ITerminal | null> = this.#terminalStateChanged$?.asObservable();
  //#endregion

  //#region injects
  #terminaisServ = inject(TerminaisService);
  //#endregion
}