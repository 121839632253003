//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  // docData,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
} from 'rxjs';
import {
  catchError,
  map
} from "rxjs/operators";
//#endregion

//#region libs
import { checkImg } from '../../../_libs/_misc/_imgs';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import { IFormaPgto } from '../../_misc/_models/_interfaces/_cols';
import {
  ODOCS_TAG,
  IMG_NO_IMG
} from '../../../_core/_misc/_models/consts';
import { TFormasPgto } from '../../_misc/_models/_types';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class FormasPgtoService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IFormaPgto>): IFormaPgto {
    // const IMG: string = row?._img || '';
    const IMG_LINK: string = row?.imgLink || '';
    const TIPO: TFormasPgto | null = row?._tipo || null;
    return {
      // id
      id: row?.id || '',
      imgLink: IMG_LINK,

      // system
      _criadoEm: row?._criadoEm || null,
      _img: row?._img || IMG_NO_IMG,
      _nome: row?._nome || '',
      _tipo: TIPO,

      // realtime
      __distribuidora: !!row?.__distribuidora,
      __formas: row?.__formas || [],
      __img: IMG_LINK || row?.__img || IMG_NO_IMG,
      __sel: {
        entrega: !!row?.__sel?.entrega,
        retirada: !!row?.__sel?.retirada,
        distribuidora: !!row?.__sel?.distribuidora,
      },
      // __possuiBandeiras: ,
      // __permiteTroco: ,
      __permiteTroco: !!row?.__permiteTroco,
      __possuiBandeiras: !!row?.__possuiBandeiras,
      __trocoPara: Number(row?.__trocoPara) || 0,
    };

    // const IMG_LINK: string = row?.imgLink || '';
    // if (!IMG_LINK) {
    //   const IMG: string = !!row?._img
    //     ? `${environment.firebase.mercadeiro.storage.root}formas-pgto%2f${row?._img}.svg?alt=media`
    //     : '';
    //   checkImg(
    //     IMG,
    //     () => R.__img = IMG,
    //     () => R.__img = IMG_NO_IMG
    //   );
    // } // if
  }

  lfix(row: Partial<IFormaPgto>): Promise<TNullable<IFormaPgto>> {
    // const R: IFormaPgto = this.fix(row);
    return new Promise(
      (resolve, reject) => {
        const FORMA_ID: string = row?.id || '';
        if (!FORMA_ID) {
          resolve(null); // )reject(new Error('Nenhuma rede indicada.'));
        } else {
          const IMG_LINK: string = row?.imgLink || '';
          const IMG: string = !!row?._img
            ? `${environment.firebase.mercadeiro.storage.root}/formas-pgto%2f${row?._img}.svg?alt=media`
            : '';
          if (!IMG_LINK) {
            // console.log(IMG);
            checkImg(
              IMG,
              // () => { console.log(IMG); resolve(this.fix({ ...row, __logo: IMG })) },
              () => resolve(this.fix({ ...row, __img: IMG })),
              () => resolve(this.fix({ ...row, __img: IMG_NO_IMG })),
            );
          } else {
            resolve(this.fix(row));
          } // else
        } // else
      }
    );
  }

  fixes(docs: Partial<IFormaPgto>[]): IFormaPgto[] {
    return (docs || []).map((doc: Partial<IFormaPgto>) => this.fix(doc));
  }

  lfixes(docs: Partial<IFormaPgto>[]): Observable<IFormaPgto[]> {
    // console.log(docs);
    const PROMISES: Promise<IFormaPgto>[] = (docs || [])
      .map((doc: Partial<IFormaPgto>) => this.lfix(doc));
    return from(Promise.all(PROMISES));
  }
  //#endregion

  //#region R
  docs(): Observable<IFormaPgto[]> {
    const PATH: string = `_formas-pgto/${ODOCS_TAG}`;
    // console.log(PATH);      
    // return (docData(doc(this.#db, PATH)) as Observable<IFormaPgto[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}))),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<IFormaPgto[], ObservableInput<IFormaPgto[]>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando formas de pgto.')
        )
      );
  }
  //#endregion
}
