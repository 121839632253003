//#region ng
import {
  Component,
  EventEmitter,
  Inject,
} from '@angular/core';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

//#region meilisearch
import { MeiliSearch } from 'meilisearch';
//#endregion

//#region 3rd
import {
  first,
  finalize
} from 'rxjs/operators';
import { Subscription } from 'rxjs';
//#endregion

//#region models
import {
  ILoja,
  IMeilisearch,
  IProduto,
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  MeilisearchService,
  ProdutosService,
} from '../../_shared/_mercadeiro/_ng/_services';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
//#endregion

//#region libs
import { calcMeiliNomeIndice } from '../../_shared/_mercadeiro/_misc/_libs';
//#endregion

@Component({
  selector: 'app-produto-add-modal',
  templateUrl: './produto-add.modal.html',
  styleUrls: ['./produto-add.modal.scss']
})
export class ProdutoAddModal {
  //#region publics
  page: number;
  produtos: IProduto[];
  //#endregion

  //#region filter
  private _filter: string;
  get filter(): string { return this._filter; }
  set filter(val: string) {
    this._filter = val;
    this._refresh();
  }
  //#endregion

  //#region loja
  private _loja: Partial<ILoja>;
  set loja(val: Partial<ILoja>) {
    this._loja = val;
    const LOJA_ID: string = val?.id || '';
    const MEILISEARCH_ID: string = val?.meilisearch?.id || '';
    // console.log(MEILISEARCH_ID);
    this._nomeIndice = calcMeiliNomeIndice(LOJA_ID || '', 'produtos');
    // this._lojaPath = this.loja?.__idInfo?.lojaPath || '';

    if (!!MEILISEARCH_ID) {
      const SUB: Subscription = this._meilisearchServ.doc(MEILISEARCH_ID)
        .pipe(
          first(),
          finalize(() => SUB && SUB.unsubscribe())
        )
        .subscribe(
          (meili: IMeilisearch) => {
            // console.log(meili);
            const MEILI: Partial<IMeilisearch> = this._meilisearchServ.fix(meili);
            // console.log(MEILI);
            const { host: HOST, searchKey: API_KEY } = MEILI;
            // console.log(HOST, API_KEY);
            this._meilisearchClient = new MeiliSearch(
              {
                host: HOST || '',
                apiKey: API_KEY || ''
              }
            );
            this._refresh();
          },
          // err => console.error(err)
        );
    } // if
  }
  get loja(): Partial<ILoja> {
    return this._loja;
  }
  //#endregion

  //#region privates
  private _meilisearchClient: MeiliSearch;
  private _nomeIndice: string;
  //#endregion

  //#region events
  searchFocusEvent$: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ProdutoAddModal>,
    private _produtosServ: ProdutosService,
    private _meilisearchServ: MeilisearchService,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.loja = this.data?.loja || null;
  }

  ngAfterViewInit() {
    this._focus();
  }
  //#endregion

  //#region functions
  tid_pro(index: any, item: IProduto): string { return item?.id || ''; }

  private _focus() {
    setTimeout(
      () => this.searchFocusEvent$.emit(true),
      FOCUS_TIMEOUT
    );
  }

  private _refresh() {
    if (
      !!this._meilisearchClient
      && !!this._nomeIndice
    ) {
      // console.log(FILTERS);
      this._meilisearchClient
        .index(this._nomeIndice)
        .search(
          this.filter,
          {
            // filter: FILTERS,
            limit: 1000
          }
        )
        .then(
          (res) => {
            this.produtos = this._produtosServ.fixes((res?.hits || []) as IProduto[]);
          });
    } // if
  }
  //#endregion

  //#region methods
  onModalCloseClick(val: IProduto | null) {
    this._dialogRef.close(val);
  }

  onProdutoClick(p: IProduto) {
    // console.log(p);
    this.onModalCloseClick(p);
  }
  //#endregion
}
