<!-- <p>pedido() {{ pedido() | json }}</p> -->

<ng-container *ngIf="vm$() | async as vm">
  <div class="box"
       *ngIf="!!pedido()">

    <!-- <p>tabPedidoAction$() | async {{ tabPedidoAction$() | async }}</p> -->
    <!-- <p>vm {{ vm | json }}</p> -->
    <!-- <p>vm?.splitVertical {{ vm?.splitVertical | json }}</p> -->
    <!-- <p>vm?.viewStyle {{ vm?.viewStyle | json }}</p> -->
    <!-- <p>pedido() {{ pedido() | json }}</p> -->
    <!-- <p>pedido()?._status {{ pedido()?._status }}</p> -->
    <!-- <p>pedido()?._percSeparado {{ pedido()?._percSeparado }}</p> -->
    <!-- <p>pedido()?.__actions {{ pedido()?.__actions | json }}</p> -->
    <!-- <p>pedido()?.carrinho?.separado?.qtde?.volumes {{ pedido()?.carrinho?._separado?.qtde?.volumes | json }}</p> -->
    <!-- <p>pedido()?.obsSeparacao {{ pedido()?.obsSeparacao | json }}</p> -->
    <!-- <p>pedido()?.obsSeparacao {{ pedido()?.obsSeparacao | json }}</p> -->
    <!-- <p>pedido()?._isModificado {{ pedido()?._isModificado }}</p> -->

    <div class="d-flex">
      <div class="d-flex flex-1">
        <div class="card-pedido card text-bg-light me-2"
             *ngIf="!!vm?.showInfo">
          <div class="card-header d-flex justify-content-between">
            <div class="my-auto">
              <button mat-icon-button
                      matTooltip="Voltar para pedidos"
                      color="light"
                      routerLink="/pedidos">
                <span class="icon-b-arrow-l"></span>
              </button>
            </div>

            <div class="my-auto me-3">
              <small><span class="o-50">Pedido #</span><strong>{{ this.pedido()?.id | numPedido }}</strong></small>
            </div>
          </div>

          <div class="card-body">
            <div class="flex-1">
              <div class="segment mb-4">
                <ul class="nav justify-content-center nav-pills">
                  <li class="nav-item"
                      (click)="tabPedidoAction$().next('status')">
                    <a class="nav-link"
                       [class.active]="vm?.tab === 'status'">
                      Status
                    </a>
                  </li>

                  <li class="nav-item"
                      (click)="tabPedidoAction$().next('totais')">
                    <a class="nav-link"
                       [class.active]="vm?.tab === 'totais'">
                      Totais
                    </a>
                  </li>

                  <li class="nav-item"
                      (click)="tabPedidoAction$().next('detalhes')">
                    <a class="nav-link"
                       [class.active]="vm?.tab === 'detalhes'">
                      Detalhes
                    </a>
                  </li>
                </ul>
              </div>

              <div [ngSwitch]="vm?.tab">
                <div *ngSwitchCase="'totais'">
                  <me-mat-pedido-info-totais [pedido]="pedido()" />
                </div>

                <div *ngSwitchCase="'detalhes'">
                  <me-mat-pedido-info-detalhes [pedido]="pedido()" />
                </div>

                <div *ngSwitchDefault>
                  <me-html-pedido-timeline [idPedido]="pedido()?.id || ''"
                                           [lojaPath]="loja()?.__idInfo?.lojaPath"
                                           [showOperadores]="true" />

                  <mat-accordion>
                    <mat-expansion-panel [expanded]="pedido()?._status === 'aguardandoSeparacao'">
                      <mat-expansion-panel-header [class.wiggle]="pedido()?._status === 'aguardandoSeparacao'">
                        <mat-panel-title>
                          Aguardando separação
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex flex-column">
                        <div class="px-2">
                          <mat-form-field appearance="outline"
                                          floatLabel="always"
                                          class="w-100">
                            <mat-label>Obs separação</mat-label>
                            <input matInput
                                   [readonly]="pedido()?._status !== 'emSeparacao'"
                                   [(ngModel)]="obsSeparacao">
                            <!-- pedido().obsSeparacao -->
                            <button matSuffix
                                    color="primary"
                                    matTooltip="Gravar obs separação"
                                    mat-icon-button
                                    style="position: relative; top: -8px;"
                                    (click)="onGravaObsSeparacaoClick()"
                                    *ngIf="obsSeparacao !== pedido()?.obsSeparacao">
                              <span class="icon-o-save"></span>
                            </button>
                          </mat-form-field>
                        </div>

                        <button mat-stroked-button
                                class="flex-1 m-2"
                                color="light"
                                (click)="onIniciarSeparacaoClick()"
                                [disabled]="!pedido()?.__actions?.canIniciarSeparacao">
                          <span class="icon-b-shopping-cart o-50"></span>&nbsp;Iniciar separação
                        </button>
                      </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="pedido()?._status === 'emSeparacao'">
                      <mat-expansion-panel-header [class.wiggle]="pedido()?._status === 'emSeparacao'">
                        <mat-panel-title>
                          Em separação
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex flex-column">
                        <!-- <button mat-stroked-button
                                class="flex-1 m-2"
                                color="light"
                                [disabled]="!pedido()?.__actions?.canRelatorioModificacoes"
                                (click)="onRelatorioModificacoesClick()">
                          <span class="icon-b-edit o-50"></span>&nbsp;Relatório modificações
                        </button> -->

                        <button mat-stroked-button
                                class="flex-1 m-2"
                                color="light"
                                [disabled]="!pedido()?.__actions?.canConcluirSeparacao"
                                (click)="onConcluirSeparacaoClick()">
                          <span class="icon-b-shopping-cart-checked o-50"></span>&nbsp;Concluir separação
                        </button>
                      </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel
                                         [expanded]="['aguardandoEntrega', 'aguardandoRetirada', 'emEntrega'].includes(pedido()?._status)">
                      <mat-expansion-panel-header
                                                  [class.wiggle]="['aguardandoEntrega', 'aguardandoRetirada', 'emEntrega'].includes(pedido()?._status)">
                        <mat-panel-title>
                          Finalizar
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex flex-column">
                        <button mat-stroked-button
                                class="flex-1 m-2"
                                color="light"
                                [disabled]="!pedido()?.__actions?.canConcluirPedido"
                                (click)="onConcluirPedidoClick()">
                          <span class="icon-b-check o-50"></span>&nbsp;Concluir pedido
                        </button>

                        <button mat-stroked-button
                                class="flex-1 m-2"
                                color="light"
                                [disabled]="!pedido()?.__actions?.canCancelarPedido"
                                (click)="onCancelarPedidoClick()">
                          <span class="icon-b-cancel o-50"></span>&nbsp;Cancelar pedido
                        </button>
                      </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Relatórios
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex flex-column">
                        <button mat-stroked-button
                                class="flex-1 m-2"
                                color="light"
                                [disabled]="!pedido()?.__actions?.canRelatorioSeparacao"
                                (click)="onRelatorioSeparacaoClick()">
                          <span class="icon-r-transfer o-50"></span>&nbsp;Relatório separação
                        </button>

                        <!-- <button mat-stroked-button
                                class="flex-1 m-2"
                                color="light"
                                [disabled]="!pedido()?._isModificado"
                                (click)="onRelatorioModificacoesClick()">
                          <span class="icon-b-edit o-50"></span>&nbsp;Relatório modificações
                        </button> -->

                        <button mat-stroked-button
                                class="flex-1 m-2"
                                color="light"
                                [disabled]="!pedido()?.__actions?.canRelatorioSeparado"
                                (click)="onRelatorioSeparadoClick()">
                          <span class="icon-r-check o-50"></span>&nbsp;Relatório separado
                        </button>
                      </div>
                    </mat-expansion-panel>

                    <!-- <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Downloads
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex flex-column">
                      <button mat-stroked-button
                              class="flex-1 m-2"
                              color="light"
                              (click)="onRelatorioSeparacaoClick()"
                              [disabled]="pedido()?._status === 'aguardandoSeparacao'">
                        <span class="icon-graph-rows o-50"></span>&nbsp;Relatório separação
                      </button>

                      <button mat-stroked-button
                              class="flex-1 m-2"
                              color="light"
                              [disabled]="['aguardandoSeparacao', 'emSeparacao'].includes(pedido()?._status)">
                        <span class="icon-copy o-50"></span>&nbsp;Relatório separado
                      </button>
                    </div>
                    </mat-expansion-panel> -->
                  </mat-accordion>
                </div>
              </div>
            </div>

            <!--
            <div [ngSwitch]="tabPedido">
              <div *ngSwitchCase="'totais'">
                <me-mat-pedido-info-totais [pedido]="pedido"></me-mat-pedido-info-totais>
              </div>

              <div *ngSwitchCase="'detalhes'">
                <me-mat-pedido-info-detalhes [pedido]="pedido"></me-mat-pedido-info-detalhes>
              </div>

              <div *ngSwitchDefault>
                <me-html-pedido-timeline [idPedido]="pedidoId"
                                         [lojaPath]="lojaPath"
                                         [showOperadores]="true" />
              </div>
            </div>
          </div> -->
          </div>
        </div>

        <div class="card-itens flex-1 card text-bg-light">
          <h5 class="card-header d-flex justify-content-between">
            <div class="my-auto">
              <button mat-icon-button
                      [matTooltip]="!vm?.showInfo ? 'Mostar informações' : 'Ocultar informações'"
                      color="light"
                      (click)="showInfoAction$().next(!vm?.showInfo)">
                <small class="{{!vm?.showInfo ? 'icon-o-sidebar-on' : 'icon-o-sidebar-off'}} o-50"></small>
              </button>

              <button mat-icon-button
                      [matTooltip]="!!vm?.splitVertical ? 'Divisão horizontal' : 'Divisão vertical'"
                      color="light"
                      (click)="splitVerticalAction$().next(!vm?.splitVertical)"
                      *ngIf="pedido()?._status === 'emSeparacao' && pedido()?._percSeparado < 100">
                <small class="{{!!vm?.splitVertical ? 'icon-b-double-arrow-h' : 'icon-b-double-arrow-v'}} o-50"></small>
              </button>
            </div>

            <div class="my-auto flex-1">
              Itens do pedido
            </div>

            <div class="ms-auto my-auto"
                 *ngIf="pedido()?._status === 'emSeparacao'">
              <mat-button-toggle-group name="viewStyle"
                                       [ngModel]="vm?.viewStyle"
                                       (ngModelChange)="onViewStyleClick($event)">
                <!-- <mat-button-toggle value="auto"
                                   matTooltip="Automático">
                  <div class="icon-o-robot"></div>
                </mat-button-toggle> -->

                <mat-button-toggle value="table"
                                   matTooltip="Tabela">
                  <div class="icon-o-list"></div>
                </mat-button-toggle>

                <mat-button-toggle value="cards"
                                   matTooltip="Cartões">
                  <div class="icon-o-grid-small"></div>
                </mat-button-toggle>

                <!-- <mat-button-toggle value="grade"
                                   matTooltip="Grade">
                  <div class="icon-o-grid-big"></div>
                </mat-button-toggle> -->
              </mat-button-toggle-group>
            </div>

            <div class="my-auto">
              <div class="input-group ms-2">
                <input type="text"
                       class="form-control"
                       id="search"
                       [focus]="searchFocusEvent$()"
                       placeholder="Produto ?"
                       [ngModel]="vm?.searchTerm"
                       (ngModelChange)="searchTermAction$().next($event)">
                <button class="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                        [disabled]="!vm?.searchTerm"
                        (click)="searchTermAction$()?.next('')">
                  <span class="icon-b-cancel"></span>
                </button>
              </div>
            </div>

            <div class="my-auto ms-2">
              <button mat-icon-button
                      matTooltip="Atualiza pedido"
                      (click)="onRefreshClick()">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </h5>

          <div class="card-body d-flex">
            <div *ngIf="pedido()?._status === 'emSeparacao'; else naoSeparando"
                 class="flex-1">
              <itens-separacao [searchTerm]="vm?.searchTerm"
                               [splitVertical]="vm?.splitVertical"
                               [viewStyle]="vm?.viewStyle"
                               [pedido]="pedido()" />
            </div>
            <ng-template #naoSeparando>
              <div class="w-100">
                <me-html-pedido-itens [searchTerm]="vm?.searchTerm"
                                      [pedido]="pedido()"
                                      [isMobile]="false" />
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>