<!-- <p>statusPedido {{ statusPedido | json }}</p> -->
<!-- <p>pedido?._status {{ pedido?._status }}</p> -->
<!-- <p>pedido?.__actions {{ pedido?.__actions | json }}</p> -->
<!-- <p>pedido {{ pedido | json }}</p> -->

<div *ngIf="!!pedido"
     class="w-card pedido d-flex flex-column h-100">
  <div class="w-card--content">
    <div class="head d-flex">
      <div>
        <span class="badge bg-light"
              [class.p1]="!isMobile"
              [class.p2]="!!isMobile">
          <small class="o-50">#</small><span [class.rem-11]="!isMobile">{{ pedido?.id | numPedido }}</span>
        </span>
        <br>
        <small class="o-50"> {{ pedido?._criadoEm?.toDate() | date: 'dd/MM/yyyy HH:mm' }}</small>
      </div>

      <div class="ms-auto">
        <span class="badge bg-{{statusPedido()?.theme}} p-2">
          {{ statusPedido()?.caption }}
        </span>
        <br>
        <span *ngIf="pedido?._status !== 'aguardandoSeparacao'">
          <small class="o-50"> {{ pedido?.__statusInfo?._timestamp?.toDate() | date: 'dd/MM/yyyy HH:mm' }}</small>
        </span>
      </div>
    </div>

    <!-- <hr> -->

    <div>
      <span class="primary shade">{{ pedido?.conta?.__nomeCompleto }}</span>
    </div>

    <div>
      <strong class="primary tint">
        {{ pedido?.carrinho?.__loja?.isRetirada ? 'RETIRADA' : 'ENTREGA' }}
      </strong>&nbsp;<small class="o-50">(
        {{ pedido?._info?.horario?.val }} )</small>
    </div>

    <div>
      <div class="badge bg-light p-2 rem-12">
        <div *ngIf="!!pedido?._isSeparado; else naoSeparadoTotal">
          <small class="o-40 line-through me-1">
            {{ pedido?.carrinho?.__solicitado?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}
          </small>
          <!-- {{ pedido?.__total?.totalPagar | currency: "BRL":"symbol":"1.2-2" }} -->
          {{ pedido?.carrinho?._separado?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}
        </div>
        <ng-template #naoSeparadoTotal>
          {{ pedido?.carrinho?.__solicitado?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}
        </ng-template>
      </div>
      <small class="o-50">( {{ pedido?._info?.formaPgto?.val }} )</small>
    </div>

    <div class="d-flex text-center"
         *ngIf="!!pedido?._isSeparado; else naoSeparado">
      <div class="flex-1">{{ pedido?.carrinho?._separado?.qtde?.itens }}<small class="o-50">
          item(ns)</small>
      </div>
      <div class="flex-1">{{ pedido?.carrinho?._separado?.qtde?.volumes }}<small class="o-50"> vol(s)</small></div>
      <!-- <div class="flex-1"> <small class="o-50 line-thru">{{ pedido?.carrinho?.__solicitado?.qtde?.itens
          }}</small> / {{ pedido?.carrinho?._separado?.qtde?.itens }} <small class="o-50"> item(ns)</small></div>
      <div class="flex-1"><small class="o-50 line-thru">{{ pedido?.carrinho?.__solicitado?.qtde?.volumes }}</small> / {{
        pedido?.carrinho?._separado?.qtde?.volumes }} <small class="o-50"> vol(s)</small></div> -->
    </div>
    <ng-template #naoSeparado>
      <div class="d-flex text-center">
        <div class="flex-1">{{ pedido?.carrinho?.__solicitado?.qtde?.itens }}<small class="o-50">
            item(ns)</small>
        </div>
        <div class="flex-1">{{ pedido?.carrinho?.__solicitado?.qtde?.volumes }}<small class="o-50"> vol(s)</small></div>
      </div>
    </ng-template>
  </div>

  <!-- <hr> -->

  <div class="w-card--footer d-flex gap-1 mt-auto pt-2">
    <button mat-flat-button
            matTooltip="Repetir pedido"
            class="flex-1"
            color="light"
            *ngIf="!isMonitor"
            (click)="repetirPedido$.emit(pedido)">
      <span class="icon-r-redo o-50"></span><span *ngIf="!isMobile">&nbsp;Repetir pedido</span>
    </button>

    <button mat-flat-button
            matTooltip="Detalhes"
            class="flex-1"
            color="light"
            (click)="detalhes$.emit(pedido)">
      <span class="icon-r-search o-50"></span><span *ngIf="!isMobile">&nbsp;Detalhes</span>
    </button>

    <button mat-flat-button
            matTooltip="Iniciar separação"
            class="flex-1"
            color="light"
            (click)="iniciarSeparacao$?.emit()"
            *ngIf="!!isMonitor"
            [disabled]="!pedido?.__actions?.canIniciarSeparacao">
      <span class="
            icon-b-shopping-cart
            o-50"></span>
    </button>

    <button mat-flat-button
            matTooltip="Concluir separação"
            class="flex-1"
            color="light"
            (click)="concluirSeparacao$?.emit()"
            *ngIf="!!isMonitor"
            [disabled]="!pedido?.__actions?.canConcluirSeparacao">
      <span class="icon-b-shopping-cart-checked o-50"></span>
    </button>

    <button mat-flat-button
            matTooltip="Concluir pedido"
            class="flex-1"
            color="light"
            (click)="concluirPedido$?.emit()"
            *ngIf="!!isMonitor"
            [disabled]="!pedido?.__actions?.canConcluirPedido">
      <span class="icon-b-check o-50"></span>
    </button>
  </div>

  <mat-progress-bar mode="determinate"
                    matTooltip="{{ pedido?._percSeparado }}% separado"
                    [value]="pedido?._percSeparado"
                    class="mt-1"
                    *ngIf="!!isMonitor">
  </mat-progress-bar>
</div>