//#region ng
import { Injectable } from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  throwError
} from 'rxjs';
//#endregion

//#region models
import { ICorMessage } from '../../_misc/_models/_interfaces/_misc/message';
import { TCorBsTheme } from '../../_misc/_models/_types';
import { ICorMessageScope } from '../../_misc/_models/_interfaces/_misc';
//#endregion

@Injectable(
  { providedIn: 'root' }
)
export class CorMessagesService {
  //#region events
  #messageSubject$ = new BehaviorSubject<ICorMessage | null>(null);
  msgChanged$: Observable<ICorMessage | null> = this.#messageSubject$?.asObservable();
  //#endregion

  //#region methods
  send(
    msg: string = '',
    theme: TCorBsTheme = 'success',
    scope: ICorMessageScope = 'all'
  ) {
    // console.log(msg, theme, scope);
    this.#messageSubject$.next(!!msg ? { msg, theme, scope } : null);
  }

  clear() {
    this.send();
  }

  onCatchError(
    err: any,
    msg: string,
    theme: TCorBsTheme = 'danger',
    scope: ICorMessageScope = 'all'
  ): Observable<any> {
    // console.log(msg, theme, scope, err);
    this.send(msg, theme, scope);
    console.error(err);
    return throwError(() => err);
  }
  //#endregion
}
