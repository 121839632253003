//#region 3rd
// import { set } from 'lodash';
//#endregion

//#region models
import {
  // ICarrinhoInfoMap,
  IItemCarrinhoInfoMap,
  IItemCarrinhoMap,
  IPedidoDeptoInfoMap,
} from '../_models/_interfaces/_maps';
// import { IProduto } from '../_models/_interfaces/_cols';
import { TNullable } from '../../../_core/_misc/_models/_types';
import { TItemCarrinhoStatus } from '../_models/_types';
import { IProduto } from '../_models/_interfaces/_cols';
//#endregion

//#region libs
import {
  compareValues,
  uniqueListBy,
} from '../../../_libs/_misc/_arrays';
import {
  calcQtdeFracionado,
  isQtdeMin,
} from '.';
//#endregion

export function calcItemCarrinhoStatus(
  qtdeSolicitada: number,
  qtdeEncontrada: TNullable<number>,
  qtdeSeparada: TNullable<number>
): TItemCarrinhoStatus {
  /* console.log(
    {
      qtdeSolicitada,
      qtdeEncontrada,
      encontradaOk: qtdeEncontrada !== null,
      qtdeSeparada,
      separadaOk: qtdeSeparada !== null,
    }
  ); */
  return qtdeEncontrada !== null
    ? qtdeEncontrada === 0
      ? 'naoEncontrado'
      : qtdeSeparada !== null
        ? qtdeSeparada === 0
          ? 'removido'
          : qtdeSolicitada === 0
            ? 'adicionado'
            : qtdeSolicitada === qtdeSeparada
              ? 'confirmado'
              : 'modificado'
        : 'pendente'
    : 'pendente';
}

export function mapItemCarrinhoInfo(
  item: IItemCarrinhoMap,
  qtde: TNullable<number>,
  isSeparado: boolean,
  qtdeSeparada: TNullable<number>
): IItemCarrinhoInfoMap {
  const QTDE: TNullable<number> = !!isSeparado
    ? qtdeSeparada ?? null
    : item?.__solicitado?.qtde?.val ?? null;
  const QTDE_ENCONTRADA: TNullable<number> = !isSeparado
    ? null
    : qtdeSeparada === null
      ? qtde ?? null
      : item?._separado?.qtde?.encontrado ?? qtde;
  const DIF: number = (
    !!isSeparado
      && qtdeSeparada !== null
      && QTDE_ENCONTRADA !== null
      ? qtdeSeparada - QTDE_ENCONTRADA
      : 0
  );
  // !!isSeparado && console.log({ qtde, QTDE, QTDE_ENCONTRADA, qtdeSeparada, DIF });

  const PRODUTO_DESC: number = Number(item?.produto?.preco?.desc) || 0;
  const PRODUTO_PRECO: number = Number(item?.produto?.preco?.produto) || 0;
  const TOT_PROD: number = (PRODUTO_PRECO * (QTDE || 0)) || 0;
  // const TOT_DESC_PROD: number = ((PRODUTO_PRECO - PRODUTO_DESC) * QTDE) || 0;
  const TOT_DESC_PROD: number = item?.produto?.preco?.desc || 0;
  const TOT_DESC_ITEM: number = item?.produto?.__descItem || 0;
  const TOT_DESC: number = TOT_DESC_PROD + TOT_DESC_ITEM;
  // console.log(TOT_PROD, TOT_DESC_PROD, TOT_DESC_ITEM, TOT_DESC);

  return {
    qtde: {
      fixed: QTDE !== null
        ? calcQtdeFracionado(
          item?.produto as IProduto,
          QTDE
        )
        : null,
      val: QTDE,
      encontrado: QTDE_ENCONTRADA,
      __dif: DIF,
      isMin: isQtdeMin(item?.produto as IProduto, QTDE || 0),
    },
    valor: {
      unit: {
        desc: PRODUTO_DESC,
        liq: PRODUTO_PRECO - PRODUTO_DESC,
        val: PRODUTO_PRECO,
      },
      total: {
        desc: TOT_DESC,
        liq: TOT_PROD - TOT_DESC,
        val: TOT_PROD,
      },
    },
  };

  /*

  /*
  if (QTDE !== null) {
    ret.qtde = {
      fixed: calcQtdeFracionado(item?.produto as IProduto, QTDE),
      val: QTDE,
      encontrado: !!isSeparado ? qtdeEncontrada : null,
      __dif: DIF,
      isMin: isQtdeMin(item?.produto as IProduto, QTDE),
    };
    // console.log(ret.qtde);

    const PROD_ITEM_DESC: number = item?.produto?.__descItem || 0;
    const PROD_PRECO_DESC: number = item?.produto?.preco?.desc || 0;
    // console.log(PROD_ITEM_DESC, PROD_PRECO_DESC);    
    const QTDE_FIXED: number = ret?.qtde?.fixed || 0;
    const DESC: number = PROD_ITEM_DESC + (PROD_PRECO_DESC * QTDE_FIXED);
    set(ret,
      'valor.total',
      {
        desc: DESC,
        liq: QTDE_FIXED * PRODUTO_PRECO - DESC,
        val: QTDE_FIXED * PRODUTO_PRECO,
      }
    );
  } // if
  
  let ret: IItemCarrinhoInfoMap = {
    qtde: {
      fixed: calcQtdeFracionado(
        item?.produto as IProduto,
        QTDE || 0
      ),
      val: QTDE,
      encontrado: !!isSeparado
        ? qtdeSeparada === null
          ? item?._separado?.qtde?.encontrado
          : qtde
        : 0,
      __dif: DIF,
      isMin: isQtdeMin(item?.produto as IProduto, QTDE || 0),
    },
    valor: {
      unit: {
        desc: PRODUTO_DESC,
        liq: PRODUTO_PRECO - PRODUTO_DESC,
        val: PRODUTO_PRECO,
      },
      total: {
        desc: TOT_DESC,
        liq: TOT_PROD - TOT_DESC,
        val: TOT_PROD,
      },
    },
  };

  */

  // return ret;
  /*
  // console.log(qtde, qtdeEncontrada);
  const QTDE: number = Number(qtde) || 0;
  

  // console.log(qtde); 
  */

  // console.table(ret?.valor?.total);

  /*
  // console.table(item?.__solicitado);
  console.table(item?.__solicitado?.valor);
  // console.log(qtde, QTDE, qtdeEncontrada, QTDE_SOLICITADA, DIF);

  if (qtde === null) {
    return {
      qtde: {
        fixed: null,
        val: null,
        encontrado: qtdeEncontrada,
        __dif: null,
        isMin: null,
      },
      valor: {
        unit: {
          desc: PRODUTO_DESC,
          liq: PRODUTO_PRECO - PRODUTO_DESC,
          val: PRODUTO_PRECO,
        },
        total: {
          desc: 0,
          liq: 0,
          val: 0,
        },
      },
    }
  } // if

  
  return {
    qtde: {
      fixed: calcQtdeFracionado(item?.produto as IProduto, QTDE),
      val: QTDE,
      encontrado: !!IS_ENCONTRADA ? qtdeEncontrada : null,
      __dif: DIF,
      isMin: isQtdeMin(item?.produto as IProduto, QTDE),
    },
    valor: {
      unit: {
        desc: PRODUTO_DESC,
        liq: PRODUTO_PRECO - PRODUTO_DESC,
        val: PRODUTO_PRECO,
      },
      // total: {
      //   desc: PRODUTO_DESC * QTDE,
      //   liq: (PRODUTO_PRECO - PRODUTO_DESC) * QTDE,
      //   val: PRODUTO_PRECO * QTDE,
      // },
      total: {
        desc: TOT_DESC,
        liq: TOT_PROD - TOT_DESC,
        val: TOT_PROD,
      },
    },
  };
  */
}

/* export function mapCarrinhoInfo(solicitadoItens: IItemCarrinhoMap[] | []): ICarrinhoInfoMap { // calcSolicitadoCarrinho
  const RET: ICarrinhoInfoMap = {
    itens: solicitadoItens,
    qtde: {
      itens: solicitadoItens?.length,
      volumes: 0,
    },
    total: {
      desc: 0,
      liq: 0,
      prod: 0,
    },
  };

  !!solicitadoItens?.length && solicitadoItens
    .forEach(
      (i: IItemCarrinhoMap) => {
        // console.log(i);
        const PROD_PRECO: number = Number(i?.produto?.preco?.produto) || 0;
        const PROD_DESC: number = Number(i?.produto?.preco?.desc) || 0;
        const PROD_LIQ: number = PROD_PRECO - PROD_DESC;
        const ITEM_QTDE: number = Number(i?.__solicitado?.qtde?.fixed) || 0;
        // console.log(ITEM_QTDE, PROD_PRECO, PROD_DESC, PROD_LIQ);

        set(
          RET,
          'qtde.volumes',
          (Number(RET?.qtde?.volumes) || 0) + ITEM_QTDE
        );

        set(
          RET,
          'total.prod',
          (Number(RET?.total?.prod) || 0) + PROD_PRECO * ITEM_QTDE
        );

        set(
          RET,
          'total.desc',
          (Number(RET?.total?.desc) || 0) + PROD_DESC * ITEM_QTDE
        );

        set(
          RET,
          'total.liq',
          (Number(RET?.total?.liq) || 0) + PROD_LIQ * ITEM_QTDE
        );
      }
    );

  // console.log(RET);
  return RET;
} */

// __solicitado: ICarrinhoInfoMap | null;

export function mapPedidoDeptosInfo(items: IItemCarrinhoMap[]): IPedidoDeptoInfoMap[] { // pedidoDeptosInfo
  return uniqueListBy(
    (items || [])
      .map(
        (i: IItemCarrinhoMap) => {
          const NOME_D1: string = i?.produto?.departamentos?.d1?.nome || '';
          const NOME_D2: string = i?.produto?.departamentos?.d2?.nome || '';
          const NOME_D3: string = i?.produto?.departamentos?.d3?.nome || '';
          return {
            id: `${NOME_D1}${NOME_D2}${NOME_D3}`,
            idD1: i?.produto?.departamentos?.d1?.id || '',
            idD2: i?.produto?.departamentos?.d2?.id || '',
            idD3: i?.produto?.departamentos?.d3?.id || '',
            nomeD1: NOME_D1,
            nomeD2: NOME_D2,
            nomeD3: NOME_D3,
            qtdeItens: 0,
            qtdeVols: 0,
          };
        }
      )
      .sort(compareValues('id')),
    'id'
  );
}

export function mapPedidoDeptoItens( // pedidoDeptoItens
  itens: IItemCarrinhoMap[],
  deptoInfo: IPedidoDeptoInfoMap
): IItemCarrinhoMap[] {
  // console.log(d);
  const DEPTO_ID_D1: string = deptoInfo?.idD1 || '';
  const DEPTO_ID_D2: string = deptoInfo?.idD2 || '';
  const DEPTO_ID_D3: string = deptoInfo?.idD3 || '';
  return (itens || [])
    .filter(
      (i: IItemCarrinhoMap) => {
        const PEDIDO_ID_D1: string = i?.produto?.departamentos?.d1?.id || '';
        const PEDIDO_ID_D2: string = i?.produto?.departamentos?.d2?.id || '';
        const PEDIDO_ID_D3: string = i?.produto?.departamentos?.d3?.id || '';
        return PEDIDO_ID_D1 === DEPTO_ID_D1
          && PEDIDO_ID_D2 === DEPTO_ID_D2
          && PEDIDO_ID_D3 === DEPTO_ID_D3;
      }
    )
    .sort(compareValues('produto.nome'));
  // console.log(DEPTO_ITENS);
}
