<ng-container *ngIf="vm$() | async as vm">
  <div *ngIf="!!pedidos; else nenhumItem">
    <div class="d-flex">
      <div class="mx-auto mb-2"
           *ngIf="!!pedidos?.length">
        <cor-mat-ngx-pagination-nav pagId="pedidos"
                                    size="lg"
                                    [isMobile]="false"
                                    (pageChange$)="pagPageAction$().next($event)">
        </cor-mat-ngx-pagination-nav>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Criado em / Nro</th>
          <th scope="col">Loja / Cliente</th>
          <th scope="col">Tipo / Forma pgto</th>
          <th scope="col"
              class="text-center">Itens/Vols / Valor produtos
          </th>
          <th scope="col"
              class="text-center">TS Cli/Loj / Ent
          </th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody>
        <tr
            *ngFor="let p of pedidos | paginate: { id: 'pedidos', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: pedidos?.length }; trackBy:tid_ite ">
          <td>
            <div>
              <small class="o-50"> {{ p?._criadoEm?.toDate() | date: 'dd/MM/yyyy HH:mm' }}</small><br />
              <small><span class="class o-50">#</span>{{ p?.id | numPedido }}</small>
            </div>
          </td>

          <td>
            <div>
              <small>{{ p?._loja?.nome }}</small><br />
              <small>{{ p?._conta?.nomeCompleto }}</small><br />
            </div>
          </td>

          <td>
            <strong class="primary tint">{{ p?._isRetirada ? 'RETIRADA' : 'ENTREGA' }}</strong><br />
            <small>{{ p?._formaPgto?.nome }}</small>
          </td>

          <td class="text-center">
            <small>{{ p?._total?.qtde?.itens }} / {{ p?._total?.qtde?.volumes }}</small><br />
            <strong>{{ p?._total?.valor?.prod | currency: "BRL":"symbol":"1.2-2" }}</strong>
          </td>

          <td class="text-center">
            <small>{{ p?._total?.servicos?.cliente | currency: "BRL":"symbol":"1.2-2" }} / <strong>{{
                p?._total?.servicos?.loja |
                currency: "BRL":"symbol":"1.2-2" }}</strong></small><br />
            <small>{{ p?._total?.taxaEntrega | currency: "BRL":"symbol":"1.2-2" }}</small>
          </td>

          <td>
            <div class="d-flex">
              <div class="ms-auto">
                <button mat-flat-button
                        matTooltip="Detalhes"
                        class="flex-1"
                        color="light"
                        (click)="onDetalhesClick(p)">
                  <span class="icon-r-search o-50"></span>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #nenhumItem>
    <div class="alert alert-light text-center"
         role="alert">
      Nenhum pedido.
    </div>
  </ng-template>
</ng-container>