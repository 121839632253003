<!-- <p>conta {{ conta | json }}</p> -->
<ng-container *ngIf="!!conta && !!notificacoes()">
  <section class="chat">
    <div class="messages-chat">
      <ng-container *ngFor="let n of notificacoes(); trackBy:tid_not">
        <div class="message p-relative">
          <div [class.response]="n?.__response">
            <div class="photo"
                 style="background-image: url({{ conta?.__urlFoto }});"
                 *ngIf="!n?.__response">
              <div class="online"></div>
            </div>
            <p class="text">
              <strong>{{ n?.caption }}</strong>
              <br />
              {{ n?.msg }}
            </p>
          </div>
        </div>
        <p class="time"
           [class.response-time]="n?.__response"> {{ n?._criadoEm.toDate() | date: 'EE dd/MM/yyyy HH:mm' }}</p>
      </ng-container>
    </div>
  </section>

  <div *ngIf="!!paginator?.nextEnabled; else listaCarregada">
    <div class="d-flex mb-3">
      <button type="button"
              class="btn btn-light m-auto round m-auto"
              (click)="paginator.next()">
        <span class="o-20 icon-b-chevron-d me-2"></span>Carregar mais...
      </button>
    </div>
  </div>
  <ng-template #listaCarregada>
    <div class="d-flex mb-4">
      <div class="alert alert-light text-center flex-1"
           role="alert">
        {{ !!notificacoes()?.length ? 'Todas notificações exibidas.' : 'Nenhuma notificação.' }}
      </div>
    </div>
  </ng-template>
</ng-container>