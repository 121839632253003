//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  signal,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
//#endregion

//#region models
interface IReveal {
  state: boolean;
  icon: "icon-r-eye" | "icon-r-eye-blocked";
  type: "password" | "text";
};
import { CorFormValidation } from "../../../_ng/_models/_classes/form-validation";
import { ICorLoginRet } from "../../../_misc/_models/_interfaces/_rets/login";
import { FOCUS_TIMEOUT } from "../../../_misc/_models/consts";
//#endregion

//#region services
import { CorFbAuthService } from "../../../_ng/_services/fb-auth.service";
import {
  CoreService,
  CorStorageService,
  CorMessagesService
} from "../../../_ng/_services";
//#endregion

@Component({
  selector: "cor-mat-login-email-form",
  templateUrl: "login-email-form.component.html",
  styleUrls: ["login-email-form.component.scss"]
})
export class CorMatLoginEmailFormComponent {
  //#region inputs
  // buttonCaption
  @Input() buttonCaption: string = 'Entrar';
  // isMobile
  @Input() isMobile: boolean = false;
  // placeholders
  @Input() placeholders: {
    email: string;
    senha: string;
  } = {
      email: 'Seu e-mail',
      senha: 'Sua senha'
    };
  // showEsqueciSenha
  @Input() showEsqueciSenha: boolean = true;
  // showLembrarEmail
  @Input() showLembrarEmail: boolean = true;
  // showRegistrar
  @Input() showRegistrar: boolean = true;
  //#endregion

  //#region outputs
  // esqueciSenha$
  @Output() esqueciSenha$ = new EventEmitter<void>();
  // registrar$
  @Output() registrar$ = new EventEmitter<void>();
  // logged$
  @Output() logged$ = new EventEmitter<any>();
  //#endregion

  //#region publics
  emailSugerido = signal<string>('');
  fv = signal<CorFormValidation>(null);
  loginForm = signal<FormGroup>(null);
  reveal = signal<IReveal>(null);
  //#endregion

  //#region events
  emailFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #authServ = inject(CorFbAuthService);
  #corStorageServ = inject(CorStorageService);
  #fb = inject(FormBuilder);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#msgServ.send();
    this.emailSugerido.set(this.#corStorageServ.emailLoginGet() || '');
    this.loginForm.set(
      this.#fb.group({
        email: [this.emailSugerido(), [Validators.maxLength(60), Validators.required, Validators.email]],
        lembrar: [this.emailSugerido() !== ''],
        senha: ['', [Validators.minLength(6), Validators.required]],
      })
    );

    this.reveal.set(
      {
        state: false,
        icon: "icon-r-eye",
        type: "password"
      }
    );
    this.onRevealClicked();
  }

  ngAfterViewInit() {
    this.#focus();
  }
  //#endregion

  //#region Controls getters
  get emailRef(): AbstractControl { return this.loginForm()?.get('email'); }
  get lembrarRef(): AbstractControl { return this.loginForm()?.get('lembrar'); }
  get senhaRef(): AbstractControl { return this.loginForm()?.get('senha'); }
  //#endregion

  //#region functions
  #focus() {
    // console.log(this.isMobile);
    !this.isMobile && setTimeout(
      () => { this.emailFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  setEmail(email: string) {
    if (email) {
      this.emailRef.setValue(email);
    } // if
    this.lembrarRef.setValue(!!email);
  }
  //#endregion

  //#region methods
  onSubmitClick() {
    this.#msgServ.clear();
    const CREDENCIAIS: ICorLoginRet = {
      email: this.loginForm()?.value?.email || '',
      senha: this.loginForm()?.value?.senha || '',
      lembrar: !!this.loginForm()?.value?.lembrar
    };

    CoreService.swalLoaderEvent$.next({
      title: '',
      text: 'Procurando seu cadastro.'
    });
    const {
      email: EMAIL,
      senha: SENHA
    } = CREDENCIAIS;
    this.#authServ.loginWithEmailAndPassword(EMAIL, SENHA)
      .then(val => {
        this.logged$.emit(val);
        // console.log(val);
        CoreService.swalLoaderEvent$.next(null);
        if (CREDENCIAIS.lembrar) {
          this.#corStorageServ.emailLoginSet(CREDENCIAIS.email);
        } else {
          this.#corStorageServ.emailLoginRemove();
        } // else
        this.emailSugerido.set(this.#corStorageServ.emailLoginGet() || '');
        this.#focus();
      })
      .catch(error => {
        console.error(error);
        CoreService.swalLoaderEvent$.next(null);
        this.#msgServ.send('E-mail ou senha incorretos.', 'warning', 'dialog');
        this.#focus();
      });
  }

  onRevealClicked() {
    this.reveal.update(
      (prev: IReveal) => {
        const NEW_STATE: boolean = !prev?.state;
        return {
          state: !!NEW_STATE,
          icon: !!NEW_STATE ? "icon-r-eye" : "icon-r-eye-blocked",
          type: NEW_STATE ? "password" : "text"
        };
      }
    )
  }
  //#endregion
}
