<form novalidate
      #f="ngForm"
      (ngSubmit)="submit$.next()">

  <!-- <p>f.valid {{ f.valid }}</p> -->
  <!-- <p>repeated {{ repeated }}</p> -->
  <!-- <p>texto {{ texto }}</p> -->
  <!-- <p>repeat {{ repeat }}</p> -->

  <mat-form-field class="w-100 mb-2"
                  [appearance]="isMobile ? 'fill' : 'outline'">
    <!-- <mat-label></mat-label> -->
    <input type="text"
           matInput
           placeholder="Digite {{ texto }}"
           required
           [(ngModel)]="repeat"
           name="repeat"
           [focus]="textoFocusEvent$()"
           onReturn>
    <!-- <mat-hint *ngIf="fv.lerror(emailRef)">
      <span class="danger"> {{ fv.error(emailRef) }} </span>
    </mat-hint> -->
  </mat-form-field>

  <div class="d-flex">
    <div class="flex-1"></div>
    <button type="submit"
            class="pointer"
            mat-raised-button
            color="primary"
            [disabled]="!repeated()">
      CONFIRMAR
    </button>
  </div>
</form>