<!-- <p>offsetStorageKey {{ offsetStorageKey }}</p> -->
<!-- <p>offsetSel {{ offsetSel }}</p> -->

<div class="d-flex"
     [class.lg]="size === 'lg'">
  <button mat-stroked-button
          class="bs"
          [disabled]="!paginator?.firstEnabled"
          (click)="paginator?.first()">
    <span class="icon-b-first o-30"
          matTooltip="Primeiro">
    </span>
    <span *ngIf="!isMobile">
      &nbsp;Primeiro
    </span>
  </button>

  <button mat-stroked-button
          class="bm"
          [disabled]="!paginator?.previousEnabled"
          (click)="paginator?.prev()">
    <span class="icon-r-chevron-l o-30"
          matTooltip="Anterior">
    </span>
    <span *ngIf="!isMobile">
      &nbsp;Anterior
    </span>
  </button>

  <div class="page-item"
       *ngIf="offsetSel">
    <select class="form-select h-100"
            [(ngModel)]="offsetSel">
      <option value="1"
              *ngFor="let v of itensPorPag(); trackBy:tidn"
              [value]="v">
        {{ v }} itens
      </option>
    </select>
  </div>

  <button mat-stroked-button
          class="bm"
          [disabled]="!paginator?.nextEnabled"
          (click)="paginator?.next()">
    <span *ngIf="!isMobile">
      Próximo&nbsp;
    </span>
    <span class="icon-r-chevron-r o-30"
          matTooltip="Próximo">
    </span>
  </button>

  <button mat-stroked-button
          class="be"
          [disabled]="!paginator?.lastEnabled"
          (click)="paginator?.last()">
    <span *ngIf="!isMobile">
      Último&nbsp;
    </span>
    <span class="icon-b-last o-30"
          matTooltip="Último">
    </span>
  </button>
</div>