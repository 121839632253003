<!-- <p>item?.__solicitado {{ item?.__solicitado | json }}</p> -->
<!-- <div *ngIf="!!item">
  <span *ngIf="!item?.produto?.estoqueCritico">
    <span class="preco-off"
          *ngIf="item?.__solicitado?._valor?.desconto">
      {{ item?.__solicitado?._valor?.total | currency: "BRL":"symbol":"1.2-2" }}
    </span>
    <span class="preco-liquido">
      {{ item?.__solicitado?._valor?.liquido | currency: "BRL":"symbol":"1.2-2" }}
    </span>
  </span>
</div>  -->

<!-- <p>isSeparado {{ isSeparado }}</p> -->

<!-- <p>item?.__solicitado?.valor {{ item?.__solicitado?.valor | json }}</p> -->

<div *ngIf="!!item"
     class="text-center">
  <div *ngIf="isSeparado; else itemSolicitado">
    <span *ngIf="!!item?._separado?.valor?.total?.desc"
          class="o-50 line-thru">
      <small>
        {{ item?._separado?.valor?.total?.val | currency: "BRL":"symbol":"1.2-2" }}
      </small>
      <br *ngIf="!isInline">
    </span>
    <strong class="preco-liquido">
      {{ item?._separado?.valor?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}
    </strong>
  </div>
  <ng-template #itemSolicitado>
    <span *ngIf="!!item?.__solicitado?.valor?.total?.desc"
          class="o-50 line-thru">
      <small>
        {{ item?.__solicitado?.valor?.total?.val | currency: "BRL":"symbol":"1.2-2" }}
      </small>
      <br *ngIf="!isInline">
    </span>
    <strong class="preco-liquido">
      {{ item?.__solicitado?.valor?.total?.liq | currency: "BRL":"symbol":"1.2-2" }}
    </strong>
  </ng-template>
</div>
