//#region ng
import {
  Component,
  Input
} from "@angular/core";
//#endregion

//#region models
import { IProImgProdutoMap } from "../../../../_produtos/_misc/_models/_interfaces/_maps";
//#endregion

@Component({
  selector: "me-bs-imgs-slider",
  templateUrl: "imgs-slider.component.html",
  styleUrls: ["imgs-slider.component.scss"]
})
export class MeBsImgsSliderComponent {
  //#region inputs
  // imgs
  @Input() imgs: IProImgProdutoMap[] = [];
  //#endregion
}
