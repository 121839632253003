<ng-container *ngIf="vm$() | async as vm">
  <div class="container-fluid">
    <div class="d-flex">
      <div class="col search">
        <mat-form-field appearance="outline"
                        class="w-100">
          <mat-label>Nome</mat-label>
          <button mat-button
                  matPrefix
                  mat-icon-button>
            <mat-icon class="o-50">search</mat-icon>
          </button>
          <input matInput
                 type="text"
                 [focus]="searchFocusEvent$()"
                 [ngModel]="searchTermAction$() | async"
                 (ngModelChange)="searchTermAction$()?.next($event)">
          <button mat-button
                  *ngIf="!!(searchTermAction$() | async)"
                  matSuffix
                  mat-icon-button
                  (click)="searchTermAction$()?.next('')">
            <mat-icon class="o-50">close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.redes?.length">
        <cor-mat-pag-sel-offset size="lg"
                                [storageKey]="offsetStorageKey"
                                (change$)="pagItemsPerPageAction$().next($event)" />
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.redes?.length">
        <cor-mat-ngx-pagination-nav pagId="redes"
                                    size="lg"
                                    [isMobile]="true"
                                    (pageChange$)="pagPageAction$().next($event)" />
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.redes?.length">
        <cor-mat-viewstyle-sel size="lg"
                               [storageKey]="viewStyleStorageKey"
                               (change$)="viewStyleAction$().next($event)" />
      </div>
    </div>

    <!-- <p>vm {{ vm| json }}</p> -->
    <!-- <p>addBtnHint {{ addBtnHint| json }}</p> -->
    <!-- <p>checkBtnHint {{ checkBtnHint| json }}</p> -->
    <!-- <p>deleteBtnHint {{ deleteBtnHint| json }}</p> -->
    <!-- <p>editBtnHint {{ editBtnHint| json }}</p> -->
    <!-- <p>searchBtnHint {{ searchBtnHint| json }}</p> -->
    <!-- <p>hasButtons() {{ hasButtons()| json }}</p> -->

    <div class="row">
      <div class="col">
        <div class="w-cards redes"
             *ngIf="vm?.viewStyle === 'cards'; else viewTable">
          <div
               *ngFor="let r of (vm?.redes | paginate: { id: 'redes', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: vm?.redes?.length } | redesLfixes) | async; trackBy:tid_red">
            <me-mat-rede-card [rede]="r"
                              [showTheme]="showTheme"
                              [addBtnHint]="addBtnHint"
                              [checkBtnHint]="checkBtnHint"
                              [deleteBtnHint]="deleteBtnHint"
                              [editBtnHint]="editBtnHint"
                              [searchBtnHint]="searchBtnHint"
                              (add$)="add$.emit($event)"
                              (check$)="check$.emit($event)"
                              (delete$)="delete$.emit($event)"
                              (edit$)="edit$?.emit($event)"
                              (search$)="search$.emit($event)" />
          </div>
        </div>
        <ng-template #viewTable>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>

                <th scope="col"
                    class="text-center">
                  Ativo
                </th>

                <th scope="col">Nome</th>

                <th scope="col"
                    class="text-center"
                    *ngIf="!!showTheme">
                  Tema
                </th>

                <th scope="col"
                    *ngIf="hasButtons()">
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let r of (vm?.redes | paginate: { id: 'redes', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: vm?.redes?.length } | redesLfixes) | async; trackBy:tid_red"
                  [class.off]="!r?.ativo?._status">
                <td>
                  <img [src]="r?.__logo"
                       alt=""
                       class="img-fluid">
                </td>

                <td class="text-center">
                  <cor-html-status-checkbox [status]="r?.ativo?._status" />
                </td>

                <td>
                  {{ r?.nome }}<br>
                </td>

                <td *ngIf="!!showTheme">
                  <div class="d-flex">
                    <div class="m-auto">
                      <div class="color-sample bg-{{r?.tema}}"></div>
                    </div>
                  </div>
                </td>

                <td *ngIf="hasButtons()">
                  <div class="d-flex justify-content-around">
                    <button mat-icon-button
                            [matTooltip]="addBtnHint"
                            (click)="add$?.emit(r)"
                            *ngIf="!!addBtnHint">
                      <mat-icon>add</mat-icon>
                    </button>

                    <button mat-icon-button
                            [matTooltip]="checkBtnHint"
                            (click)="check$?.emit(r)"
                            *ngIf="!!checkBtnHint">
                      <mat-icon>check</mat-icon>
                    </button>


                    <button mat-icon-button
                            [matTooltip]="searchBtnHint"
                            (click)="search$.emit(r)"
                            *ngIf="!!searchBtnHint">
                      <mat-icon>search</mat-icon>
                    </button>

                    <button mat-icon-button
                            [matTooltip]="editBtnHint"
                            (click)="edit$?.emit(r)"
                            *ngIf="!!editBtnHint">
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-icon-button
                            [matTooltip]="deleteBtnHint"
                            (click)="delete$?.emit(r)"
                            *ngIf="!!deleteBtnHint">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>

        <div class="alert alert-light text-center"
             role="alert"
             *ngIf="!vm?.redes?.length">
          Nenhuma rede.
        </div>
      </div>
    </div>
  </div>
</ng-container>