//#region ng
import { Injectable } from '@angular/core';
//#endregion

//#region models
import { IDevice } from '../../_misc/_models/_interfaces/_cols';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  //#region misc
  fix(row: Partial<IDevice>): IDevice {
    return {
      // id
      id: row?.id || '',

      // system
      _criadoEm: row?._criadoEm || null,
      _data: row?._data || null,
      _push: {
        conta: row?._push?.conta || '',
        token: row?._push?.token || ''
      },
    };
  }

  fixes(docs: Partial<IDevice>[]): IDevice[] {
    return (docs || []).map((doc: Partial<IDevice>) => this.fix(doc));
  }
  //#endregion
}